// import React, { useState, useEffect, useRef } from 'react';
// import { TextField, Button, Typography, Container, Paper, Box, FormControl, InputLabel, Select, MenuItem, Snackbar, Rating, Chip, Modal, LinearProgress } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Confetti from 'react-confetti';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate, useLocation } from 'react-router-dom';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

// const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     borderRadius: '8px',
//     boxShadow: 24,
//     p: 4,
// };

// const AddBar = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         tags: [],
//         schedule: []  // New field for storing the schedule
//     });
//     const [cities, setCities] = useState([]);
//     const [states, setStates] = useState([]);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [currentModal, setCurrentModal] = useState(1);
//     const [showConfetti, setShowConfetti] = useState(false);
//     const [showLoginModal, setShowLoginModal] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const quillRef = useRef(null);

//     const tags = [
//         "Kava 🌴", "Kratom 🌿", "WiFi 📶", "WFH friendly 💻", "LGBTQ friendly 🏳️‍🌈",
//         "Matcha tea 🍵", "Cold brew ☕️", "Dark 🌙", "Light 🌞", "Vegan options 🥗",
//         "Live music 🎵", "Board games 🎲", "Pet friendly 🐾", "Outdoor seating 🌳", "Open late 🌙"
//     ];

//     const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

//     useEffect(() => {
//         fetchStates();
//     }, []);

//     const fetchStates = async () => {
//         try {
//             console.log('Fetching states from Supabase');
//             const { data, error } = await supabase.from('bars').select('state');
//             if (error) throw error;

//             const uniqueStates = [...new Set(data.map(item => item.state).filter(Boolean))];
//             setStates(uniqueStates);
//         } catch (error) {
//             console.error('Error fetching states:', error);
//             setSnackbarMessage('Failed to load states');
//             setSnackbarOpen(true);
//         }
//     };

//     const fetchCities = async (state) => {
//         try {
//             console.log('Fetching cities from Supabase');
//             const { data, error } = await supabase
//                 .from('bars')
//                 .select('city')
//                 .eq('state', state);

//             if (error) throw error;

//             const uniqueCities = [...new Set(data.map(item => item.city).filter(Boolean))];
//             setCities(uniqueCities);
//         } catch (error) {
//             console.error('Error fetching cities:', error);
//             setSnackbarMessage('Failed to load cities');
//             setSnackbarOpen(true);
//         }
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         console.log(`Input change detected - ${name}:`, value);
//         setFormData(prev => ({ ...prev, [name]: value }));
//         if (name === 'state') {
//             fetchCities(value);
//         }
//     };

//     const handleReviewChange = () => {
//         // This function is now empty as we're not updating on every change
//     };

//     const handleTagToggle = (tag) => {
//         console.log(`Tag toggle - ${tag}`);
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     };

//     const handleScheduleChange = (day, openingTime, closingTime) => {
//         setFormData(prev => {
//             const newSchedule = [...prev.schedule];
//             newSchedule[day] = `${daysOfWeek[day]}: ${openingTime} – ${closingTime}`;
//             return { ...prev, schedule: newSchedule };
//         });
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         // Check file size
//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             console.error(`File is too large: ${file.size / (1024 * 1024)} MB. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             console.log('Uploading file:');
//             console.log(`- Original Filename: ${file.name}`);
//             console.log(`- File Extension: ${fileExt}`);
//             console.log(`- Generated Filename: ${fileName}`);
//             console.log(`- Uploading to path: ${filePath}`);

//             // Upload the file to Supabase storage
//             const { data: uploadData, error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) {
//                 console.error('Error uploading store image:', uploadError);
//                 setSnackbarMessage('Error uploading image. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             console.log('Upload data:', uploadData);
//             console.log('File uploaded successfully.');
//             console.log('Retrieving public URL for store image');

//             // Retrieve the public URL for the uploaded file
//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) {
//                 console.error('Error getting public URL for store image:', urlError);
//                 setSnackbarMessage('Error retrieving image URL. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             if (!publicUrlData || !publicUrlData.publicUrl) {
//                 console.error('Public URL not found for the uploaded image.');
//                 setSnackbarMessage('Unable to retrieve image URL.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const publicUrl = publicUrlData.publicUrl;
//             console.log('Store image uploaded successfully. URL:', publicUrl);

//             // Update the form data with the public URL of the uploaded image
//             setFormData((prev) => ({ ...prev, store_image: publicUrl }));
//             setLoading(false);
//         } catch (error) {
//             console.error('Unexpected error during image upload:', error);
//             setSnackbarMessage('Unexpected error occurred during image upload.');
//             setSnackbarOpen(true);
//             setLoading(false);
//         }
//     };

//     const handleNext = () => {
//         console.log('Navigating to the next step');
//         setCurrentModal(prev => prev + 1);
//     };

//     const handleBack = () => {
//         console.log('Navigating to the previous step');
//         setCurrentModal(prev => prev - 1);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Submitting bar form data:', formData);

//         console.log('Getting current session from Supabase');
//         const { data: { session }, error: sessionError } = await supabase.auth.getSession();
//         if (sessionError || !session) {
//             console.error('Error: No active session found or error retrieving session.', sessionError);
//             setSnackbarMessage('Please log in to submit a new Kava Bar.');
//             setSnackbarOpen(true);
//             setShowLoginModal(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             console.log('Inserting bar data into Supabase:', formData);
//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([{
//                     ...formData,
//                     addedBy: session.user.id
//                 }])
//                 .select();

//             if (error) throw error;

//             console.log('Bar added successfully:', data);

//             setSnackbarMessage('🎉 Bar added successfully! 🎉');
//             setSnackbarOpen(true);

//             if (data && data[0] && data[0].id) {
//                 console.log('Navigating to new bar page with ID:', data[0].id);
//                 navigate(`/bars/${data[0].id}`);

//                 console.log('Incrementing addedBarCount for the user');
//                 const { data: profileData, error: profileError } = await supabase
//                     .from('profiles')
//                     .select('addedBarCount')
//                     .eq('id', session.user.id)
//                     .single();

//                 if (profileError) {
//                     console.error('Error fetching user profile:', profileError);
//                     setSnackbarMessage('Error updating user profile.');
//                     setSnackbarOpen(true);
//                 } else {
//                     const newAddedBarCount = (profileData.addedBarCount || 0) + 1;

//                     console.log('Updating user profile with new addedBarCount:', newAddedBarCount);
//                     const { error: updateProfileError } = await supabase
//                         .from('profiles')
//                         .upsert({
//                             id: session.user.id,
//                             email: session.user.email,
//                             addedBarCount: newAddedBarCount
//                         }, {
//                             onConflict: ['id'],
//                             returning: 'minimal',
//                         });

//                     if (updateProfileError) {
//                         console.error('Error updating user profile:', updateProfileError);
//                         setSnackbarMessage('Error updating user profile.');
//                         setSnackbarOpen(true);
//                     }
//                 }
//             } else {
//                 console.error('No bar ID returned after insertion');
//                 setSnackbarMessage('Bar added, but couldn\'t navigate to its page');
//                 setSnackbarOpen(true);
//             }

//             resetForm();
//         } catch (error) {
//             console.error('Error during submission:', error);
//             setSnackbarMessage('Error submitting form. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const resetForm = () => {
//         console.log('Resetting form data');
//         setFormData({
//             name: '',
//             address: '',
//             website: '',
//             rating: 0,
//             reviews: '',
//             phone_number: '',
//             city: '',
//             state: '',
//             store_image: '',
//             tags: [],
//             schedule: []  // Resetting the schedule array
//         });
//         setCurrentModal(1);
//     };

//     const handleGoogleLogin = async () => {
//         try {
//             console.log('Initiating Google login');
//             const { error } = await supabase.auth.signInWithOAuth({
//                 provider: 'google',
//             });

//             if (error) {
//                 console.error('Error logging in with Google:', error);
//                 setSnackbarMessage('Error logging in. Please try again.');
//                 setSnackbarOpen(true);
//             } else {
//                 console.log('Google login successful, navigating to /add');
//                 setShowLoginModal(false);
//                 navigate('/add', { state: { formData } }); // Preserving form data after login
//             }
//         } catch (error) {
//             console.error('Error during Google login:', error);
//             setSnackbarMessage('Error logging in. Please try again.');
//             setSnackbarOpen(true);
//         }
//     };

//     useEffect(() => {
//         const savedFormData = location.state?.formData;
//         if (savedFormData) {
//             console.log('Restoring form data after login:', savedFormData);
//             setFormData(savedFormData);
//         }
//     }, [location.state]);

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>
//                         Add New Kava Bar 🍹
//                     </Typography>

//                     {showConfetti && <Confetti />}
//                     {loading && <LinearProgress />}

//                     <Modal open={currentModal === 1} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Kava Bar Name 🎉</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Kava Bar Name"
//                                 variant="outlined"
//                                 margin="normal"
//                                 required
//                                 name="name"
//                                 value={formData.name}
//                                 onChange={handleInputChange}
//                             />
//                             <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 2} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Review 📝</Typography>
//                             <ReactQuill
//                                 ref={quillRef}
//                                 theme="snow"
//                                 placeholder="Write a review..."
//                                 style={{ height: '200px', marginBottom: '40px' }}
//                                 onChange={handleReviewChange}
//                             />
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 3} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Address 🏠</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Address"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="address"
//                                 value={formData.address}
//                                 onChange={handleInputChange}
//                             />
//                             <FormControl fullWidth margin="normal">
//                                 <InputLabel>State</InputLabel>
//                                 <Select
//                                     value={formData.state}
//                                     label="State"
//                                     name="state"
//                                     onChange={handleInputChange}
//                                 >
//                                     {states.map((state, index) => (
//                                         <MenuItem key={index} value={state}>{state}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             <FormControl fullWidth margin="normal">
//                                 <InputLabel>City</InputLabel>
//                                 <Select
//                                     value={formData.city}
//                                     label="City"
//                                     name="city"
//                                     onChange={handleInputChange}
//                                 >
//                                     {cities.map((city, index) => (
//                                         <MenuItem key={index} value={city}>{city}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 4} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Details and Opening Hours 📅</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Website (optional)"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="website"
//                                 value={formData.website}
//                                 onChange={handleInputChange}
//                             />
//                             <TextField
//                                 fullWidth
//                                 label="Phone Number (optional)"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="phone_number"
//                                 value={formData.phone_number}
//                                 onChange={handleInputChange}
//                             />
//                             <Box sx={{ mt: 2, mb: 2 }}>
//                                 <Typography gutterBottom>Tags</Typography>
//                                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                                     {tags.map((tag) => (
//                                         <Chip
//                                             key={tag}
//                                             label={tag}
//                                             onClick={() => handleTagToggle(tag)}
//                                             color={formData.tags.includes(tag) ? "primary" : "default"}
//                                         />
//                                     ))}
//                                 </Box>
//                             </Box>
//                             <Box sx={{ mt: 2, mb: 2 }}>
//                                 <Typography component="legend">Rating</Typography>
//                                 <Rating
//                                     name="rating"
//                                     value={formData.rating}
//                                     onChange={(event, newValue) => {
//                                         setFormData(prev => ({ ...prev, rating: newValue }));
//                                     }}
//                                 />
//                             </Box>
//                             {daysOfWeek.map((day, index) => (
//                                 <Box key={index} sx={{ mt: 2 }}>
//                                     <Typography>{day}</Typography>
//                                     <TimePicker
//                                         label="Opening Time"
//                                         value={formData.schedule[index]?.openingTime || null}
//                                         onChange={(newValue) => {
//                                             handleScheduleChange(index, newValue, formData.schedule[index]?.closingTime);
//                                         }}
//                                     />
//                                     <TimePicker
//                                         label="Closing Time"
//                                         value={formData.schedule[index]?.closingTime || null}
//                                         onChange={(newValue) => {
//                                             handleScheduleChange(index, formData.schedule[index]?.openingTime, newValue);
//                                         }}
//                                     />
//                                 </Box>
//                             ))}
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 5} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Upload Store Image 🖼️</Typography>
//                             <Button variant="contained" component="label" startIcon={<UploadIcon />}>
//                                 Upload
//                                 <input type="file" hidden onChange={handleImageUpload} />
//                             </Button>
//                             {formData.store_image && (
//                                 <Box sx={{ mt: 2 }}>
//                                     <Typography>Uploaded Image URL: {formData.store_image}</Typography>
//                                 </Box>
//                             )}
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleSubmit} variant="contained">Submit 🎉</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     {/* Login Modal */}
//                     <Modal open={showLoginModal} onClose={() => setShowLoginModal(false)}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Login Required</Typography>
//                             <Typography>Please log in to submit a new Kava Bar.</Typography>
//                             <Button
//                                 variant="contained"
//                                 onClick={handleGoogleLogin}
//                                 sx={{ mt: 2 }}
//                             >
//                                 Login with Google
//                             </Button>
//                         </Box>
//                     </Modal>
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(false)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// export default AddBar;

//////////////////////////////////WORKING IMAGE CODE//////////////////////////////////////
// import React, { useState } from 'react';
// import { TextField, Button, Container, Paper, Box, Snackbar, LinearProgress } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { createClient } from '@supabase/supabase-js';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

// const AddBar = () => {
//     const [formData, setFormData] = useState({ name: '', store_image: '' });
//     const [loading, setLoading] = useState(false);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prev) => ({ ...prev, [name]: value }));
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { data: uploadData, error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) {
//                 setSnackbarMessage('Error uploading image. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) {
//                 setSnackbarMessage('Error retrieving image URL. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const publicUrl = publicUrlData.publicUrl;
//             setFormData((prev) => ({ ...prev, store_image: publicUrl }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             setSnackbarMessage('Unexpected error occurred during image upload.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleSubmit = async () => {
//         if (!formData.name || !formData.store_image) {
//             setSnackbarMessage('Please provide both bar name and upload an image.');
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([{ ...formData }]);

//             if (error) {
//                 setSnackbarMessage('Error adding bar. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             setSnackbarMessage('Bar added successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             setSnackbarMessage('Unexpected error occurred while adding the bar.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <Container maxWidth="sm" sx={{ py: 6 }}>
//             <Paper elevation={3} sx={{ p: 4 }}>
//                 <Box sx={{ mb: 4 }}>
//                     <TextField
//                         fullWidth
//                         label="Kava Bar Name"
//                         variant="outlined"
//                         margin="normal"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                     />
//                 </Box>
//                 <Box sx={{ mb: 4 }}>
//                     <Button variant="contained" component="label" startIcon={<UploadIcon />}>
//                         Upload Bar Photo
//                         <input type="file" hidden onChange={handleImageUpload} />
//                     </Button>
//                     {loading && <LinearProgress />}
//                 </Box>
//                 {formData.store_image && (
//                     <Box sx={{ mt: 2 }}>
//                         <img src={formData.store_image} alt="Bar" style={{ width: '100%' }} />
//                     </Box>
//                 )}
//                 <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
//                     <Button variant="contained" onClick={handleSubmit}>
//                         Submit Bar
//                     </Button>
//                 </Box>
//             </Paper>
//             <Snackbar
//                 open={snackbarOpen}
//                 autoHideDuration={6000}
//                 onClose={() => setSnackbarOpen(false)}
//                 message={snackbarMessage}
//             />
//         </Container>
//     );
// };

// export default AddBar;
//////////////////////////////////////////////////

// import React, { useState, useEffect, useRef } from 'react';
// import { TextField, Button, Typography, Container, Paper, Box, FormControl, InputLabel, Select, MenuItem, Snackbar, Rating, Chip, Modal, LinearProgress } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Confetti from 'react-confetti';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate, useLocation } from 'react-router-dom';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

// const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     borderRadius: '8px',
//     boxShadow: 24,
//     p: 4,
// };

// const AddBar = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         tags: [],
//         opening_hours: []
//     });
//     const [cities, setCities] = useState([]);
//     const [states, setStates] = useState([]);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [currentModal, setCurrentModal] = useState(1);
//     const [showConfetti, setShowConfetti] = useState(false);
//     const [showLoginModal, setShowLoginModal] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const quillRef = useRef(null);

//     const tags = [
//         "Kava 🌴", "Kratom 🌿", "WiFi 📶", "WFH friendly 💻", "LGBTQ friendly 🏳️‍🌈",
//         "Matcha tea 🍵", "Cold brew ☕️", "Dark 🌙", "Light 🌞", "Vegan options 🥗",
//         "Live music 🎵", "Board games 🎲", "Pet friendly 🐾", "Outdoor seating 🌳", "Open late 🌙"
//     ];

//     const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

//     useEffect(() => {
//         fetchStates();
//     }, []);

//     const fetchStates = async () => {
//         try {
//             const { data, error } = await supabase.from('bars').select('state');
//             if (error) throw error;

//             const uniqueStates = [...new Set(data.map(item => item.state).filter(Boolean))];
//             setStates(uniqueStates);
//         } catch (error) {
//             setSnackbarMessage('Failed to load states');
//             setSnackbarOpen(true);
//         }
//     };

//     const fetchCities = async (state) => {
//         try {
//             const { data, error } = await supabase
//                 .from('bars')
//                 .select('city')
//                 .eq('state', state);

//             if (error) throw error;

//             const uniqueCities = [...new Set(data.map(item => item.city).filter(Boolean))];
//             setCities(uniqueCities);
//         } catch (error) {
//             setSnackbarMessage('Failed to load cities');
//             setSnackbarOpen(true);
//         }
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//         if (name === 'state') {
//             fetchCities(value);
//         }
//     };

//     const handleReviewChange = (value) => {
//         setFormData(prev => ({ ...prev, reviews: value }));
//     };

//     const handleTagToggle = (tag) => {
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     };

//     const handleOpeningHoursChange = (day, openingTime, closingTime) => {
//         setFormData(prev => {
//             const newOpeningHours = [...prev.opening_hours];
//             newOpeningHours[day] = `${daysOfWeek[day]}: ${openingTime} – ${closingTime}`;
//             return { ...prev, opening_hours: newOpeningHours };
//         });
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { data: uploadData, error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) {
//                 setSnackbarMessage('Error uploading image. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) {
//                 setSnackbarMessage('Error retrieving image URL. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const publicUrl = publicUrlData.publicUrl;
//             setFormData((prev) => ({ ...prev, store_image: publicUrl }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             setSnackbarMessage('Unexpected error occurred during image upload.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleNext = () => {
//         setCurrentModal(prev => prev + 1);
//     };

//     const handleBack = () => {
//         setCurrentModal(prev => prev - 1);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         console.log('Submitting bar form data:', formData);

//         const { data: { session }, error: sessionError } = await supabase.auth.getSession();
//         if (sessionError || !session) {
//             setSnackbarMessage('Please log in to submit a new Kava Bar.');
//             setSnackbarOpen(true);
//             setShowLoginModal(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([{ ...formData, addedBy: session.user.id }])
//                 .select();

//             if (error) throw error;

//             setSnackbarMessage('🎉 Bar added successfully! 🎉');
//             setSnackbarOpen(true);

//             if (data && data[0] && data[0].id) {
//                 console.log('Bar added successfully with ID:', data[0].id);
//                 navigate(`/bars/${data[0].id}`);

//                 const { data: profileData, error: profileError } = await supabase
//                     .from('profiles')
//                     .select('addedBarCount')
//                     .eq('id', session.user.id)
//                     .single();

//                 if (profileError) {
//                     console.error('Error fetching user profile:', profileError);
//                     setSnackbarMessage('Error updating user profile.');
//                     setSnackbarOpen(true);
//                 } else {
//                     const newAddedBarCount = (profileData.addedBarCount || 0) + 1;

//                     const { error: updateProfileError } = await supabase
//                         .from('profiles')
//                         .upsert({
//                             id: session.user.id,
//                             email: session.user.email,
//                             addedBarCount: newAddedBarCount
//                         }, {
//                             onConflict: ['id'],
//                             returning: 'minimal',
//                         });

//                     if (updateProfileError) {
//                         console.error('Error updating user profile:', updateProfileError);
//                         setSnackbarMessage('Error updating user profile.');
//                         setSnackbarOpen(true);
//                     }
//                 }
//             } else {
//                 console.error('No bar ID returned after insertion');
//                 setSnackbarMessage('Bar added, but couldn\'t navigate to its page');
//                 setSnackbarOpen(true);
//             }

//             resetForm();
//         } catch (error) {
//             console.error('Error during form submission:', error);
//             setSnackbarMessage('Error submitting form. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const resetForm = () => {
//         setFormData({
//             name: '',
//             address: '',
//             website: '',
//             rating: 0,
//             reviews: '',
//             phone_number: '',
//             city: '',
//             state: '',
//             store_image: '',
//             tags: [],
//             opening_hours: []
//         });
//         setCurrentModal(1);
//     };

//     const handleGoogleLogin = async () => {
//         try {
//             const { error } = await supabase.auth.signInWithOAuth({
//                 provider: 'google',
//             });

//             if (error) {
//                 console.error('Error logging in with Google:', error);
//                 setSnackbarMessage('Error logging in. Please try again.');
//                 setSnackbarOpen(true);
//             } else {
//                 setShowLoginModal(false);
//                 navigate('/add', { state: { formData } });
//             }
//         } catch (error) {
//             console.error('Error during Google login:', error);
//             setSnackbarMessage('Error logging in. Please try again.');
//             setSnackbarOpen(true);
//         }
//     };

//     useEffect(() => {
//         const savedFormData = location.state?.formData;
//         if (savedFormData) {
//             setFormData(savedFormData);
//         }
//     }, [location.state]);

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>
//                         Add New Kava Bar 🍹
//                     </Typography>

//                     {showConfetti && <Confetti />}
//                     {loading && <LinearProgress />}

//                     <Modal open={currentModal === 1} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Kava Bar Name 🎉</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Kava Bar Name"
//                                 variant="outlined"
//                                 margin="normal"
//                                 required
//                                 name="name"
//                                 value={formData.name}
//                                 onChange={handleInputChange}
//                             />
//                             <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 2} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Review 📝</Typography>
//                             <ReactQuill
//                                 ref={quillRef}
//                                 theme="snow"
//                                 placeholder="Write a review..."
//                                 style={{ height: '200px', marginBottom: '40px' }}
//                                 onChange={handleReviewChange}
//                             />
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 3} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Address 🏠</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Address"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="address"
//                                 value={formData.address}
//                                 onChange={handleInputChange}
//                             />
//                             <FormControl fullWidth margin="normal">
//                                 <InputLabel>State</InputLabel>
//                                 <Select
//                                     value={formData.state}
//                                     label="State"
//                                     name="state"
//                                     onChange={handleInputChange}
//                                 >
//                                     {states.map((state, index) => (
//                                         <MenuItem key={index} value={state}>{state}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             <FormControl fullWidth margin="normal">
//                                 <InputLabel>City</InputLabel>
//                                 <Select
//                                     value={formData.city}
//                                     label="City"
//                                     name="city"
//                                     onChange={handleInputChange}
//                                 >
//                                     {cities.map((city, index) => (
//                                         <MenuItem key={index} value={city}>{city}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 4} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Details and Opening Hours 📅</Typography>
//                             <TextField
//                                 fullWidth
//                                 label="Website (optional)"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="website"
//                                 value={formData.website}
//                                 onChange={handleInputChange}
//                             />
//                             <TextField
//                                 fullWidth
//                                 label="Phone Number (optional)"
//                                 variant="outlined"
//                                 margin="normal"
//                                 name="phone_number"
//                                 value={formData.phone_number}
//                                 onChange={handleInputChange}
//                             />
//                             <Box sx={{ mt: 2, mb: 2 }}>
//                                 <Typography gutterBottom>Tags</Typography>
//                                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                                     {tags.map((tag) => (
//                                         <Chip
//                                             key={tag}
//                                             label={tag}
//                                             onClick={() => handleTagToggle(tag)}
//                                             color={formData.tags.includes(tag) ? "primary" : "default"}
//                                         />
//                                     ))}
//                                 </Box>
//                             </Box>
//                             <Box sx={{ mt: 2, mb: 2 }}>
//                                 <Typography component="legend">Rating</Typography>
//                                 <Rating
//                                     name="rating"
//                                     value={formData.rating}
//                                     onChange={(event, newValue) => {
//                                         setFormData(prev => ({ ...prev, rating: newValue }));
//                                     }}
//                                 />
//                             </Box>
//                             {daysOfWeek.map((day, index) => (
//                                 <Box key={index} sx={{ mt: 2 }}>
//                                     <Typography>{day}</Typography>
//                                     <TimePicker
//                                         label="Opening Time"
//                                         value={formData.opening_hours[index]?.openingTime || null}
//                                         onChange={(newValue) => {
//                                             handleOpeningHoursChange(index, newValue, formData.opening_hours[index]?.closingTime);
//                                         }}
//                                     />
//                                     <TimePicker
//                                         label="Closing Time"
//                                         value={formData.opening_hours[index]?.closingTime || null}
//                                         onChange={(newValue) => {
//                                             handleOpeningHoursChange(index, formData.opening_hours[index]?.openingTime, newValue);
//                                         }}
//                                     />
//                                 </Box>
//                             ))}
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button onClick={handleNext} variant="contained">Next 🎈</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     <Modal open={currentModal === 5} onClose={handleBack}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Upload Store Image 🖼️</Typography>
//                             <Button variant="contained" component="label" startIcon={<UploadIcon />}>
//                                 Choose Photo
//                                 <input type="file" hidden onChange={handleImageUpload} />
//                             </Button>
//                             {formData.store_image && (
//                                 <Box sx={{ mt: 2 }}>
//                                     <Typography>Uploaded Image URL: {formData.store_image}</Typography>
//                                 </Box>
//                             )}
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                 <Button onClick={handleBack}>Back ⬅️</Button>
//                                 <Button
//                                     variant="contained"
//                                     onClick={() => {
//                                         console.log('Photo submitted:', formData.store_image);
//                                         setSnackbarMessage('Photo submitted successfully!');
//                                         setSnackbarOpen(true);
//                                     }}
//                                 >
//                                     Submit Photo
//                                 </Button>
//                                 <Button onClick={handleSubmit} variant="contained">Submit 🎉</Button>
//                             </Box>
//                         </Box>
//                     </Modal>

//                     {/* Login Modal */}
//                     <Modal open={showLoginModal} onClose={() => setShowLoginModal(false)}>
//                         <Box sx={{ ...modalStyle }}>
//                             <Typography variant="h6" gutterBottom>Login Required</Typography>
//                             <Typography>Please log in to submit a new Kava Bar.</Typography>
//                             <Button
//                                 variant="contained"
//                                 onClick={handleGoogleLogin}
//                                 sx={{ mt: 2 }}
//                             >
//                                 Login with Google
//                             </Button>
//                         </Box>
//                     </Modal>
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(false)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// import React, { useState, useEffect } from 'react';
// import {
//     TextField, Button, Typography, Container, Paper, Box, FormControl,
//     InputLabel, Select, MenuItem, Snackbar, Rating, Chip, LinearProgress
// } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate } from 'react-router-dom';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

// const generateFiveDigitUUID = () => Math.floor(10000 + Math.random() * 90000).toString();

// const tags = [
//     "Kava 🥥", "Kratom 🍵", "Chill 😌", "Unique 🦄", "Hidden Gem 💎", "Friendly 😊",
//     "Music 🎵", "Relaxing 🧘", "Art 🎨", "CBD 🌿", "Board Games 🎲", "Pet Friendly 🐾",
//     "Outdoor Seating 🌳", "Open Late 🌙", "WiFi 📶", "Herbal 🌺", "Community 🤝", "Events 📅"
// ];

// const AddBar = () => {
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         opening_hours: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         local_image_path: '',
//         latitude: null,
//         longitude: null,
//         tags: [],
//         "generated_reviews.Mini Review": '',
//         "generated_reviews.Review": '',
//         "generated_reviews.Tags": '',
//         "generated_reviews.Bar Theme": '',
//         Bar_Vibe: '',
//         Menu_Highlights: '',
//         "generated_reviews.Special Accommodations": ''
//     });

//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [states, setStates] = useState([]);
//     const [cities, setCities] = useState([]);

//     useEffect(() => {
//         fetchStates();
//     }, []);

//     const fetchStates = async () => {
//         try {
//             const { data, error } = await supabase.from('bars').select('state');
//             if (error) throw error;
//             const uniqueStates = [...new Set(data.map(item => item.state).filter(Boolean))];
//             setStates(uniqueStates);
//         } catch (error) {
//             console.error('Error fetching states:', error);
//             setSnackbarMessage('Failed to load states');
//             setSnackbarOpen(true);
//         }
//     };

//     const fetchCities = async (state) => {
//         try {
//             const { data, error } = await supabase
//                 .from('bars')
//                 .select('city')
//                 .eq('state', state);
//             if (error) throw error;
//             const uniqueCities = [...new Set(data.map(item => item.city).filter(Boolean))];
//             setCities(uniqueCities);
//         } catch (error) {
//             console.error('Error fetching cities:', error);
//             setSnackbarMessage('Failed to load cities');
//             setSnackbarOpen(true);
//         }
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//         if (name === 'state') {
//             fetchCities(value);
//         }
//     };

//     const handleTagToggle = (tag) => {
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     };

//     const handleOpeningHoursChange = (openingTime, closingTime) => {
//         const formattedOpeningHours = `${openingTime?.format('hh:mm A')} - ${closingTime?.format('hh:mm A')}`;
//         setFormData(prev => ({ ...prev, opening_hours: formattedOpeningHours }));
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) throw uploadError;

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) throw urlError;

//             setFormData(prev => ({
//                 ...prev,
//                 store_image: publicUrlData.publicUrl,
//                 local_image_path: `images_2/${fileName}`
//             }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             console.error('Error during image upload:', error);
//             setSnackbarMessage('Error uploading image. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);

//         try {
//             const dataToSubmit = {
//                 ...formData,
//                 id: generateFiveDigitUUID(),
//                 tags: formData.tags.join(','),
//                 latitude: parseFloat(formData.latitude) || null,
//                 longitude: parseFloat(formData.longitude) || null,
//                 rating: parseFloat(formData.rating) || 0,
//             };

//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([dataToSubmit])
//                 .select();

//             if (error) throw error;

//             if (data && data[0] && data[0].id) {
//                 setSnackbarMessage('🎉 Bar added successfully! 🎉');
//                 setSnackbarOpen(true);
//                 navigate(`/kavabar/${data[0].id}`);
//             } else {
//                 throw new Error('No bar ID returned after insertion');
//             }
//         } catch (error) {
//             console.error('Error during form submission:', error);
//             setSnackbarMessage(error.message || 'Error submitting form. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>Add New Kava Bar 🍹</Typography>
//                     {loading && <LinearProgress />}
//                     <form onSubmit={handleSubmit}>
//                         <TextField
//                             fullWidth
//                             label="Kava Bar Name"
//                             variant="outlined"
//                             margin="normal"
//                             required
//                             name="name"
//                             value={formData.name}
//                             onChange={handleInputChange}
//                         />
//                         <FormControl fullWidth margin="normal">
//                             <InputLabel>State</InputLabel>
//                             <Select
//                                 value={formData.state}
//                                 label="State"
//                                 name="state"
//                                 onChange={handleInputChange}
//                                 required
//                             >
//                                 {states.map((state, index) => (
//                                     <MenuItem key={index} value={state}>{state}</MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                         <FormControl fullWidth margin="normal">
//                             <InputLabel>City</InputLabel>
//                             <Select
//                                 value={formData.city}
//                                 label="City"
//                                 name="city"
//                                 onChange={handleInputChange}
//                                 required
//                             >
//                                 {cities.map((city, index) => (
//                                     <MenuItem key={index} value={city}>{city}</MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                         <TextField
//                             fullWidth
//                             label="Address"
//                             variant="outlined"
//                             margin="normal"
//                             required
//                             name="address"
//                             value={formData.address}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Website"
//                             variant="outlined"
//                             margin="normal"
//                             name="website"
//                             value={formData.website}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Phone Number"
//                             variant="outlined"
//                             margin="normal"
//                             name="phone_number"
//                             value={formData.phone_number}
//                             onChange={handleInputChange}
//                         />
//                         <Box sx={{ mt: 2, mb: 2 }}>
//                             <Typography gutterBottom>Tags</Typography>
//                             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                                 {tags.map((tag) => (
//                                     <Chip
//                                         key={tag}
//                                         label={tag}
//                                         onClick={() => handleTagToggle(tag)}
//                                         color={formData.tags.includes(tag) ? "primary" : "default"}
//                                     />
//                                 ))}
//                             </Box>
//                         </Box>
//                         <Box sx={{ mt: 2, mb: 2 }}>
//                             <Typography component="legend">Rating</Typography>
//                             <Rating
//                                 name="rating"
//                                 value={formData.rating}
//                                 onChange={(event, newValue) => {
//                                     setFormData(prev => ({ ...prev, rating: newValue }));
//                                 }}
//                             />
//                         </Box>
//                         <Box sx={{ mt: 2 }}>
//                             <Typography>Opening Hours</Typography>
//                             <Box sx={{ display: 'flex', gap: 2 }}>
//                                 <TimePicker
//                                     label="Opening Time"
//                                     value={formData.opening_time ? dayjs(formData.opening_time, 'HH:mm') : null}
//                                     onChange={(newValue) => {
//                                         handleOpeningHoursChange(newValue, formData.closing_time);
//                                     }}
//                                 />
//                                 <TimePicker
//                                     label="Closing Time"
//                                     value={formData.closing_time ? dayjs(formData.closing_time, 'HH:mm') : null}
//                                     onChange={(newValue) => {
//                                         handleOpeningHoursChange(formData.opening_time, newValue);
//                                     }}
//                                 />
//                             </Box>
//                         </Box>
//                         <TextField
//                             fullWidth
//                             label="Latitude"
//                             variant="outlined"
//                             margin="normal"
//                             name="latitude"
//                             value={formData.latitude}
//                             onChange={handleInputChange}
//                             type="number"
//                         />
//                         <TextField
//                             fullWidth
//                             label="Longitude"
//                             variant="outlined"
//                             margin="normal"
//                             name="longitude"
//                             value={formData.longitude}
//                             onChange={handleInputChange}
//                             type="number"
//                         />
//                         <TextField
//                             fullWidth
//                             label="Mini Review"
//                             variant="outlined"
//                             margin="normal"
//                             multiline
//                             rows={2}
//                             name="generated_reviews.Mini Review"
//                             value={formData["generated_reviews.Mini Review"]}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Full Review"
//                             variant="outlined"
//                             margin="normal"
//                             multiline
//                             rows={4}
//                             name="generated_reviews.Review"
//                             value={formData["generated_reviews.Review"]}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Review Tags"
//                             variant="outlined"
//                             margin="normal"
//                             name="generated_reviews.Tags"
//                             value={formData["generated_reviews.Tags"]}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Bar Theme"
//                             variant="outlined"
//                             margin="normal"
//                             name="generated_reviews.Bar Theme"
//                             value={formData["generated_reviews.Bar Theme"]}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Bar Vibe"
//                             variant="outlined"
//                             margin="normal"
//                             name="Bar_Vibe"
//                             value={formData.Bar_Vibe}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Menu Highlights"
//                             variant="outlined"
//                             margin="normal"
//                             name="Menu_Highlights"
//                             value={formData.Menu_Highlights}
//                             onChange={handleInputChange}
//                         />
//                         <TextField
//                             fullWidth
//                             label="Special Accommodations"
//                             variant="outlined"
//                             margin="normal"
//                             name="generated_reviews.Special Accommodations"
//                             value={formData["generated_reviews.Special Accommodations"]}
//                             onChange={handleInputChange}
//                         />
//                         <Button
//                             variant="contained"
//                             component="label"
//                             startIcon={<UploadIcon />}
//                             sx={{ mt: 2 }}
//                         >
//                             Upload Store Image
//                             <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
//                         </Button>
//                         {formData.store_image && (
//                             <Box sx={{ mt: 2 }}>
//                                 <Typography>Uploaded Image:</Typography>
//                                 <img src={formData.store_image} alt="Uploaded store" style={{ maxWidth: '100%', maxHeight: '200px' }} />
//                             </Box>
//                         )}
//                         <Button
//                             type="submit"
//                             variant="contained"
//                             color="primary"
//                             fullWidth
//                             sx={{ mt: 3 }}
//                             disabled={loading}
//                         >
//                             Submit Kava Bar
//                         </Button>
//                     </form>
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(true)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// export default AddBar;
// import React, { useState, useEffect, useRef } from 'react';
// import {
//     TextField, Button, Typography, Container, Paper, Box, FormControl,
//     InputLabel, Select, MenuItem, Snackbar, Rating, Chip, LinearProgress,
//     Stepper, Step, StepLabel, StepContent
// } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate } from 'react-router-dom';
// import Map, { Marker, NavigationControl, GeolocateControl } from 'react-map-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';


// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
// const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

// const generateFiveDigitUUID = () => Math.floor(10000 + Math.random() * 90000).toString();

// const tags = [
//     "Kava 🥥", "Kratom 🍵", "Chill 😌", "Unique 🦄", "Hidden Gem 💎", "Friendly 😊",
//     "Music 🎵", "Relaxing 🧘", "Art 🎨", "CBD 🌿", "Board Games 🎲", "Pet Friendly 🐾",
//     "Outdoor Seating 🌳", "Open Late 🌙", "WiFi 📶", "Herbal 🌺", "Community 🤝", "Events 📅"
// ];

// const AddBar = () => {
//     const navigate = useNavigate();
//     const [activeStep, setActiveStep] = useState(0);
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         opening_hours: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         local_image_path: '',
//         latitude: null,
//         longitude: null,
//         tags: [],
//         "generated_reviews.Mini Review": '',
//         "generated_reviews.Review": '',
//         "generated_reviews.Tags": '',
//         "generated_reviews.Bar Theme": '',
//         Bar_Vibe: '',
//         Menu_Highlights: '',
//         "generated_reviews.Special Accommodations": ''
//     });

//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [viewport, setViewport] = useState({
//         latitude: 37.7749,
//         longitude: -122.4194,
//         zoom: 10
//     });
//     const mapRef = useRef(null);

//     useEffect(() => {
//         if (mapRef.current) {
//             const geocoder = new MapboxGeocoder({
//                 accessToken: MAPBOX_TOKEN,
//                 mapboxgl: mapRef.current.getMap(),
//             });

//             mapRef.current.getMap().addControl(geocoder);

//             geocoder.on('result', (e) => {
//                 const { result } = e;
//                 setFormData(prev => ({
//                     ...prev,
//                     address: result.place_name,
//                     latitude: result.center[1],
//                     longitude: result.center[0],
//                     city: result.context.find(c => c.id.startsWith('place'))?.text || '',
//                     state: result.context.find(c => c.id.startsWith('region'))?.text || '',
//                 }));
//                 setViewport({
//                     latitude: result.center[1],
//                     longitude: result.center[0],
//                     zoom: 14
//                 });
//             });

//             return () => {
//                 mapRef.current.getMap().removeControl(geocoder);
//             };
//         }
//     }, [mapRef]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//     };

//     const handleTagToggle = (tag) => {
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     };

//     const handleOpeningHoursChange = (openingTime, closingTime) => {
//         const formattedOpeningHours = `${openingTime?.format('hh:mm A')} - ${closingTime?.format('hh:mm A')}`;
//         setFormData(prev => ({ ...prev, opening_hours: formattedOpeningHours }));
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) throw uploadError;

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) throw urlError;

//             setFormData(prev => ({
//                 ...prev,
//                 store_image: publicUrlData.publicUrl,
//                 local_image_path: `images_2/${fileName}`
//             }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             console.error('Error during image upload:', error);
//             setSnackbarMessage('Error uploading image. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleMapClick = (event) => {
//         const [longitude, latitude] = event.lngLat;
//         setFormData(prev => ({
//             ...prev,
//             latitude,
//             longitude
//         }));
//         setViewport(prev => ({
//             ...prev,
//             latitude,
//             longitude
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);

//         try {
//             const dataToSubmit = {
//                 ...formData,
//                 id: generateFiveDigitUUID(),
//                 tags: formData.tags.join(','),
//                 latitude: parseFloat(formData.latitude) || null,
//                 longitude: parseFloat(formData.longitude) || null,
//                 rating: parseFloat(formData.rating) || 0,
//             };

//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([dataToSubmit])
//                 .select();

//             if (error) throw error;

//             if (data && data[0] && data[0].id) {
//                 setSnackbarMessage('🎉 Bar added successfully! 🎉');
//                 setSnackbarOpen(true);
//                 navigate(`/kavabar/${data[0].id}`);
//             } else {
//                 throw new Error('No bar ID returned after insertion');
//             }
//         } catch (error) {
//             console.error('Error during form submission:', error);
//             setSnackbarMessage(error.message || 'Error submitting form. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleNext = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     };

//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     };

//     const steps = [
//         {
//             label: 'Basic Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Kava Bar Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="name"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                     />
//                     <Box sx={{ mt: 2, mb: 2, height: 300 }}>
//                         <Typography gutterBottom>Select Location on Map</Typography>
//                         <Map
//                             ref={mapRef}
//                             {...viewport}
//                             onMove={evt => setViewport(evt.viewState)}
//                             style={{ width: '100%', height: '100%' }}
//                             mapStyle="mapbox://styles/mapbox/streets-v11"
//                             mapboxAccessToken={MAPBOX_TOKEN}
//                             onClick={handleMapClick}
//                         >
//                             {formData.latitude && formData.longitude && (
//                                 <Marker
//                                     latitude={formData.latitude}
//                                     longitude={formData.longitude}
//                                 >
//                                     <img src="./leafly.svg" alt="Marker" style={{ width: 24, height: 24 }} />
//                                 </Marker>
//                             )}
//                             <NavigationControl position="top-right" />
//                             <GeolocateControl
//                                 position="top-left"
//                                 trackUserLocation
//                                 onGeolocate={(e) => {
//                                     setFormData(prev => ({
//                                         ...prev,
//                                         latitude: e.coords.latitude,
//                                         longitude: e.coords.longitude
//                                     }));
//                                     setViewport(prev => ({
//                                         ...prev,
//                                         latitude: e.coords.latitude,
//                                         longitude: e.coords.longitude,
//                                         zoom: 14
//                                     }));
//                                 }}
//                             />
//                         </Map>
//                     </Box>
//                     <TextField
//                         fullWidth
//                         label="Address"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="address"
//                         value={formData.address}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="City"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="city"
//                         value={formData.city}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="State"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="state"
//                         value={formData.state}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Contact Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Website"
//                         variant="outlined"
//                         margin="normal"
//                         name="website"
//                         value={formData.website}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Phone Number"
//                         variant="outlined"
//                         margin="normal"
//                         name="phone_number"
//                         value={formData.phone_number}
//                         onChange={handleInputChange}
//                     />
//                     <Box sx={{ mt: 2 }}>
//                         <Typography>Opening Hours</Typography>
//                         <Box sx={{ display: 'flex', gap: 2 }}>
//                             <TimePicker
//                                 label="Opening Time"
//                                 value={formData.opening_time ? dayjs(formData.opening_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(newValue, formData.closing_time);
//                                 }}
//                             />
//                             <TimePicker
//                                 label="Closing Time"
//                                 value={formData.closing_time ? dayjs(formData.closing_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(formData.opening_time, newValue);
//                                 }}
//                             />
//                         </Box>
//                     </Box>
//                 </>
//             )
//         },
//         {
//             label: 'Bar Details',
//             content: (
//                 <>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography gutterBottom>Tags</Typography>
//                         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                             {tags.map((tag) => (
//                                 <Chip
//                                     key={tag}
//                                     label={tag}
//                                     onClick={() => handleTagToggle(tag)}
//                                     color={formData.tags.includes(tag) ? "primary" : "default"}
//                                 />
//                             ))}
//                         </Box>
//                     </Box>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography component="legend">Rating</Typography>
//                         <Rating
//                             name="rating"
//                             value={formData.rating}
//                             onChange={(event, newValue) => {
//                                 setFormData(prev => ({ ...prev, rating: newValue }));
//                             }}
//                         />
//                     </Box>
//                     <TextField
//                         fullWidth
//                         label="Bar Theme"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Bar Theme"
//                         value={formData["generated_reviews.Bar Theme"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bar Vibe"
//                         variant="outlined"
//                         margin="normal"
//                         name="Bar_Vibe"
//                         value={formData.Bar_Vibe}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Menu Highlights"
//                         variant="outlined"
//                         margin="normal"
//                         name="Menu_Highlights"
//                         value={formData.Menu_Highlights}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Special Accommodations"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Special Accommodations"
//                         value={formData["generated_reviews.Special Accommodations"]}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Reviews and Image',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Mini Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={2}
//                         name="generated_reviews.Mini Review"
//                         value={formData["generated_reviews.Mini Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={4}
//                         name="generated_reviews.Review"
//                         value={formData["generated_reviews.Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Review Tags"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Tags"
//                         value={formData["generated_reviews.Tags"]}
//                         onChange={handleInputChange}
//                     />
//                     <Button
//                         variant="contained"
//                         component="label"
//                         startIcon={<UploadIcon />}
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Store Image
//                         <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
//                     </Button>
//                     {formData.store_image && (
//                         <Box sx={{ mt: 2 }}>
//                             <Typography>Uploaded Image:</Typography>
//                             <img src={formData.store_image} alt="Uploaded store" style={{ maxWidth: '100%', maxHeight: '200px' }} />
//                         </Box>
//                     )}
//                 </>
//             )
//         }
//     ];

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>Add New Kava Bar 🍹</Typography>
//                     {loading && <LinearProgress />}
//                     <Stepper activeStep={activeStep} orientation="vertical">
//                         {steps.map((step, index) => (
//                             <Step key={step.label}>
//                                 <StepLabel
//                                     optional={
//                                         index === 3 ? (
//                                             <Typography variant="caption">Last step</Typography>
//                                         ) : null
//                                     }
//                                 >
//                                     {step.label}
//                                 </StepLabel>
//                                 <StepContent>
//                                     <form>
//                                         {step.content}
//                                         <Box sx={{ mb: 2 }}>
//                                             <div>
//                                                 <Button
//                                                     variant="contained"
//                                                     onClick={index === steps.length - 1 ? handleSubmit : handleNext}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     {index === steps.length - 1 ? 'Submit' : 'Continue'}
//                                                 </Button>
//                                                 <Button
//                                                     disabled={index === 0}
//                                                     onClick={handleBack}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     Back
//                                                 </Button>
//                                             </div>
//                                         </Box>
//                                     </form>
//                                 </StepContent>
//                             </Step>
//                         ))}
//                     </Stepper>
//                     {activeStep === steps.length && (
//                         <Paper square elevation={0} sx={{ p: 3 }}>
//                             <Typography>All steps completed - you&apos;re finished</Typography>
//                             <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
//                                 Add Another Bar
//                             </Button>
//                         </Paper>
//                     )}
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(false)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// export default AddBar;


// import React, { useState, useCallback } from 'react';
// import {
//     TextField, Button, Typography, Container, Paper, Box,
//     Snackbar, Rating, Chip, LinearProgress,
//     Stepper, Step, StepLabel, StepContent
// } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate } from 'react-router-dom';
// import Map, { Marker, NavigationControl, GeolocateControl } from 'react-map-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
// const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

// const generateFiveDigitUUID = () => Math.floor(10000 + Math.random() * 90000).toString();

// const tags = [
//     "Kava 🥥", "Kratom 🍵", "Chill 😌", "Unique 🦄", "Hidden Gem 💎", "Friendly 😊",
//     "Music 🎵", "Relaxing 🧘", "Art 🎨", "CBD 🌿", "Board Games 🎲", "Pet Friendly 🐾",
//     "Outdoor Seating 🌳", "Open Late 🌙", "WiFi 📶", "Herbal 🌺", "Community 🤝", "Events 📅"
// ];

// const AddBar = () => {
//     const navigate = useNavigate();
//     const [activeStep, setActiveStep] = useState(0);
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         opening_hours: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         local_image_path: '',
//         latitude: null,
//         longitude: null,
//         tags: [],
//         "generated_reviews.Mini Review": '',
//         "generated_reviews.Review": '',
//         "generated_reviews.Tags": '',
//         "generated_reviews.Bar Theme": '',
//         Bar_Vibe: '',
//         Menu_Highlights: '',
//         "generated_reviews.Special Accommodations": ''
//     });

//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [viewport, setViewport] = useState({
//         latitude: 37.7749,
//         longitude: -122.4194,
//         zoom: 10
//     });

//     const handleInputChange = useCallback((e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//     }, []);

//     const handleTagToggle = useCallback((tag) => {
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     }, []);

//     const handleOpeningHoursChange = useCallback((openingTime, closingTime) => {
//         const formattedOpeningHours = `${openingTime?.format('hh:mm A')} - ${closingTime?.format('hh:mm A')}`;
//         setFormData(prev => ({ ...prev, opening_hours: formattedOpeningHours }));
//     }, []);

//     const handleImageUpload = useCallback(async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) throw uploadError;

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) throw urlError;

//             setFormData(prev => ({
//                 ...prev,
//                 store_image: publicUrlData.publicUrl,
//                 local_image_path: `images_2/${fileName}`
//             }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             console.error('Error during image upload:', error);
//             setSnackbarMessage('Error uploading image. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     }, []);

//     const handleMapClick = useCallback((event) => {
//         const [longitude, latitude] = event.lngLat;
//         setFormData(prev => ({
//             ...prev,
//             latitude,
//             longitude
//         }));
//         setViewport(prev => ({
//             ...prev,
//             latitude,
//             longitude
//         }));
//     }, []);

//     const handleSubmit = useCallback(async (e) => {
//         e.preventDefault();
//         setLoading(true);

//         try {
//             const dataToSubmit = {
//                 ...formData,
//                 id: generateFiveDigitUUID(),
//                 tags: formData.tags.join(','),
//                 latitude: parseFloat(formData.latitude) || null,
//                 longitude: parseFloat(formData.longitude) || null,
//                 rating: parseFloat(formData.rating) || 0,
//             };

//             const { data, error } = await supabase
//                 .from('bars')
//                 .insert([dataToSubmit])
//                 .select();

//             if (error) throw error;

//             if (data && data[0] && data[0].id) {
//                 setSnackbarMessage('🎉 Bar added successfully! 🎉');
//                 setSnackbarOpen(true);
//                 navigate(`/kavabar/${data[0].id}`);
//             } else {
//                 throw new Error('No bar ID returned after insertion');
//             }
//         } catch (error) {
//             console.error('Error during form submission:', error);
//             setSnackbarMessage(error.message || 'Error submitting form. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     }, [formData, navigate]);

//     const handleNext = useCallback(() => {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     }, []);

//     const handleBack = useCallback(() => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     }, []);

//     const steps = [
//         {
//             label: 'Basic Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Kava Bar Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="name"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                     />
//                     <Box sx={{ mt: 2, mb: 2, height: 300 }}>
//                         <Typography gutterBottom>Select Location on Map</Typography>
//                         <Map
//                             {...viewport}
//                             onMove={evt => setViewport(evt.viewState)}
//                             style={{ width: '100%', height: '100%' }}
//                             mapStyle="mapbox://styles/mapbox/streets-v11"
//                             mapboxAccessToken={MAPBOX_TOKEN}
//                             onClick={handleMapClick}
//                         >
//                             {formData.latitude && formData.longitude && (
//                                 <Marker
//                                     latitude={formData.latitude}
//                                     longitude={formData.longitude}
//                                 >
//                                     <img src="./leafly.svg" alt="Marker" style={{ width: 24, height: 24 }} />
//                                 </Marker>
//                             )}
//                             <NavigationControl position="top-right" />
//                             <GeolocateControl
//                                 position="top-left"
//                                 trackUserLocation
//                                 onGeolocate={(e) => {
//                                     setFormData(prev => ({
//                                         ...prev,
//                                         latitude: e.coords.latitude,
//                                         longitude: e.coords.longitude
//                                     }));
//                                     setViewport(prev => ({
//                                         ...prev,
//                                         latitude: e.coords.latitude,
//                                         longitude: e.coords.longitude,
//                                         zoom: 14
//                                     }));
//                                 }}
//                             />
//                         </Map>
//                     </Box>
//                     <TextField
//                         fullWidth
//                         label="Address"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="address"
//                         value={formData.address}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="City"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="city"
//                         value={formData.city}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="State"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="state"
//                         value={formData.state}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Contact Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Website"
//                         variant="outlined"
//                         margin="normal"
//                         name="website"
//                         value={formData.website}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Phone Number"
//                         variant="outlined"
//                         margin="normal"
//                         name="phone_number"
//                         value={formData.phone_number}
//                         onChange={handleInputChange}
//                     />
//                     <Box sx={{ mt: 2 }}>
//                         <Typography>Opening Hours</Typography>
//                         <Box sx={{ display: 'flex', gap: 2 }}>
//                             <TimePicker
//                                 label="Opening Time"
//                                 value={formData.opening_time ? dayjs(formData.opening_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(newValue, formData.closing_time);
//                                 }}
//                             />
//                             <TimePicker
//                                 label="Closing Time"
//                                 value={formData.closing_time ? dayjs(formData.closing_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(formData.opening_time, newValue);
//                                 }}
//                             />
//                         </Box>
//                     </Box>
//                 </>
//             )
//         },
//         {
//             label: 'Bar Details',
//             content: (
//                 <>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography gutterBottom>Tags</Typography>
//                         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                             {tags.map((tag) => (
//                                 <Chip
//                                     key={tag}
//                                     label={tag}
//                                     onClick={() => handleTagToggle(tag)}
//                                     color={formData.tags.includes(tag) ? "primary" : "default"}
//                                 />
//                             ))}
//                         </Box>
//                     </Box>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography component="legend">Rating</Typography>
//                         <Rating
//                             name="rating"
//                             value={formData.rating}
//                             onChange={(event, newValue) => {
//                                 setFormData(prev => ({ ...prev, rating: newValue }));
//                             }}
//                         />
//                     </Box>
//                     <TextField
//                         fullWidth
//                         label="Bar Theme"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Bar Theme"
//                         value={formData["generated_reviews.Bar Theme"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bar Vibe"
//                         variant="outlined"
//                         margin="normal"
//                         name="Bar_Vibe"
//                         value={formData.Bar_Vibe}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Menu Highlights"
//                         variant="outlined"
//                         margin="normal"
//                         name="Menu_Highlights"
//                         value={formData.Menu_Highlights}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Special Accommodations"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Special Accommodations"
//                         value={formData["generated_reviews.Special Accommodations"]}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Reviews and Image',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Mini Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={2}
//                         name="generated_reviews.Mini Review"
//                         value={formData["generated_reviews.Mini Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={4}
//                         name="generated_reviews.Review"
//                         value={formData["generated_reviews.Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Review Tags"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Tags"
//                         value={formData["generated_reviews.Tags"]}
//                         onChange={handleInputChange}
//                     />
//                     <Button
//                         variant="contained"
//                         component="label"
//                         startIcon={<UploadIcon />}
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Store Image
//                         <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
//                     </Button>
//                     {formData.store_image && (
//                         <Box sx={{ mt: 2 }}>
//                             <Typography>Uploaded Image:</Typography>
//                             <img src={formData.store_image} alt="Uploaded store" style={{ maxWidth: '100%', maxHeight: '200px' }} />
//                         </Box>
//                     )}
//                 </>
//             )
//         },
//     ];

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>Add New Kava Bar 🍹</Typography>
//                     {loading && <LinearProgress />}
//                     <Stepper activeStep={activeStep} orientation="vertical">
//                         {steps.map((step, index) => (
//                             <Step key={step.label}>
//                                 <StepLabel
//                                     optional={
//                                         index === steps.length - 1 ? (
//                                             <Typography variant="caption">Last step</Typography>
//                                         ) : null
//                                     }
//                                 >
//                                     {step.label}
//                                 </StepLabel>
//                                 <StepContent>
//                                     <form>
//                                         {step.content}
//                                         <Box sx={{ mb: 2 }}>
//                                             <div>
//                                                 <Button
//                                                     variant="contained"
//                                                     onClick={index === steps.length - 1 ? handleSubmit : handleNext}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     {index === steps.length - 1 ? 'Submit' : 'Continue'}
//                                                 </Button>
//                                                 <Button
//                                                     disabled={index === 0}
//                                                     onClick={handleBack}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     Back
//                                                 </Button>
//                                             </div>
//                                         </Box>
//                                     </form>
//                                 </StepContent>
//                             </Step>
//                         ))}
//                     </Stepper>
//                     {activeStep === steps.length && (
//                         <Paper square elevation={0} sx={{ p: 3 }}>
//                             <Typography>All steps completed - you&apos;re finished</Typography>
//                             <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
//                                 Add Another Bar
//                             </Button>
//                         </Paper>
//                     )}
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(false)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// export default AddBar;
// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import {
//     TextField, Button, Typography, Container, Paper, Box,
//     Snackbar, Rating, Chip, LinearProgress,
//     Stepper, Step, StepLabel, StepContent
// } from '@mui/material';
// import { Upload as UploadIcon } from '@mui/icons-material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import { createClient } from '@supabase/supabase-js';
// import { useNavigate } from 'react-router-dom';
// import Map, { Marker, NavigationControl, GeolocateControl } from 'react-map-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
// const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

// const generateFiveDigitUUID = () => Math.floor(10000 + Math.random() * 90000).toString();

// const tags = [
//     "Kava 🥥", "Kratom 🍵", "Chill 😌", "Unique 🦄", "Hidden Gem 💎", "Friendly 😊",
//     "Music 🎵", "Relaxing 🧘", "Art 🎨", "CBD 🌿", "Board Games 🎲", "Pet Friendly 🐾",
//     "Outdoor Seating 🌳", "Open Late 🌙", "WiFi 📶", "Herbal 🌺", "Community 🤝", "Events 📅"
// ];

// // Map Component
// const MapComponent = ({ formData, setFormData, viewport, setViewport }) => {
//     const mapRef = useRef(null);
//     const [mapLoaded, setMapLoaded] = useState(false);

//     useEffect(() => {
//         if (mapRef.current) {
//             mapRef.current.on('load', () => {
//                 setMapLoaded(true);
//             });
//         }
//     }, []);

//     const handleMapClick = useCallback((event) => {
//         if (!mapLoaded || !event?.lngLat) {
//             console.error('Map not loaded or invalid lngLat');
//             return;
//         }

//         const { lngLat } = event;
//         if (Array.isArray(lngLat) && lngLat.length === 2) {
//             const [longitude, latitude] = lngLat;
//             setFormData(prev => ({
//                 ...prev,
//                 latitude,
//                 longitude
//             }));
//             setViewport(prev => ({
//                 ...prev,
//                 latitude,
//                 longitude
//             }));
//         } else {
//             console.error('Invalid lngLat value:', lngLat);
//         }
//     }, [mapLoaded, setFormData, setViewport]);

//     const handleGeolocate = useCallback((e) => {
//         setFormData(prev => ({
//             ...prev,
//             latitude: e.coords.latitude,
//             longitude: e.coords.longitude
//         }));
//         setViewport(prev => ({
//             ...prev,
//             latitude: e.coords.latitude,
//             longitude: e.coords.longitude,
//             zoom: 14
//         }));
//     }, [setFormData, setViewport]);

//     const handleLocateUser = useCallback(() => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 position => {
//                     const { latitude, longitude } = position.coords;
//                     setFormData(prev => ({
//                         ...prev,
//                         latitude,
//                         longitude
//                     }));
//                     setViewport(prev => ({
//                         ...prev,
//                         latitude,
//                         longitude,
//                         zoom: 14
//                     }));
//                 },
//                 error => {
//                     console.error('Error getting user location:', error);
//                 }
//             );
//         } else {
//             console.error('Geolocation is not supported by this browser.');
//         }
//     }, [setFormData, setViewport]);

//     const handleMapError = useCallback((error) => {
//         console.error('Mapbox Error:', error);
//     }, []);

//     return (
//         <Box sx={{ mt: 2, mb: 2, height: 300 }}>
//             <Typography gutterBottom>Select Location on Map</Typography>
//             <Button variant="contained" onClick={handleLocateUser} sx={{ mb: 2 }}>
//                 Use My Location
//             </Button>
//             <Map
//                 ref={mapRef}
//                 {...viewport}
//                 onMove={evt => setViewport(evt.viewState)}
//                 style={{ width: '100%', height: '100%' }}
//                 mapStyle="mapbox://styles/mapbox/streets-v11"
//                 mapboxAccessToken={MAPBOX_TOKEN}
//                 onClick={handleMapClick}
//                 onError={handleMapError}
//             >
//                 {mapLoaded && formData.latitude && formData.longitude && (
//                     <Marker
//                         latitude={formData.latitude}
//                         longitude={formData.longitude}
//                     >
//                         <img src="./leafly.svg" alt="Marker" style={{ width: 24, height: 24 }} />
//                     </Marker>
//                 )}
//                 <NavigationControl position="top-right" />
//                 <GeolocateControl
//                     position="top-left"
//                     trackUserLocation
//                     onGeolocate={handleGeolocate}
//                 />
//             </Map>
//         </Box>
//     );
// };

// // AddBar Component
// const AddBar = () => {
//     const navigate = useNavigate();
//     const [activeStep, setActiveStep] = useState(0);
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         website: '',
//         rating: 0,
//         reviews: '',
//         opening_hours: '',
//         phone_number: '',
//         city: '',
//         state: '',
//         store_image: '',
//         local_image_path: '',
//         latitude: null,
//         longitude: null,
//         tags: [],
//         "generated_reviews.Mini Review": '',
//         "generated_reviews.Review": '',
//         "generated_reviews.Tags": '',
//         "generated_reviews.Bar Theme": '',
//         Bar_Vibe: '',
//         Menu_Highlights: '',
//         "generated_reviews.Special Accommodations": ''
//     });

//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [viewport, setViewport] = useState({
//         latitude: 37.7749,
//         longitude: -122.4194,
//         zoom: 10
//     });
//     const [mapError, setMapError] = useState(null);

//     const handleInputChange = useCallback((e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//     }, []);

//     const handleTagToggle = useCallback((tag) => {
//         setFormData(prev => ({
//             ...prev,
//             tags: prev.tags.includes(tag)
//                 ? prev.tags.filter(t => t !== tag)
//                 : [...prev.tags, tag]
//         }));
//     }, []);

//     const handleOpeningHoursChange = useCallback((openingTime, closingTime) => {
//         const formattedOpeningHours = `${openingTime?.format('hh:mm A')} - ${closingTime?.format('hh:mm A')}`;
//         setFormData(prev => ({ ...prev, opening_hours: formattedOpeningHours }));
//     }, []);

//     const handleImageUpload = useCallback(async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_MB = 50;
//         const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `barphotos/${fileName}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('barphotos')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) throw uploadError;

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('barphotos')
//                 .getPublicUrl(filePath);

//             if (urlError) throw urlError;

//             setFormData(prev => ({
//                 ...prev,
//                 store_image: publicUrlData.publicUrl,
//                 local_image_path: `images_2/${fileName}`
//             }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             console.error('Error during image upload:', error);
//             setSnackbarMessage('Error uploading image. Please try again.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     }, []);

// const handleSubmit = useCallback(async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//         const dataToSubmit = {
//             ...formData,
//             id: generateFiveDigitUUID(),
//             tags: formData.tags.join(','),
//             latitude: parseFloat(formData.latitude) || null,
//             longitude: parseFloat(formData.longitude) || null,
//             rating: parseFloat(formData.rating) || 0,
//         };

//         const { data, error } = await supabase
//             .from('bars')
//             .insert([dataToSubmit])
//             .select();

//         if (error) throw error;

//         if (data && data[0] && data[0].id) {
//             setSnackbarMessage('🎉 Bar added successfully! 🎉');
//             setSnackbarOpen(true);
//             navigate(`/kavabar/${data[0].id}`);
//         } else {
//             throw new Error('No bar ID returned after insertion');
//         }
//     } catch (error) {
//         console.error('Error during form submission:', error);
//         setSnackbarMessage(error.message || 'Error submitting form. Please try again.');
//         setSnackbarOpen(true);
//     } finally {
//         setLoading(false);
//     }
// }, [formData, navigate]);

// const handleNext = useCallback(() => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
// }, []);

//     const handleBack = useCallback(() => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     }, []);

//     const handleMapError = useCallback((error) => {
//         console.error('Map Error:', error);
//         setMapError('An error occurred while loading the map. Please try refreshing the page.');
//     }, []);

//     const steps = [
//         {
//             label: 'Basic Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Kava Bar Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="name"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                     />
//                     {mapError ? (
//                         <Typography color="error">{mapError}</Typography>
//                     ) : (
//                         <ErrorBoundary onError={handleMapError}>
//                             <MapComponent
//                                 formData={formData}
//                                 setFormData={setFormData}
//                                 viewport={viewport}
//                                 setViewport={setViewport}
//                             />
//                         </ErrorBoundary>
//                     )}
//                     <TextField
//                         fullWidth
//                         label="Address"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="address"
//                         value={formData.address}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="City"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="city"
//                         value={formData.city}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="State"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="state"
//                         value={formData.state}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Contact Information',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Website"
//                         variant="outlined"
//                         margin="normal"
//                         name="website"
//                         value={formData.website}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Phone Number"
//                         variant="outlined"
//                         margin="normal"
//                         name="phone_number"
//                         value={formData.phone_number}
//                         onChange={handleInputChange}
//                     />
//                     <Box sx={{ mt: 2 }}>
//                         <Typography>Opening Hours</Typography>
//                         <Box sx={{ display: 'flex', gap: 2 }}>
//                             <TimePicker
//                                 label="Opening Time"
//                                 value={formData.opening_time ? dayjs(formData.opening_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(newValue, formData.closing_time);
//                                 }}
//                             />
//                             <TimePicker
//                                 label="Closing Time"
//                                 value={formData.closing_time ? dayjs(formData.closing_time, 'HH:mm') : null}
//                                 onChange={(newValue) => {
//                                     handleOpeningHoursChange(formData.opening_time, newValue);
//                                 }}
//                             />
//                         </Box>
//                     </Box>
//                 </>
//             )
//         },
//         {
//             label: 'Bar Details',
//             content: (
//                 <>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography gutterBottom>Tags</Typography>
//                         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                             {tags.map((tag) => (
//                                 <Chip
//                                     key={tag}
//                                     label={tag}
//                                     onClick={() => handleTagToggle(tag)}
//                                     color={formData.tags.includes(tag) ? "primary" : "default"}
//                                 />
//                             ))}
//                         </Box>
//                     </Box>
//                     <Box sx={{ mt: 2, mb: 2 }}>
//                         <Typography component="legend">Rating</Typography>
//                         <Rating
//                             name="rating"
//                             value={formData.rating}
//                             onChange={(event, newValue) => {
//                                 setFormData(prev => ({ ...prev, rating: newValue }));
//                             }}
//                         />
//                     </Box>
//                     <TextField
//                         fullWidth
//                         label="Bar Theme"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Bar Theme"
//                         value={formData["generated_reviews.Bar Theme"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bar Vibe"
//                         variant="outlined"
//                         margin="normal"
//                         name="Bar_Vibe"
//                         value={formData.Bar_Vibe}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Menu Highlights"
//                         variant="outlined"
//                         margin="normal"
//                         name="Menu_Highlights"
//                         value={formData.Menu_Highlights}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Special Accommodations"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Special Accommodations"
//                         value={formData["generated_reviews.Special Accommodations"]}
//                         onChange={handleInputChange}
//                     />
//                 </>
//             )
//         },
//         {
//             label: 'Reviews and Image',
//             content: (
//                 <>
//                     <TextField
//                         fullWidth
//                         label="Mini Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={2}
//                         name="generated_reviews.Mini Review"
//                         value={formData["generated_reviews.Mini Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Review"
//                         variant="outlined"
//                         margin="normal"
//                         multiline
//                         rows={4}
//                         name="generated_reviews.Review"
//                         value={formData["generated_reviews.Review"]}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Review Tags"
//                         variant="outlined"
//                         margin="normal"
//                         name="generated_reviews.Tags"
//                         value={formData["generated_reviews.Tags"]}
//                         onChange={handleInputChange}
//                     />
//                     <Button
//                         variant="contained"
//                         component="label"
//                         startIcon={<UploadIcon />}
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Store Image
//                         <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
//                     </Button>
//                     {formData.store_image && (
//                         <Box sx={{ mt: 2 }}>
//                             <Typography>Uploaded Image:</Typography>
//                             <img src={formData.store_image} alt="Uploaded store" style={{ maxWidth: '100%', maxHeight: '200px' }} />
//                         </Box>
//                     )}
//                 </>
//             )
//         },
//     ];

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Container maxWidth="md" sx={{ py: 6 }}>
//                 <Paper elevation={3} sx={{ p: 4 }}>
//                     <Typography variant="h5" gutterBottom>Add New Kava Bar 🍹</Typography>
//                     {loading && <LinearProgress />}
//                     <Stepper activeStep={activeStep} orientation="vertical">
//                         {steps.map((step, index) => (
//                             <Step key={step.label}>
//                                 <StepLabel
//                                     optional={
//                                         index === steps.length - 1 ? (
//                                             <Typography variant="caption">Last step</Typography>
//                                         ) : null
//                                     }
//                                 >
//                                     {step.label}
//                                 </StepLabel>
//                                 <StepContent>
//                                     <form>
//                                         {step.content}
//                                         <Box sx={{ mb: 2 }}>
//                                             <div>
//                                                 <Button
//                                                     variant="contained"
//                                                     onClick={index === steps.length - 1 ? handleSubmit : handleNext}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     {index === steps.length - 1 ? 'Submit' : 'Continue'}
//                                                 </Button>
//                                                 <Button
//                                                     disabled={index === 0}
//                                                     onClick={handleBack}
//                                                     sx={{ mt: 1, mr: 1 }}
//                                                 >
//                                                     Back
//                                                 </Button>
//                                             </div>
//                                         </Box>
//                                     </form>
//                                 </StepContent>
//                             </Step>
//                         ))}
//                     </Stepper>
//                     {activeStep === steps.length && (
//                         <Paper square elevation={0} sx={{ p: 3 }}>
//                             <Typography>All steps completed - you&apos;re finished</Typography>
//                             <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
//                                 Add Another Bar
//                             </Button>
//                         </Paper>
//                     )}
//                 </Paper>
//                 <Snackbar
//                     open={snackbarOpen}
//                     autoHideDuration={6000}
//                     onClose={() => setSnackbarOpen(false)}
//                     message={snackbarMessage}
//                 />
//             </Container>
//         </LocalizationProvider>
//     );
// };

// class ErrorBoundary extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = { hasError: false };
//     }

//     static getDerivedStateFromError(error) {
//         return { hasError: true };
//     }

//     componentDidCatch(error, errorInfo) {
//         this.props.onError(error, errorInfo);
//     }

//     render() {
//         if (this.state.hasError) {
//             return <Typography color="error">Something went wrong.</Typography>;
//         }

//         return this.props.children;
//     }
// }

// export default AddBar;

import React, { useState, useCallback } from 'react';
import {
    TextField, Button, Typography, Container, Paper, Box,
    Snackbar, Rating, Chip, LinearProgress,
    Stepper, Step, StepLabel, StepContent
} from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MapComponent from './MapComponent';  // Import your simpler MapComponent

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
const generateFiveDigitUUID = () => Math.floor(10000 + Math.random() * 90000).toString();

const tags = [
    "Kava 🥥", "Kratom 🍵", "Chill 😌", "Unique 🦄", "Hidden Gem 💎", "Friendly 😊",
    "Music 🎵", "Relaxing 🧘", "Art 🎨", "CBD 🌿", "Board Games 🎲", "Pet Friendly 🐾",
    "Outdoor Seating 🌳", "Open Late 🌙", "WiFi 📶", "Herbal 🌺", "Community 🤝", "Events 📅"
];

// AddBar Component
const AddBar = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        website: '',
        rating: 0,
        city: '',
        state: '',
        store_image: '',
        local_image_path: '',
        latitude: null,
        longitude: null,
        tags: [],
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleTagToggle = useCallback((tag) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.includes(tag)
                ? prev.tags.filter(t => t !== tag)
                : [...prev.tags, tag]
        }));
    }, []);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const MAX_FILE_SIZE_MB = 50;
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE_BYTES) {
            setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
            setSnackbarOpen(true);
            return;
        }

        try {
            setLoading(true);
            const fileExt = file.name.split('.').pop();
            const fileName = `${Date.now()}.${fileExt}`;
            const filePath = `barphotos/${fileName}`;

            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('barphotos')
                .upload(filePath, file, { upsert: true });

            if (uploadError) {
                setSnackbarMessage('Error uploading image. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const { data: publicUrlData, error: urlError } = supabase.storage
                .from('barphotos')
                .getPublicUrl(filePath);

            if (urlError) {
                setSnackbarMessage('Error retrieving image URL. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const publicUrl = publicUrlData.publicUrl;
            setFormData((prev) => ({ ...prev, store_image: publicUrl }));
            setSnackbarMessage('Image uploaded successfully!');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Unexpected error occurred during image upload.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const dataToSubmit = {
                ...formData,
                id: generateFiveDigitUUID(),
                tags: formData.tags.join(','),
                latitude: parseFloat(formData.latitude) || null,
                longitude: parseFloat(formData.longitude) || null,
                rating: parseFloat(formData.rating) || 0,
            };

            const { data, error } = await supabase
                .from('bars')
                .insert([dataToSubmit])
                .select();

            if (error) throw error;

            if (data && data[0] && data[0].id) {
                setSnackbarMessage('🎉 Bar added successfully! 🎉');
                setSnackbarOpen(true);
                navigate(`/kavabar/${data[0].id}`);
            } else {
                throw new Error('No bar ID returned after insertion');
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            setSnackbarMessage(error.message || 'Error submitting form. Please try again.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    }, [formData, navigate]);

    const steps = [
        {
            label: 'Basic Information',
            content: (
                <>
                    <TextField
                        fullWidth
                        label="Kava Bar Name"
                        variant="outlined"
                        margin="normal"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <MapComponent
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <TextField
                        fullWidth
                        label="Address"
                        variant="outlined"
                        margin="normal"
                        required
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        label="City"
                        variant="outlined"
                        margin="normal"
                        required
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        label="State"
                        variant="outlined"
                        margin="normal"
                        required
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                    />
                </>
            )
        },
        {
            label: 'Contact Information',
            content: (
                <>
                    <TextField
                        fullWidth
                        label="Website"
                        variant="outlined"
                        margin="normal"
                        name="website"
                        value={formData.website}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        margin="normal"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                    />
                </>
            )
        },
        {
            label: 'Bar Details',
            content: (
                <>
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography gutterBottom>Tags</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {tags.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onClick={() => handleTagToggle(tag)}
                                    color={formData.tags.includes(tag) ? "primary" : "default"}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography component="legend">Rating</Typography>
                        <Rating
                            name="rating"
                            value={formData.rating}
                            onChange={(event, newValue) => {
                                setFormData(prev => ({ ...prev, rating: newValue }));
                            }}
                        />
                    </Box>
                </>
            )
        },
        {
            label: 'Upload Image',
            content: (
                <>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<UploadIcon />}
                        sx={{ mt: 2 }}
                    >
                        Upload Store Image
                        <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
                    </Button>
                    {formData.store_image && (
                        <Box sx={{ mt: 2 }}>
                            <Typography>Uploaded Image:</Typography>
                            <img src={formData.store_image} alt="Uploaded store" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </Box>
                    )}
                </>
            )
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container maxWidth="md" sx={{ py: 6 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Typography variant="h5" gutterBottom>Add New Kava Bar 🍹</Typography>
                    {loading && <LinearProgress />}
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                                <StepContent>
                                    {step.content}
                                    <Box sx={{ mb: 2 }}>
                                        <Button
                                            variant="contained"
                                            onClick={index === steps.length - 1 ? handleSubmit : () => setActiveStep(index + 1)}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Submit' : 'Continue'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={() => setActiveStep(index - 1)}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you're finished</Typography>
                            <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
                                Add Another Bar
                            </Button>
                        </Paper>
                    )}
                </Paper>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                />
            </Container>
        </LocalizationProvider>
    );
};

export default AddBar;










