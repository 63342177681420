// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   AppBar, Toolbar, Box, Avatar, Typography, Container, Paper, Button, List, ListItem,
//   ListItemAvatar, ListItemText, IconButton, CssBaseline, TextField, ThemeProvider, createTheme, FormControlLabel, Checkbox, Chip, Rating, Card, CardMedia, CardContent
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Send as SendIcon, ThumbUp as ThumbUpIcon,
//   Reply as ReplyIcon, Logout as LogoutIcon, Delete as DeleteIcon, Share as ShareIcon, Edit as EditIcon,
//   Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Chat as ChatIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import supabase from './supabaseClient';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   borderRadius: '16px',
//   backgroundColor: '#2f3136',
//   transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
//   '&:hover': {
//     transform: 'translateY(-5px)',
//     boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
//   },
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(2),
//   },
//   [theme.breakpoints.up('md')]: {
//     padding: theme.spacing(3),
//   },
// }));

// const StyledChip = styled(Chip)(({ theme }) => ({
//   margin: theme.spacing(0.5),
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   fontWeight: 'bold',
// }));

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371; // Radius of the earth in km
//   const dLat = deg2rad(lat2 - lat1);
//   const dLon = deg2rad(lon1 - lon1);
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//     Math.sin(dLon / 2) * Math.sin(dLon / 2)
//     ;
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c; // Distance in km
//   return d;
// };

// const deg2rad = (deg) => {
//   return deg * (Math.PI / 180);
// };

// const KavaBarDetail = () => {
//   const { barName } = useParams();
//   const navigate = useNavigate();
//   const [barData, setBarData] = useState(null);
//   const [reviews, setReviews] = useState([]);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [newReview, setNewReview] = useState({ rating: 0, comment: '' });
//   const [isAnonymous, setIsAnonymous] = useState(false);
//   const [error, setError] = useState(null);
//   const [relatedBars, setRelatedBars] = useState([]);

//   useEffect(() => {
//     const checkUser = async () => {
//       const { data } = await supabase.auth.getUser();
//       if (data) {
//         console.log("User data:", data);
//         setUser(data.user);
//       }
//     };
//     checkUser();
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const formattedBarName = decodeURIComponent(barName).replace(/-/g, ' ').trim();
//         console.log('Searching for bar:', formattedBarName);

//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select(`
//             *,
//             "generated_reviews.Review",
//             "generated_reviews.Tags",
//             "generated_reviews.Bar Vibe",
//             "generated_reviews.Menu Highlights",
//             "generated_reviews.Special Accommodations"
//           `)
//           .ilike('name', `%${formattedBarName}%`)
//           .single();

//         if (barError) {
//           console.error('Error fetching bar data:', barError);
//           throw barError;
//         }

//         if (!barData) {
//           throw new Error('No bar found with the given name');
//         }

//         console.log('Bar data fetched:', barData);

//         const { data: reviews, error: reviewsError } = await supabase
//           .from('reviews')
//           .select('*')
//           .eq('bar_name', barData.name);

//         if (reviewsError) {
//           console.error('Error fetching reviews:', reviewsError);
//           throw reviewsError;
//         }

//         const averageRating = reviews.length > 0
//           ? reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
//           : 0;

//         // Replace brackets in fields
//         const cleanedData = {
//           ...barData,
//           "generated_reviews.Tags": barData["generated_reviews.Tags"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Bar Vibe": barData["generated_reviews.Bar Vibe"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Menu Highlights": barData["generated_reviews.Menu Highlights"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Special Accommodations": barData["generated_reviews.Special Accommodations"].replace(/[\[\]']/g, ''),
//           rating: averageRating,
//           num_ratings: reviews.length
//         };

//         setBarData(cleanedData);
//         setReviews(reviews);

//       } catch (err) {
//         console.error('Error in fetchData:', err);
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [barName]);

//   useEffect(() => {
//     const fetchRelatedBars = async () => {
//       if (barData) {
//         let { data: cityBars, error } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id);

//         if (error) {
//           console.error('Error fetching related bars:', error);
//           return;
//         }

//         if (cityBars.length < 3) {
//           // If fewer than 3 bars in the city, fetch bars from the same state
//           let { data: stateBars, error: stateError } = await supabase
//             .from('bars')
//             .select('*')
//             .eq('state', barData.state)
//             .neq('id', barData.id);

//           if (stateError) {
//             console.error('Error fetching state bars:', stateError);
//             return;
//           }

//           // Sort state bars by distance if we have fewer than 3 city bars
//           stateBars = stateBars.map(bar => ({
//             ...bar,
//             distance: calculateDistance(barData.latitude, barData.longitude, bar.latitude, bar.longitude)
//           })).sort((a, b) => a.distance - b.distance);

//           setRelatedBars([...cityBars, ...stateBars].slice(0, 5));
//         } else {
//           setRelatedBars(cityBars.slice(0, 5));
//         }
//       }
//     };

//     fetchRelatedBars();
//   }, [barData]);

//   const handleSignIn = async () => {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//       provider: 'google',
//     });
//     if (error) {
//       console.error('Error signing in:', error);
//     } else {
//       setUser(data.user);
//     }
//   };

//   const handleSignOut = async () => {
//     const { error } = await supabase.auth.signOut();
//     if (error) {
//       console.error('Error signing out:', error);
//     } else {
//       setUser(null);
//     }
//   };

//   const handleReviewSubmit = async (e) => {
//     e.preventDefault();
//     if (!user && !isAnonymous) {
//       alert("You must be signed in to submit a review or post anonymously.");
//       return;
//     }

//     const { rating, comment } = newReview;
//     if (rating > 0 && barData) {
//       const reviewData = {
//         bar_name: barData.name,
//         rating,
//         comment,
//       };

//       const { data, error } = await supabase
//         .from('reviews')
//         .insert([reviewData])
//         .select();

//       if (error) {
//         console.error('Error submitting review:', error);
//       } else {
//         setReviews([data[0], ...reviews]);
//         setNewReview({ rating: 0, comment: '' });
//       }
//     }
//   };

//   const theme = createTheme({
//     palette: {
//       mode: 'dark',
//       background: { default: '#36393f' },
//       primary: { main: '#5865F2' },
//       secondary: { main: '#00bfa5' }
//     },
//     typography: {
//       fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     },
//   });

//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;
//   if (!barData) return <div>Bar not found</div>;

//   const mergedHighlights = barData["generated_reviews.Menu Highlights"] + ", " + barData["generated_reviews.Special Accommodations"];
//   const allTags = barData["generated_reviews.Tags"] + ", " + mergedHighlights;

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <AppBar position="fixed" color="primary">
//         <Toolbar>
//           <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h6" sx={{ flexGrow: 1 }}>
//             {barData.name}
//           </Typography>
//           {user ? (
//             <Button color="inherit" onClick={handleSignOut}>
//               <LogoutIcon />
//               Sign Out
//             </Button>
//           ) : (
//             <Button color="inherit" onClick={handleSignIn}>
//               Sign In with Google
//             </Button>
//           )}
//         </Toolbar>
//       </AppBar>
//       <Container component="main" sx={{ mt: 10, mb: 4 }}>
//         <Card sx={{ mb: 4 }}>
//           <CardMedia
//             component="img"
//             height="300"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             onError={(e) => e.target.src = '/default.png'}
//           />
//         </Card>
//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h4">{barData.name}</Typography>
//           <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//             <Rating value={barData.rating} precision={0.1} readOnly />
//             <Typography variant="body2" sx={{ ml: 1 }}>({barData.num_ratings} reviews)</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.address}, {barData.city}, {barData.state}</Typography>
//           </Box>
//           {barData.phone_number && (
//             <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//               <PhoneIcon />
//               <Typography variant="body1" sx={{ ml: 1 }}>{barData.phone_number}</Typography>
//             </Box>
//           )}
//           {barData.website && (
//             <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//               <LanguageIcon />
//               <Button href={barData.website} target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
//                 Visit Website
//               </Button>
//             </Box>
//           )}
//           <Button
//             startIcon={<ChatIcon />}
//             variant="contained"
//             color="primary"
//             sx={{ mt: 2 }}
//             onClick={() => navigate(`/chat/${barData.city.toLowerCase().replace(/\s+/g, '-')}`)}
//           >
//             Join Yak Room
//           </Button>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Bar Vibe</Typography>
//           <Typography variant="body1">{barData["generated_reviews.Bar Vibe"]}</Typography>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
//             {allTags.split(',').map((tag, index) => (
//               <StyledChip key={index} label={tag.trim()} />
//             ))}
//           </Box>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Reviews</Typography>
//           <Box component="form" onSubmit={handleReviewSubmit}>
//             <Rating
//               name="rating"
//               value={newReview.rating}
//               onChange={(event, newValue) => {
//                 setNewReview({ ...newReview, rating: newValue });
//               }}
//             />
//             <TextField
//               fullWidth
//               multiline
//               rows={4}
//               variant="outlined"
//               placeholder="Write your review..."
//               value={newReview.comment}
//               onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
//               sx={{ mt: 2, mb: 2 }}
//             />
//             <FormControlLabel
//               control={<Checkbox checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />}
//               label="Post anonymously"
//             />
//             <Button type="submit" variant="contained" color="primary">
//               Submit Review
//             </Button>
//           </Box>
//           <List>
//             {reviews.map((review) => (
//               <ListItem key={review.id} alignItems="flex-start">
//                 <ListItemAvatar>
//                   <Avatar src="/user-avatar.png" />
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={
//                     <Box display="flex" alignItems="center">
//                       <Rating value={review.rating} readOnly />
//                       <Typography variant="caption" sx={{ ml: 1 }}>
//                         {new Date(review.created_at).toLocaleString()}
//                       </Typography>
//                     </Box>
//                   }
//                   secondary={review.comment}
//                 />
//               </ListItem>
//             ))}
//           </List>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Related Kava Bars</Typography>
//           <Slider {...settings}>
//             {relatedBars.map((bar) => (
//               <Card key={bar.id} sx={{ m: 1 }}>
//                 <CardMedia
//                   component="img"
//                   height="140"
//                   image={bar.store_image || '/default.png'}
//                   alt={bar.name}
//                   onError={(e) => e.target.src = '/default.png'}
//                   sx={{
//                     [theme.breakpoints.down('sm')]: {
//                       height: 100,
//                       margin: 1,
//                     },
//                     [theme.breakpoints.up('md')]: {
//                       height: 140,
//                       margin: 2,
//                     },
//                   }}
//                 />
//                 <CardContent>
//                   <Typography gutterBottom variant="h6" component="div">
//                     {bar.name}
//                   </Typography>
//                   <Typography variant="body2" color="text.secondary">
//                     {bar.city}, {bar.state}
//                   </Typography>
//                   <Button
//                     size="small"
//                     onClick={() => navigate(`/kavabar/${bar.name.toLowerCase().replace(/\s+/g, '-')}`)}
//                   >
//                     View Details
//                   </Button>
//                 </CardContent>
//               </Card>
//             ))}
//           </Slider>
//         </StyledPaper>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;
// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Typography, Container, Paper, Button, List, ListItem,
//   ListItemAvatar, ListItemText, IconButton, CssBaseline, TextField, ThemeProvider, createTheme, FormControlLabel, Checkbox, Chip, Rating, Card, CardMedia, CardContent
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Send as SendIcon, ThumbUp as ThumbUpIcon,
//   Reply as ReplyIcon, Logout as LogoutIcon, Delete as DeleteIcon, Share as ShareIcon, Edit as EditIcon,
//   Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Chat as ChatIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import supabase from './supabaseClient';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   borderRadius: '16px',
//   backgroundColor: '#2f3136',
//   transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
//   '&:hover': {
//     transform: 'translateY(-5px)',
//     boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
//   },
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(2),
//   },
//   [theme.breakpoints.up('md')]: {
//     padding: theme.spacing(3),
//   },
// }));

// const StyledChip = styled(Chip)(({ theme }) => ({
//   margin: theme.spacing(0.5),
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   fontWeight: 'bold',
// }));

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371; // Radius of the earth in km
//   const dLat = deg2rad(lat2 - lat1);
//   const dLon = deg2rad(lon1 - lon1);
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//     Math.sin(dLon / 2) * Math.sin(dLon / 2)
//     ;
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c; // Distance in km
//   return d;
// };

// const deg2rad = (deg) => {
//   return deg * (Math.PI / 180);
// };

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [barData, setBarData] = useState(null);
//   const [reviews, setReviews] = useState([]);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [newReview, setNewReview] = useState({ rating: 0, comment: '' });
//   const [isAnonymous, setIsAnonymous] = useState(false);
//   const [error, setError] = useState(null);
//   const [relatedBars, setRelatedBars] = useState([]);

//   useEffect(() => {
//     const checkUser = async () => {
//       const { data } = await supabase.auth.getUser();
//       if (data) {
//         console.log("User data:", data);
//         setUser(data.user);
//       }
//     };
//     checkUser();
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         console.log('Fetching bar by ID:', id);

//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select(`
//             *,
//             "generated_reviews.Review",
//             "generated_reviews.Tags",
//             "generated_reviews.Bar Vibe",
//             "generated_reviews.Menu Highlights",
//             "generated_reviews.Special Accommodations"
//           `)
//           .eq('id', id)
//           .maybeSingle();

//         if (barError) {
//           console.error('Error fetching bar data:', barError);
//           throw barError;
//         }

//         if (!barData) {
//           throw new Error('No bar found with the given ID');
//         }

//         console.log('Bar data fetched:', barData);

//         const { data: reviews, error: reviewsError } = await supabase
//           .from('reviews')
//           .select('*')
//           .eq('bar_name', barData.name);

//         if (reviewsError) {
//           console.error('Error fetching reviews:', reviewsError);
//           throw reviewsError;
//         }

//         const averageRating = reviews.length > 0
//           ? reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
//           : 0;

//         // Replace brackets in fields
//         const cleanedData = {
//           ...barData,
//           "generated_reviews.Tags": barData["generated_reviews.Tags"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Bar Vibe": barData["generated_reviews.Bar Vibe"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Menu Highlights": barData["generated_reviews.Menu Highlights"].replace(/[\[\]']/g, ''),
//           "generated_reviews.Special Accommodations": barData["generated_reviews.Special Accommodations"].replace(/[\[\]']/g, ''),
//           rating: averageRating,
//           num_ratings: reviews.length
//         };

//         setBarData(cleanedData);
//         setReviews(reviews);

//       } catch (err) {
//         console.error('Error in fetchData:', err);
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   useEffect(() => {
//     const fetchRelatedBars = async () => {
//       if (barData) {
//         let { data: cityBars, error } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id);

//         if (error) {
//           console.error('Error fetching related bars:', error);
//           return;
//         }

//         if (cityBars.length < 3) {
//           // If fewer than 3 bars in the city, fetch bars from the same state
//           let { data: stateBars, error: stateError } = await supabase
//             .from('bars')
//             .select('*')
//             .eq('state', barData.state)
//             .neq('id', barData.id);

//           if (stateError) {
//             console.error('Error fetching state bars:', stateError);
//             return;
//           }

//           // Sort state bars by distance if we have fewer than 3 city bars
//           stateBars = stateBars.map(bar => ({
//             ...bar,
//             distance: calculateDistance(barData.latitude, barData.longitude, bar.latitude, bar.longitude)
//           })).sort((a, b) => a.distance - b.distance);

//           setRelatedBars([...cityBars, ...stateBars].slice(0, 5));
//         } else {
//           setRelatedBars(cityBars.slice(0, 5));
//         }
//       }
//     };

//     fetchRelatedBars();
//   }, [barData]);

//   const handleSignIn = async () => {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//       provider: 'google',
//     });
//     if (error) {
//       console.error('Error signing in:', error);
//     } else {
//       setUser(data.user);
//     }
//   };

//   const handleSignOut = async () => {
//     const { error } = await supabase.auth.signOut();
//     if (error) {
//       console.error('Error signing out:', error);
//     } else {
//       setUser(null);
//     }
//   };

//   const handleReviewSubmit = async (e) => {
//     e.preventDefault();
//     if (!user && !isAnonymous) {
//       alert("You must be signed in to submit a review or post anonymously.");
//       return;
//     }

//     const { rating, comment } = newReview;
//     if (rating > 0 && barData) {
//       const reviewData = {
//         bar_name: barData.name,
//         rating,
//         comment,
//       };

//       const { data, error } = await supabase
//         .from('reviews')
//         .insert([reviewData])
//         .select();

//       if (error) {
//         console.error('Error submitting review:', error);
//       } else {
//         setReviews([data[0], ...reviews]);
//         setNewReview({ rating: 0, comment: '' });
//       }
//     }
//   };

//   const theme = createTheme({
//     palette: {
//       mode: 'dark',
//       background: { default: '#36393f' },
//       primary: { main: '#5865F2' },
//       secondary: { main: '#00bfa5' }
//     },
//     typography: {
//       fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     },
//   });

//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;
//   if (!barData) return <div>Bar not found</div>;

//   const mergedHighlights = barData["generated_reviews.Menu Highlights"] + ", " + barData["generated_reviews.Special Accommodations"];
//   const allTags = barData["generated_reviews.Tags"] + ", " + mergedHighlights;

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Container component="main" sx={{ mt: 10, mb: 4 }}>
//         <Card sx={{ mb: 4 }}>
//           <CardMedia
//             component="img"
//             height="300"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             onError={(e) => e.target.src = '/default.png'}
//           />
//         </Card>
//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h4">{barData.name}</Typography>
//           <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//             <Rating value={barData.rating} precision={0.1} readOnly />
//             <Typography variant="body2" sx={{ ml: 1 }}>({barData.num_ratings} reviews)</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.address}, {barData.city}, {barData.state}</Typography>
//           </Box>
//           {barData.phone_number && (
//             <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//               <PhoneIcon />
//               <Typography variant="body1" sx={{ ml: 1 }}>{barData.phone_number}</Typography>
//             </Box>
//           )}
//           {barData.website && (
//             <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//               <LanguageIcon />
//               <Button href={barData.website} target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
//                 Visit Website
//               </Button>
//             </Box>
//           )}
//           <Button
//             startIcon={<ChatIcon />}
//             variant="contained"
//             color="primary"
//             sx={{ mt: 2 }}
//             onClick={() => navigate(`/chat/${barData.city.toLowerCase().replace(/\s+/g, '-')}`)}
//           >
//             Join Yak Room
//           </Button>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Bar Vibe</Typography>
//           <Typography variant="body1">{barData["generated_reviews.Bar Vibe"]}</Typography>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
//             {allTags.split(',').map((tag, index) => (
//               <StyledChip key={index} label={tag.trim()} />
//             ))}
//           </Box>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Reviews</Typography>
//           <Box component="form" onSubmit={handleReviewSubmit}>
//             <Rating
//               name="rating"
//               value={newReview.rating}
//               onChange={(event, newValue) => {
//                 setNewReview({ ...newReview, rating: newValue });
//               }}
//             />
//             <TextField
//               fullWidth
//               multiline
//               rows={4}
//               variant="outlined"
//               placeholder="Write your review..."
//               value={newReview.comment}
//               onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
//               sx={{ mt: 2, mb: 2 }}
//             />
//             <FormControlLabel
//               control={<Checkbox checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />}
//               label="Post anonymously"
//             />
//             <Button type="submit" variant="contained" color="primary">
//               Submit Review
//             </Button>
//           </Box>
//           <List>
//             {reviews.map((review) => (
//               <ListItem key={review.id} alignItems="flex-start">
//                 <ListItemAvatar>
//                   <Avatar src="/user-avatar.png" />
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={
//                     <Box display="flex" alignItems="center">
//                       <Rating value={review.rating} readOnly />
//                       <Typography variant="caption" sx={{ ml: 1 }}>
//                         {new Date(review.created_at).toLocaleString()}
//                       </Typography>
//                     </Box>
//                   }
//                   secondary={review.comment}
//                 />
//               </ListItem>
//             ))}
//           </List>
//         </StyledPaper>

//         <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
//           <Typography variant="h5" gutterBottom>Related Kava Bars</Typography>
//           <Slider {...settings}>
//             {relatedBars.map((bar) => (
//               <Card key={bar.id} sx={{ m: 1 }}>
//                 <CardMedia
//                   component="img"
//                   height="140"
//                   image={bar.store_image || '/default.png'}
//                   alt={bar.name}
//                   onError={(e) => e.target.src = '/default.png'}
//                   sx={{
//                     [theme.breakpoints.down('sm')]: {
//                       height: 100,
//                       margin: 1,
//                     },
//                     [theme.breakpoints.up('md')]: {
//                       height: 140,
//                       margin: 2,
//                     },
//                   }}
//                 />
//                 <CardContent>
//                   <Typography gutterBottom variant="h6" component="div">
//                     {bar.name}
//                   </Typography>
//                   <Typography variant="body2" color="text.secondary">
//                     {bar.city}, {bar.state}
//                   </Typography>
//                   <Button
//                     size="small"
//                     onClick={() => navigate(`/kavabar/${bar.id}`)}
//                   >
//                     View Details
//                   </Button>
//                 </CardContent>
//               </Card>
//             ))}
//           </Slider>
//         </StyledPaper>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;

// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Rating, CardContent, Typography, Container, Button, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import supabase from './supabaseClient';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64', // Pink color
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e', // Brown color
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [reviews, setReviews] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(0);
//   const [favoritesCount, setFavoritesCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select(`
//             *,
//             "generated_reviews.Review",
//             "generated_reviews.Tags",
//             "generated_reviews.Bar Vibe",
//             "generated_reviews.Menu Highlights",
//             "generated_reviews.Special Accommodations"
//           `)
//           .eq('id', id)
//           .maybeSingle();

//         if (barError) throw barError;
//         if (!barData) throw new Error('No bar found with the given ID');

//         setBarData(barData);

//         const { data: reviews, error: reviewsError } = await supabase
//           .from('reviews')
//           .select('*')
//           .eq('bar_name', barData.name);

//         if (reviewsError) throw reviewsError;

//         setReviews(reviews);

//         // Fetch related bars based on city and state
//         let { data: relatedBarsData, error: relatedBarsError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id);

//         if (relatedBarsError) throw relatedBarsError;

//         if (relatedBarsData.length < 3) {
//           let { data: stateBarsData, error: stateBarsError } = await supabase
//             .from('bars')
//             .select('*')
//             .eq('state', barData.state)
//             .neq('id', barData.id);

//           if (stateBarsError) throw stateBarsError;

//           setRelatedBars(stateBarsData.slice(0, 5));
//         } else {
//           setRelatedBars(relatedBarsData.slice(0, 5));
//         }

//         // Fetch regulars who have this bar as their homebar
//         const { data: regularsData, error: regularsError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .eq('homebar', barData.id);

//         if (regularsError) throw regularsError;

//         setRegulars(regularsData);

//         // Fetch number of visitors (beento) and favorites
//         const { count: visitorsCount, error: visitorsError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('beenTo', [barData.id]);

//         if (visitorsError) throw visitorsError;

//         setVisitorsCount(visitorsCount);

//         const { count: favoritesCount, error: favoritesError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('favorites', [barData.id]);

//         if (favoritesError) throw favoritesError;

//         setFavoritesCount(favoritesCount);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography color="error">{error}</Typography>;
//   if (!barData) return <Typography>No bar found</Typography>;

//   const cleanedTags = barData["generated_reviews.Tags"]
//     .replace(/[\[\]']/g, '')
//     .split(',')
//     .map(tag => tag.trim());

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{ pt: 10, pb: 4 }}>
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//             <span style={{ opacity: 0.5 }}> / </span>
//             <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//             <span style={{ opacity: 0.7 }}> / </span>
//             <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//           </Typography>
//         </Box>

//         <StyledCard>
//           <CardMedia
//             component="img"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             sx={{ objectFit: 'cover', borderRadius: '8px' }}
//           />
//           <RatingCircle>{barData.rating.toFixed(1)}</RatingCircle>
//           <AddPhotoFab aria-label="add">
//             <AddIcon />
//           </AddPhotoFab>
//         </StyledCard>

//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>{barData.name}</Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.city}, {barData.state}</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <Typography variant="body1" sx={{ mr: 2 }}>🧳 {visitorsCount} Visitors</Typography>
//             <Typography variant="body1">❤️ {favoritesCount} Favorites</Typography>
//           </Box>
//           <Button
//             startIcon={<LanguageIcon />}
//             href={barData.website}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Website
//           </Button>
//           <Button
//             startIcon={<PhoneIcon />}
//             href={`tel:${barData.phone_number}`}
//             sx={{ mx: 1 }}
//           >
//             Phone
//           </Button>
//           <Button
//             startIcon={<LocationOnIcon />}
//             href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Directions
//           </Button>
//         </Box>

//         {regulars.length > 0 && (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {regulars.map((regular) => (
//                 <Chip
//                   key={regular.id}
//                   avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                   label={regular.username}
//                   onClick={() => navigate(`/profile/${regular.id}`)}
//                   clickable
//                   sx={{ fontWeight: 'bold' }}
//                 />
//               ))}
//             </Box>
//           </Box>
//         )}

//         <Typography variant="h6" sx={{ mb: 2 }}>Bar Vibe</Typography>
//         <Typography variant="body1" sx={{ mb: 4 }}>{barData["generated_reviews.Bar Vibe"]}</Typography>

//         <TagsContainer>
//           {cleanedTags.map((tag, index) => (
//             <Chip key={index} label={tag} />
//           ))}
//         </TagsContainer>

//         <Typography variant="h6" sx={{ mt: 4 }}>{reviews.length ? 'User Reviews' : 'Reviews'}</Typography>
//         <Box>
//           {reviews.length > 0 ? (
//             reviews.map((review) => (
//               <Box key={review.id} sx={{ mb: 3 }}>
//                 <Typography variant="body1">{review.comment}</Typography>
//                 <Rating value={review.rating} precision={0.1} readOnly size="small" />
//                 <Typography variant="caption">{new Date(review.created_at).toLocaleString()}</Typography>
//               </Box>
//             ))
//           ) : (
//             <Typography>No reviews available. Be the first to review!</Typography>
//           )}
//         </Box>

//         <Typography variant="h6" sx={{ mt: 4 }}>Related Kava Bars</Typography>
//         <Slider
//           dots
//           infinite={false}
//           speed={500}
//           slidesToShow={3}
//           slidesToScroll={1}
//           responsive={[
//             { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } }
//           ]}
//         >
//           {relatedBars.map((bar) => (
//             <Card key={bar.id} sx={{ m: 1 }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={bar.store_image || '/default.png'}
//                 alt={bar.name}
//                 sx={{ objectFit: 'cover' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h6" component="div">
//                   {bar.name}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {bar.city}, {bar.state}
//                 </Typography>
//                 <Button
//                   size="small"
//                   onClick={() => navigate(`/kavabar/${bar.id}`)}
//                 >
//                   View Details
//                 </Button>
//               </CardContent>
//             </Card>
//           ))}
//         </Slider>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;


// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Rating, CardContent, Typography, Container, Button, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
//   Tooltip, Popover, List, ListItem, ListItemAvatar, ListItemText, Divider, TextField, Badge
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon,
//   Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
//   Reply as ReplyIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon, Flag as FlagIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import { createClient } from '@supabase/supabase-js';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// // Initialize Supabase client
// const supabase = createClient('YOUR_SUPABASE_URL', 'YOUR_SUPABASE_ANON_KEY');

// // Styled components
// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64',
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e',
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const CommentCard = styled(Card)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   borderRadius: '12px',
//   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//   overflow: 'visible',
// }));

// const CommentContent = styled(CardContent)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

// const ReplyCard = styled(Card)(({ theme }) => ({
//   marginTop: theme.spacing(1),
//   marginLeft: theme.spacing(4),
//   borderRadius: '8px',
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
// }));

// const ReplyContent = styled(CardContent)(({ theme }) => ({
//   padding: theme.spacing(1, 2),
// }));

// const UserListPopover = styled(Popover)(({ theme }) => ({
//   pointerEvents: 'none',
// }));

// const UserList = styled(List)(({ theme }) => ({
//   padding: theme.spacing(1),
//   maxHeight: '300px',
//   overflow: 'auto',
// }));

// // Theme
// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [comments, setComments] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(0);
//   const [favoritesCount, setFavoritesCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [user, setUser] = useState(null);
//   const [beenToArray, setBeenToArray] = useState([]);
//   const [favoritesArray, setFavoritesArray] = useState([]);
//   const [visitorAnchorEl, setVisitorAnchorEl] = useState(null);
//   const [favoriteAnchorEl, setFavoriteAnchorEl] = useState(null);
//   const [visitors, setVisitors] = useState([]);
//   const [favorites, setFavorites] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         // Fetch bar data
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('id', id)
//           .single();

//         if (barError) throw barError;
//         setBarData(barData);

//         // Fetch comments
//         const { data: commentsData, error: commentsError } = await supabase
//           .from('comments')
//           .select('*')
//           .eq('bar_name', barData.name)
//           .order('created_at', { ascending: false });

//         if (commentsError) throw commentsError;
//         setComments(commentsData);

//         // Fetch related bars
//         const { data: relatedBarsData, error: relatedBarsError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id)
//           .limit(5);

//         if (relatedBarsError) throw relatedBarsError;
//         setRelatedBars(relatedBarsData);

//         // Fetch regulars
//         const { data: regularsData, error: regularsError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .eq('homebar', barData.id);

//         if (regularsError) throw regularsError;
//         setRegulars(regularsData);

//         // Fetch visitors count
//         const { count: visitorsCount, error: visitorsError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('beenTo', [barData.id]);

//         if (visitorsError) throw visitorsError;
//         setVisitorsCount(visitorsCount);

//         // Fetch favorites count
//         const { count: favoritesCount, error: favoritesError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('favorites', [barData.id]);

//         if (favoritesError) throw favoritesError;
//         setFavoritesCount(favoritesCount);

//         // Fetch current user data
//         const { data: userData, error: userError } = await supabase.auth.getUser();
//         if (userError) throw userError;
//         setUser(userData.user);

//         if (userData.user) {
//           const { data: profileData, error: profileError } = await supabase
//             .from('profiles')
//             .select('beenTo, favorites')
//             .eq('id', userData.user.id)
//             .single();

//           if (profileError) throw profileError;

//           setBeenToArray(profileData.beenTo?.map(String) || []);
//           setFavoritesArray(profileData.favorites?.map(String) || []);
//         }

//         // Fetch visitors
//         const { data: visitorsData, error: visitorsDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('beenTo', [barData.id]);

//         if (visitorsDataError) throw visitorsDataError;
//         setVisitors(visitorsData);

//         // Fetch favorites
//         const { data: favoritesData, error: favoritesDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('favorites', [barData.id]);

//         if (favoritesDataError) throw favoritesDataError;
//         setFavorites(favoritesData);

//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const handleBeenTo = async () => {
//     if (!user) return;

//     try {
//       const updatedBeenTo = beenToArray.includes(id)
//         ? beenToArray.filter(barId => barId !== id)
//         : [...beenToArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ beenTo: updatedBeenTo })
//         .eq("id", user.id);

//       if (error) throw error;

//       setBeenToArray(updatedBeenTo);
//       setVisitorsCount(updatedBeenTo.includes(id) ? visitorsCount + 1 : visitorsCount - 1);
//     } catch (error) {
//       console.error("Error handling beenTo:", error);
//     }
//   };

//   const handleFavorite = async () => {
//     if (!user) return;

//     try {
//       const updatedFavorites = favoritesArray.includes(id)
//         ? favoritesArray.filter(barId => barId !== id)
//         : [...favoritesArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       if (error) throw error;

//       setFavoritesArray(updatedFavorites);
//       setFavoritesCount(updatedFavorites.includes(id) ? favoritesCount + 1 : favoritesCount - 1);
//     } catch (error) {
//       console.error("Error handling favorite:", error);
//     }
//   };

//   const handleVisitorHover = (event) => {
//     setVisitorAnchorEl(event.currentTarget);
//   };

//   const handleVisitorHoverClose = () => {
//     setVisitorAnchorEl(null);
//   };

//   const handleFavoriteHover = (event) => {
//     setFavoriteAnchorEl(event.currentTarget);
//   };

//   const handleFavoriteHoverClose = () => {
//     setFavoriteAnchorEl(null);
//   };

//   const handleCommentLike = async (commentId) => {
//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ likes: comments.find(c => c.id === commentId).likes + 1 })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, likes: comment.likes + 1 } : comment
//       ));
//     } catch (error) {
//       console.error("Error liking comment:", error);
//     }
//   };

//   const handleCommentDislike = async (commentId) => {
//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ downvotes: comments.find(c => c.id === commentId).downvotes + 1 })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, downvotes: comment.downvotes + 1 } : comment
//       ));
//     } catch (error) {
//       console.error("Error disliking comment:", error);
//     }
//   };

//   const handleReply = async (commentId) => {
//     // This function would open a reply form or modal
//     console.log(`Reply to comment: ${commentId}`);
//     // Implement your reply logic here
//   };

//   const handleFlagComment = async (commentId) => {
//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ has_profanity: true })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, has_profanity: true } : comment
//       ));
//     } catch (error) {
//       console.error("Error flagging comment:", error);
//     }
//   };

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography color="error">{error}</Typography>;
//   if (!barData) return <Typography>No bar found</Typography>;

//   const isBeenTo = beenToArray.includes(id);
//   const isFavorite = favoritesArray.includes(id);

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{
//         pt: 10,
//         pb: 4,
//         px: { xs: 2, sm: 4, md: 6, lg: 8 }
//       }}>
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//             <span style={{ opacity: 0.5 }}> / </span>
//             <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//             <span style={{ opacity: 0.7 }}> / </span>
//             <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//           </Typography>
//         </Box>

//         <StyledCard>
//           <CardMedia
//             component="img"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             sx={{
//               objectFit: 'cover',
//               borderRadius: '8px',
//               height: { xs: '300px', md: '150px' }
//             }}
//           />
//           <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>
//           <AddPhotoFab aria-label="add">
//             <AddIcon />
//           </AddPhotoFab>
//         </StyledCard>

//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>{barData.name}</Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.city}, {barData.state}</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
//               <IconButton
//                 onClick={handleBeenTo}
//                 color={isBeenTo ? "primary" : "default"}
//                 onMouseEnter={handleVisitorHover}
//                 onMouseLeave={handleVisitorHoverClose}
//               >
//                 {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>🧳 {visitorsCount} Visitors</Typography>
//             <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
//               <IconButton
//                 onClick={handleFavorite}
//                 color={isFavorite ? "secondary" : "default"}
//                 onMouseEnter={handleFavoriteHover}
//                 onMouseLeave={handleFavoriteHoverClose}
//               >
//                 {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>❤️ {favoritesCount} Favorites</Typography>
//           </Box>
//           <Button
//             startIcon={<LanguageIcon />}
//             href={barData.website}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Website
//           </Button>
//           <Button
//             startIcon={<PhoneIcon />}
//             href={`tel:${barData.phone_number}`}
//             sx={{ mx: 1 }}
//           >
//             Phone
//           </Button>
//           <Button
//             startIcon={<LocationOnIcon />}
//             href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Directions
//           </Button>
//         </Box>

//         {regulars.length > 0 && (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {regulars.map((regular) => (
//                 <Chip
//                   key={regular.id}
//                   avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                   label={regular.username}
//                   onClick={() => navigate(`/profile/${regular.id}`)}
//                   clickable
//                   sx={{ fontWeight: 'bold' }}
//                 />
//               ))}
//             </Box>
//           </Box>
//         )}

//         <Typography variant="h6" sx={{ mb: 2 }}>Bar Vibe</Typography>
//         <Typography variant="body1" sx={{ mb: 4 }}>{barData.bar_vibe || "No vibe description available."}</Typography>

//         <TagsContainer>
//           {barData.tags && barData.tags.split(',').map((tag, index) => (
//             <Chip key={index} label={tag.trim()} />
//           ))}
//         </TagsContainer>

//         <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>Kava Salon</Typography>
//         <Box>
//           {comments.length > 0 ? (
//             comments.map((comment) => (
//               <CommentCard key={comment.id}>
//                 <CommentContent>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                     <Avatar src={`/avatars/${comment.id}.png`} sx={{ mr: 2 }} />
//                     <Box>
//                       <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
//                         {comment.is_annoymouse ? 'Anonymous' : comment.username}
//                       </Typography>
//                       <Typography variant="body2" color="text.secondary">
//                         {new Date(comment.created_at).toLocaleDateString()}
//                       </Typography>
//                     </Box>
//                   </Box>
//                   <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>{comment.comment}</Typography>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                     <Rating value={comment.rating} precision={0.1} readOnly size="small" sx={{ mr: 2 }} />
//                     <Chip label={`${comment.reply_count} replies`} size="small" sx={{ mr: 1 }} />
//                     <Badge badgeContent={comment.likes} color="primary" sx={{ mr: 1 }}>
//                       <IconButton size="small" onClick={() => handleCommentLike(comment.id)}>
//                         <ThumbUpIcon fontSize="small" />
//                       </IconButton>
//                     </Badge>
//                     <Badge badgeContent={comment.downvotes} color="error" sx={{ mr: 1 }}>
//                       <IconButton size="small" onClick={() => handleCommentDislike(comment.id)}>
//                         <ThumbDownIcon fontSize="small" />
//                       </IconButton>
//                     </Badge>
//                     {comment.has_profanity && (
//                       <Tooltip title="This comment may contain inappropriate content">
//                         <FlagIcon color="warning" fontSize="small" />
//                       </Tooltip>
//                     )}
//                   </Box>
//                   <Button
//                     startIcon={<ReplyIcon />}
//                     size="small"
//                     onClick={() => handleReply(comment.id)}
//                   >
//                     Reply
//                   </Button>
//                   <IconButton size="small" onClick={() => handleFlagComment(comment.id)} sx={{ ml: 1 }}>
//                     <FlagIcon fontSize="small" />
//                   </IconButton>
//                 </CommentContent>
//               </CommentCard>
//             ))
//           ) : (
//             <Typography>No reviews available. Be the first to review!</Typography>
//           )}
//         </Box>

//         <UserListPopover
//           open={Boolean(visitorAnchorEl)}
//           anchorEl={visitorAnchorEl}
//           onClose={handleVisitorHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {visitors.map((visitor) => (
//               <React.Fragment key={visitor.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={visitor.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={visitor.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <UserListPopover
//           open={Boolean(favoriteAnchorEl)}
//           anchorEl={favoriteAnchorEl}
//           onClose={handleFavoriteHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {favorites.map((favorite) => (
//               <React.Fragment key={favorite.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={favorite.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={favorite.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <Typography variant="h6" sx={{ mt: 4 }}>Related Kava Bars</Typography>
//         <Slider
//           dots
//           infinite={false}
//           speed={500}
//           slidesToShow={3}
//           slidesToScroll={1}
//           responsive={[
//             { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } }
//           ]}
//         >
//           {relatedBars.map((bar) => (
//             <Card key={bar.id} sx={{ m: 1 }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={bar.store_image || '/default.png'}
//                 alt={bar.name}
//                 sx={{ objectFit: 'cover', height: '140px' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h6" component="div">
//                   {bar.name}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {bar.city}, {bar.state}
//                 </Typography>
//                 <Button
//                   size="small"
//                   onClick={() => navigate(`/kavabar/${bar.id}`)}
//                 >
//                   View Details
//                 </Button>
//               </CardContent>
//             </Card>
//           ))}
//         </Slider>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;

// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Rating, CardContent, Typography, Container, Button, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
//   Tooltip, Popover, List, ListItem, ListItemAvatar, ListItemText, Divider, Badge, Modal, Slide, Alert, Snackbar
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon,
//   Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
//   Reply as ReplyIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon, Flag as FlagIcon, Edit as EditIcon, Email as EmailIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import supabase from './supabaseClient';
// import KavaBarComments from './KavaBarComments'; // Import the new component

// // Styled components
// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64',
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e',
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const CommentCard = styled(Card)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   borderRadius: '12px',
//   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//   overflow: 'visible',
// }));

// const CommentContent = styled(CardContent)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

// const ReplyCard = styled(Card)(({ theme }) => ({
//   marginTop: theme.spacing(1),
//   marginLeft: theme.spacing(4),
//   borderRadius: '8px',
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
// }));

// const ReplyContent = styled(CardContent)(({ theme }) => ({
//   padding: theme.spacing(1, 2),
// }));

// const UserListPopover = styled(Popover)(({ theme }) => ({
//   pointerEvents: 'none',
// }));

// const UserList = styled(List)(({ theme }) => ({
//   padding: theme.spacing(1),
//   maxHeight: '300px',
//   overflow: 'auto',
// }));

// // Profanity filter
// const profanityFilter = (text) => {
//   const profanityDictionary = ['badword1', 'badword2', 'badword3']; // Add actual bad words
//   const regex = new RegExp(`\\b(${profanityDictionary.join('|')})\\b`, 'gi');
//   return text.replace(regex, '****');
// };

// // Theme
// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [comments, setComments] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(0);
//   const [favoritesCount, setFavoritesCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [user, setUser] = useState(null);
//   const [beenToArray, setBeenToArray] = useState([]);
//   const [favoritesArray, setFavoritesArray] = useState([]);
//   const [visitorAnchorEl, setVisitorAnchorEl] = useState(null);
//   const [favoriteAnchorEl, setFavoriteAnchorEl] = useState(null);
//   const [visitors, setVisitors] = useState([]);
//   const [favorites, setFavorites] = useState([]);
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [currentUser, setCurrentUser] = useState(null);

//   // Fetch user data
//   useEffect(() => {
//     const fetchUserData = async () => {
//       const { data: user, error } = await supabase.auth.getUser();
//       if (user) {
//         setCurrentUser(user);
//       }
//     };

//     fetchUserData();
//   }, []);

//   // Fetch bar data
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('id', id)
//           .single();

//         if (barError) throw barError;
//         setBarData(barData);

//         const { data: commentsData, error: commentsError } = await supabase
//           .from('comments')
//           .select('*')
//           .eq('bar_name', barData.name)
//           .order('created_at', { ascending: false });

//         if (commentsError) throw commentsError;
//         setComments(commentsData);

//         const { data: relatedBarsData, error: relatedBarsError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id)
//           .limit(10);

//         if (relatedBarsError) throw relatedBarsError;
//         setRelatedBars(relatedBarsData);

//         const { data: regularsData, error: regularsError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .eq('homebar', barData.id);

//         if (regularsError) throw regularsError;
//         setRegulars(regularsData);

//         const { count: visitorsCount, error: visitorsError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('beenTo', [barData.id]);

//         if (visitorsError) throw visitorsError;
//         setVisitorsCount(visitorsCount);

//         const { count: favoritesCount, error: favoritesError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('favorites', [barData.id]);

//         if (favoritesError) throw favoritesError;
//         setFavoritesCount(favoritesCount);

//         const { data: userData, error: userError } = await supabase.auth.getUser();
//         if (userError) throw userError;
//         setUser(userData.user);

//         if (userData.user) {
//           const { data: profileData, error: profileError } = await supabase
//             .from('profiles')
//             .select('beenTo, favorites')
//             .eq('id', userData.user.id)
//             .single();

//           if (profileError) throw profileError;

//           setBeenToArray(profileData.beenTo?.map(String) || []);
//           setFavoritesArray(profileData.favorites?.map(String) || []);
//         }

//         const { data: visitorsData, error: visitorsDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('beenTo', [barData.id]);

//         if (visitorsDataError) throw visitorsDataError;
//         setVisitors(visitorsData);

//         const { data: favoritesData, error: favoritesDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('favorites', [barData.id]);

//         if (favoritesDataError) throw favoritesDataError;
//         setFavorites(favoritesData);

//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const handleBeenTo = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedBeenTo = beenToArray.includes(id)
//         ? beenToArray.filter(barId => barId !== id)
//         : [...beenToArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ beenTo: updatedBeenTo })
//         .eq("id", user.id);

//       if (error) throw error;

//       setBeenToArray(updatedBeenTo);
//       setVisitorsCount(updatedBeenTo.includes(id) ? visitorsCount + 1 : visitorsCount - 1);
//     } catch (error) {
//       console.error("Error handling beenTo:", error);
//     }
//   };

//   const handleFavorite = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedFavorites = favoritesArray.includes(id)
//         ? favoritesArray.filter(barId => barId !== id)
//         : [...favoritesArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       if (error) throw error;

//       setFavoritesArray(updatedFavorites);
//       setFavoritesCount(updatedFavorites.includes(id) ? favoritesCount + 1 : favoritesCount - 1);
//     } catch (error) {
//       console.error("Error handling favorite:", error);
//     }
//   };

//   const handleVisitorHover = (event) => {
//     setVisitorAnchorEl(event.currentTarget);
//   };

//   const handleVisitorHoverClose = () => {
//     setVisitorAnchorEl(null);
//   };

//   const handleFavoriteHover = (event) => {
//     setFavoriteAnchorEl(event.currentTarget);
//   };

//   const handleFavoriteHoverClose = () => {
//     setFavoriteAnchorEl(null);
//   };

//   // Define handleCommentLike function
//   const handleCommentLike = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ likes: comments.find(c => c.id === commentId).likes + 1 })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, likes: comment.likes + 1 } : comment
//       ));
//     } catch (error) {
//       console.error("Error liking comment:", error);
//     }
//   };

//   // Define handleReply function
//   const handleReply = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }
//     // Logic to handle reply
//     console.log(`Reply to comment: ${commentId}`);
//     // Implement your reply logic here
//   };

//   const handleFlagComment = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ has_profanity: true })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, has_profanity: true } : comment
//       ));
//     } catch (error) {
//       console.error("Error flagging comment:", error);
//     }
//   };

//   const handleEditBar = () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     // Navigate to a bar edit page, or open an edit modal
//     navigate(`/edit/kavabar/${id}`);
//   };

//   const handleAddComment = async (newComment) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data: addedComment, error } = await supabase
//         .from('comments')
//         .insert({ ...newComment, bar_name: barData.name, user_id: user.id })
//         .single();

//       if (error) throw error;

//       setComments([addedComment, ...comments]);
//     } catch (error) {
//       console.error("Error adding comment:", error);
//     }
//   };

//   const handleEditComment = async (commentId, updatedContent) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data: updatedComment, error } = await supabase
//         .from('comments')
//         .update({ content: profanityFilter(updatedContent) })
//         .eq('id', commentId)
//         .single();

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? updatedComment : comment
//       ));
//     } catch (error) {
//       console.error("Error editing comment:", error);
//     }
//   };

//   const handleDeleteComment = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { error } = await supabase
//         .from('comments')
//         .delete()
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.filter(comment => comment.id !== commentId));
//     } catch (error) {
//       console.error("Error deleting comment:", error);
//     }
//   };

//   const handleHighlightComment = async (commentId) => {
//     // Logic to highlight a comment (e.g., pin it or mark as important)
//     console.log(`Highlight comment: ${commentId}`);
//   };

//   const handleSubscribeToThread = async (threadId) => {
//     // Logic to subscribe to comment thread notifications
//     console.log(`Subscribe to thread: ${threadId}`);
//   };

//   const handleSubmitCarouselImages = async (images) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { error } = await supabase.storage
//         .from('bars')
//         .upload(`${barData.id}/${Date.now()}-carousel-images`, images);

//       if (error) throw error;

//       setSnackbarMessage('Images uploaded successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error uploading images:", error);
//     }
//   };

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography color="error">{error}</Typography>;
//   if (!barData) return <Typography>No bar found</Typography>;

//   const isBeenTo = beenToArray.includes(id);
//   const isFavorite = favoritesArray.includes(id);

//   const cleanAndSplitTags = (tags) => {
//     if (!tags) return [];
//     const cleanedTags = tags.replace(/[\[\]"']/g, '').split(',');
//     return cleanedTags.map(tag => tag.trim()).filter(tag => tag !== '');
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{
//         pt: 10,
//         pb: 4,
//         px: { xs: 2, sm: 4, md: 6, lg: 8 }
//       }}>
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//             <span style={{ opacity: 0.5 }}> / </span>
//             <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//             <span style={{ opacity: 0.7 }}> / </span>
//             <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//           </Typography>
//         </Box>

//         <StyledCard>
//           <CardMedia
//             component="img"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             sx={{
//               objectFit: 'cover',
//               borderRadius: '8px',
//               height: { xs: '300px', md: '150px' }
//             }}
//           />
//           <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>
//           <AddPhotoFab aria-label="add" onClick={() => handleSubmitCarouselImages()}>
//             <AddIcon />
//           </AddPhotoFab>
//         </StyledCard>

//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>{barData.name}</Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.city}, {barData.state}</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
//               <IconButton
//                 onClick={handleBeenTo}
//                 color={isBeenTo ? "primary" : "default"}
//                 onMouseEnter={handleVisitorHover}
//                 onMouseLeave={handleVisitorHoverClose}
//               >
//                 {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>🧳 {visitorsCount} Visitors</Typography>
//             <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
//               <IconButton
//                 onClick={handleFavorite}
//                 color={isFavorite ? "secondary" : "default"}
//                 onMouseEnter={handleFavoriteHover}
//                 onMouseLeave={handleFavoriteHoverClose}
//               >
//                 {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>❤️ {favoritesCount} Favorites</Typography>
//           </Box>
//           <Button
//             startIcon={<LanguageIcon />}
//             href={barData.website}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Website
//           </Button>
//           <Button
//             startIcon={<PhoneIcon />}
//             href={`tel:${barData.phone_number}`}
//             sx={{ mx: 1 }}
//           >
//             Phone
//           </Button>
//           <Button
//             startIcon={<LocationOnIcon />}
//             href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Directions
//           </Button>
//           {user && (
//             <Button
//               startIcon={<EditIcon />}
//               onClick={handleEditBar}
//               sx={{ mx: 1 }}
//             >
//               Edit Bar Details
//             </Button>
//           )}
//         </Box>

//         {regulars.length > 0 && (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {regulars.map((regular) => (
//                 <Chip
//                   key={regular.id}
//                   avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                   label={regular.username}
//                   onClick={() => navigate(`/user/${regular.username}`)}
//                   clickable
//                   sx={{ fontWeight: 'bold' }}
//                 />
//               ))}
//             </Box>
//           </Box>
//         )}

//         {barData.bar_vibe && barData.bar_vibe.length > 0 && (
//           <>
//             <Typography variant="h6" sx={{ mb: 2 }}>Bar Vibe</Typography>
//             <Typography variant="body1" sx={{ mb: 4 }}>{barData.bar_vibe}</Typography>
//           </>
//         )}

//         <TagsContainer>
//           {cleanAndSplitTags(barData.tags).map((tag, index) => (
//             <Chip
//               key={index}
//               label={tag}
//               variant="outlined"
//               size="small"
//             />
//           ))}
//         </TagsContainer>

//         <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>Kava Salon</Typography>
//         {currentUser && (
//           <KavaBarComments
//             comments={comments}
//             currentUser={{
//               username: currentUser.username,
//               avatar_url: currentUser.avatar_url,
//             }}
//             barData={barData}
//             onAddComment={handleAddComment}
//             onEditComment={handleEditComment}
//             onDeleteComment={handleDeleteComment}
//             onLikeComment={handleCommentLike}
//             onReplyToComment={handleReply}
//             onEmojiReact={(commentId, emoji) => console.log(`Reacted with ${emoji} on comment ${commentId}`)}
//           />
//         )}

//         <UserListPopover
//           open={Boolean(visitorAnchorEl)}
//           anchorEl={visitorAnchorEl}
//           onClose={handleVisitorHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {visitors.map((visitor) => (
//               <React.Fragment key={visitor.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={visitor.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={visitor.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <UserListPopover
//           open={Boolean(favoriteAnchorEl)}
//           anchorEl={favoriteAnchorEl}
//           onClose={handleFavoriteHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {favorites.map((favorite) => (
//               <React.Fragment key={favorite.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={favorite.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={favorite.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <Typography variant="h6" sx={{ mt: 4 }}>Related Kava Bars</Typography>
//         <Slider
//           dots
//           infinite={false}
//           speed={500}
//           slidesToShow={3}
//           slidesToScroll={1}
//           responsive={[
//             { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } }
//           ]}
//         >
//           {relatedBars.map((bar) => (
//             <Card key={bar.id} sx={{ m: 1 }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={bar.store_image || '/default.png'}
//                 alt={bar.name}
//                 sx={{ objectFit: 'cover', height: '200px' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h6" component="div">
//                   {bar.name}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {bar.city}, {bar.state}
//                 </Typography>
//                 <Button
//                   size="small"
//                   onClick={() => navigate(`/kavabar/${bar.id}`)}
//                 >
//                   View Details
//                 </Button>
//               </CardContent>
//             </Card>
//           ))}
//         </Slider>
//       </Container>

//       <Modal
//         open={showLoginModal}
//         onClose={() => setShowLoginModal(false)}
//       >
//         <Box sx={{
//           position: 'absolute', top: '50%', left: '50%',
//           transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
//           p: 4, boxShadow: 24, borderRadius: 2
//         }}>
//           <Typography variant="h6" gutterBottom>
//             Please Log In
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             You need to log in to perform this action. Would you like to log in now?
//           </Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 setShowLoginModal(false);
//                 navigate('/login'); // Navigate to login page
//               }}
//             >
//               Yes, log in
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//         TransitionComponent={(props) => <Slide {...props} direction="left" />}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;
// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Rating, CardContent, Typography, Container, Button, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
//   Tooltip, Popover, List, ListItem, ListItemAvatar, ListItemText, Divider, Modal, Slide, Alert, Snackbar
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon,
//   Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
//   Reply as ReplyIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon, Flag as FlagIcon, Edit as EditIcon, Email as EmailIcon
// } from '@mui/icons-material';
// import { styled } from '@mui/system';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import supabase from './supabaseClient';
// import KavaBarComments from './KavaBarComments';


// // Styled components
// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64',
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e',
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const UserListPopover = styled(Popover)(({ theme }) => ({
//   pointerEvents: 'none',
// }));

// const UserList = styled(List)(({ theme }) => ({
//   padding: theme.spacing(1),
//   maxHeight: '300px',
//   overflow: 'auto',
// }));

// // Profanity filter
// const profanityFilter = (text) => {
//   const profanityDictionary = ['badword1', 'badword2', 'badword3']; // Add actual bad words
//   const regex = new RegExp(`\\b(${profanityDictionary.join('|')})\\b`, 'gi');
//   return text.replace(regex, '****');
// };

// // Theme
// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [comments, setComments] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(0);
//   const [favoritesCount, setFavoritesCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [user, setUser] = useState(null);
//   const [beenToArray, setBeenToArray] = useState([]);
//   const [favoritesArray, setFavoritesArray] = useState([]);
//   const [visitorAnchorEl, setVisitorAnchorEl] = useState(null);
//   const [favoriteAnchorEl, setFavoriteAnchorEl] = useState(null);
//   const [visitors, setVisitors] = useState([]);
//   const [favorites, setFavorites] = useState([]);
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [currentUser, setCurrentUser] = useState(null);

//   // Fetch user data
//   useEffect(() => {
//     const fetchUserData = async () => {
//       const { data: user, error } = await supabase.auth.getUser();
//       if (user) {
//         setCurrentUser(user);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleSubmitCarouselImages = () => {
//     // Implement the logic for submitting carousel images
//     console.log("Submitting carousel images");
//     // You might want to open a modal for image upload or navigate to an upload page
//   };

//   const handleAddComment = async (newComment) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .insert([
//           {
//             bar_name: barData.name,
//             user_id: user.id,
//             comment: newComment,
//             created_at: new Date()
//           }
//         ]);

//       if (error) throw error;

//       // Refresh comments after adding a new one
//       const { data: updatedComments, error: fetchError } = await supabase
//         .from('comments')
//         .select('*')
//         .eq('bar_name', barData.name)
//         .order('created_at', { ascending: false });

//       if (fetchError) throw fetchError;

//       setComments(updatedComments);
//       setSnackbarMessage('Comment added successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error adding comment:", error);
//       setSnackbarMessage('Failed to add comment');
//       setSnackbarOpen(true);
//     }
//   };


//   // Fetch bar data
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('id', id)
//           .single();

//         if (barError) throw barError;
//         setBarData(barData);

//         const { data: commentsData, error: commentsError } = await supabase
//           .from('comments')
//           .select('*')
//           .eq('bar_name', barData.name)
//           .order('created_at', { ascending: false });

//         if (commentsError) throw commentsError;
//         setComments(commentsData);

//         const { data: relatedBarsData, error: relatedBarsError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('city', barData.city)
//           .neq('id', barData.id)
//           .limit(10);

//         if (relatedBarsError) throw relatedBarsError;
//         setRelatedBars(relatedBarsData);

//         const { data: regularsData, error: regularsError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .eq('homebar', barData.id);

//         if (regularsError) throw regularsError;
//         setRegulars(regularsData);

//         const { count: visitorsCount, error: visitorsError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('beenTo', [barData.id]);

//         if (visitorsError) throw visitorsError;
//         setVisitorsCount(visitorsCount);

//         const { count: favoritesCount, error: favoritesError } = await supabase
//           .from('profiles')
//           .select('*', { count: 'exact' })
//           .contains('favorites', [barData.id]);

//         if (favoritesError) throw favoritesError;
//         setFavoritesCount(favoritesCount);

//         const { data: userData, error: userError } = await supabase.auth.getUser();
//         if (userError) throw userError;
//         setUser(userData.user);

//         if (userData.user) {
//           const { data: profileData, error: profileError } = await supabase
//             .from('profiles')
//             .select('beenTo, favorites')
//             .eq('id', userData.user.id)
//             .single();

//           if (profileError) throw profileError;

//           setBeenToArray(profileData.beenTo?.map(String) || []);
//           setFavoritesArray(profileData.favorites?.map(String) || []);
//         }

//         const { data: visitorsData, error: visitorsDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('beenTo', [barData.id]);

//         if (visitorsDataError) throw visitorsDataError;
//         setVisitors(visitorsData);

//         const { data: favoritesData, error: favoritesDataError } = await supabase
//           .from('profiles')
//           .select('id, username, avatar_url')
//           .contains('favorites', [barData.id]);

//         if (favoritesDataError) throw favoritesDataError;
//         setFavorites(favoritesData);

//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const handleBeenTo = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedBeenTo = beenToArray.includes(id)
//         ? beenToArray.filter(barId => barId !== id)
//         : [...beenToArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ beenTo: updatedBeenTo })
//         .eq("id", user.id);

//       if (error) throw error;

//       setBeenToArray(updatedBeenTo);
//       setVisitorsCount(updatedBeenTo.includes(id) ? visitorsCount + 1 : visitorsCount - 1);
//     } catch (error) {
//       console.error("Error handling beenTo:", error);
//     }
//   };

//   const handleFavorite = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedFavorites = favoritesArray.includes(id)
//         ? favoritesArray.filter(barId => barId !== id)
//         : [...favoritesArray, id];

//       const { error } = await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       if (error) throw error;

//       setFavoritesArray(updatedFavorites);
//       setFavoritesCount(updatedFavorites.includes(id) ? favoritesCount + 1 : favoritesCount - 1);
//     } catch (error) {
//       console.error("Error handling favorite:", error);
//     }
//   };

//   const handleEditComment = async (commentId, editedContent) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ content: editedContent })
//         .eq('id', commentId)
//         .eq('user_id', user.id);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, content: editedContent } : comment
//       ));
//       setSnackbarMessage('Comment updated successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error editing comment:", error);
//       setSnackbarMessage('Failed to update comment');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleDeleteComment = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .delete()
//         .eq('id', commentId)
//         .eq('user_id', user.id);

//       if (error) throw error;

//       setComments(comments.filter(comment => comment.id !== commentId));
//       setSnackbarMessage('Comment deleted successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error deleting comment:", error);
//       setSnackbarMessage('Failed to delete comment');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleVisitorHover = (event) => {
//     setVisitorAnchorEl(event.currentTarget);
//   };

//   const handleVisitorHoverClose = () => {
//     setVisitorAnchorEl(null);
//   };

//   const handleFavoriteHover = (event) => {
//     setFavoriteAnchorEl(event.currentTarget);
//   };

//   const handleFavoriteHoverClose = () => {
//     setFavoriteAnchorEl(null);
//   };

//   const handleCommentLike = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from('comments')
//         .update({ likes: comments.find(c => c.id === commentId).likes + 1 })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, likes: comment.likes + 1 } : comment
//       ));
//     } catch (error) {
//       console.error("Error liking comment:", error);
//     }
//   };

//   const handleReply = async (commentId) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }
//     // Logic to handle reply
//     console.log(`Reply to comment: ${commentId}`);
//     // Implement your reply logic here
//   };

//   const handleEditBar = () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     // Navigate to a bar edit page, or open an edit modal
//     navigate(`/edit/kavabar/${id}`);
//   };

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography color="error">{error}</Typography>;
//   if (!barData) return <Typography>No bar found</Typography>;

//   const isBeenTo = beenToArray.includes(id);
//   const isFavorite = favoritesArray.includes(id);

//   const cleanAndSplitTags = (tags) => {
//     if (!tags) return [];
//     const cleanedTags = tags.replace(/[\[\]"']/g, '').split(',');
//     return cleanedTags.map(tag => tag.trim()).filter(tag => tag !== '');
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{
//         pt: 10,
//         pb: 4,
//         px: { xs: 2, sm: 4, md: 6, lg: 8 }
//       }}>
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//             <span style={{ opacity: 0.5 }}> / </span>
//             <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//             <span style={{ opacity: 0.7 }}> / </span>
//             <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//           </Typography>
//         </Box>

//         <StyledCard>
//           <CardMedia
//             component="img"
//             image={barData.store_image || '/default.png'}
//             alt={barData.name}
//             sx={{
//               objectFit: 'cover',
//               borderRadius: '8px',
//               height: { xs: '300px', md: '150px' }
//             }}
//           />
//           <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>
//           <AddPhotoFab aria-label="add" onClick={() => handleSubmitCarouselImages()}>
//             <AddIcon />
//           </AddPhotoFab>
//         </StyledCard>

//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>{barData.name}</Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>{barData.city}, {barData.state}</Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
//               <IconButton
//                 onClick={handleBeenTo}
//                 color={isBeenTo ? "primary" : "default"}
//                 onMouseEnter={handleVisitorHover}
//                 onMouseLeave={handleVisitorHoverClose}
//               >
//                 {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>🧳 {visitorsCount} Visitors</Typography>
//             <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
//               <IconButton
//                 onClick={handleFavorite}
//                 color={isFavorite ? "secondary" : "default"}
//                 onMouseEnter={handleFavoriteHover}
//                 onMouseLeave={handleFavoriteHoverClose}
//               >
//                 {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//               </IconButton>
//             </Tooltip>
//             <Typography variant="body1" sx={{ mx: 1 }}>❤️ {favoritesCount} Favorites</Typography>
//           </Box>
//           <Button
//             startIcon={<LanguageIcon />}
//             href={barData.website}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Website
//           </Button>
//           <Button
//             startIcon={<PhoneIcon />}
//             href={`tel:${barData.phone_number}`}
//             sx={{ mx: 1 }}
//           >
//             Phone
//           </Button>
//           <Button
//             startIcon={<LocationOnIcon />}
//             href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ mx: 1 }}
//           >
//             Directions
//           </Button>
//           {user && (
//             <Button
//               startIcon={<EditIcon />}
//               onClick={handleEditBar}
//               sx={{ mx: 1 }}
//             >
//               Edit Bar Details
//             </Button>
//           )}
//         </Box>

//         {regulars.length > 0 && (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {regulars.map((regular) => (
//                 <Chip
//                   key={regular.id}
//                   avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                   label={regular.username}
//                   onClick={() => navigate(`/user/${regular.username}`)}
//                   clickable
//                   sx={{ fontWeight: 'bold' }}
//                 />
//               ))}
//             </Box>
//           </Box>
//         )}

//         {barData.bar_vibe && barData.bar_vibe.length > 0 && (
//           <>
//             <Typography variant="h6" sx={{ mb: 2 }}>Bar Vibe</Typography>
//             <Typography variant="body1" sx={{ mb: 4 }}>{barData.bar_vibe}</Typography>
//           </>
//         )}

//         <TagsContainer>
//           {cleanAndSplitTags(barData.tags).map((tag, index) => (
//             <Chip
//               key={index}
//               label={tag}
//               variant="outlined"
//               size="small"
//             />
//           ))}
//         </TagsContainer>

//         <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>Kava Salon</Typography>
//         {currentUser && (
//           <KavaBarComments
//             comments={comments}
//             currentUser={{
//               username: currentUser.username,
//               avatar_url: currentUser.avatar_url,
//             }}
//             barData={barData}
//             onAddComment={handleAddComment}
//             onEditComment={handleEditComment}
//             onDeleteComment={handleDeleteComment}
//             onLikeComment={handleCommentLike}
//             onReplyToComment={handleReply}
//             onEmojiReact={(commentId, emoji) => console.log(`Reacted with ${emoji} on comment ${commentId}`)}
//           />
//         )}

//         <UserListPopover
//           open={Boolean(visitorAnchorEl)}
//           anchorEl={visitorAnchorEl}
//           onClose={handleVisitorHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {visitors.map((visitor) => (
//               <React.Fragment key={visitor.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={visitor.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={visitor.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <UserListPopover
//           open={Boolean(favoriteAnchorEl)}
//           anchorEl={favoriteAnchorEl}
//           onClose={handleFavoriteHoverClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//           }}
//         >
//           <UserList>
//             {favorites.map((favorite) => (
//               <React.Fragment key={favorite.id}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar src={favorite.avatar_url || '/default-avatar.png'} />
//                   </ListItemAvatar>
//                   <ListItemText primary={favorite.username} />
//                 </ListItem>
//                 <Divider variant="inset" component="li" />
//               </React.Fragment>
//             ))}
//           </UserList>
//         </UserListPopover>

//         <Typography variant="h6" sx={{ mt: 4 }}>Related Kava Bars</Typography>
//         <Slider
//           dots
//           infinite={false}
//           speed={500}
//           slidesToShow={3}
//           slidesToScroll={1}
//           responsive={[
//             { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } }
//           ]}
//         >
//           {relatedBars.map((bar) => (
//             <Card key={bar.id} sx={{ m: 1 }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={bar.store_image || '/default.png'}
//                 alt={bar.name}
//                 sx={{ objectFit: 'cover', height: '200px' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h6" component="div">
//                   {bar.name}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {bar.city}, {bar.state}
//                 </Typography>
//                 <Button
//                   size="small"
//                   onClick={() => navigate(`/kavabar/${bar.id}`)}
//                 >
//                   View Details
//                 </Button>
//               </CardContent>
//             </Card>
//           ))}
//         </Slider>
//       </Container>

//       <Modal
//         open={showLoginModal}
//         onClose={() => setShowLoginModal(false)}
//       >
//         <Box sx={{
//           position: 'absolute', top: '50%', left: '50%',
//           transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
//           p: 4, boxShadow: 24, borderRadius: 2
//         }}>
//           <Typography variant="h6" gutterBottom>
//             Please Log In
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             You need to log in to perform this action. Would you like to log in now?
//           </Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 setShowLoginModal(false);
//                 navigate('/login'); // Navigate to login page
//               }}
//             >
//               Yes, log in
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//         TransitionComponent={(props) => <Slide {...props} direction="left" />}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;
// import React, { useState, useEffect } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Typography, Container, Button, CardContent, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
//   Tooltip, Snackbar, Slide, Alert, TextField
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon,
//   Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
//   Edit as EditIcon, Send as SendIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon
// } from '@mui/icons-material';
// import { styled, keyframes } from '@mui/system';
// import supabase from './supabaseClient';
// import LoginModal from './LoginModal';

// const MAX_DEPTH = 15;

// const pulseKeyframe = keyframes`
//   0% {
//     background-position: 0% 0%;
//   }
//   100% {
//     background-position: -135% 0%;
//   }
// `;

// const PulsingSkeleton = styled('div')(({ theme, width, height }) => ({
//   width: width || '100%',
//   height: height || '20px',
//   borderRadius: '4px',
//   background: `linear-gradient(90deg, ${theme.palette.background.paper} 0px, ${theme.palette.action.hover} 40px, ${theme.palette.background.paper} 80px)`,
//   backgroundSize: '400% 100%',
//   animation: `${pulseKeyframe} 1.6s ease-in-out infinite`,
// }));

// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64',
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e',
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const CommentContainer = styled(Box)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   paddingLeft: theme.spacing(2),
//   borderLeft: `2px solid ${theme.palette.divider}`,
// }));

// const ReplyContainer = styled(Box)(({ theme, depth = 0 }) => ({
//   marginTop: theme.spacing(1),
//   marginLeft: theme.spacing(4 * depth),
//   paddingLeft: theme.spacing(2),
//   borderLeft: `2px solid ${theme.palette.divider}`,
// }));

// const NearbyBarsScroll = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   overflowX: 'auto',
//   padding: theme.spacing(2),
//   '&::-webkit-scrollbar': {
//     display: 'none',
//   },
//   scrollbarWidth: 'none',
//   msOverflowStyle: 'none',
// }));

// const NearbyBarCard = styled(Card)(({ theme }) => ({
//   minWidth: 200,
//   maxWidth: 200,
//   margin: theme.spacing(0, 1),
//   cursor: 'pointer',
// }));

// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7', paper: '#FFFFFF' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//     action: { hover: '#F0F0F0' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [comments, setComments] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(null);
//   const [favoritesCount, setFavoritesCount] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [userLoading, setUserLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [user, setUser] = useState(null);
//   const [profileData, setProfileData] = useState({ username: '', avatar_url: '' });
//   const [beenToArray, setBeenToArray] = useState([]);
//   const [favoritesArray, setFavoritesArray] = useState([]);
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [newCommentContent, setNewCommentContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [replyContent, setReplyContent] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);

//       if (!id) {
//         setError("No bar ID provided");
//         setLoading(false);
//         return;
//       }

//       try {
//         // Fetch bar data
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('id', id)
//           .single();

//         if (barError) throw barError;
//         setBarData(barData);

//         // Fetch comments using a join with profiles to get username and avatar_url
//         const { data: commentsData, error: commentsError } = await supabase
//           .from('comments_new')
//           .select(`
//             *,
//             profiles (
//               username,
//               avatar_url
//             )
//           `)
//           .eq('bar_name', barData.name)
//           .order('created_at', { ascending: false });

//         if (commentsError) throw commentsError;
//         setComments(commentsData);

//         // Fetch other related data
//         const [relatedBarsResponse, visitorsResponse, favoritesResponse, regularsResponse] = await Promise.all([
//           supabase.from('bars').select('*').eq('city', barData.city).neq('id', id).limit(10),
//           supabase.from('profiles').select('*', { count: 'exact' }).contains('beenTo', [id]),
//           supabase.from('profiles').select('*', { count: 'exact' }).contains('favorites', [id]),
//           supabase.from('profiles').select('id, username, avatar_url').eq('homebar', id)
//         ]);

//         if (relatedBarsResponse.error) throw relatedBarsResponse.error;
//         setRelatedBars(relatedBarsResponse.data);

//         if (visitorsResponse.error) throw visitorsResponse.error;
//         setVisitorsCount(visitorsResponse.count);

//         if (favoritesResponse.error) throw favoritesResponse.error;
//         setFavoritesCount(favoritesResponse.count);

//         if (regularsResponse.error) throw regularsResponse.error;
//         setRegulars(regularsResponse.data);

//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       setUserLoading(true);
//       try {
//         const { data: authData, error: authError } = await supabase.auth.getUser();

//         if (authError) throw authError;

//         const authUser = authData?.user;
//         if (authUser) {
//           setUser(authUser);

//           const { data: profile, error: profileError } = await supabase
//             .from('profiles')
//             .select('username, avatar_url, beenTo, favorites')
//             .eq('id', authUser.id)
//             .single();

//           if (profileError || !profile) {
//             // If profile fetch fails or returns null, set defaults
//             const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
//             setProfileData({
//               username: `BulaMan${randomNumber}`,
//               avatar_url: '/default-avatar.png'
//             });
//           } else {
//             setProfileData(profile);
//             setBeenToArray(profile.beenTo || []);
//             setFavoritesArray(profile.favorites || []);
//           }
//         }
//       } catch (err) {
//         console.error('Error fetching profile data:', err);
//         // If there's an unexpected error, also fallback to default
//         const randomNumber = Math.floor(1000 + Math.random() * 9000);
//         setProfileData({
//           username: `BulaMan${randomNumber}`,
//           avatar_url: '/default-avatar.png'
//         });
//       } finally {
//         setUserLoading(false);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleSubmitCarouselImages = async (event) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     try {
//       const file = files[0];
//       const fileName = `${id}/${Date.now()}-carousel-image`;

//       const { error } = await supabase.storage
//         .from('barphotos')
//         .upload(fileName, file, { upsert: true });

//       if (error) throw error;

//       setSnackbarMessage('Image uploaded successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       setSnackbarMessage('Failed to upload image');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleBeenTo = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedBeenTo = beenToArray.includes(id)
//         ? beenToArray.filter(barId => barId !== id)
//         : [...beenToArray, id];

//       await supabase
//         .from("profiles")
//         .update({ beenTo: updatedBeenTo })
//         .eq("id", user.id);

//       setBeenToArray(updatedBeenTo);
//       setVisitorsCount(prevCount => updatedBeenTo.includes(id) ? prevCount + 1 : prevCount - 1);
//     } catch (error) {
//       console.error("Error handling beenTo:", error);
//     }
//   };

//   const handleFavorite = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedFavorites = favoritesArray.includes(id)
//         ? favoritesArray.filter(barId => barId !== id)
//         : [...favoritesArray, id];

//       await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       setFavoritesArray(updatedFavorites);
//       setFavoritesCount(prevCount => updatedFavorites.includes(id) ? prevCount + 1 : prevCount - 1);
//     } catch (error) {
//       console.error("Error handling favorite:", error);
//     }
//   };

//   const handleAddComment = async (commentContent, parentId = null) => {
//     if (!user || !barData) {
//       setShowLoginModal(true);
//       return;
//     }

//     if (!commentContent.trim()) {
//       return; // Don't add empty comments
//     }

//     const newId = uuidv4(); // Generate a new UUID

//     const newComment = {
//       id: newId,  // Assign the generated UUID as the comment's ID
//       comment: commentContent,
//       bar_id: barData.id,
//       bar_name: barData.name,
//       rating: 0,  // Default to 0 if rating is not provided
//       user_id: user.id,
//       username: profileData.username,
//       avatar_url: profileData.avatar_url,
//       parent_id: parentId || null,  // Set to null for top-level comments
//       likes: 0,
//       dislikes: 0,
//       created_at: new Date().toISOString()
//     };

//     // Optimistically update the UI
//     setComments(prevComments => [newComment, ...prevComments]);

//     try {
//       const { data: savedComment, error } = await supabase
//         .from('comments_new')
//         .insert(newComment)
//         .single();

//       if (error) throw error;

//       // Optional: You could update the comment with any additional information returned from Supabase if necessary
//       setComments(prevComments =>
//         prevComments.map(comment =>
//           comment.id === newId ? savedComment : comment
//         )
//       );
//     } catch (error) {
//       console.error("Error adding comment:", error);
//       setSnackbarMessage('Failed to add comment');
//       setSnackbarOpen(true);
//       // Optionally, remove the optimistically added comment if the server request fails
//       setComments(prevComments => prevComments.filter(comment => comment.id !== newId));
//     }
//   };




//   const handleReaction = async (commentId, reactionType) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const comment = comments.find(c => c.id === commentId);
//       const newReactionCount = comment[reactionType] + 1;

//       const { error } = await supabase
//         .from('comments_new')
//         .update({ [reactionType]: newReactionCount })
//         .eq('id', commentId);

//       if (error) throw error;

//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, [reactionType]: newReactionCount } : comment
//       ));
//     } catch (error) {
//       console.error(`Error handling ${reactionType}:`, error);
//     }
//   };

//   const renderComments = (parentId = null, depth = 0) => {
//     if (depth > MAX_DEPTH) return null;

//     // Filter comments based on parentId and ensure comments are valid
//     const filteredComments = comments.filter(comment => comment && comment.parent_id === parentId);

//     return filteredComments.map((comment) => {
//       // Add a check to ensure the comment object is valid
//       if (!comment || !comment.id) return null;

//       const profile = comment.profiles || {}; // Fallback to an empty object if profiles is undefined
//       const avatarUrl = profile.avatar_url || '/default-avatar.png'; // Fallback to a default avatar image
//       const username = profile.username || 'Unknown User'; // Fallback to 'Unknown User' if username is not available

//       return (
//         <CommentContainer key={comment.id} depth={depth}>
//           <Box display="flex" alignItems="center" mb={1}>
//             <Avatar src={avatarUrl} alt={username} />
//             <Box ml={2}>
//               <Typography variant="subtitle2">{username}</Typography>
//               <Typography variant="caption" color="textSecondary">
//                 {new Date(comment.created_at).toLocaleDateString()}
//               </Typography>
//             </Box>
//           </Box>
//           <Typography variant="body1" mb={1}>{comment.comment}</Typography>
//           <Box display="flex" alignItems="center">
//             <IconButton size="small" onClick={() => handleReaction(comment.id, 'likes')}>
//               <ThumbUpIcon />
//             </IconButton>
//             <Typography variant="caption" sx={{ mr: 1 }}>{comment.likes}</Typography>
//             <IconButton size="small" onClick={() => handleReaction(comment.id, 'dislikes')}>
//               <ThumbDownIcon />
//             </IconButton>
//             <Typography variant="caption" sx={{ mr: 1 }}>{comment.dislikes}</Typography>
//             <Button size="small" onClick={() => setReplyingTo(comment.id)}>Reply</Button>
//           </Box>
//           {replyingTo === comment.id && (
//             <Box mt={2}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Write a reply..."
//                 value={replyContent}
//                 onChange={(e) => setReplyContent(e.target.value)}
//                 onKeyPress={(e) => handleKeyPress(e, replyContent, comment.id)} // Submit on Enter key press
//               />
//               <Button
//                 startIcon={<SendIcon />}
//                 onClick={() => {
//                   handleAddComment(replyContent, comment.id);
//                   setReplyContent('');
//                   setReplyingTo(null);
//                 }}
//               >
//                 Send Reply
//               </Button>
//             </Box>
//           )}
//           <ReplyContainer depth={depth}>
//             {renderComments(comment.id, depth + 1)}
//           </ReplyContainer>
//         </CommentContainer>
//       );
//     });
//   };

//   // Function to handle keypress and submit on Enter key
//   const handleKeyPress = (e, commentContent, parentId = null) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleAddComment(commentContent, parentId);
//       setNewCommentContent('');
//       setReplyContent(''); // Clear reply content if applicable
//       setReplyingTo(null); // Reset replying state
//     }
//   };


//   const handleEditBar = () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     navigate(`/edit/kavabar/${id}`);
//   };

//   const renderNearbyBars = () => (
//     <NearbyBarsScroll>
//       {relatedBars.map((bar) => (
//         <NearbyBarCard key={bar.id} onClick={() => navigate(`/kavabar/${bar.id}`)}>
//           <CardMedia
//             component="img"
//             height="140"
//             image={bar.store_image || '/default.png'}
//             alt={bar.name}
//           />
//           <CardContent>
//             <Typography variant="subtitle1" noWrap>{bar.name}</Typography>
//             <Typography variant="body2" color="text.secondary" noWrap>
//               {bar.city}, {bar.state}
//             </Typography>
//           </CardContent>
//         </NearbyBarCard>
//       ))}
//     </NearbyBarsScroll>
//   );

//   if (error) return <Typography color="error">{error}</Typography>;

//   const isBeenTo = beenToArray.includes(id);
//   const isFavorite = favoritesArray.includes(id);

//   const cleanAndSplitTags = (tags) => {
//     if (!tags) return [];
//     const cleanedTags = tags.replace(/[\[\]"']/g, '').split(',');
//     return cleanedTags.map(tag => tag.trim()).filter(tag => tag !== '');
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{
//         pt: 10,
//         pb: 20,
//         px: { xs: 2, sm: 4, md: 6, lg: 8 }
//       }}>
//         {/* Bar Details */}
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             {loading ? (
//               <PulsingSkeleton width={200} height={20} />
//             ) : (
//               <>
//                 <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//                 <span style={{ opacity: 0.5 }}> / </span>
//                 <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//                 <span style={{ opacity: 0.7 }}> / </span>
//                 <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//               </>
//             )}
//           </Typography>
//         </Box>

//         <StyledCard>
//           {loading ? (
//             <PulsingSkeleton width="100%" height={300} />
//           ) : (
//             <CardMedia
//               component="img"
//               image={barData.store_image || '/default.png'}
//               alt={barData.name}
//               sx={{
//                 objectFit: 'cover',
//                 borderRadius: '8px',
//                 height: { xs: '300px', md: '150px' }
//               }}
//             />
//           )}
//           {loading ? (
//             <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
//               <PulsingSkeleton width={50} height={50} style={{ borderRadius: '50%' }} />
//             </Box>
//           ) : (
//             <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>
//           )}
//           {userLoading ? (
//             <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
//               <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%' }} />
//             </Box>
//           ) : (
//             <AddPhotoFab aria-label="add">
//               <input
//                 type="file"
//                 accept="image/*"
//                 style={{ display: 'none' }}
//                 onChange={handleSubmitCarouselImages}
//               />
//               <AddIcon />
//             </AddPhotoFab>
//           )}
//         </StyledCard>

//         {/* Bar Info */}
//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>
//             {loading ? <PulsingSkeleton width={300} height={40} /> : barData.name}
//           </Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>
//               {loading ? <PulsingSkeleton width={150} height={20} /> : `${barData.city}, ${barData.state}`}
//             </Typography>
//           </Box>

//           {/* Visitor & Favorite Buttons */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             {userLoading ? (
//               <>
//                 <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
//                 <PulsingSkeleton width={60} height={20} style={{ marginRight: '16px' }} />
//                 <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
//                 <PulsingSkeleton width={60} height={20} />
//               </>
//             ) : (
//               <>
//                 <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
//                   <IconButton
//                     onClick={handleBeenTo}
//                     color={isBeenTo ? "primary" : "default"}
//                   >
//                     {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                   </IconButton>
//                 </Tooltip>
//                 <Typography variant="body1" sx={{ mx: 1 }}>
//                   {loading ? <PulsingSkeleton width={50} height={20} /> : `🧳 ${visitorsCount} Visitors`}
//                 </Typography>
//                 <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
//                   <IconButton
//                     onClick={handleFavorite}
//                     color={isFavorite ? "secondary" : "default"}
//                   >
//                     {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//                   </IconButton>
//                 </Tooltip>
//                 <Typography variant="body1" sx={{ mx: 1 }}>
//                   {loading ? <PulsingSkeleton width={50} height={20} /> : `❤️ ${favoritesCount} Favorites`}
//                 </Typography>
//               </>
//             )}
//           </Box>

//           {/* Buttons */}
//           {loading ? (
//             <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
//               <PulsingSkeleton width={100} height={36} />
//               <PulsingSkeleton width={100} height={36} />
//               <PulsingSkeleton width={100} height={36} />
//               {user && <PulsingSkeleton width={100} height={36} />}
//             </Box>
//           ) : (
//             <>
//               <Button
//                 startIcon={<LanguageIcon />}
//                 href={barData.website}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{ mx: 1 }}
//               >
//                 Website
//               </Button>
//               <Button
//                 startIcon={<PhoneIcon />}
//                 href={`tel:${barData.phone_number}`}
//                 sx={{ mx: 1 }}>
//                 Phone
//               </Button>
//               <Button
//                 startIcon={<LocationOnIcon />}
//                 href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{ mx: 1 }}
//               >
//                 Directions
//               </Button>
//               {user && (
//                 <Button
//                   startIcon={<EditIcon />}
//                   onClick={handleEditBar}
//                   sx={{ mx: 1 }}
//                 >
//                   Edit Bar Details
//                 </Button>
//               )}
//             </>
//           )}
//         </Box>

//         {/* Regulars */}
//         {userLoading ? (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <PulsingSkeleton width={100} height={24} style={{ marginBottom: '16px' }} />
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {[...Array(5)].map((_, index) => (
//                 <PulsingSkeleton key={index} width={100} height={32} style={{ borderRadius: '16px' }} />
//               ))}
//             </Box>
//           </Box>
//         ) : (
//           regulars.length > 0 && (
//             <Box sx={{ mb: 4, textAlign: 'center' }}>
//               <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//               <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//                 {regulars.map((regular) => (
//                   <Chip
//                     key={regular.id}
//                     avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                     label={regular.username}
//                     onClick={() => navigate(`/user/${regular.username}`)}
//                     clickable
//                     sx={{ fontWeight: 'bold' }}
//                   />
//                 ))}
//               </Box>
//             </Box>
//           )
//         )}

//         {/* Tags */}
//         {loading ? (
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
//             {[...Array(5)].map((_, index) => (
//               <PulsingSkeleton key={index} width={80} height={32} style={{ borderRadius: '16px' }} />
//             ))}
//           </Box>
//         ) : (
//           barData.tags && (
//             <TagsContainer>
//               {cleanAndSplitTags(barData.tags).map((tag, index) => (
//                 <Chip
//                   key={index}
//                   label={tag}
//                   variant="outlined"
//                   size="small"
//                 />
//               ))}
//             </TagsContainer>
//           )
//         )}

//         {/* Comments Section */}
//         <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>Kava Salon</Typography>
//         <Box mb={4}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Write a comment..."
//             value={newCommentContent}
//             onChange={(e) => setNewCommentContent(e.target.value)}
//           />
//           <Button
//             startIcon={<SendIcon />}
//             onClick={() => {
//               handleAddComment(newCommentContent);
//               setNewCommentContent('');
//             }}
//           >
//             Post Comment
//           </Button>
//         </Box>
//         {loading ? (
//           <Box>
//             {[...Array(3)].map((_, index) => (
//               <Box key={index} sx={{ mb: 3 }}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                   <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '16px' }} />
//                   <Box>
//                     <PulsingSkeleton width={120} height={20} style={{ marginBottom: '4px' }} />
//                     <PulsingSkeleton width={80} height={16} />
//                   </Box>
//                 </Box>
//                 <PulsingSkeleton width="100%" height={60} style={{ marginBottom: '8px' }} />
//                 <PulsingSkeleton width={100} height={32} />
//               </Box>
//             ))}
//           </Box>
//         ) : (
//           renderComments()
//         )}

//         {/* Nearby Bars */}
//         <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Nearby Kava Bars</Typography>
//         {loading ? (
//           <NearbyBarsScroll>
//             {[...Array(5)].map((_, index) => (
//               <NearbyBarCard key={index}>
//                 <PulsingSkeleton width={200} height={140} />
//                 <CardContent>
//                   <PulsingSkeleton width={160} height={24} style={{ marginBottom: '8px' }} />
//                   <PulsingSkeleton width={120} height={20} />
//                 </CardContent>
//               </NearbyBarCard>
//             ))}
//           </NearbyBarsScroll>
//         ) : (
//           renderNearbyBars()
//         )}

//         {/* Snackbar and LoginModal */}
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//           TransitionComponent={(props) => <Slide {...props} direction="left" />}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>

//         <LoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)} />
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   Box, Avatar, Card, CardMedia, Typography, Container, Button, CardContent, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
//   Tooltip, Snackbar, Slide, Alert, TextField
// } from '@mui/material';
// import {
//   ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Add as AddIcon,
//   Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
//   Edit as EditIcon, Send as SendIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon
// } from '@mui/icons-material';
// import { styled, keyframes } from '@mui/system';
// import supabase from './supabaseClient';
// import LoginModal from './LoginModal';

// const MAX_DEPTH = 15;

// const pulseKeyframe = keyframes`
//   0% {
//     background-position: 0% 0%;
//   }
//   100% {
//     background-position: -135% 0%;
//   }
// `;

// const PulsingSkeleton = styled('div')(({ theme, width, height }) => ({
//   width: width || '100%',
//   height: height || '20px',
//   borderRadius: '4px',
//   background: `linear-gradient(90deg, ${theme.palette.background.paper} 0px, ${theme.palette.action.hover} 40px, ${theme.palette.background.paper} 80px)`,
//   backgroundSize: '400% 100%',
//   animation: `${pulseKeyframe} 1.6s ease-in-out infinite`,
// }));

// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   borderRadius: 0,
//   margin: theme.spacing(2, 0),
//   '&::after': {
//     content: '""',
//     display: 'block',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//   },
// }));

// const RatingCircle = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#eb9c64',
//   borderRadius: '50%',
//   padding: theme.spacing(1),
//   width: 50,
//   height: 50,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '#4e342e',
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
// }));

// const TagsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(2),
// }));

// const AddPhotoFab = styled(Fab)(({ theme }) => ({
//   position: 'absolute',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   backgroundColor: '#8fbf9f',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#76a688',
//   },
// }));

// const CommentContainer = styled(Box)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   paddingLeft: theme.spacing(2),
//   borderLeft: `2px solid ${theme.palette.divider}`,
// }));

// const ReplyContainer = styled(Box)(({ theme, depth = 0 }) => ({
//   marginTop: theme.spacing(1),
//   marginLeft: theme.spacing(4 * depth),
//   paddingLeft: theme.spacing(2),
//   borderLeft: `2px solid ${theme.palette.divider}`,
// }));

// const NearbyBarsScroll = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   overflowX: 'auto',
//   padding: theme.spacing(2),
//   '&::-webkit-scrollbar': {
//     display: 'none',
//   },
//   scrollbarWidth: 'none',
//   msOverflowStyle: 'none',
// }));

// const NearbyBarCard = styled(Card)(({ theme }) => ({
//   minWidth: 200,
//   maxWidth: 200,
//   margin: theme.spacing(0, 1),
//   cursor: 'pointer',
// }));

// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     background: { default: '#F5ECD7', paper: '#FFFFFF' },
//     primary: { main: '#eb9c64' },
//     secondary: { main: '#8fbf9f' },
//     text: { primary: '#353535' },
//     action: { hover: '#F0F0F0' },
//   },
// });

// const KavaBarDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [barData, setBarData] = useState(null);
//   const [comments, setComments] = useState([]);
//   const [relatedBars, setRelatedBars] = useState([]);
//   const [regulars, setRegulars] = useState([]);
//   const [visitorsCount, setVisitorsCount] = useState(null);
//   const [favoritesCount, setFavoritesCount] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [userLoading, setUserLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [user, setUser] = useState(null);
//   const [profileData, setProfileData] = useState({ username: '', avatar_url: '' });
//   const [beenToArray, setBeenToArray] = useState([]);
//   const [favoritesArray, setFavoritesArray] = useState([]);
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [newCommentContent, setNewCommentContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [replyContent, setReplyContent] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);

//       if (!id) {
//         setError("No bar ID provided");
//         setLoading(false);
//         return;
//       }

//       try {
//         const { data: barData, error: barError } = await supabase
//           .from('bars')
//           .select('*')
//           .eq('id', id)
//           .single();

//         if (barError) throw barError;
//         setBarData(barData);

//         const { data: commentsData, error: commentsError } = await supabase
//           .from('comments_new')
//           .select(`
//           *,
//           profiles:user_id (
//             username,
//             avatar_url
//           )
//         `)
//           .eq('bar_name', barData.name)
//           .order('created_at', { ascending: false });

//         if (commentsError) throw commentsError;
//         setComments(commentsData.map(comment => ({
//           ...comment,
//           profiles: comment.profiles[0] // Flatten the profiles array
//         })));

//         const [relatedBarsResponse, visitorsResponse, favoritesResponse, regularsResponse] = await Promise.all([
//           supabase.from('bars').select('*').eq('city', barData.city).neq('id', id).limit(10),
//           supabase.from('profiles').select('*', { count: 'exact' }).contains('beenTo', [id]),
//           supabase.from('profiles').select('*', { count: 'exact' }).contains('favorites', [id]),
//           supabase.from('profiles').select('id, username, avatar_url').eq('homebar', id)
//         ]);

//         if (relatedBarsResponse.error) throw relatedBarsResponse.error;
//         setRelatedBars(relatedBarsResponse.data);

//         if (visitorsResponse.error) throw visitorsResponse.error;
//         setVisitorsCount(visitorsResponse.count);

//         if (favoritesResponse.error) throw favoritesResponse.error;
//         setFavoritesCount(favoritesResponse.count);

//         if (regularsResponse.error) throw regularsResponse.error;
//         setRegulars(regularsResponse.data);

//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       setUserLoading(true);
//       try {
//         const { data: authData, error: authError } = await supabase.auth.getUser();

//         if (authError) throw authError;

//         const authUser = authData?.user;
//         if (authUser) {
//           setUser(authUser);

//           const { data: profile, error: profileError } = await supabase
//             .from('profiles')
//             .select('username, avatar_url, beenTo, favorites')
//             .eq('id', authUser.id)
//             .single();

//           if (profileError || !profile) {
//             const randomNumber = Math.floor(1000 + Math.random() * 9000);
//             setProfileData({
//               username: `BulaMan${randomNumber}`,
//               avatar_url: '/default-avatar.png'
//             });
//           } else {
//             setProfileData(profile);
//             setBeenToArray(profile.beenTo || []);
//             setFavoritesArray(profile.favorites || []);
//           }
//         }
//       } catch (err) {
//         console.error('Error fetching profile data:', err);
//         const randomNumber = Math.floor(1000 + Math.random() * 9000);
//         setProfileData({
//           username: `BulaMan${randomNumber}`,
//           avatar_url: '/default-avatar.png'
//         });
//       } finally {
//         setUserLoading(false);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleSubmitCarouselImages = async (event) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     try {
//       const file = files[0];
//       const fileName = `${id}/${Date.now()}-carousel-image`;

//       const { error } = await supabase.storage
//         .from('barphotos')
//         .upload(fileName, file, { upsert: true });

//       if (error) throw error;

//       setSnackbarMessage('Image uploaded successfully');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       setSnackbarMessage('Failed to upload image');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleBeenTo = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedBeenTo = beenToArray.includes(id)
//         ? beenToArray.filter(barId => barId !== id)
//         : [...beenToArray, id];

//       await supabase
//         .from("profiles")
//         .update({ beenTo: updatedBeenTo })
//         .eq("id", user.id);

//       setBeenToArray(updatedBeenTo);
//       setVisitorsCount(prevCount => updatedBeenTo.includes(id) ? prevCount + 1 : prevCount - 1);
//     } catch (error) {
//       console.error("Error handling beenTo:", error);
//     }
//   };

//   const handleFavorite = async () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const updatedFavorites = favoritesArray.includes(id)
//         ? favoritesArray.filter(barId => barId !== id)
//         : [...favoritesArray, id];

//       await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       setFavoritesArray(updatedFavorites);
//       setFavoritesCount(prevCount => updatedFavorites.includes(id) ? prevCount + 1 : prevCount - 1);
//     } catch (error) {
//       console.error("Error handling favorite:", error);
//     }
//   };

//   const handleAddComment = useCallback(async (commentContent, parentId = null) => {
//     if (!user || !barData) {
//       setShowLoginModal(true);
//       return;
//     }

//     if (!commentContent.trim()) {
//       return; // Don't add empty comments
//     }

//     if (!profileData.username) {
//       console.error("Username is not set");
//       setSnackbarMessage('Unable to post comment: username not set');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newId = uuidv4();

//     const newComment = {
//       id: newId,
//       comment: commentContent,
//       bar_id: barData.id,
//       bar_name: barData.name,
//       rating: 0,
//       user_id: user.id,
//       username: profileData.username,
//       parent_id: parentId,  // This will be null for top-level comments
//       likes: 0,
//       dislikes: 0,
//       created_at: new Date().toISOString(),
//     };

//     const newCommentWithProfile = {
//       ...newComment,
//       profiles: {
//         username: profileData.username,
//         avatar_url: profileData.avatar_url
//       }
//     };

//     // Optimistically update the UI
//     setComments(prevComments => {
//       if (parentId) {
//         // For replies, find the parent comment and add the reply to it
//         return prevComments.map(comment =>
//           comment.id === parentId
//             ? { ...comment, replies: [...(comment.replies || []), newCommentWithProfile] }
//             : comment
//         );
//       } else {
//         // For top-level comments, add to the beginning of the list
//         return [newCommentWithProfile, ...prevComments];
//       }
//     });

//     try {
//       const { data: savedComment, error } = await supabase
//         .from('comments_new')
//         .insert(newComment)
//         .single();

//       if (error) throw error;

//       if (savedComment) {
//         const updatedComment = {
//           ...savedComment,
//           profiles: {
//             username: profileData.username,
//             avatar_url: profileData.avatar_url
//           }
//         };

//         // Update the state with the saved comment
//         setComments(prevComments => {
//           if (parentId) {
//             return prevComments.map(comment =>
//               comment.id === parentId
//                 ? {
//                   ...comment,
//                   replies: (comment.replies || []).map(reply =>
//                     reply.id === newId ? updatedComment : reply
//                   )
//                 }
//                 : comment
//             );
//           } else {
//             return prevComments.map(comment =>
//               comment.id === newId ? updatedComment : comment
//             );
//           }
//         });
//       }
//     } catch (error) {
//       console.error("Error adding comment:", error);
//       setSnackbarMessage('Failed to add comment');
//       setSnackbarOpen(true);
//       // Remove the optimistically added comment if the server request fails
//       setComments(prevComments => {
//         if (parentId) {
//           return prevComments.map(comment =>
//             comment.id === parentId
//               ? { ...comment, replies: (comment.replies || []).filter(reply => reply.id !== newId) }
//               : comment
//           );
//         } else {
//           return prevComments.filter(comment => comment.id !== newId);
//         }
//       });
//     }
//   }, [user, barData, profileData, setShowLoginModal, setSnackbarMessage, setSnackbarOpen]);
//   const handleReaction = async (commentId, reactionType) => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     try {
//       const comment = comments.find(c => c.id === commentId);
//       const newReactionCount = comment[reactionType] + 1;

//       const { error } = await supabase
//         .from('comments_new')
//         .update({ [reactionType]: newReactionCount })
//         .eq('id', commentId);

//       if (error) throw error;
//       setComments(comments.map(comment =>
//         comment.id === commentId ? { ...comment, [reactionType]: newReactionCount } : comment
//       ));
//     } catch (error) {
//       console.error(`Error handling ${reactionType}:`, error);
//     }
//   };

//   const renderComments = useCallback((parentId = null, depth = 0) => {
//     if (depth > MAX_DEPTH) return null;

//     const filteredComments = comments.filter(comment => comment && comment.parent_id === parentId);

//     return filteredComments.map((comment) => {
//       if (!comment || !comment.id) return null;

//       const profile = comment.profiles || {};
//       const avatarUrl = profile.avatar_url || '/default-avatar.png';
//       const username = profile.username || 'Unknown User';

//       return (
//         <CommentContainer key={comment.id} depth={depth}>
//           <Box display="flex" alignItems="center" mb={1}>
//             <Avatar src={avatarUrl} alt={username} />
//             <Box ml={2}>
//               <Typography variant="subtitle2">{username}</Typography>
//               <Typography variant="caption" color="textSecondary">
//                 {new Date(comment.created_at).toLocaleDateString()}
//               </Typography>
//             </Box>
//           </Box>
//           <Typography variant="body1" mb={1}>{comment.comment}</Typography>
//           <Box display="flex" alignItems="center">
//             <IconButton size="small" onClick={() => handleReaction(comment.id, 'likes')}>
//               <ThumbUpIcon />
//             </IconButton>
//             <Typography variant="caption" sx={{ mr: 1 }}>{comment.likes}</Typography>
//             <IconButton size="small" onClick={() => handleReaction(comment.id, 'dislikes')}>
//               <ThumbDownIcon />
//             </IconButton>
//             <Typography variant="caption" sx={{ mr: 1 }}>{comment.dislikes}</Typography>
//             <Button size="small" onClick={() => setReplyingTo(comment.id)}>Reply</Button>
//           </Box>
//           {replyingTo === comment.id && (
//             <Box mt={2}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Write a reply..."
//                 value={replyContent}
//                 onChange={(e) => setReplyContent(e.target.value)}
//                 onKeyPress={(e) => handleKeyPress(e, replyContent, comment.id)}
//               />
//               <Button
//                 startIcon={<SendIcon />}
//                 onClick={() => {
//                   handleAddComment(replyContent, comment.id);
//                   setReplyContent('');
//                   setReplyingTo(null);
//                 }}
//               >
//                 Send Reply
//               </Button>
//             </Box>
//           )}
//           <ReplyContainer depth={depth}>
//             {renderComments(comment.id, depth + 1)}
//           </ReplyContainer>
//         </CommentContainer>
//       );
//     });
//   }, [comments, replyingTo, replyContent, handleReaction, setReplyingTo, handleAddComment]);

//   const handleKeyPress = useCallback((e, commentContent, parentId = null) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleAddComment(commentContent, parentId);
//       setNewCommentContent('');
//       setReplyContent('');
//       setReplyingTo(null);
//     }
//   }, [handleAddComment]);

//   const handleEditBar = () => {
//     if (!user) {
//       setShowLoginModal(true);
//       return;
//     }

//     navigate(`/edit/kavabar/${id}`);
//   };

//   const renderNearbyBars = () => (
//     <NearbyBarsScroll>
//       {relatedBars.map((bar) => (
//         <NearbyBarCard key={bar.id} onClick={() => navigate(`/kavabar/${bar.id}`)}>
//           <CardMedia
//             component="img"
//             height="140"
//             image={bar.store_image || '/default.png'}
//             alt={bar.name}
//           />
//           <CardContent>
//             <Typography variant="subtitle1" noWrap>{bar.name}</Typography>
//             <Typography variant="body2" color="text.secondary" noWrap>
//               {bar.city}, {bar.state}
//             </Typography>
//           </CardContent>
//         </NearbyBarCard>
//       ))}
//     </NearbyBarsScroll>
//   );

//   if (error) return <Typography color="error">{error}</Typography>;

//   const isBeenTo = beenToArray.includes(id);
//   const isFavorite = favoritesArray.includes(id);

//   const cleanAndSplitTags = (tags) => {
//     if (!tags) return [];
//     const cleanedTags = tags.replace(/[\[\]"']/g, '').split(',');
//     return cleanedTags.map(tag => tag.trim()).filter(tag => tag !== '');
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" sx={{
//         pt: 10,
//         pb: 20,
//         px: { xs: 2, sm: 4, md: 6, lg: 8 }
//       }}>
//         {/* Bar Details */}
//         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
//             {loading ? (
//               <PulsingSkeleton width={200} height={20} />
//             ) : (
//               <>
//                 <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
//                 <span style={{ opacity: 0.5 }}> / </span>
//                 <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
//                 <span style={{ opacity: 0.7 }}> / </span>
//                 <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
//               </>
//             )}
//           </Typography>
//         </Box>

//         <StyledCard>
//           {loading ? (
//             <PulsingSkeleton width="100%" height={300} />
//           ) : (
//             <CardMedia
//               component="img"
//               image={barData.store_image || '/default.png'}
//               alt={barData.name}
//               sx={{
//                 objectFit: 'cover',
//                 borderRadius: '8px',
//                 height: { xs: '300px', md: '150px' }
//               }}
//             />
//           )}
//           {loading ? (
//             <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
//               <PulsingSkeleton width={50} height={50} style={{ borderRadius: '50%' }} />
//             </Box>
//           ) : (
//             <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>
//           )}
//           {userLoading ? (
//             <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
//               <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%' }} />
//             </Box>
//           ) : (
//             <AddPhotoFab aria-label="add">
//               <input
//                 type="file"
//                 accept="image/*"
//                 style={{ display: 'none' }}
//                 onChange={handleSubmitCarouselImages}
//               />
//               <AddIcon />
//             </AddPhotoFab>
//           )}
//         </StyledCard>

//         {/* Bar Info */}
//         <Box sx={{ mb: 4, textAlign: 'center' }}>
//           <Typography variant="h4" sx={{ mb: 2 }}>
//             {loading ? <PulsingSkeleton width={300} height={40} /> : barData.name}
//           </Typography>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             <LocationOnIcon />
//             <Typography variant="body1" sx={{ ml: 1 }}>
//               {loading ? <PulsingSkeleton width={150} height={20} /> : `${barData.city}, ${barData.state}`}
//             </Typography>
//           </Box>

//           {/* Visitor & Favorite Buttons */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
//             {userLoading ? (
//               <>
//                 <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
//                 <PulsingSkeleton width={60} height={20} style={{ marginRight: '16px' }} />
//                 <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
//                 <PulsingSkeleton width={60} height={20} />
//               </>
//             ) : (
//               <>
//                 <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
//                   <IconButton
//                     onClick={handleBeenTo}
//                     color={isBeenTo ? "primary" : "default"}
//                   >
//                     {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                   </IconButton>
//                 </Tooltip>
//                 <Typography variant="body1" sx={{ mx: 1 }}>
//                   {loading ? <PulsingSkeleton width={50} height={20} /> : `🧳 ${visitorsCount} Visitors`}
//                 </Typography>
//                 <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
//                   <IconButton
//                     onClick={handleFavorite}
//                     color={isFavorite ? "secondary" : "default"}
//                   >
//                     {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//                   </IconButton>
//                 </Tooltip>
//                 <Typography variant="body1" sx={{ mx: 1 }}>
//                   {loading ? <PulsingSkeleton width={50} height={20} /> : `❤️ ${favoritesCount} Favorites`}
//                 </Typography>
//               </>
//             )}
//           </Box>

//           {/* Buttons */}
//           {loading ? (
//             <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
//               <PulsingSkeleton width={100} height={36} />
//               <PulsingSkeleton width={100} height={36} />
//               <PulsingSkeleton width={100} height={36} />
//               {user && <PulsingSkeleton width={100} height={36} />}
//             </Box>
//           ) : (
//             <>
//               <Button
//                 startIcon={<LanguageIcon />}
//                 href={barData.website}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{ mx: 1 }}
//               >
//                 Website
//               </Button>
//               <Button
//                 startIcon={<PhoneIcon />}
//                 href={`tel:${barData.phone_number}`}
//                 sx={{ mx: 1 }}>
//                 Phone
//               </Button>
//               <Button
//                 startIcon={<LocationOnIcon />}
//                 href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{ mx: 1 }}
//               >
//                 Directions
//               </Button>
//               {user && (
//                 <Button
//                   startIcon={<EditIcon />}
//                   onClick={handleEditBar}
//                   sx={{ mx: 1 }}
//                 >
//                   Edit Bar Details
//                 </Button>
//               )}
//             </>
//           )}
//         </Box>

//         {/* Regulars */}
//         {userLoading ? (
//           <Box sx={{ mb: 4, textAlign: 'center' }}>
//             <PulsingSkeleton width={100} height={24} style={{ marginBottom: '16px' }} />
//             <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//               {[...Array(5)].map((_, index) => (
//                 <PulsingSkeleton key={index} width={100} height={32} style={{ borderRadius: '16px' }} />
//               ))}
//             </Box>
//           </Box>
//         ) : (
//           regulars.length > 0 && (
//             <Box sx={{ mb: 4, textAlign: 'center' }}>
//               <Typography variant="h6" sx={{ mb: 2 }}>Regulars</Typography>
//               <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
//                 {regulars.map((regular) => (
//                   <Chip
//                     key={regular.id}
//                     avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
//                     label={regular.username}
//                     onClick={() => navigate(`/user/${regular.username}`)}
//                     clickable
//                     sx={{ fontWeight: 'bold' }}
//                   />
//                 ))}
//               </Box>
//             </Box>
//           )
//         )}

//         {/* Tags */}
//         {loading ? (
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
//             {[...Array(5)].map((_, index) => (
//               <PulsingSkeleton key={index} width={80} height={32} style={{ borderRadius: '16px' }} />
//             ))}
//           </Box>
//         ) : (
//           barData.tags && (
//             <TagsContainer>
//               {cleanAndSplitTags(barData.tags).map((tag, index) => (
//                 <Chip
//                   key={index}
//                   label={tag}
//                   variant="outlined"
//                   size="small"
//                 />
//               ))}
//             </TagsContainer>
//           )
//         )}

//         {/* Comments Section */}
//         <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>Kava Salon</Typography>
//         <Box mb={4}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Write a comment..."
//             value={newCommentContent}
//             onChange={(e) => setNewCommentContent(e.target.value)}
//             onKeyPress={(e) => handleKeyPress(e, newCommentContent)}
//           />
//           <Button
//             startIcon={<SendIcon />}
//             onClick={() => {
//               handleAddComment(newCommentContent);
//               setNewCommentContent('');
//             }}
//           >
//             Post Comment
//           </Button>
//         </Box>
//         {loading ? (
//           <Box>
//             {[...Array(3)].map((_, index) => (
//               <Box key={index} sx={{ mb: 3 }}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                   <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '16px' }} />
//                   <Box>
//                     <PulsingSkeleton width={120} height={20} style={{ marginBottom: '4px' }} />
//                     <PulsingSkeleton width={80} height={16} />
//                   </Box>
//                 </Box>
//                 <PulsingSkeleton width="100%" height={60} style={{ marginBottom: '8px' }} />

//                 <PulsingSkeleton width={100} height={32} />
//               </Box>
//             ))}
//           </Box>
//         ) : (
//           renderComments()
//         )}

//         {/* Nearby Bars */}
//         <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Nearby Kava Bars</Typography>
//         {loading ? (
//           <NearbyBarsScroll>
//             {[...Array(5)].map((_, index) => (
//               <NearbyBarCard key={index}>
//                 <PulsingSkeleton width={200} height={140} />
//                 <CardContent>
//                   <PulsingSkeleton width={160} height={24} style={{ marginBottom: '8px' }} />
//                   <PulsingSkeleton width={120} height={20} />
//                 </CardContent>
//               </NearbyBarCard>
//             ))}
//           </NearbyBarsScroll>
//         ) : (
//           renderNearbyBars()
//         )}

//         {/* Snackbar and LoginModal */}
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//           TransitionComponent={(props) => <Slide {...props} direction="left" />}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>

//         <LoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)} />
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default KavaBarDetail;
import React, { useState, useEffect, useCallback } from 'react';
import { Rating } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box, Avatar, Card, CardMedia, Typography, Container, Button, CardContent, Chip, IconButton, Fab, useMediaQuery, ThemeProvider, createTheme,
  Tooltip, Snackbar, Slide, Alert, TextField, ImageList, ImageListItem, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon, Phone as PhoneIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, Camera as CameraIcon,
  Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
  Edit as EditIcon, Send as SendIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon,
  ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/system';
import supabase from './supabaseClient';
import LoginModal from './LoginModal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const PhotoUploadOverlay = ({ onFileSelect }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 1,
      }}
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        onChange={onFileSelect}
      />
      <label htmlFor="icon-button-file">
        <Tooltip title="Upload your own photos of the bar">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
          >
            <CameraAltIcon />
          </IconButton>
        </Tooltip>
      </label>
    </Box>
  );
};

const MAX_DEPTH = 15;

const pulseKeyframe = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

const PulsingSkeleton = styled('div')(({ theme, width, height }) => ({
  width: width || '100%',
  height: height || '20px',
  borderRadius: '4px',
  background: `linear-gradient(90deg, ${theme.palette.background.paper} 0px, ${theme.palette.action.hover} 40px, ${theme.palette.background.paper} 80px)`,
  backgroundSize: '400% 100%',
  animation: `${pulseKeyframe} 1.6s ease-in-out infinite`,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: 0,
  margin: theme.spacing(2, 0),
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
  },
}));

const RatingCircle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: '#eb9c64',
  borderRadius: '50%',
  padding: theme.spacing(1),
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#4e342e',
  fontWeight: 'bold',
  fontSize: '1.2rem',
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const CommentContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  borderLeft: `2px solid ${theme.palette.divider}`,
}));

const ReplyContainer = styled(Box)(({ theme, depth = 0 }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(4 * depth),
  paddingLeft: theme.spacing(2),
  borderLeft: `2px solid ${theme.palette.divider}`,
}));

const NearbyBarsScroll = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
}));

const NearbyBarCard = styled(Card)(({ theme }) => ({
  minWidth: 200,
  maxWidth: 200,
  margin: theme.spacing(0, 1),
  cursor: 'pointer',
}));

const PhotoCarousel = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(1),
  padding: theme.spacing(2, 0),
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
}));

const CarouselButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const theme = createTheme({
  palette: {
    mode: 'light',
    background: { default: '#F5ECD7', paper: '#FFFFFF' },
    primary: { main: '#eb9c64' },
    secondary: { main: '#8fbf9f' },
    text: { primary: '#353535' },
    action: { hover: '#F0F0F0' },
  },
});

const KavaBarDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [barData, setBarData] = useState(null);
  const [comments, setComments] = useState([]);
  const [relatedBars, setRelatedBars] = useState([]);
  const [regulars, setRegulars] = useState([]);
  const [visitorsCount, setVisitorsCount] = useState(null);
  const [favoritesCount, setFavoritesCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [userPhotos, setUserPhotos] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [profileData, setProfileData] = useState({ username: '', avatar_url: '' });
  const [beenToArray, setBeenToArray] = useState([]);
  const [favoritesArray, setFavoritesArray] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [newCommentContent, setNewCommentContent] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [mainPhoto, setMainPhoto] = useState(null);
  const [newBarVibe, setNewBarVibe] = useState('');
  const [newMenuHighlights, setNewMenuHighlights] = useState('');

  const handleSubmitReview = async (type) => {
    try {
      const updatedReviews = {
        ...barData.generated_reviews,
        [type]: type === 'Bar Vibe' ? newBarVibe : newMenuHighlights,
      };

      const { error } = await supabase
        .from('bars')
        .update({ generated_reviews: updatedReviews })
        .eq('id', barData.id);

      if (error) throw error;

      setBarData((prevData) => ({
        ...prevData,
        generated_reviews: updatedReviews,
      }));

      if (type === 'Bar Vibe') setNewBarVibe('');
      else setNewMenuHighlights('');
    } catch (error) {
      console.error('Error updating review:', error);
    }
  };

  const handlePrevPhoto = () => {
    setCarouselIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleRatingChange = async (newRating) => {
    const currentRating = barData.rating; // Assume this is the current rating from Google
    const newWeightedRating = (0.8 * currentRating) + (0.2 * newRating);

    // Update the rating in the database
    try {
      const { data, error } = await supabase
        .from('bars')
        .update({ rating: newWeightedRating })
        .eq('id', barData.id);

      if (error) throw error;

      // Notify the user
      setSnackbarMessage('Thank you for your rating!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating rating:', error);
    }
  };

  const handleNextPhoto = () => {
    setCarouselIndex((prevIndex) => (prevIndex < userPhotos.length - 1 ? prevIndex + 1 : prevIndex));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (!id) {
        setError("No bar ID provided");
        setLoading(false);
        return;
      }

      try {
        const { data: barData, error: barError } = await supabase
          .from('bars')
          .select('*')
          .eq('id', id)
          .single();

        if (barError) throw barError;
        setBarData(barData);

        const { data: photoData, error: photoError } = await supabase
          .storage
          .from('barphotos')
          .list(`${id}/`);

        if (photoError) throw photoError;

        const photoUrls = await Promise.all(
          photoData.map(async (photo) => {
            const { data } = supabase.storage.from('barphotos').getPublicUrl(`${id}/${photo.name}`);
            return data.publicUrl;
          })
        );

        setUserPhotos(photoUrls);

        if (barData.store_image) {
          setMainPhoto(barData.store_image);
        } else if (photoUrls.length > 0) {
          setMainPhoto(photoUrls[0]);
        } else {
          setMainPhoto('/default.png');
        }

        const { data: commentsData, error: commentsError } = await supabase
          .from('comments_new')
          .select(`
          *,
          profiles:user_id (
            username,
            avatar_url
          )
        `)
          .eq('bar_name', barData.name)
          .order('created_at', { ascending: false });

        if (commentsError) throw commentsError;
        setComments(
          commentsData.map((comment) => ({
            ...comment,
            profiles: comment.profiles[0],
          }))
        );

        const [relatedBarsResponse, visitorsResponse, favoritesResponse, regularsResponse] = await Promise.all([
          supabase.from('bars').select('*').eq('city', barData.city).neq('id', id).limit(10),
          supabase.from('profiles').select('*', { count: 'exact' }).contains('beenTo', [id]),
          supabase.from('profiles').select('*', { count: 'exact' }).contains('favorites', [id]),
          supabase.from('profiles').select('id, username, avatar_url').eq('homebar', id),
        ]);

        if (relatedBarsResponse.error) throw relatedBarsResponse.error;
        setRelatedBars(relatedBarsResponse.data);

        if (visitorsResponse.error) throw visitorsResponse.error;
        setVisitorsCount(visitorsResponse.count);

        if (favoritesResponse.error) throw favoritesResponse.error;
        setFavoritesCount(favoritesResponse.count);

        if (regularsResponse.error) throw regularsResponse.error;
        setRegulars(regularsResponse.data);
      } catch (err) {
        setError(err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchUserData = async () => {
      setUserLoading(true);
      try {
        const { data: authData, error: authError } = await supabase.auth.getUser();

        if (authError) throw authError;

        const authUser = authData?.user;
        if (authUser) {
          setUser(authUser);

          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('username, avatar_url, beenTo, favorites')
            .eq('id', authUser.id)
            .single();

          if (profileError || !profile) {
            const randomNumber = Math.floor(1000 + Math.random() * 9000);
            setProfileData({
              username: `BulaMan${randomNumber}`,
              avatar_url: '/default-avatar.png',
            });
          } else {
            setProfileData(profile);
            setBeenToArray(profile.beenTo || []);
            setFavoritesArray(profile.favorites || []);
          }
        }
      } catch (err) {
        console.error('Error fetching profile data:', err);
        const randomNumber = Math.floor(1000 + Math.random() * 9000);
        setProfileData({
          username: `BulaMan${randomNumber}`,
          avatar_url: '/default-avatar.png',
        });
      } finally {
        setUserLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handlePhotoUpload = async (event) => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${id}/${Date.now()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('barphotos')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: publicUrlData } = supabase.storage
        .from('barphotos')
        .getPublicUrl(fileName);

      if (!publicUrlData) throw new Error('Failed to get public URL');

      const newPhotoUrl = publicUrlData.publicUrl;

      setUserPhotos((prevPhotos) => [...prevPhotos, newPhotoUrl]);

      if (!mainPhoto || mainPhoto === '/default.png') {
        setMainPhoto(newPhotoUrl);
      }

      setSnackbarMessage('Photo uploaded successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error uploading photo:', error);
      setSnackbarMessage('Failed to upload photo');
      setSnackbarOpen(true);
    }
  };

  const handleBeenTo = async () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    try {
      const updatedBeenTo = beenToArray.includes(id)
        ? beenToArray.filter((barId) => barId !== id)
        : [...beenToArray, id];

      await supabase
        .from('profiles')
        .update({ beenTo: updatedBeenTo })
        .eq('id', user.id);

      setBeenToArray(updatedBeenTo);
      setVisitorsCount((prevCount) => (updatedBeenTo.includes(id) ? prevCount + 1 : prevCount - 1));
    } catch (error) {
      console.error('Error handling beenTo:', error);
    }
  };

  const handleFavorite = async () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    try {
      const updatedFavorites = favoritesArray.includes(id)
        ? favoritesArray.filter((barId) => barId !== id)
        : [...favoritesArray, id];

      await supabase
        .from('profiles')
        .update({ favorites: updatedFavorites })
        .eq('id', user.id);

      setFavoritesArray(updatedFavorites);
      setFavoritesCount((prevCount) => (updatedFavorites.includes(id) ? prevCount + 1 : prevCount - 1));
    } catch (error) {
      console.error('Error handling favorite:', error);
    }
  };

  const handleAddComment = useCallback(
    async (commentContent, parentId = null) => {
      if (!user || !barData) {
        setShowLoginModal(true);
        return;
      }

      if (!commentContent.trim()) {
        return;
      }

      if (!profileData.username) {
        console.error('Username is not set');
        setSnackbarMessage('Unable to post comment: username not set');
        setSnackbarOpen(true);
        return;
      }

      const newId = uuidv4();

      const newComment = {
        id: newId,
        comment: commentContent,
        bar_id: barData.id,
        bar_name: barData.name,
        rating: 0,
        user_id: user.id,
        username: profileData.username,
        parent_id: parentId,
        likes: 0,
        dislikes: 0,
        created_at: new Date().toISOString(),
      };

      const newCommentWithProfile = {
        ...newComment,
        profiles: {
          username: profileData.username,
          avatar_url: profileData.avatar_url,
        },
      };

      setComments((prevComments) => {
        if (parentId) {
          return prevComments.map((comment) =>
            comment.id === parentId
              ? { ...comment, replies: [...(comment.replies || []), newCommentWithProfile] }
              : comment
          );
        } else {
          return [newCommentWithProfile, ...prevComments];
        }
      });

      try {
        const { data: savedComment, error } = await supabase
          .from('comments_new')
          .insert(newComment)
          .single();

        if (error) throw error;

        if (savedComment) {
          const updatedComment = {
            ...savedComment,
            profiles: {
              username: profileData.username,
              avatar_url: profileData.avatar_url,
            },
          };

          setComments((prevComments) => {
            if (parentId) {
              return prevComments.map((comment) =>
                comment.id === parentId
                  ? {
                    ...comment,
                    replies: (comment.replies || []).map((reply) => (reply.id === newId ? updatedComment : reply)),
                  }
                  : comment
              );
            } else {
              return prevComments.map((comment) => (comment.id === newId ? updatedComment : comment));
            }
          });
        }
      } catch (error) {
        console.error('Error adding comment:', error);
        setSnackbarMessage('Failed to add comment');
        setSnackbarOpen(true);
        setComments((prevComments) => {
          if (parentId) {
            return prevComments.map((comment) =>
              comment.id === parentId
                ? { ...comment, replies: (comment.replies || []).filter((reply) => reply.id !== newId) }
                : comment
            );
          } else {
            return prevComments.filter((comment) => comment.id !== newId);
          }
        });
      }
    },
    [user, barData, profileData, setShowLoginModal, setSnackbarMessage, setSnackbarOpen]
  );

  const handleReaction = async (commentId, reactionType) => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    try {
      const comment = comments.find((c) => c.id === commentId);
      const newReactionCount = comment[reactionType] + 1;

      const { error } = await supabase
        .from('comments_new')
        .update({ [reactionType]: newReactionCount })
        .eq('id', commentId);

      if (error) throw error;
      setComments(
        comments.map((comment) =>
          comment.id === commentId ? { ...comment, [reactionType]: newReactionCount } : comment
        )
      );
    } catch (error) {
      console.error(`Error handling ${reactionType}:`, error);
    }
  };

  const renderComments = useCallback(
    (parentId = null, depth = 0) => {
      if (depth > MAX_DEPTH) return null;

      const filteredComments = comments.filter((comment) => comment && comment.parent_id === parentId);

      return filteredComments.map((comment) => {
        if (!comment || !comment.id) return null;

        const profile = comment.profiles || {};
        const avatarUrl = profile.avatar_url || '/default-avatar.png';
        const username = profile.username || 'Unknown User';

        return (
          <CommentContainer key={comment.id}>
            <Box display="flex" alignItems="center" mb={1}>
              <Avatar src={avatarUrl} alt={username} />
              <Box ml={2}>
                <Typography variant="subtitle2">{username}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {new Date(comment.created_at).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1" mb={1}>
              {comment.comment}
            </Typography>
            <Box display="flex" alignItems="center">
              <IconButton size="small" onClick={() => handleReaction(comment.id, 'likes')}>
                <ThumbUpIcon />
              </IconButton>
              <Typography variant="caption" sx={{ mr: 1 }}>
                {comment.likes}
              </Typography>
              <IconButton size="small" onClick={() => handleReaction(comment.id, 'dislikes')}>
                <ThumbDownIcon />
              </IconButton>
              <Typography variant="caption" sx={{ mr: 1 }}>
                {comment.dislikes}
              </Typography>
              <Button size="small" onClick={() => setReplyingTo(comment.id)}>
                Reply
              </Button>
            </Box>
            {replyingTo === comment.id && (
              <Box mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Write a reply..."
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, replyContent, comment.id)}
                />
                <Button
                  startIcon={<SendIcon />}
                  onClick={() => {
                    handleAddComment(replyContent, comment.id);
                    setReplyContent('');
                    setReplyingTo(null);
                  }}
                >
                  Send Reply
                </Button>
              </Box>
            )}
            <ReplyContainer>{renderComments(comment.id, depth + 1)}</ReplyContainer>
          </CommentContainer>
        );
      });
    },
    [comments, replyingTo, replyContent, handleReaction, setReplyingTo, handleAddComment]
  );

  const handleKeyPress = useCallback(
    (e, commentContent, parentId = null) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleAddComment(commentContent, parentId);
        setNewCommentContent('');
        setReplyContent('');
        setReplyingTo(null);
      }
    },
    [handleAddComment]
  );

  const handleEditBar = () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    navigate(`/edit/kavabar/${id}`);
  };

  const renderNearbyBars = () => (
    <NearbyBarsScroll>
      {relatedBars.map((bar) => (
        <NearbyBarCard key={bar.id} onClick={() => navigate(`/kavabar/${bar.id}`)}>
          <CardMedia
            component="img"
            image={bar.store_image || './default.png'}
            alt={bar.name}
            sx={{
              width: '150%', // Ensure the image container takes up the full width
              height: '100px', // Fixed height for uniformity
              objectFit: 'contain', // Contain the image within the container
              backgroundColor: '#F5ECD7', // Optional: Add a background color to fill empty space
            }}
          />
          <CardContent>
            <Typography variant="subtitle1" noWrap>
              {bar.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {bar.city}, {bar.state}
            </Typography>
          </CardContent>
        </NearbyBarCard>
      ))}
    </NearbyBarsScroll>
  );



  if (error) return <Typography color="error">{error}</Typography>;

  const isBeenTo = beenToArray.includes(id);
  const isFavorite = favoritesArray.includes(id);

  const cleanAndSplitTags = (tags) => {
    if (!tags) return [];
    const cleanedTags = tags.replace(/[\[\]"']/g, '').split(',');
    return cleanedTags.map((tag) => tag.trim()).filter((tag) => tag !== '');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        sx={{
          pt: 10,
          pb: 20,
          px: { xs: 2, sm: 4, md: 6, lg: 8 },
        }}
      >
        {/* Bar Details */}
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)} color="primary">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body2" sx={{ ml: 2, fontWeight: 'bold' }}>
            {loading ? (
              <PulsingSkeleton width={200} height={20} />
            ) : (
              <>
                <span style={{ opacity: 0.3 }}>{barData.state.toUpperCase()}</span>
                <span style={{ opacity: 0.5 }}> / </span>
                <span style={{ opacity: 0.5 }}>{barData.city.toUpperCase()}</span>
                <span style={{ opacity: 0.7 }}> / </span>
                <span style={{ opacity: 0.7 }}>{barData.name.toUpperCase()}</span>
              </>
            )}
          </Typography>
        </Box>

        <StyledCard>
          {loading ? (
            <PulsingSkeleton width="100%" height={300} />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image={mainPhoto}
                alt={barData.name}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '8px',
                  height: { xs: '300px', md: '400px' },
                }}
              />
              <PhotoUploadOverlay onFileSelect={handlePhotoUpload} />
            </Box>
          )}
          {!loading && <RatingCircle>{barData.rating?.toFixed(1) || 'N/A'}</RatingCircle>}
        </StyledCard>

        {/* Photo Carousel */}
        {!loading && userPhotos.length > 0 && (
          <Box sx={{ position: 'relative', mt: 2 }}>
            <PhotoCarousel>
              {userPhotos.map((photo, index) => (
                <Box
                  key={index}
                  component="img"
                  src={photo}
                  alt={`User photo ${index + 1}`}
                  sx={{
                    width: 100,
                    height: 100,
                    objectFit: 'cover',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setMainPhoto(photo)}
                />
              ))}
            </PhotoCarousel>
            <CarouselButton onClick={handlePrevPhoto} disabled={carouselIndex === 0} sx={{ left: 0 }}>
              <ChevronLeftIcon />
            </CarouselButton>
            <CarouselButton onClick={handleNextPhoto} disabled={carouselIndex >= userPhotos.length - 1} sx={{ right: 0 }}>
              <ChevronRightIcon />
            </CarouselButton>
          </Box>
        )}

        {/* Bar Info */}
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {loading ? <PulsingSkeleton width={300} height={40} /> : barData.name}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <LocationOnIcon />
            <Typography variant="body1" sx={{ ml: 1 }}>
              {loading ? <PulsingSkeleton width={150} height={20} /> : `${barData.city}, ${barData.state}`}
            </Typography>
          </Box>

          {/* Visitor & Favorite Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            {userLoading ? (
              <>
                <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
                <PulsingSkeleton width={60} height={20} style={{ marginRight: '16px' }} />
                <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
                <PulsingSkeleton width={60} height={20} />
              </>
            ) : (
              <>
                <Tooltip title={isBeenTo ? "You've been here" : "Mark as visited"}>
                  <IconButton onClick={handleBeenTo} color={isBeenTo ? "primary" : "default"}>
                    {isBeenTo ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Tooltip>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {loading ? <PulsingSkeleton width={50} height={20} /> : `🧳 ${visitorsCount} Visitors`}
                </Typography>
                <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
                  <IconButton onClick={handleFavorite} color={isFavorite ? "secondary" : "default"}>
                    {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                </Tooltip>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {loading ? <PulsingSkeleton width={50} height={20} /> : `❤️ ${favoritesCount} Favorites`}
                </Typography>
              </>
            )}
          </Box>

          {/* Buttons */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <PulsingSkeleton width={100} height={36} />
              <PulsingSkeleton width={100} height={36} />
              <PulsingSkeleton width={100} height={36} />
              {user && <PulsingSkeleton width={100} height={36} />}
            </Box>
          ) : (
            <>
              <Button startIcon={<LanguageIcon />} href={barData.website} target="_blank" rel="noopener noreferrer" sx={{ mx: 1 }}>
                Website
              </Button>
              <Button startIcon={<PhoneIcon />} href={`tel:${barData.phone_number}`} sx={{ mx: 1 }}>
                Phone
              </Button>
              <Button
                startIcon={<LocationOnIcon />}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(barData.address)}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mx: 1 }}
              >
                Directions
              </Button>
              {/* {user && (
                <Button startIcon={<EditIcon />} onClick={handleEditBar} sx={{ mx: 1 }}>
                  Edit Bar Details
                </Button>
              )} */}
            </>
          )}
        </Box>

        {/* Regulars */}
        {userLoading ? (
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <PulsingSkeleton width={100} height={24} style={{ marginBottom: '16px' }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
              {[...Array(5)].map((_, index) => (
                <PulsingSkeleton key={index} width={100} height={32} style={{ borderRadius: '16px' }} />
              ))}
            </Box>
          </Box>
        ) : (
          regulars.length > 0 && (
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Regulars
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
                {regulars.map((regular) => (
                  <Chip
                    key={regular.id}
                    avatar={<Avatar src={regular.avatar_url || '/default-avatar.png'} />}
                    label={regular.username}
                    onClick={() => navigate(`/user/${regular.username}`)}
                    clickable
                    sx={{ fontWeight: 'bold' }}
                  />
                ))}
              </Box>
            </Box>
          )
        )}

        {/* Tags */}
        {loading ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
            {[...Array(5)].map((_, index) => (
              <PulsingSkeleton key={index} width={80} height={32} style={{ borderRadius: '16px' }} />
            ))}
          </Box>
        ) : (
          barData.tags && (
            <TagsContainer>
              {cleanAndSplitTags(barData.tags).map((tag, index) => (
                <Chip key={index} label={tag} variant="outlined" size="small" />
              ))}
            </TagsContainer>
          )
        )}
        {(barData?.Bar_Vibe || barData?.Menu_Highlights) && (
          <Box sx={{ mt: 2 }}>
            {barData?.Bar_Vibe && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Vibe Check 𓆉</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{barData.Bar_Vibe}</Typography>
                </AccordionDetails>
              </Accordion>
            )}
            {barData?.Menu_Highlights && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Unique Treasures 🍯</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {barData?.Menu_Highlights &&
                      barData.Menu_Highlights.replace(/[\[\]']/g, '') // Remove brackets and single quotes
                        .split(',') // Split by commas
                        .map((item, index) => (
                          <Chip key={index} label={item.trim()} variant="outlined" size="small" />
                        ))
                    }
                  </Typography>


                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        )}

        {/* Comments Section */}
        <Typography variant="h5" sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          Reviews
        </Typography>
        <Box mb={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Write a comment..."
            value={newCommentContent}
            onChange={(e) => setNewCommentContent(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, newCommentContent)}
          />
          <Button
            startIcon={<SendIcon />}
            onClick={() => {
              handleAddComment(newCommentContent);
              setNewCommentContent('');
            }}
          >
            review
          </Button>
        </Box>
        {loading ? (
          <Box>
            {[...Array(3)].map((_, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <PulsingSkeleton width={40} height={40} style={{ borderRadius: '50%', marginRight: '16px' }} />
                  <Box>
                    <PulsingSkeleton width={120} height={20} style={{ marginBottom: '4px' }} />
                    <PulsingSkeleton width={80} height={16} />
                  </Box>
                </Box>
                <PulsingSkeleton width="100%" height={60} style={{ marginBottom: '8px' }} />
                <PulsingSkeleton width={100} height={32} />
              </Box>
            ))}
          </Box>
        ) : (
          renderComments()
        )}

        {/* Nearby Bars */}
        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
          Nearby Kava Bars
        </Typography>
        {loading ? (
          <NearbyBarsScroll>
            {[...Array(5)].map((_, index) => (
              <NearbyBarCard key={index}>
                <PulsingSkeleton width={200} height={140} />
                <CardContent>
                  <PulsingSkeleton width={160} height={24} style={{ marginBottom: '8px' }} />
                  <PulsingSkeleton width={120} height={20} />
                </CardContent>
              </NearbyBarCard>
            ))}
          </NearbyBarsScroll>
        ) : (
          renderNearbyBars()
        )}

        {/* Snackbar and LoginModal */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <LoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)} />
      </Container>
    </ThemeProvider>
  );
};

export default KavaBarDetail;









































