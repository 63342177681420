import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = "pk.eyJ1Ijoic2FteXRoZWNhcnRvZ3JhcGhlciIsImEiOiJjbGt3M3V4OGwwMGFtM2hyejdvZ2VwYXlyIn0.VnFjiwvwArLqfNSdVvrDSQ";
const MapComponent = ({ formData, setFormData }) => {
    const [viewState, setViewState] = useState({
        latitude: formData.latitude || 37.7749,
        longitude: formData.longitude || -122.4194,
        zoom: 10
    });

    useEffect(() => {
        if (formData.latitude && formData.longitude) {
            setViewState(prev => ({
                ...prev,
                latitude: formData.latitude,
                longitude: formData.longitude,
                zoom: 14
            }));
        }
    }, [formData.latitude, formData.longitude]);

    const handleMapClick = useCallback((event) => {
        if (event.lngLat) {
            const { lng, lat } = event.lngLat;
            setFormData(prev => ({
                ...prev,
                latitude: lat,
                longitude: lng,
            }));
        }
    }, [setFormData]);

    const handleLocateUser = useCallback(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setFormData(prev => ({
                        ...prev,
                        latitude,
                        longitude,
                    }));
                    setViewState(prev => ({
                        ...prev,
                        latitude,
                        longitude,
                        zoom: 14
                    }));
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [setFormData]);

    if (!MAPBOX_TOKEN) {
        console.error('Mapbox token is missing');
        return <Typography color="error">Map cannot be loaded due to missing configuration.</Typography>;
    }

    return (
        <Box sx={{ mt: 2, mb: 2, height: 400 }}>
            <Typography gutterBottom>Select Location on Map</Typography>
            <Button variant="contained" onClick={handleLocateUser} sx={{ mb: 2 }}>
                Use My Location
            </Button>
            <Map
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={MAPBOX_TOKEN}
                onClick={handleMapClick}
            >
                {formData.latitude && formData.longitude && (
                    <Marker
                        latitude={formData.latitude}
                        longitude={formData.longitude}
                        draggable
                        onDragEnd={(e) => {
                            if (e.lngLat) {
                                setFormData(prev => ({
                                    ...prev,
                                    latitude: e.lngLat.lat,
                                    longitude: e.lngLat.lng,
                                }));
                            }
                        }}
                    />
                )}
            </Map>
        </Box>
    );
};

export default MapComponent;