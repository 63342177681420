import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import {
    AppBar, Toolbar, Box, Avatar, Typography, Container, Paper, Button, List, ListItem,
    ListItemAvatar, ListItemText, IconButton, CssBaseline, TextField, ThemeProvider, createTheme, Divider, FormControlLabel, Checkbox, Chip
} from '@mui/material';
import {
    ArrowBack as ArrowBackIcon, Send as SendIcon, ThumbUp as ThumbUpIcon,
    Reply as ReplyIcon, Home as HomeIcon, Logout as LogoutIcon, Delete as DeleteIcon, Share as ShareIcon, Edit as EditIcon, LocationOn as LocationOnIcon
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 56,
    height: 56,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: '16px',
    backgroundColor: '#f0f0f0',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
}));

const ChatDrawer = ({ cities }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'column',
                alignItems: 'center',
                width: isMobile ? '100%' : { xs: 100, sm: 80, md: 140 },
                height: isMobile ? 'auto' : '100vh',
                backgroundColor: '#f0f0f0',
                paddingTop: isMobile ? 0 : '16px',
                paddingBottom: isMobile ? '16px' : 0,
                position: isMobile ? 'fixed' : 'fixed',
                bottom: isMobile ? 0 : 'auto',
                top: isMobile ? 'auto' : 64,
                zIndex: 1200,
                overflowX: isMobile ? 'auto' : 'hidden',
                overflowY: isMobile ? 'hidden' : 'auto',
                justifyContent: isMobile ? 'center' : 'flex-start'
            }}
        >
            <StyledAvatar
                src="/general-chat-icon.png"
                onClick={() => navigate('/chat')}
                title="General Chat"
            />
            <Typography variant="caption" color="black" component="div">General</Typography>
            {cities.filter(city => city.name !== 'General').map((city, index) => (
                <Box key={index} textAlign="center" sx={{ mx: isMobile ? 2 : 0 }}>
                    <StyledAvatar
                        src={city.image || "/default.png"}
                        alt={city.name}
                        title={city.name}
                        onClick={() => {
                            console.log(`Navigating to /chat/${city.name.toLowerCase().replace(/\s+/g, '-')}`);
                            navigate(`/chat/${city.name.toLowerCase().replace(/\s+/g, '-')}`);
                        }}
                    />
                    <Typography variant="caption" color="black" component="div">{city.name}</Typography>
                </Box>
            ))}
        </Box>
    );
};

const CityBars = ({ bars }) => {
    const navigate = useNavigate();
    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="h6" color="black">Bars in this city:</Typography>
            {bars.map((bar) => (
                <Button
                    key={bar.id}
                    onClick={() => navigate(`/kavabar/${bar.name.toLowerCase().replace(/\s+/g, '-')}`)}
                    sx={{ mr: 1, mb: 1 }}
                >
                    {bar.name}
                </Button>
            ))}
        </Box>
    );
};

const ChatPage = () => {
    const { cityName } = useParams();
    const [newPost, setNewPost] = useState('');
    const [newReply, setNewReply] = useState('');
    const [replyToPostId, setReplyToPostId] = useState(null);
    const [cities, setCities] = useState([]);
    const [user, setUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingPostId, setEditingPostId] = useState(null);
    const [editingContent, setEditingContent] = useState('');
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [city, setCity] = useState('');
    const [currentCityBars, setCurrentCityBars] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            const { data } = await supabase.auth.getUser();
            if (data) {
                console.log("User data:", data);
                setUser(data.user);
            }
        };
        checkUser();
    }, []);

    useEffect(() => {
        const fetchBars = async () => {
            let { data: bars, error } = await supabase
                .from('bars')
                .select('*');
            if (error) {
                console.error('Error fetching bars:', error);
            } else {
                const citiesWithBars = bars.reduce((acc, bar) => {
                    if (!acc[bar.city]) {
                        acc[bar.city] = { name: bar.city, image: bar.store_image, bars: [] };
                    }
                    acc[bar.city].bars.push(bar);
                    return acc;
                }, {});

                const allCities = [
                    { name: 'General', image: '/general-chat-icon.png', bars: [] },
                    ...Object.values(citiesWithBars)
                ];

                console.log("Fetched cities:", allCities);
                setCities(allCities);

                if (cityName) {
                    const formattedCityName = cityName.replace(/-/g, ' ');
                    console.log(`Formatted city name from URL: ${formattedCityName}`);
                    const selectedCity = allCities.find(
                        city => city.name.toLowerCase() === formattedCityName.toLowerCase()
                    );
                    if (selectedCity) {
                        console.log(`Selected city: ${selectedCity.name}`);
                        setCity(selectedCity.name);
                        setCurrentCityBars(selectedCity.bars);
                    } else {
                        console.error(`City not found: ${formattedCityName}`);
                        setCity('General');
                        setCurrentCityBars([]);
                    }
                } else {
                    setCity('General');
                    setCurrentCityBars([]);
                }
            }
        };

        fetchBars();
    }, [cityName]);

    useEffect(() => {
        if (city) {
            document.title = `${city} yak room 🦙`;
        } else {
            document.title = `General yak room 🦙`;
        }
        console.log("Current city:", city);
    }, [city]);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            let query = supabase
                .from('posts')
                .select('*, users (display_name, avatar_url), replies (*, users (display_name, avatar_url))')
                .order('created_at', { ascending: false });

            if (city && city !== 'General') {
                query = query.eq('city', city);
            } else if (city === 'General') {
                query = query.is('city', null);
            }

            const { data, error } = await query;

            if (error) {
                console.error('Error fetching posts:', error);
            } else {
                console.log("Fetched posts:", data);
                setPosts(data.map(post => ({
                    ...post,
                    replies: post.replies || []
                })));
            }
            setLoading(false);
        };

        fetchPosts();
    }, [city]);

    const handleSignIn = async () => {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
        });
        if (error) {
            console.error('Error signing in:', error);
        } else {
            setUser(data.user);
        }
    };

    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error);
        } else {
            setUser(null);
        }
    };

    const ensureUserExists = async (user) => {
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', user.id)
            .single();

        if (error && error.code === 'PGRST116') {
            const { error: insertError } = await supabase
                .from('users')
                .insert([
                    { id: user.id, display_name: user.user_metadata.full_name, avatar_url: user.user_metadata.avatar_url }
                ]);

            if (insertError) {
                console.error('Error inserting user:', insertError);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            alert("You must be signed in to create a post.");
            handleSignIn();
            return;
        }

        await ensureUserExists(user);

        if (newPost.trim()) {
            const postData = {
                user_id: user.id,
                content: newPost,
                is_anonymous: isAnonymous,
            };

            if (city && city !== 'General') {
                postData.city = city;
            }

            const { data, error } = await supabase
                .from('posts')
                .insert([postData])
                .select('*');

            if (error) {
                console.error('Error creating post:', error);
            } else if (data && data[0]) {
                setNewPost('');

                // Immediately add the new post to the state
                const newPostWithUser = {
                    ...data[0],
                    users: {
                        display_name: user.user_metadata.full_name,
                        avatar_url: user.user_metadata.avatar_url
                    },
                    replies: []
                };
                setPosts(prevPosts => [newPostWithUser, ...prevPosts]);

                // Fetch the complete post data in the background
                fetchCompletePostData(data[0].id);
            }
        }
    };

    const fetchCompletePostData = async (postId) => {
        const { data, error } = await supabase
            .from('posts')
            .select('*, users (display_name, avatar_url), replies (*)')
            .eq('id', postId)
            .single();

        if (error) {
            console.error('Error fetching complete post data:', error);
        } else if (data) {
            setPosts(prevPosts => prevPosts.map(post =>
                post.id === data.id ? data : post
            ));
        }
    };

    const handleReplySubmit = async (postId, e) => {
        e.preventDefault();
        if (!user) {
            alert("You must be signed in to reply.");
            handleSignIn();
            return;
        }

        await ensureUserExists(user);

        if (newReply.trim()) {
            const { data, error } = await supabase
                .from('replies')
                .insert([
                    { post_id: postId, user_id: user.id, content: newReply }
                ])
                .select('*');

            if (error) {
                console.error('Error creating reply:', error);
            } else if (data && data[0]) {
                setNewReply('');
                setReplyToPostId(null);

                // Immediately add the new reply to the state
                const newReplyWithUser = {
                    ...data[0],
                    users: {
                        display_name: user.user_metadata.full_name,
                        avatar_url: user.user_metadata.avatar_url
                    }
                };

                const updatedPosts = posts.map((post) =>
                    post.id === postId
                        ? { ...post, replies: [...(post.replies || []), newReplyWithUser] }
                        : post
                );
                setPosts(updatedPosts);

                // Fetch the complete reply data in the background
                fetchCompleteReplyData(postId, data[0].id);
            }
        }
    };

    const fetchCompleteReplyData = async (postId, replyId) => {
        const { data, error } = await supabase
            .from('replies')
            .select('*, users (display_name, avatar_url)')
            .eq('id', replyId)
            .single();

        if (error) {
            console.error('Error fetching complete reply data:', error);
        } else if (data) {
            setPosts(prevPosts => prevPosts.map(post =>
                post.id === postId
                    ? {
                        ...post, replies: post.replies.map(reply =>
                            reply.id === data.id ? data : reply
                        )
                    }
                    : post
            ));
        }
    };

    const handleEditSubmit = async (postId) => {
        if (!user) {
            alert("You must be signed in to edit a post.");
            handleSignIn();
            return;
        }

        await ensureUserExists(user);

        if (editingContent.trim()) {
            const { error } = await supabase
                .from('posts')
                .update({ content: editingContent })
                .eq('id', postId);

            if (error) {
                console.error('Error editing post:', error);
            } else {
                setEditingPostId(null);
                setEditingContent('');
                const updatedPosts = posts.map((post) => post.id === postId ? { ...post, content: editingContent } : post);
                setPosts(updatedPosts);
            }
        }
    };

    const handleDelete = async (postId) => {
        if (!user) {
            alert("You must be signed in to delete a post.");
            handleSignIn();
            return;
        }

        await ensureUserExists(user);

        const { error: deleteRepliesError } = await supabase
            .from('replies')
            .delete()
            .eq('post_id', postId);

        if (deleteRepliesError) {
            console.error('Error deleting replies:', deleteRepliesError);
            return;
        }

        const { error: deletePostError } = await supabase
            .from('posts')
            .delete()
            .eq('id', postId);

        if (deletePostError) {
            console.error('Error deleting post:', deletePostError);
            return;
        }

        setPosts(posts.filter(post => post.id !== postId));
    };

    const handleLike = async (postId) => {
        if (!user) {
            alert("You must be signed in to like a post.");
            handleSignIn();
            return;
        }

        await ensureUserExists(user);

        const { data, error } = await supabase
            .rpc('increment_likes', { post_id: postId });

        if (error) {
            console.error('Error liking post:', error);
        } else {
            const updatedPosts = posts.map((post) => post.id === postId ? { ...post, likes: (post.likes || 0) + 1 } : post);
            setPosts(updatedPosts);
        }
    };

    const handleEditorChange = (e, setEditorState) => {
        setEditorState(e.target.value);
    };

    const theme = createTheme({
        palette: {
            mode: 'light',
            background: { default: '#f0f0f0' },
            primary: { main: '#5865F2' },
            secondary: { main: '#00bfa5' }
        },
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate('/')}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        {city ? `${city} Yak Room 🦙` : 'Select a city'}
                    </Typography>
                    {user ? (
                        <Button color="inherit" onClick={handleSignOut}>
                            <LogoutIcon />
                            Sign Out
                        </Button>
                    ) : (
                        <>
                            <Button color="inherit" onClick={handleSignIn}>
                                Sign In with Google
                            </Button>
                            <Button color="inherit" onClick={handleSignIn}>
                                Sign Up with Google
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Box sx={{
                display: 'flex',
                minHeight: '100vh',
                backgroundColor: '#f0f0f0',
            }}>
                <ChatDrawer cities={cities} />
                <Container component="main" sx={{
                    flexGrow: 1,
                    p: 3,
                    transition: 'margin-left 0.3s, width 0.3s',
                    marginLeft: { xs: '60px', sm: '80px', md: '120px' },
                    width: { xs: 'calc(100% - 60px)', sm: 'calc(100% - 80px)', md: 'calc(100% - 120px)' },
                    marginTop: '64px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography variant="h4" sx={{ color: theme.palette.secondary.main, marginBottom: 2 }}>
                        {city ? `${city} Yak Room 🦙` : 'Select a city'}
                    </Typography>
                    {city !== 'General' && currentCityBars && <CityBars bars={currentCityBars} />}
                    <StyledPaper elevation={3} sx={{ p: 3, mb: 4 }}>
                        <FormControlLabel
                            control={<Checkbox checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />}
                            label="Post anonymously"
                        />
                        <Box component="form" onSubmit={handleSubmit} mt={2}>
                            <TextField
                                value={newPost}
                                onChange={(e) => handleEditorChange(e, setNewPost)}
                                placeholder="yak anonymously..."
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                sx={{ backgroundColor: '#ffffff', borderRadius: '8px', color: 'black' }}
                                InputProps={{
                                    style: { color: 'black' }
                                }}
                            />
                            <Box mt={2} display="flex" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SendIcon />}
                                    sx={{ borderRadius: '24px' }}
                                >
                                    Post
                                </Button>
                            </Box>
                        </Box>
                    </StyledPaper>
                    <List>
                        {posts && posts.map((post) => (
                            <StyledPaper key={post.id} elevation={3} sx={{ mb: 2, p: 2 }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="User Avatar" src={post.is_anonymous ? "/placeholder.svg?height=40&width=40" : (post.users?.avatar_url || "/placeholder.svg?height=40&width=40")} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                                <Typography variant="body1" color="black" sx={{ mr: 1 }} component="span">
                                                    {post.is_anonymous ? 'Anonymous' : (post.users?.display_name || 'Anonymous')}
                                                </Typography>
                                                <StyledChip
                                                    icon={<LocationOnIcon />}
                                                    label={post.city || 'General'}
                                                    size="small"
                                                    component="span"
                                                />
                                            </Box>
                                        }
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="textSecondary" component="div">
                                                    {post.created_at ? new Date(post.created_at).toLocaleString() : 'No timestamp'}
                                                </Typography>
                                                {editingPostId === post.id ? (
                                                    <Box>
                                                        <TextField
                                                            value={editingContent}
                                                            onChange={(e) => handleEditorChange(e, setEditingContent)}
                                                            multiline
                                                            rows={4}
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ backgroundColor: '#ffffff', borderRadius: '8px', color: 'black', mt: 2 }}
                                                            InputProps={{
                                                                style: { color: 'black' }
                                                            }}
                                                        />
                                                        <Box display="flex" justifyContent="flex-end" mt={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleEditSubmit(post.id)}
                                                                sx={{ mr: 1 }}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => setEditingPostId(null)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Typography variant="body2" color="black" sx={{ mt: 1 }} component="div">{post.content}</Typography>
                                                )}
                                                <Box mt={1} display="flex" alignItems="center">
                                                    <IconButton size="small" color="primary" onClick={() => handleLike(post.id)}>
                                                        <ThumbUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }} component="span">{post.likes || 0}</Typography>
                                                    <IconButton size="small" color="primary" onClick={() => setReplyToPostId(post.id)}>
                                                        <ReplyIcon fontSize="small" />
                                                    </IconButton>
                                                    {post.user_id === user?.id && (
                                                        <>
                                                            <IconButton size="small" color="primary" onClick={() => {
                                                                setEditingPostId(post.id);
                                                                setEditingContent(post.content);
                                                            }}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton size="small" color="primary" onClick={() => handleDelete(post.id)}>
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                    <IconButton size="small" color="primary" onClick={() => navigator.clipboard.writeText(post.content)}>
                                                        <ShareIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItem>
                                {replyToPostId === post.id && (
                                    <Box ml={4} mt={2} sx={{ borderLeft: '2px solid #5865F2', pl: 2 }}>
                                        <Typography variant="body2" color="textSecondary" component="div">Replying to {post.is_anonymous ? 'Anonymous' : (post.users?.display_name || 'Anonymous')}</Typography>
                                        <Box component="form" onSubmit={(e) => handleReplySubmit(post.id, e)} mt={2}>
                                            <TextField
                                                value={newReply}
                                                onChange={(e) => handleEditorChange(e, setNewReply)}
                                                multiline
                                                rows={2}
                                                variant="outlined"
                                                fullWidth
                                                sx={{ backgroundColor: '#ffffff', borderRadius: '8px', color: 'black' }}
                                                InputProps={{
                                                    style: { color: 'black' }
                                                }}
                                            />
                                            <Box mt={2} display="flex" justifyContent="flex-end">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={<SendIcon />}
                                                    sx={{ borderRadius: '24px' }}
                                                >
                                                    Reply
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                {post.replies && post.replies.map((reply, replyIndex) => (
                                    <Box key={replyIndex} ml={4} mt={1} sx={{ borderLeft: '2px solid #5865F2', pl: 2 }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar alt="User Avatar" src={reply.is_anonymous ? "/placeholder.svg?height=40&width=40" : (reply.users?.avatar_url || "/placeholder.svg?height=40&width=40")} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography variant="body1" color="black" component="span">{reply.is_anonymous ? 'Anonymous' : (reply.users?.display_name || 'Anonymous')}</Typography>}
                                                secondary={
                                                    <>
                                                        <Typography variant="body2" color="textSecondary" component="div">
                                                            {reply.created_at ? new Date(reply.created_at).toLocaleString() : 'No timestamp'}
                                                        </Typography>
                                                        <Typography variant="body2" color="black" component="div">{reply.content}</Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </Box>
                                ))}
                                <Divider variant="inset" component="li" />
                            </StyledPaper>
                        ))}
                    </List>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default ChatPage;
