// import React, { useState, useEffect } from 'react';
// import { Box, Avatar, Typography, Paper, Tabs, Tab, Container, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, ButtonBase, Slide, IconButton, Button, Grid, Link } from '@mui/material';
// import { PhotoCamera } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
// import supabase from './supabaseClient';

// const ProfilePage = ({ user }) => {
//     const [tabIndex, setTabIndex] = useState(0);
//     const [profile, setProfile] = useState(null);
//     const [favoriteBars, setFavoriteBars] = useState([]);
//     const [beenToBars, setBeenToBars] = useState([]);
//     const [homeBarName, setHomeBarName] = useState('');
//     const [loading, setLoading] = useState(true);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [notificationMessage, setNotificationMessage] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (!user || !user.id) {
//             console.error('No user or user ID found.');
//             return;
//         }

//         const fetchProfile = async () => {
//             try {
//                 const { data, error } = await supabase
//                     .from('profiles')
//                     .select('*')
//                     .eq('email', user.email)
//                     .single();

//                 if (error) {
//                     console.error('Error fetching profile: Profile Page', error);
//                 } else {
//                     setProfile(data);
//                     if (data.favorites) {
//                         fetchFavoriteBars(data.favorites);
//                     }
//                     if (data.beenTo) {
//                         fetchBeenToBars(data.beenTo);
//                     }
//                     if (data.homebar) {
//                         fetchHomeBarName(data.homebar);
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching profile:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         const fetchFavoriteBars = async (favoriteIds) => {
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('*')
//                     .in('id', favoriteIds);

//                 if (error) {
//                     console.error('Error fetching favorite bars:', error);
//                 } else {
//                     setFavoriteBars(data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching favorite bars:', error);
//             }
//         };

//         const fetchBeenToBars = async (beenToIds) => {
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('*')
//                     .in('id', beenToIds);

//                 if (error) {
//                     console.error('Error fetching beenTo bars:', error);
//                 } else {
//                     setBeenToBars(data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching beenTo bars:', error);
//             }
//         };

//         const fetchHomeBarName = async (homebarId) => {
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('name')
//                     .eq('id', homebarId)
//                     .single();

//                 if (error) {
//                     console.error('Error fetching HomeBar name:', error);
//                 } else {
//                     setHomeBarName(data.name);
//                 }
//             } catch (error) {
//                 console.error('Error fetching HomeBar name:', error);
//             }
//         };

//         fetchProfile();
//     }, [user]);

//     const handleTabChange = (event, newValue) => {
//         setTabIndex(newValue);
//     };

//     const handleSnackbarClose = () => {
//         setSnackbarOpen(false);
//     };

//     const handleCardClick = (barId) => {
//         navigate(`/kavabar/${barId}`);
//     };

//     const handleEditProfile = () => {
//         navigate('/profile/edit');
//     };

//     const handleAvatarUpload = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${user.id}.${fileExt}`;
//             const filePath = `avatars/${fileName}`;

//             const { data, error } = await supabase.storage
//                 .from('avatars')
//                 .upload(filePath, file, { upsert: true });

//             if (error) {
//                 console.error('Error uploading avatar:', error);
//             } else {
//                 const { publicUrl } = supabase.storage
//                     .from('avatars')
//                     .getPublicUrl(filePath).data;

//                 await supabase
//                     .from('profiles')
//                     .update({ avatar_url: publicUrl })
//                     .eq('id', user.id);

//                 setProfile((prev) => ({ ...prev, avatar_url: publicUrl }));
//             }
//         }
//     };

//     const handleBannerUpload = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             const fileExt = file.name.split('.').pop();
//             const fileName = `${user.id}.${fileExt}`;
//             const filePath = `banners/${fileName}`;

//             const { data, error } = await supabase.storage
//                 .from('banners')
//                 .upload(filePath, file, { upsert: true });

//             if (error) {
//                 console.error('Error uploading banner:', error);
//             } else {
//                 const { publicUrl } = supabase.storage
//                     .from('banners')
//                     .getPublicUrl(filePath).data;

//                 await supabase
//                     .from('profiles')
//                     .update({ bannerImage: publicUrl })
//                     .eq('id', user.id);

//                 setProfile((prev) => ({ ...prev, bannerImage: publicUrl }));
//             }
//         }
//     };

//     if (loading) {
//         return (
//             <Container>
//                 <CircularProgress />
//                 <Typography>Loading... 🌀</Typography>
//             </Container>
//         );
//     }

//     if (!profile) {
//         return <Typography>No profile found.</Typography>;
//     }

//     return (
//         <Container maxWidth="lg">
//             <Paper elevation={10} sx={{ mb: 3, position: 'relative', overflow: 'hidden' }}>
//                 <Box
//                     component="img"
//                     src={profile.bannerImage || "./default.png"}
//                     alt="Banner"
//                     sx={{ width: '100%', height: 200, objectFit: 'cover', filter: 'blur(2px)' }}
//                 />
//                 <Box position="absolute" top={0} right={0} m={2}>
//                     <input
//                         accept="image/*"
//                         style={{ display: 'none' }}
//                         id="banner-upload"
//                         type="file"
//                         onChange={handleBannerUpload}
//                     />
//                     <label htmlFor="banner-upload">
//                         <IconButton component="span" color="primary">
//                             <PhotoCamera />
//                         </IconButton>
//                     </label>
//                 </Box>
//                 <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }} sx={{ p: 2, position: 'relative', top: -60 }}>
//                     <Box position="relative" mr={2} sx={{ display: 'inline-block' }}>
//                         <Avatar
//                             src={profile.avatar_url || './tomo.gif'}
//                             alt={profile.username || 'User Avatar'}
//                             sx={{ width: 120, height: 120, mb: { xs: 2, sm: 0 } }}
//                         />
//                         <input
//                             accept="image/*"
//                             style={{ display: 'none' }}
//                             id="avatar-upload"
//                             type="file"
//                             onChange={handleAvatarUpload}
//                         />
//                         <label htmlFor="avatar-upload">
//                             <IconButton component="span" sx={{ position: 'absolute', bottom: 0, right: 0 }}>
//                                 <PhotoCamera />
//                             </IconButton>
//                         </label>
//                     </Box>
//                     <Box textAlign={{ xs: 'center', sm: 'left' }}>
//                         <Typography variant="h2" sx={{ fontWeight: 'bold', wordWrap: 'break-word' }}>{profile.username || 'No username available'}</Typography>
//                         <Typography variant="body1" sx={{ wordWrap: 'break-word', mb: 1 }}>{profile.bio || 'No bio available'}</Typography>
//                         {homeBarName && (
//                             <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
//                                 <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
//                                     🏠 <Link href={`/kavabar/${profile.homebar}/`} underline="hover" color="inherit">{homeBarName}</Link>
//                                 </Typography>
//                             </Box>
//                         )}
//                         <Button variant="outlined" onClick={handleEditProfile} sx={{ mt: 2 }}>
//                             Edit Profile
//                         </Button>
//                     </Box>
//                 </Box>
//             </Paper>
//             <Paper elevation={3}>
//                 <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
//                     <Tab label="Been To 🍵" />
//                     <Tab label="Favorites 💖" />
//                 </Tabs>
//                 <Box p={3}>
//                     {tabIndex === 0 && (
//                         <Grid container spacing={2}>
//                             {beenToBars.map((bar) => (
//                                 <Grid item xs={6} sm={4} md={3} lg={2} key={bar.id}>
//                                     <ButtonBase sx={{ width: '100%' }} onClick={() => handleCardClick(bar.id)}>
//                                         <Card sx={{
//                                             borderRadius: '16px',
//                                             overflow: 'hidden',
//                                             boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
//                                             transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
//                                             '&:hover': {
//                                                 boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
//                                             }
//                                         }}>
//                                             <CardMedia
//                                                 component="img"
//                                                 image={bar.store_image || "./default.png"}
//                                                 alt={bar.name}
//                                                 sx={{ height: '300px', objectFit: 'cover' }}
//                                                 onError={(e) => {
//                                                     e.target.onerror = null;
//                                                     e.target.src = "./default.png";
//                                                     console.error(`Failed to load image for ${bar.name}: ${bar.store_image}`);
//                                                 }}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>{bar.name}</Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </ButtonBase>
//                                 </Grid>
//                             ))}
//                         </Grid>
//                     )}
//                     {tabIndex === 1 && (
//                         <Grid container spacing={2}>
//                             {favoriteBars.map((bar) => (
//                                 <Grid item xs={6} sm={4} md={3} lg={2} key={bar.id}>
//                                     <ButtonBase sx={{ width: '100%' }} onClick={() => handleCardClick(bar.id)}>
//                                         <Card sx={{
//                                             borderRadius: '16px',
//                                             overflow: 'hidden',
//                                             boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
//                                             transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
//                                             '&:hover': {
//                                                 boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
//                                             }
//                                         }}>
//                                             <CardMedia
//                                                 component="img"
//                                                 image={bar.store_image || "./default.png"}
//                                                 alt={bar.name}
//                                                 sx={{ height: '300px', objectFit: 'cover' }}
//                                                 onError={(e) => {
//                                                     e.target.onerror = null;
//                                                     e.target.src = "./default.png";
//                                                     console.error(`Failed to load image for ${bar.name}: ${bar.store_image}`);
//                                                 }}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>{bar.name}</Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </ButtonBase>
//                                 </Grid>
//                             ))}
//                         </Grid>
//                     )}
//                 </Box>
//             </Paper>
//             <Snackbar
//                 open={snackbarOpen}
//                 autoHideDuration={6000}
//                 onClose={handleSnackbarClose}
//                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//                 TransitionComponent={(props) => <Slide {...props} direction="left" />}
//             >
//                 <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
//                     {notificationMessage}
//                 </Alert>
//             </Snackbar>
//         </Container>
//     );
// };

// export default ProfilePage;

import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, Paper, Tabs, Tab, Container, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, ButtonBase, Slide, IconButton, Button, Grid, Link, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
import supabase from './supabaseClient';

// Pulsating skeleton component
const PulsingSkeleton = ({ width, height }) => (
    <Skeleton
        variant="rectangular"
        width={width}
        height={height}
        animation="wave"
        sx={{ borderRadius: '4px' }}
    />
);

const ProfilePage = ({ user }) => {
    const { username } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [profile, setProfile] = useState(null);
    const [favoriteBars, setFavoriteBars] = useState([]);
    const [beenToBars, setBeenToBars] = useState([]);
    const [homeBarName, setHomeBarName] = useState('');
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                let data, error;
                if (username) {
                    // Fetching another user's profile
                    ({ data, error } = await supabase
                        .from('profiles')
                        .select('*')
                        .eq('username', username)
                        .single());
                } else if (user) {
                    // Fetching the current user's profile
                    ({ data, error } = await supabase
                        .from('profiles')
                        .select('*')
                        .eq('id', user.id)
                        .single());
                }

                if (error) {
                    console.error('Error fetching profile:', error);
                } else {
                    setProfile(data);
                    if (data.favorites) {
                        fetchFavoriteBars(data.favorites);
                    }
                    if (data.beenTo) {
                        fetchBeenToBars(data.beenTo);
                    }
                    if (data.homebar) {
                        fetchHomeBarName(data.homebar);
                    }
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [username, user]);

    const fetchFavoriteBars = async (favoriteIds) => {
        try {
            const { data, error } = await supabase
                .from('bars')
                .select('*')
                .in('id', favoriteIds);

            if (error) {
                console.error('Error fetching favorite bars:', error);
            } else {
                setFavoriteBars(data);
            }
        } catch (error) {
            console.error('Error fetching favorite bars:', error);
        }
    };

    const fetchBeenToBars = async (beenToIds) => {
        try {
            const { data, error } = await supabase
                .from('bars')
                .select('*')
                .in('id', beenToIds);

            if (error) {
                console.error('Error fetching beenTo bars:', error);
            } else {
                setBeenToBars(data);
            }
        } catch (error) {
            console.error('Error fetching beenTo bars:', error);
        }
    };

    const fetchHomeBarName = async (homebarId) => {
        try {
            const { data, error } = await supabase
                .from('bars')
                .select('name')
                .eq('id', homebarId)
                .single();

            if (error) {
                console.error('Error fetching HomeBar name:', error);
            } else {
                setHomeBarName(data.name);
            }
        } catch (error) {
            console.error('Error fetching HomeBar name:', error);
        }
    };

    const handleEditProfile = () => {
        navigate('/profile/edit');
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
                <Typography>Loading... 🌀</Typography>
            </Container>
        );
    }

    if (!profile) {
        return <Typography>No profile found.</Typography>;
    }

    const isOwner = user && ((!username && user.id === profile.id) || (username && user.username === username));

    return (
        <Container maxWidth="lg">
            <Paper elevation={10} sx={{ mb: 3, position: 'relative', overflow: 'hidden' }}>
                <Box
                    component="img"
                    src={profile.bannerImage || "./default.png"}
                    alt="Banner"
                    sx={{ width: '100%', height: 200, objectFit: 'cover', filter: 'blur(2px)' }}
                />
                <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }} sx={{ p: 2, position: 'relative', top: -60 }}>
                    <Box position="relative" mr={2} sx={{ display: 'inline-block' }}>
                        <Avatar
                            src={profile.avatar_url || './tomo.gif'}
                            alt={profile.username || 'User Avatar'}
                            sx={{ width: 120, height: 120, mb: { xs: 2, sm: 0 } }}
                        />
                        {isOwner && (
                            <IconButton
                                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                onClick={handleEditProfile}
                            >
                                <PhotoCamera />
                            </IconButton>
                        )}
                    </Box>
                    <Box textAlign={{ xs: 'center', sm: 'left' }}>
                        <Typography variant="h2" sx={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                            {loading ? <PulsingSkeleton width={200} height={30} /> : (profile.username || 'No username available')}
                        </Typography>
                        <Typography variant="body1" sx={{ wordWrap: 'break-word', mb: 1 }}>
                            {loading ? <PulsingSkeleton width={300} height={20} /> : (profile.bio || 'No bio available')}
                        </Typography>
                        {homeBarName && (
                            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    🏠 <Link href={`/kavabar/${profile.homebar}/`} underline="hover" color="inherit">
                                        {loading ? <PulsingSkeleton width={100} height={20} /> : homeBarName}
                                    </Link>
                                </Typography>
                            </Box>
                        )}
                        {isOwner && (
                            <Button variant="outlined" onClick={handleEditProfile} sx={{ mt: 2 }}>
                                {loading ? <PulsingSkeleton width={100} height={30} /> : 'Edit Profile'}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Paper>
            <Paper elevation={3}>
                <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} variant="fullWidth">
                    <Tab label="Been To 🍵" />
                    <Tab label="Favorites 💖" />
                </Tabs>
                <Box p={3}>
                    {tabIndex === 0 && (
                        <Grid container spacing={2}>
                            {loading
                                ? [...Array(6)].map((_, index) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                        <PulsingSkeleton width="100%" height={300} />
                                    </Grid>
                                ))
                                : beenToBars.map((bar) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={bar.id}>
                                        <ButtonBase sx={{ width: '100%' }} onClick={() => navigate(`/kavabar/${bar.id}`)}>
                                            <Card sx={{
                                                borderRadius: '16px',
                                                overflow: 'hidden',
                                                boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                                                transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                                '&:hover': {
                                                    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                                                }
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    image={bar.store_image || "./default.png"}
                                                    alt={bar.name}
                                                    sx={{ height: '300px', objectFit: 'cover' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>{bar.name}</Typography>
                                                </CardContent>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        <Grid container spacing={2}>
                            {loading
                                ? [...Array(6)].map((_, index) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                        <PulsingSkeleton width="100%" height={300} />
                                    </Grid>
                                ))
                                : favoriteBars.map((bar) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={bar.id}>
                                        <ButtonBase sx={{ width: '100%' }} onClick={() => navigate(`/kavabar/${bar.id}`)}>
                                            <Card sx={{
                                                borderRadius: '16px',
                                                overflow: 'hidden',
                                                boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                                                transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                                '&:hover': {
                                                    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                                                }
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    image={bar.store_image || "./default.png"}
                                                    alt={bar.name}
                                                    sx={{ height: '300px', objectFit: 'cover' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>{bar.name}</Typography>
                                                </CardContent>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </Box>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={(props) => <Slide {...props} direction="left" />}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
                    Notification message here...
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ProfilePage;









