// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import supabase from './supabaseClient';
// import {
//     AppBar,
//     Toolbar,
//     Box,
//     IconButton,
//     Avatar,
//     useMediaQuery,
//     Badge,
//     Autocomplete,
//     TextField,
//     Menu,
//     MenuItem,
//     FormControlLabel,
//     Switch,
//     createTheme,
//     ThemeProvider,
//     Drawer,
//     List,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     Typography,
//     Modal,
//     Button,
// } from "@mui/material";
// import { ChatBubble, Notifications, AccountCircle, Add, Explore, Map, Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
// import { FaSortAmountUp as IconSortAscending, FaSun as IconSun, FaMoon as IconMoon } from 'react-icons/fa';

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#ffedd9',
//         },
//         secondary: {
//             main: '#0c957c',
//         },
//     },
//     breakpoints: {
//         values: {
//             xs: 0,
//             sm: 600,
//             md: 900,
//             lg: 1200,
//             xl: 1536,
//         },
//     },
// });

// const ResponsiveNavBar = ({ darkMode, toggleDarkMode, user, setUser }) => {
//     const navigate = useNavigate();
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
//     const [drawerOpen, setDrawerOpen] = useState(false);
//     const [modalOpen, setModalOpen] = useState(false);
//     const [searchTerm, setSearchTerm] = useState("");
//     const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//     const isDesktopOrLargeTablet = useMediaQuery(theme.breakpoints.up('lg'));

//     const handleSearchInputChange = (event, newValue) => {
//         setSearchTerm(newValue);
//     };

//     const handleSearch = (newValue) => {
//         setSearchTerm(newValue);
//         navigate('/');
//     };

//     const handleSortMenuOpen = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleSortMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const toggleDrawer = (open) => (event) => {
//         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             return;
//         }
//         setDrawerOpen(open);
//     };

//     const handleProfileMenuOpen = (event) => {
//         setProfileMenuAnchorEl(event.currentTarget);
//     };

//     const handleProfileMenuClose = () => {
//         setProfileMenuAnchorEl(null);
//     };

//     const handleModalOpen = () => {
//         setModalOpen(true);
//     };

//     const handleModalClose = () => {
//         setModalOpen(false);
//     };

//     const handleSignOut = async () => {
//         const { error } = await supabase.auth.signOut();
//         if (error) {
//             console.error('Error signing out:', error);
//         } else {
//             setUser(null);
//             setProfileMenuAnchorEl(null);
//             navigate('/');
//         }
//     };

//     const handleLogin = async () => {
//         const { error } = await supabase.auth.signInWithOAuth({
//             provider: 'google',
//         });
//         if (error) {
//             console.error('Error signing in:', error);
//         }
//     };

//     const handleProfileClick = () => {
//         if (user) {
//             navigate('/profile');
//         } else {
//             handleLogin();
//         }
//     };

//     const drawerList = () => (
//         <Box
//             sx={{ width: isMobile ? 'auto' : 250 }}
//             role="presentation"
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//         >
//             <List>
//                 <ListItem button onClick={() => navigate('/add')}>
//                     <ListItemIcon><Add /></ListItemIcon>
//                     <ListItemText primary="Add" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/')}>
//                     <ListItemIcon><Explore /></ListItemIcon>
//                     <ListItemText primary="Explore" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/map')}>
//                     <ListItemIcon><Map /></ListItemIcon>
//                     <ListItemText primary="Map" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/chat')}>
//                     <ListItemIcon><ChatBubble /></ListItemIcon>
//                     <ListItemText primary="Chat" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/notifications')}>
//                     <ListItemIcon><Badge badgeContent={4} color="secondary"><Notifications /></Badge></ListItemIcon>
//                     <ListItemText primary="Notifications" />
//                 </ListItem>
//                 <ListItem button onClick={handleProfileClick}>
//                     <ListItemIcon><AccountCircle /></ListItemIcon>
//                     <ListItemText primary="Profile" />
//                 </ListItem>
//             </List>
//         </Box>
//     );

//     return (
//         <ThemeProvider theme={theme}>
//             <AppBar position="fixed" color="primary" elevation={1} sx={{ zIndex: 1200 }}>
//                 <Toolbar>
//                     <Typography
//                         variant="h6"
//                         component="div"
//                         sx={{
//                             flexGrow: 0,
//                             cursor: 'pointer',
//                             fontFamily: 'Ppeditorialnew, sans-serif',
//                             fontStyle: 'bold',
//                             fontWeight: 500
//                         }}
//                         onClick={() => navigate('/')}
//                     >
//                         kava collective 🦙
//                     </Typography>

//                     {isDesktopOrLargeTablet ? (
//                         <Box sx={{ flexGrow: 1, mx: 2 }}>
//                             <Autocomplete
//                                 freeSolo
//                                 options={[]}
//                                 value={searchTerm}
//                                 onInputChange={handleSearchInputChange}
//                                 renderInput={(params) => (
//                                     <TextField
//                                         {...params}
//                                         placeholder="Search kava bars"
//                                         variant="outlined"
//                                         fullWidth
//                                         sx={{ backgroundColor: '#f5f5f5' }}
//                                     />
//                                 )}
//                                 onChange={(event, newValue) => {
//                                     if (newValue) {
//                                         handleSearch(newValue);
//                                     }
//                                 }}
//                             />
//                         </Box>
//                     ) : (
//                         <Box sx={{ flexGrow: 1 }} />
//                     )}
//                     {!isDesktopOrLargeTablet && (
//                         <IconButton color="inherit" onClick={handleModalOpen}>
//                             <SearchIcon />
//                         </IconButton>
//                     )}
//                     <IconButton onClick={handleSortMenuOpen}>
//                         <IconSortAscending />
//                     </IconButton>
//                     <Menu
//                         anchorEl={anchorEl}
//                         open={Boolean(anchorEl)}
//                         onClose={handleSortMenuClose}
//                     >
//                         <MenuItem onClick={handleSortMenuClose}>Distance</MenuItem>
//                         <MenuItem onClick={handleSortMenuClose}>Rating</MenuItem>
//                     </Menu>
//                     {isDesktopOrLargeTablet && (
//                         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                             <IconButton onClick={handleProfileMenuOpen}>
//                                 {user ? (
//                                     <Avatar alt="Profile Picture" src={user.avatar_url || "/default-profile.png"} />
//                                 ) : (
//                                     <AccountCircle />
//                                 )}
//                             </IconButton>
//                             <Menu
//                                 anchorEl={profileMenuAnchorEl}
//                                 open={Boolean(profileMenuAnchorEl)}
//                                 onClose={handleProfileMenuClose}
//                             >
//                                 {user ? (
//                                     <>
//                                         <MenuItem onClick={() => { handleProfileMenuClose(); navigate('/profile'); }}>Profile</MenuItem>
//                                         <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
//                                     </>
//                                 ) : (
//                                     <MenuItem onClick={handleLogin}>Sign In</MenuItem>
//                                 )}
//                                 <MenuItem>
//                                     <FormControlLabel
//                                         control={<Switch checked={darkMode} onChange={toggleDarkMode} />}
//                                         label={darkMode ? <IconMoon /> : <IconSun />}
//                                     />
//                                 </MenuItem>
//                             </Menu>
//                         </Box>
//                     )}
//                     <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
//                         <MenuIcon />
//                     </IconButton>
//                 </Toolbar>
//             </AppBar>
//             <Drawer
//                 anchor={isMobile ? "bottom" : "right"}
//                 open={drawerOpen}
//                 onClose={toggleDrawer(false)}
//                 sx={{
//                     '& .MuiDrawer-paper': {
//                         backgroundColor: theme.palette.primary.main,
//                         ...(isMobile && { height: '50%' }),
//                     },
//                 }}
//             >
//                 {drawerList()}
//                 {!isDesktopOrLargeTablet && (
//                     <Box sx={{ p: 2 }}>
//                         {user ? (
//                             <Button color="inherit" onClick={handleSignOut} fullWidth>
//                                 Sign Out
//                             </Button>
//                         ) : (
//                             <Button color="inherit" onClick={handleLogin} fullWidth>
//                                 Sign In
//                             </Button>
//                         )}
//                     </Box>
//                 )}
//             </Drawer>
//             <Modal
//                 open={modalOpen}
//                 onClose={handleModalClose}
//                 aria-labelledby="search-modal-title"
//                 aria-describedby="search-modal-description"
//             >
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: '10%',
//                         left: '50%',
//                         transform: 'translate(-50%, 0)',
//                         width: '80%',
//                         bgcolor: 'background.paper',
//                         boxShadow: 24,
//                         p: 4,
//                     }}
//                 >
//                     <Autocomplete
//                         freeSolo
//                         options={[]}
//                         value={searchTerm}
//                         onInputChange={handleSearchInputChange}
//                         renderInput={(params) => (
//                             <TextField
//                                 {...params}
//                                 placeholder="Search kava bars"
//                                 variant="outlined"
//                                 fullWidth
//                                 sx={{ backgroundColor: '#f5f5f5' }}
//                             />
//                         )}
//                         onChange={(event, newValue) => {
//                             if (newValue) {
//                                 handleSearch(newValue);
//                                 handleModalClose();
//                             }
//                         }}
//                     />
//                 </Box>
//             </Modal>
//             <Box sx={{ mt: isMobile ? 8 : 0 }}>
//                 {/* Place your main content here */}
//             </Box>
//         </ThemeProvider>
//     );
// };

// export default ResponsiveNavBar;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar, Box, IconButton, Avatar, useMediaQuery,
    Menu, MenuItem, createTheme, ThemeProvider, Drawer, List, ListItem, ListItemIcon,
    ListItemText, Typography, TextField, Modal, Button
} from '@mui/material';
import {
    ChatBubble, Notifications, AccountCircle, Add, Explore, Map,
    Menu as MenuIcon, Search as SearchIcon
} from '@mui/icons-material';
import supabase from './supabaseClient';

const ResponsiveNavBar = ({ darkMode, toggleDarkMode, user, setUser }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [avatarUrl, setAvatarUrl] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:960px)');
    const [localUser, setLocalUser] = useState(user);

    useEffect(() => {
        const checkSession = async () => {
            const session = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
            if (session?.user) {
                updateUser(session.user);
                fetchUserProfile(session.user.id);
            } else {
                const { data: { user: supabaseUser } } = await supabase.auth.getUser();
                if (supabaseUser) {
                    updateUser(supabaseUser);
                    fetchUserProfile(supabaseUser.id);
                }
            }
        };

        checkSession();
    }, []);

    const fetchUserProfile = async (userId) => {
        try {
            const { data, error } = await supabase
                .from('profiles')
                .select('avatar_url')
                .eq('id', userId)
                .single();

            if (error) {
                console.error('Error fetching user profile:', error);
            } else if (data) {
                setAvatarUrl(data.avatar_url);
            }
        } catch (error) {
            console.error('Unexpected error fetching user profile:', error);
        }
    };

    const updateUser = (newUser) => {
        setLocalUser(newUser);
        if (typeof setUser === 'function') {
            setUser(newUser);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleProfileMenuOpen = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error);
        } else {
            updateUser(null);
            localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
            navigate('/');
        }
        handleProfileMenuClose();
    };

    const handleLogin = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
        });
        if (error) {
            console.error('Error signing in:', error);
        }
        handleProfileMenuClose();
    };

    const handleProfileClick = () => {
        if (localUser) {
            navigate('/profile');
        } else {
            handleLogin();
        }
        handleProfileMenuClose();
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/?search=${encodeURIComponent(searchTerm)}`);
        }
    };

    const drawerList = () => (
        <Box
            sx={{ width: isMobile ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={() => navigate('/add')}>
                    <ListItemIcon><Add /></ListItemIcon>
                    <ListItemText primary="Add" />
                </ListItem>
                <ListItem button onClick={() => navigate('/')}>
                    <ListItemIcon><Explore /></ListItemIcon>
                    <ListItemText primary="Explore" />
                </ListItem>
                <ListItem button onClick={() => navigate('/map')}>
                    <ListItemIcon><Map /></ListItemIcon>
                    <ListItemText primary="Map" />
                </ListItem>
                <ListItem button onClick={() => navigate('/notifications')}>
                    <ListItemIcon><Notifications /></ListItemIcon>
                    <ListItemText primary="Notifications" />
                </ListItem>
                <ListItem button onClick={handleProfileClick}>
                    <ListItemIcon><AccountCircle /></ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItem>
            </List>
        </Box>
    );

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            background: {
                default: '#F5ECD7',
            },
            primary: {
                main: '#eb9c64',
            },
            secondary: {
                main: '#8fbf9f',
            },
            text: {
                primary: '#353535',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="fixed" color="primary" elevation={1}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            cursor: 'pointer',
                            color: theme.palette.text.primary,
                            textAlign: 'left',
                        }}
                        onClick={() => navigate('/')}
                    >
                        bula maps 🦙
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isMobile && (
                            <>
                                <IconButton color="inherit" onClick={() => setSearchModalOpen(true)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                                    <MenuIcon />
                                </IconButton>
                            </>
                        )}

                        {!isTablet && (
                            <>
                                <Button onClick={() => navigate('/add')} color="inherit" startIcon={<Add />}>
                                    Add a Bar
                                </Button>
                                <Button onClick={() => navigate('/')} color="inherit" startIcon={<Explore />}>
                                    Explore
                                </Button>
                                <Button onClick={() => navigate('/map')} color="inherit" startIcon={<Map />}>
                                    Map
                                </Button>
                                <IconButton onClick={handleProfileMenuOpen}>
                                    {localUser ? (
                                        <Avatar alt="Profile Picture" src={avatarUrl || "./default.png"} />
                                    ) : (
                                        <AccountCircle />
                                    )}
                                </IconButton>
                                <Menu
                                    anchorEl={profileMenuAnchorEl}
                                    open={Boolean(profileMenuAnchorEl)}
                                    onClose={handleProfileMenuClose}
                                >
                                    {localUser ? (
                                        <>
                                            <MenuItem onClick={() => { handleProfileMenuClose(); navigate('/profile'); }}>Profile</MenuItem>
                                            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                                        </>
                                    ) : (
                                        <MenuItem onClick={handleLogin}>Sign In</MenuItem>
                                    )}
                                </Menu>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor={isMobile ? "bottom" : "left"}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#F5ECD7',
                        ...(isMobile && { height: '50%' }),
                    },
                }}
            >
                {drawerList()}
            </Drawer>
            {isMobile && (
                <Modal
                    open={searchModalOpen}
                    onClose={() => setSearchModalOpen(false)}
                    aria-labelledby="search-modal-title"
                    aria-describedby="search-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '20%',
                            left: '50%',
                            transform: 'translate(-50%, -20%)',
                            width: '80%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                            autoFocus
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') handleSearchSubmit(event);
                            }}
                        />
                    </Box>
                </Modal>
            )}
        </ThemeProvider>
    );
};

export default ResponsiveNavBar;





















