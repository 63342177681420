// import React, { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { SearchContext } from './SearchContext';
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import Map, { Marker, Popup, NavigationControl, GeolocateControl, Source, Layer } from "react-map-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
// import "./Map.css";
// import InputModal from "./inputModal";
// import ResponsiveNavBar from "./ResponsiveNavBar";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Typography from "@mui/material/Typography";
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import supabase from "./supabaseClient";



// const MAPBOX_TOKEN = "pk.eyJ1Ijoic2FteXRoZWNhcnRvZ3JhcGhlciIsImEiOiJjbGt3M3V4OGwwMGFtM2hyejdvZ2VwYXlyIn0.VnFjiwvwArLqfNSdVvrDSQ";
// const stateEmojis = {
//   AL: "🅰️", AK: "🧊", AZ: "🌵", AR: "🌳", CA: "🌴", CO: "🏔️", CT: "🦃", DE: "🏖️",
//   FL: "🌞", GA: "🍑", HI: "🌺", ID: "🥔", IL: "🌽", IN: "🏎️", IA: "🌽", KS: "🌻",
//   KY: "🏇", LA: "⚜️", ME: "🦞", MD: "🦀", MA: "🍀", MI: "🚗", MN: "❄️", MS: "🚢",
//   MO: "🏛️", MT: "🏔️", NE: "🌽", NV: "🎰", NH: "⛰️", NJ: "🏖️", NM: "🌶️", NY: "🗽",
//   NC: "🏖️", ND: "🛢️", OH: "🏈", OK: "🐎", OR: "🌲", PA: "🔔", RI: "⚓", SC: "🌴",
//   SD: "🗿", TN: "🎸", TX: "🤠", UT: "🏜️", VT: "🍁", VA: "🌸", WA: "🗻", WV: "⛏️",
//   WI: "🧀", WY: "🐏",
// };

// const stateCoordinates = {
//   AL: [32.806671, -86.791130], AK: [61.370716, -152.404419], AZ: [33.729759, -111.431221],
//   AR: [34.969704, -92.373123], CA: [36.116203, -119.681564], CO: [39.059811, -105.311104],
//   CT: [41.597782, -72.755371], DE: [39.318523, -75.507141], FL: [27.766279, -81.686783],
//   GA: [33.040619, -83.643074], HI: [21.094318, -157.498337], ID: [44.240459, -114.478828],
//   IL: [40.349457, -88.986137], IN: [39.849426, -86.258278], IA: [42.011539, -93.210526],
//   KS: [38.526600, -96.726486], KY: [37.668140, -84.670067], LA: [31.169546, -91.867805],
//   ME: [44.693947, -69.381927], MD: [39.063946, -76.802101], MA: [42.230171, -71.530106],
//   MI: [43.326618, -84.536095], MN: [45.694454, -93.900192], MS: [32.741646, -89.678696],
//   MO: [38.456085, -92.288368], MT: [46.921925, -110.454353], NE: [41.125370, -98.268082],
//   NV: [38.313515, -117.055374], NH: [43.452492, -71.563896], NJ: [40.298904, -74.521011],
//   NM: [34.840515, -106.248482], NY: [42.165726, -74.948051], NC: [35.630066, -79.806419],
//   ND: [47.528912, -99.784012], OH: [40.388783, -82.764915], OK: [35.565342, -96.928917],
//   OR: [44.572021, -122.070938], PA: [40.590752, -77.209755], RI: [41.680893, -71.511780],
//   SC: [33.856892, -80.945007], SD: [44.299782, -99.438828], TN: [35.747845, -86.692345],
//   TX: [31.054487, -97.563461], UT: [40.150032, -111.862434], VT: [44.045876, -72.710686],
//   VA: [37.769337, -78.169968], WA: [47.400902, -121.490494], WV: [38.491226, -80.954456],
//   WI: [44.268543, -89.616508], WY: [42.755966, -107.302490],
// };

// const cityEmojis = {
//   Anchorage: "❄️", Anaheim: "🎢", "Los Angeles": "🌴", Hollywood: "🎬", Sacramento: "🏛️",
//   "San Diego": "🌊", "San Francisco": "🌉", "San Jose": "🤖", "Santa Ana": "🏖️",
//   Denver: "🏔️", "Colorado Springs": "🏞️", Chicago: "🌆", Portland: "🌲", Baltimore: "🦀",
//   Boston: "🎓", "Ann Arbor": "🌳", "Bay City": "🚤", Detroit: "🚗", "Grand Rapids": "🏞️",
//   Lansing: "🏛️", Minneapolis: "🌉", "Kansas City": "🏙️", "Las Vegas": "🎰", Albuquerque: "🌵",
//   Buffalo: "❄️", "New York City": "🗽", "Oklahoma City": "🌪️", Tulsa: "🌳", "Brooklyn,NY": "🗽",
//   Hawaii: "🌺",
// };

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371;
//   const dLat = (lat2 - lat1) * Math.PI / 180;
//   const dLon = (lon2 - lon1) * Math.PI / 180;
//   const a = 0.5 - Math.cos(dLat) / 2 + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * (1 - Math.cos(dLon)) / 2;
//   return R * 2 * Math.asin(Math.sqrt(a));
// };

// const LandingPage = () => {
//   const { searchTerm } = useContext(SearchContext);
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [hoveredBar, setHoveredBar] = useState(null);
//   const [selectedState, setSelectedState] = useState("");
//   const [selectedCity, setSelectedCity] = useState("");
//   const [viewport, setViewport] = useState({
//     latitude: 37.7749,
//     longitude: -95.7129,
//     zoom: 3,
//   });
//   const [popupInfo, setPopupInfo] = useState(null);
//   const [favorites, setFavorites] = useState([]);
//   const [isInputModalOpen, setInputModalOpen] = useState(false);
//   const [darkMode, setDarkMode] = useState(false);
//   const [selectedBar, setSelectedBar] = useState(null);
//   const isMobile = useMediaQuery('(max-width:600px)');

//   const mapRef = useRef(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data: bars, error } = await supabase
//           .from("bars")
//           .select("*");

//         if (error) throw error;

//         setData(bars);
//         setFilteredData(bars);
//       } catch (error) {
//         console.error("Error fetching data from Supabase:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const userLatitude = position.coords.latitude;
//         const userLongitude = position.coords.longitude;

//         setViewport({
//           latitude: userLatitude,
//           longitude: userLongitude,
//           zoom: 3,
//         });

//         const sortedData = [...data].sort((a, b) => {
//           const distanceA = calculateDistance(userLatitude, userLongitude, a.latitude, a.longitude);
//           const distanceB = calculateDistance(userLatitude, userLongitude, b.latitude, b.longitude);
//           return distanceA - distanceB;
//         });

//         setFilteredData(sortedData);

//         if (mapRef.current) {
//           mapRef.current.flyTo({
//             center: [userLongitude, userLatitude],
//             zoom: 10,
//             speed: 1.5,
//             curve: 1,
//           });
//         }
//       },
//       (error) => {
//         console.error("Error getting user location:", error);
//       }
//     );
//   }, [data]);

//   useEffect(() => {
//     filterData(searchTerm, selectedState, selectedCity);
//   }, [searchTerm, selectedState, selectedCity]);

//   const handleStateChange = (event) => {
//     const value = event.target.value;
//     setSelectedState(value);
//     setSelectedCity("");
//     filterData(searchTerm, value, "");

//     if (value && stateCoordinates[value]) {
//       setViewport({
//         latitude: stateCoordinates[value][0],
//         longitude: stateCoordinates[value][1],
//         zoom: 6,
//       });
//     }
//   };

//   const handleCityChange = (event) => {
//     const value = event.target.value;
//     setSelectedCity(value);
//     filterData(searchTerm, selectedState, value);
//   };

//   const filterData = (search, state, city) => {
//     let filtered = data.filter(bar =>
//       (search ? bar.name.toLowerCase().includes(search.toLowerCase()) ||
//         bar.address.toLowerCase().includes(search.toLowerCase()) ||
//         bar.city.toLowerCase().includes(search.toLowerCase()) ||
//         bar.state.toLowerCase().includes(search.toLowerCase()) ||
//         (bar.reviews && bar.reviews.toLowerCase().includes(search.toLowerCase())) ||
//         (bar.website && bar.website.toLowerCase().includes(search.toLowerCase())) : true) &&
//       (state ? bar.state === state : true) &&
//       (city ? bar.city === city : true)
//     );
//     setFilteredData(filtered);
//   };

//   const handleMarkerClick = (bar) => {
//     setSelectedBar(bar);
//     navigate(`/kavabar/${bar.id}`);
//   };

//   const handleMarkerHover = (bar) => {
//     setPopupInfo(bar);
//   };

//   const handleMarkerLeave = () => {
//     setPopupInfo(null);
//   };

//   const handleFlyTo = (latitude, longitude) => {
//     if (mapRef.current) {
//       mapRef.current.flyTo({
//         center: [longitude, latitude],
//         zoom: 14,
//         speed: 1.5,
//         curve: 1,
//       });
//     }
//   };

//   const toggleFavorite = (bar) => {
//     setFavorites(prevFavorites => {
//       if (prevFavorites.includes(bar.name)) {
//         return prevFavorites.filter(fav => fav !== bar.name);
//       } else {
//         return [...prevFavorites, bar.name];
//       }
//     });
//   };

//   const toggleDarkMode = () => {
//     setDarkMode(prevMode => !prevMode);
//   };

//   const handleImageError = (e) => {
//     e.target.src = "./default.png";
//   };

//   const CustomMarker = ({ bar, onClick, onHover, onLeave }) => (
//     <div
//       onClick={onClick}
//       onMouseEnter={onHover}
//       onMouseLeave={onLeave}
//       style={{
//         cursor: 'pointer',
//         transform: hoveredBar === bar ? 'scale(1.2)' : 'scale(1)',
//         transition: 'transform 0.2s',
//       }}
//     >
//       <img src="/leafly.svg" alt="Leaf Icon" style={{ width: 24, height: 24, filter: bar === selectedBar ? 'hue-rotate(90deg)' : 'none' }} />
//     </div>
//   );

//   const getStateCount = (state) => {
//     return data.filter(bar => bar.state === state).length;
//   };

//   const getCityCount = (city) => {
//     return data.filter(bar => bar.city === city).length;
//   };

//   const handleScroll = (event) => {
//     if (isMobile) {
//       const visibleIndex = Math.round(event.target.scrollLeft / 280);
//       const bar = filteredData[visibleIndex];
//       if (bar) {
//         handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude));
//       }
//     }
//   };

//   return (
//     <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", bgcolor: "#f4e7d2", p: isMobile ? 0 : 2 }}>
//       <ResponsiveNavBar
//         darkMode={darkMode}
//         toggleDarkMode={toggleDarkMode}
//       />
//       {isMobile && (
//         <Box sx={{ p: 0, textAlign: 'center', mt: 0 }}>
//           <Typography variant="h6" component="i" sx={{ color: '#7a5d4d' }}>
//             kavayak map
//           </Typography>
//         </Box>
//       )}
//       <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
//         {!isMobile && (
//           <Box sx={{
//             display: "flex",
//             flexDirection: { xs: "column", sm: "row" },
//             width: "100%",
//             p: { xs: 0, sm: 4.5 },
//             mt: { xs: 0, sm: 5 },
//             alignItems: "center",
//             justifyContent: "center",
//             gap: 2,
//           }}>
//             <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
//               <InputLabel id="state-select-label" sx={{ color: '#353535' }}>Choose your state</InputLabel>
//               <Select
//                 labelId="state-select-label"
//                 value={selectedState}
//                 onChange={handleStateChange}
//                 fullWidth
//                 variant="outlined"
//                 sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 {Object.keys(stateCoordinates)
//                   .sort((a, b) => getStateCount(b) - getStateCount(a))
//                   .map(state => (
//                     <MenuItem key={state} value={state}>
//                       {stateEmojis[state]} {state} ({getStateCount(state)})
//                     </MenuItem>
//                   ))}
//               </Select>
//             </Box>
//             <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
//               <InputLabel id="city-select-label" sx={{ color: '#353535' }}>Choose your city</InputLabel>
//               <Select
//                 labelId="city-select-label"
//                 value={selectedCity}
//                 onChange={handleCityChange}
//                 fullWidth
//                 variant="outlined"
//                 disabled={!selectedState}
//                 sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 {Array.from(new Set(data.filter(bar => bar.state === selectedState).map(bar => bar.city)))
//                   .sort((a, b) => getCityCount(b) - getCityCount(a))
//                   .map(city => (
//                     <MenuItem key={city} value={city}>
//                       {cityEmojis[city]} {city} ({getCityCount(city)})
//                     </MenuItem>
//                   ))}
//               </Select>
//             </Box>
//           </Box>
//         )}
//         <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, flexGrow: 1 }}>
//           <Box
//             sx={{
//               width: { xs: "100%", md: "40%" },
//               height: { xs: "auto", md: "100vh" },
//               overflowY: { xs: "hidden", md: "auto" },
//               overflowX: { xs: "auto", md: "hidden" },
//               order: { xs: 2, md: 1 },
//               display: "flex",
//               flexDirection: { xs: "row", md: "column" },
//               p: 1,
//               '&::-webkit-scrollbar': {
//                 width: '8px',
//                 height: '8px',
//               },
//               '&::-webkit-scrollbar-track': {
//                 background: '#EBE2CD',
//               },
//               '&::-webkit-scrollbar-thumb': {
//                 background: '#353535',
//                 borderRadius: '4px',
//               },
//               '&::-webkit-scrollbar-thumb:hover': {
//                 background: '#353535',
//               },
//               scrollbarWidth: 'thin',
//               scrollbarColor: '#353535 #EBE2CD',
//               mt: isMobile ? 5 : 0,
//             }}
//             onScroll={handleScroll}
//           >
//             {filteredData.map((bar, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   width: { xs: "100%", md: "100%" },
//                   flexShrink: 0,
//                   mr: { xs: 10, md: 0 },
//                   mb: { xs: 10, md: 2 },
//                   p: 3,
//                   boxShadow: 1,
//                   borderRadius: 2,
//                   cursor: "pointer",
//                   transition: 'box-shadow 0.2s, transform 0.2s',
//                   backgroundColor: '#F5ECD7',
//                   '&:hover': {
//                     boxShadow: 3,
//                     transform: 'translateY(-2px)',
//                   },
//                 }}
//                 onClick={() => handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude))}
//               >
//                 <Box component="img" src={bar.store_image && bar.store_image.length > 1 ? bar.store_image : "./default.png"} alt={bar.name} sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1 }} onError={handleImageError} />
//                 <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
//                   <Box sx={{ display: "flex", flexDirection: "column" }}>
//                     <Box component="span" sx={{ fontWeight: "bold", color: '#353535' }}>{`${bar.name} ${cityEmojis[bar.city] || ''} ${stateEmojis[bar.state] || ''}`}</Box>
//                     <Box component="span" sx={{ color: '' }}>{bar.city}, {bar.state}</Box>
//                   </Box>
//                   <IconButton onClick={(e) => { e.stopPropagation(); toggleFavorite(bar); }}>
//                     {favorites.includes(bar.name) ? <FavoriteIcon sx={{ color: '#eb9c64' }} /> : <FavoriteBorderIcon sx={{ color: '#eb9c64' }} />}
//                   </IconButton>
//                 </Box>
//                 <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); handleMarkerClick(bar); }} sx={{ mt: 1, bgcolor: '#8fbf9f', '&:hover': { bgcolor: '#6c8f7d' } }}>
//                   Details
//                 </Button>
//               </Box>
//             ))}
//           </Box>

//           <Box sx={{
//             height: { xs: "50vh", md: "100vh" },
//             width: { xs: "100%", md: "60%" },
//             order: { xs: 1, md: 2 }
//           }}>
//             <Map
//               ref={mapRef}
//               initialViewState={viewport}
//               style={{ width: "100%", height: "100%" }}
//               mapStyle="mapbox://styles/mapbox/streets-v11"
//               mapboxAccessToken={MAPBOX_TOKEN}
//               scrollZoom={true}
//             >
//               {filteredData.map((bar, index) => {
//                 const lat = parseFloat(bar.latitude);
//                 const lng = parseFloat(bar.longitude);
//                 if (isNaN(lat) || isNaN(lng)) {
//                   console.log(`Invalid coordinates for ${bar.name}: Latitude: ${lat}, Longitude: ${lng}`);
//                   return null;
//                 }
//                 return (
//                   <Marker
//                     key={index}
//                     latitude={lat}
//                     longitude={lng}
//                   >
//                     <CustomMarker
//                       bar={bar}
//                       onClick={() => handleMarkerClick(bar)}
//                       onHover={() => handleMarkerHover(bar)}
//                       onLeave={handleMarkerLeave}
//                     />
//                   </Marker>
//                 );
//               })}
//               {popupInfo && (
//                 <Popup
//                   latitude={parseFloat(popupInfo.latitude)}
//                   longitude={parseFloat(popupInfo.longitude)}
//                   closeButton={false}
//                   closeOnClick={false}
//                   onClose={handleMarkerLeave}
//                   anchor="top"
//                 >
//                   <Box sx={{
//                     p: 2,
//                     borderRadius: 2,
//                     boxShadow: 3,
//                     bgcolor: '#EBE2CD',
//                     maxWidth: 300,
//                   }}>
//                     <Box component="img"
//                       src={popupInfo.store_image && popupInfo.store_image.length > 1 ? popupInfo.store_image : "./default.png"}
//                       alt={popupInfo.name}
//                       sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1, mb: 1 }}
//                       onError={handleImageError}
//                     />
//                     <Typography variant="h6" sx={{ mt: 0, mb: 1, fontWeight: "bold", color: '#353535' }}>{popupInfo.name}</Typography>
//                     <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>Rating: {popupInfo.rating}</Typography>
//                     {popupInfo.website && (
//                       <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>
//                         <a href={popupInfo.website} target="_blank" rel="noopener noreferrer" style={{ color: '#8fbf9f' }}>Visit Website</a>
//                       </Typography>
//                     )}
//                   </Box>
//                 </Popup>
//               )}
//               <GeolocateControl position="bottom-left" />
//               <NavigationControl position="bottom-right" />
//               <Source
//                 id="user-location"
//                 type="geojson"
//                 data={{
//                   type: "Feature",
//                   properties: {},
//                   geometry: {
//                     type: "Point",
//                     coordinates: [viewport.longitude, viewport.latitude],
//                   },
//                 }}
//               >
//                 <Layer
//                   id="point"
//                   type="circle"
//                   paint={{
//                     "circle-radius": 10,
//                     "circle-color": "#eb9c64",
//                   }}
//                 />
//               </Source>
//             </Map>
//           </Box>
//         </Box>
//       </Box>
//       <Box component="footer" sx={{ bgcolor: "#353535", color: "#F5ECD7", py: 2, mt: "auto", display: { xs: "none", md: "block" } }}>
//         {/* Footer content */}
//       </Box>
//       <InputModal isOpen={isInputModalOpen} onClose={() => setInputModalOpen(false)} />
//     </Box>
//   );
// }

// export default LandingPage;

import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, IconButton, useMediaQuery, Snackbar } from "@mui/material";
import { Favorite, FavoriteBorder, Visibility, VisibilityOff } from "@mui/icons-material";
import Map, { Marker, Popup, NavigationControl, GeolocateControl, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import supabase from "./supabaseClient";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import ResponsiveNavBar from "./ResponsiveNavBar";
import InputModal from "./inputModal";
import { SearchContext } from './SearchContext';

const MAPBOX_TOKEN = "pk.eyJ1Ijoic2FteXRoZWNhcnRvZ3JhcGhlciIsImEiOiJjbGt3M3V4OGwwMGFtM2hyejdvZ2VwYXlyIn0.VnFjiwvwArLqfNSdVvrDSQ";
const stateEmojis = {
  AL: "🅰️", AK: "🧊", AZ: "🌵", AR: "🌳", CA: "🌴", CO: "🏔️", CT: "🦃", DE: "🏖️",
  FL: "🌞", GA: "🍑", HI: "🌺", ID: "🥔", IL: "🌽", IN: "🏎️", IA: "🌽", KS: "🌻",
  KY: "🏇", LA: "⚜️", ME: "🦞", MD: "🦀", MA: "🍀", MI: "🚗", MN: "❄️", MS: "🚢",
  MO: "🏛️", MT: "🏔️", NE: "🌽", NV: "🎰", NH: "⛰️", NJ: "🏖️", NM: "🌶️", NY: "🗽",
  NC: "🏖️", ND: "🛢️", OH: "🏈", OK: "🐎", OR: "🌲", PA: "🔔", RI: "⚓", SC: "🌴",
  SD: "🗿", TN: "🎸", TX: "🤠", UT: "🏜️", VT: "🍁", VA: "🌸", WA: "🗻", WV: "⛏️",
  WI: "🧀", WY: "🐏",
};

const stateCoordinates = {
  AL: [32.806671, -86.791130], AK: [61.370716, -152.404419], AZ: [33.729759, -111.431221],
  AR: [34.969704, -92.373123], CA: [36.116203, -119.681564], CO: [39.059811, -105.311104],
  CT: [41.597782, -72.755371], DE: [39.318523, -75.507141], FL: [27.766279, -81.686783],
  GA: [33.040619, -83.643074], HI: [21.094318, -157.498337], ID: [44.240459, -114.478828],
  IL: [40.349457, -88.986137], IN: [39.849426, -86.258278], IA: [42.011539, -93.210526],
  KS: [38.526600, -96.726486], KY: [37.668140, -84.670067], LA: [31.169546, -91.867805],
  ME: [44.693947, -69.381927], MD: [39.063946, -76.802101], MA: [42.230171, -71.530106],
  MI: [43.326618, -84.536095], MN: [45.694454, -93.900192], MS: [32.741646, -89.678696],
  MO: [38.456085, -92.288368], MT: [46.921925, -110.454353], NE: [41.125370, -98.268082],
  NV: [38.313515, -117.055374], NH: [43.452492, -71.563896], NJ: [40.298904, -74.521011],
  NM: [34.840515, -106.248482], NY: [42.165726, -74.948051], NC: [35.630066, -79.806419],
  ND: [47.528912, -99.784012], OH: [40.388783, -82.764915], OK: [35.565342, -96.928917],
  OR: [44.572021, -122.070938], PA: [40.590752, -77.209755], RI: [41.680893, -71.511780],
  SC: [33.856892, -80.945007], SD: [44.299782, -99.438828], TN: [35.747845, -86.692345],
  TX: [31.054487, -97.563461], UT: [40.150032, -111.862434], VT: [44.045876, -72.710686],
  VA: [37.769337, -78.169968], WA: [47.400902, -121.490494], WV: [38.491226, -80.954456],
  WI: [44.268543, -89.616508], WY: [42.755966, -107.302490],
};

const cityEmojis = {
  Anchorage: "❄️", Anaheim: "🎢", "Los Angeles": "🌴", Hollywood: "🎬", Sacramento: "🏛️",
  "San Diego": "🌊", "San Francisco": "🌉", "San Jose": "🤖", "Santa Ana": "🏖️",
  Denver: "🏔️", "Colorado Springs": "🏞️", Chicago: "🌆", Portland: "🌲", Baltimore: "🦀",
  Boston: "🎓", "Ann Arbor": "🌳", "Bay City": "🚤", Detroit: "🚗", "Grand Rapids": "🏞️",
  Lansing: "🏛️", Minneapolis: "🌉", "Kansas City": "🏙️", "Las Vegas": "🎰", Albuquerque: "🌵",
  Buffalo: "❄️", "New York City": "🗽", "Oklahoma City": "🌪️", Tulsa: "🌳", "Brooklyn,NY": "🗽",
  Hawaii: "🌺",
};


const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = lat1 * (Math.PI / 180);
  const rlat2 = lat2 * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (lon2 - lon1) * (Math.PI / 180);

  return (
    2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) ** 2 +
      Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) ** 2))
  );
};

// const LandingPage = () => {
//   const { searchTerm } = useContext(SearchContext);
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [hoveredBar, setHoveredBar] = useState(null);
//   const [selectedState, setSelectedState] = useState("");
//   const [selectedCity, setSelectedCity] = useState("");
//   const [viewport, setViewport] = useState({
//     latitude: 37.7749,
//     longitude: -95.7129,
//     zoom: 2,
//   });
//   const [popupInfo, setPopupInfo] = useState(null);
//   const [favorites, setFavorites] = useState([]);
//   const [seen, setSeen] = useState([]);
//   const [isInputModalOpen, setInputModalOpen] = useState(false);
//   const [darkMode, setDarkMode] = useState(false);
//   const [selectedBar, setSelectedBar] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [user, setUser] = useState(null); // Add state for the user
//   const isMobile = useMediaQuery('(max-width:600px)');
//   const mapRef = useRef(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data: bars, error } = await supabase
//           .from("bars")
//           .select("*");

//         if (error) throw error;

//         setData(bars);
//         setFilteredData(bars);
//       } catch (error) {
//         console.error("Error fetching data from Supabase:", error);
//       }
//     };
//     fetchData();

//     const fetchUser = async () => {
//       const { data: { user }, error } = await supabase.auth.getUser();
//       if (error) {
//         console.error("Error fetching user:", error);
//       } else {
//         setUser(user);
//       }
//     };
//     fetchUser();
//   }, []);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const userLatitude = position.coords.latitude;
//         const userLongitude = position.coords.longitude;

//         setViewport({
//           latitude: userLatitude,
//           longitude: userLongitude,
//           zoom: 3,
//         });

//         const sortedData = [...data].sort((a, b) => {
//           const distanceA = calculateDistance(userLatitude, userLongitude, a.latitude, a.longitude);
//           const distanceB = calculateDistance(userLatitude, userLongitude, b.latitude, b.longitude);
//           return distanceA - distanceB;
//         });

//         setFilteredData(sortedData);

//         if (mapRef.current) {
//           mapRef.current.flyTo({
//             center: [userLongitude, userLatitude],
//             zoom: 10,
//             speed: 1.5,
//             curve: 1,
//           });
//         }
//       },
//       (error) => {
//         console.error("Error getting user location:", error);
//       }
//     );
//   }, [data]);

//   useEffect(() => {
//     filterData(searchTerm, selectedState, selectedCity);
//   }, [searchTerm, selectedState, selectedCity]);

//   const handleStateChange = (event) => {
//     const value = event.target.value;
//     setSelectedState(value);
//     setSelectedCity("");
//     filterData(searchTerm, value, "");

//     if (value && stateCoordinates[value]) {
//       setViewport({
//         latitude: stateCoordinates[value][0],
//         longitude: stateCoordinates[value][1],
//         zoom: 6,
//       });
//     }
//   };

//   const handleCityChange = (event) => {
//     const value = event.target.value;
//     setSelectedCity(value);
//     filterData(searchTerm, selectedState, value);
//   };

//   const filterData = (search, state, city) => {
//     let filtered = data.filter(bar =>
//       (search ? bar.name.toLowerCase().includes(search.toLowerCase()) ||
//         bar.address.toLowerCase().includes(search.toLowerCase()) ||
//         bar.city.toLowerCase().includes(search.toLowerCase()) ||
//         bar.state.toLowerCase().includes(search.toLowerCase()) ||
//         (bar.reviews && bar.reviews.toLowerCase().includes(search.toLowerCase())) ||
//         (bar.website && bar.website.toLowerCase().includes(search.toLowerCase())) : true) &&
//       (state ? bar.state === state : true) &&
//       (city ? bar.city === city : true)
//     );
//     setFilteredData(filtered);
//   };

//   const handleMarkerClick = (bar) => {
//     setSelectedBar(bar);
//     navigate(`/kavabar/${bar.id}`);
//   };

//   const handleMarkerHover = (bar) => {
//     setPopupInfo(bar);
//   };

//   const handleMarkerLeave = () => {
//     setPopupInfo(null);
//   };

//   const handleFlyTo = (latitude, longitude) => {
//     if (mapRef.current) {
//       mapRef.current.flyTo({
//         center: [longitude, latitude],
//         zoom: 14,
//         speed: 1.5,
//         curve: 1,
//       });
//     }
//   };

//   const toggleFavorite = async (barId) => {
//     if (!user) {
//       setSnackbarMessage("Please log in to favorite a bar.");
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       const updatedFavorites = favorites.includes(barId)
//         ? favorites.filter(id => id !== barId)
//         : [...favorites, barId];

//       const { data, error } = await supabase
//         .from("profiles")
//         .update({ favorites: updatedFavorites })
//         .eq("id", user.id);

//       if (error) {
//         console.error("Error updating favorites:", error);
//       } else {
//         setFavorites(updatedFavorites);
//         setSnackbarMessage(favorites.includes(barId)
//           ? "Removed from your favorites!"
//           : "Added to your favorites!");
//         setSnackbarOpen(true);
//         console.log("Updated favorites:", updatedFavorites);
//       }
//     } catch (error) {
//       console.error("Error handling like:", error);
//     }
//   };

//   const toggleSeen = async (barId) => {
//     if (!user) {
//       setSnackbarMessage("Please log in to mark a bar as visited.");
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       const updatedSeen = seen.includes(barId)
//         ? seen.filter(id => id !== barId)
//         : [...seen, barId];

//       const { data, error } = await supabase
//         .from("profiles")
//         .update({ beenTo: updatedSeen })
//         .eq("id", user.id);

//       if (error) {
//         console.error("Error updating seen list:", error);
//       } else {
//         setSeen(updatedSeen);
//         setSnackbarMessage(seen.includes(barId)
//           ? "Removed from your visited bars!"
//           : "Added to your visited bars!");
//         setSnackbarOpen(true);
//         console.log("Updated seen:", updatedSeen);
//       }
//     } catch (error) {
//       console.error("Error handling seen:", error);
//     }
//   };

//   const toggleDarkMode = () => {
//     setDarkMode(prevMode => !prevMode);
//   };

//   const handleImageError = (e) => {
//     e.target.src = "./default.png";
//   };

//   const CustomMarker = ({ bar, onClick, onHover, onLeave }) => (
//     <div
//       onClick={onClick}
//       onMouseEnter={onHover}
//       onMouseLeave={onLeave}
//       style={{
//         cursor: 'pointer',
//         transform: hoveredBar === bar ? 'scale(1.2)' : 'scale(1)',
//         transition: 'transform 0.2s',
//       }}
//     >
//       <img src="/leafly.svg" alt="Leaf Icon" style={{ width: 24, height: 24, filter: bar === selectedBar ? 'hue-rotate(90deg)' : 'none' }} />
//     </div>
//   );

//   const getStateCount = (state) => data.filter(bar => bar.state === state).length;

//   const getCityCount = (city) => data.filter(bar => bar.city === city).length;

//   const handleScroll = (event) => {
//     if (isMobile) {
//       const visibleIndex = Math.round(event.target.scrollLeft / 280);
//       const bar = filteredData[visibleIndex];
//       if (bar) {
//         handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude));
//       }
//     }
//   };

//   return (
//     <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", bgcolor: "#f4e7d2", p: isMobile ? 0 : 2 }}>
//       <ResponsiveNavBar
//         darkMode={darkMode}
//         toggleDarkMode={toggleDarkMode}
//       />
//       {isMobile && (
//         <Box sx={{ p: 0, textAlign: 'center', mt: 0 }}>
//           <Typography variant="h6" component="i" sx={{ color: '#7a5d4d' }}>
//             kavayak map
//           </Typography>
//         </Box>
//       )}
//       <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
//         {!isMobile && (
//           <Box sx={{
//             display: "flex",
//             flexDirection: { xs: "column", sm: "row" },
//             width: "100%",
//             p: { xs: 0, sm: 4.5 },
//             mt: { xs: 0, sm: 5 },
//             alignItems: "center",
//             justifyContent: "center",
//             gap: 2,
//           }}>
//             <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
//               <InputLabel id="state-select-label" sx={{ color: '#353535' }}>Choose your state</InputLabel>
//               <Select
//                 labelId="state-select-label"
//                 value={selectedState}
//                 onChange={handleStateChange}
//                 fullWidth
//                 variant="outlined"
//                 sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 {Object.keys(stateCoordinates)
//                   .sort((a, b) => getStateCount(b) - getStateCount(a))
//                   .map(state => (
//                     <MenuItem key={state} value={state}>
//                       {stateEmojis[state]} {state} ({getStateCount(state)})
//                     </MenuItem>
//                   ))}
//               </Select>
//             </Box>
//             <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
//               <InputLabel id="city-select-label" sx={{ color: '#353535' }}>Choose your city</InputLabel>
//               <Select
//                 labelId="city-select-label"
//                 value={selectedCity}
//                 onChange={handleCityChange}
//                 fullWidth
//                 variant="outlined"
//                 disabled={!selectedState}
//                 sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 {Array.from(new Set(data.filter(bar => bar.state === selectedState).map(bar => bar.city)))
//                   .sort((a, b) => getCityCount(b) - getCityCount(a))
//                   .map(city => (
//                     <MenuItem key={city} value={city}>
//                       {cityEmojis[city]} {city} ({getCityCount(city)})
//                     </MenuItem>
//                   ))}
//               </Select>
//             </Box>
//           </Box>
//         )}
//         <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, flexGrow: 1 }}>
//           <Box
//             sx={{
//               width: { xs: "100%", md: "40%" },
//               height: { xs: "auto", md: "100vh" },
//               overflowY: { xs: "hidden", md: "auto" },
//               overflowX: { xs: "auto", md: "hidden" },
//               order: { xs: 2, md: 1 },
//               display: "flex",
//               flexDirection: { xs: "row", md: "column" },
//               p: 1,
//               '&::-webkit-scrollbar': {
//                 width: '8px',
//                 height: '8px',
//               },
//               '&::-webkit-scrollbar-track': {
//                 background: '#EBE2CD',
//               },
//               '&::-webkit-scrollbar-thumb': {
//                 background: '#353535',
//                 borderRadius: '4px',
//               },
//               '&::-webkit-scrollbar-thumb:hover': {
//                 background: '#353535',
//               },
//               scrollbarWidth: 'thin',
//               scrollbarColor: '#353535 #EBE2CD',
//               mt: isMobile ? 5 : 0,
//             }}
//             onScroll={handleScroll}
//           >
//             {filteredData.map((bar, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   width: { xs: "100%", md: "100%" },
//                   flexShrink: 0,
//                   mr: { xs: 10, md: 0 },
//                   mb: { xs: 10, md: 2 },
//                   p: 3,
//                   boxShadow: 1,
//                   borderRadius: 2,
//                   cursor: "pointer",
//                   transition: 'box-shadow 0.2s, transform 0.2s',
//                   backgroundColor: '#F5ECD7',
//                   '&:hover': {
//                     boxShadow: 3,
//                     transform: 'translateY(-2px)',
//                   },
//                 }}
//                 onClick={() => handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude))}
//               >
//                 <Box component="img" src={bar.store_image && bar.store_image.length > 1 ? bar.store_image : "./default.png"} alt={bar.name} sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1 }} onError={handleImageError} />
//                 <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
//                   <Box sx={{ display: "flex", flexDirection: "column" }}>
//                     <Box component="span" sx={{ fontWeight: "bold", color: '#353535' }}>{`${bar.name} ${cityEmojis[bar.city] || ''} ${stateEmojis[bar.state] || ''}`}</Box>
//                     <Box component="span" sx={{ color: '' }}>{bar.city}, {bar.state}</Box>
//                   </Box>
//                   <Box sx={{ display: "flex", alignItems: "center" }}>
//                     {/* <IconButton onClick={(e) => { e.stopPropagation(); toggleFavorite(bar.id); }}>
//                       {favorites.includes(bar.id) ? <Favorite sx={{ color: '#eb9c64' }} /> : <FavoriteBorder sx={{ color: '#eb9c64' }} />}
//                     </IconButton>
//                     <IconButton onClick={(e) => { e.stopPropagation(); toggleSeen(bar.id); }}>
//                       {seen.includes(bar.id) ? <Visibility sx={{ color: '#4caf50' }} /> : <VisibilityOff sx={{ color: '#1976d2' }} />}
//                     </IconButton> */}
//                   </Box>
//                 </Box>
//                 <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); handleMarkerClick(bar); }} sx={{ mt: 1, bgcolor: '#8fbf9f', '&:hover': { bgcolor: '#6c8f7d' } }}>
//                   Details
//                 </Button>
//               </Box>
//             ))}
//           </Box>

//           <Box sx={{
//             height: { xs: "50vh", md: "100vh" },
//             width: { xs: "100%", md: "60%" },
//             order: { xs: 1, md: 2 }
//           }}>
//             <Map
//               ref={mapRef}
//               initialViewState={viewport}
//               style={{ width: "100%", height: "100%" }}
//               mapStyle="mapbox://styles/mapbox/streets-v11"
//               mapboxAccessToken={MAPBOX_TOKEN}
//               scrollZoom={true}
//             >
//               {filteredData.map((bar, index) => {
//                 const lat = parseFloat(bar.latitude);
//                 const lng = parseFloat(bar.longitude);
//                 if (isNaN(lat) || isNaN(lng)) {
//                   console.log(`Invalid coordinates for ${bar.name}: Latitude: ${lat}, Longitude: ${lng}`);
//                   return null;
//                 }
//                 return (
//                   <Marker
//                     key={index}
//                     latitude={lat}
//                     longitude={lng}
//                   >
//                     <CustomMarker
//                       bar={bar}
//                       onClick={() => handleMarkerClick(bar)}
//                       onHover={() => handleMarkerHover(bar)}
//                       onLeave={handleMarkerLeave}
//                     />
//                   </Marker>
//                 );
//               })}
//               {popupInfo && (
//                 <Popup
//                   latitude={parseFloat(popupInfo.latitude)}
//                   longitude={parseFloat(popupInfo.longitude)}
//                   closeButton={false}
//                   closeOnClick={false}
//                   onClose={handleMarkerLeave}
//                   anchor="top"
//                 >
//                   <Box sx={{
//                     p: 2,
//                     borderRadius: 2,
//                     boxShadow: 3,
//                     bgcolor: '#EBE2CD',
//                     maxWidth: 300,
//                   }}>
//                     <Box component="img"
//                       src={popupInfo.store_image && popupInfo.store_image.length > 1 ? popupInfo.store_image : "./default.png"}
//                       alt={popupInfo.name}
//                       sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1, mb: 1 }}
//                       onError={handleImageError}
//                     />
//                     <Typography variant="h6" sx={{ mt: 0, mb: 1, fontWeight: "bold", color: '#353535' }}>{popupInfo.name}</Typography>
//                     <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>Rating: {popupInfo.rating}</Typography>
//                     {popupInfo.website && (
//                       <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>
//                         <a href={popupInfo.website} target="_blank" rel="noopener noreferrer" style={{ color: '#8fbf9f' }}>Visit Website</a>
//                       </Typography>
//                     )}
//                   </Box>
//                 </Popup>
//               )}
//               <GeolocateControl position="bottom-left" />
//               <NavigationControl position="bottom-right" />
//               <Source
//                 id="user-location"
//                 type="geojson"
//                 data={{
//                   type: "Feature",
//                   properties: {},
//                   geometry: {
//                     type: "Point",
//                     coordinates: [viewport.longitude, viewport.latitude],
//                   },
//                 }}
//               >
//                 <Layer
//                   id="point"
//                   type="circle"
//                   paint={{
//                     "circle-radius": 10,
//                     "circle-color": "#eb9c64",
//                   }}
//                 />
//               </Source>
//             </Map>
//           </Box>
//         </Box>
//       </Box>
//       <Box component="footer" sx={{ bgcolor: "#353535", color: "#F5ECD7", py: 2, mt: "auto", display: { xs: "none", md: "block" } }}>
//         {/* Footer content */}
//       </Box>
//       <InputModal isOpen={isInputModalOpen} onClose={() => setInputModalOpen(false)} />
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={3000}
//         onClose={() => setSnackbarOpen(false)}
//         message={snackbarMessage}
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//       />
//     </Box>
//   );
// };

// export default LandingPage;
const LandingPage = () => {
  const { searchTerm } = useContext(SearchContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hoveredBar, setHoveredBar] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [viewport, setViewport] = useState({
    latitude: 37.7749,
    longitude: -95.7129,
    zoom: 2,
  });
  const [popupInfo, setPopupInfo] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [seen, setSeen] = useState([]);
  const [isInputModalOpen, setInputModalOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [selectedBar, setSelectedBar] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const mapRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: bars, error } = await supabase
          .from("bars")
          .select("*");

        if (error) throw error;

        setData(bars);
        setFilteredData(bars);
      } catch (error) {
        console.error("Error fetching data from Supabase:", error);
      }
    };
    fetchData();

    const fetchUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error("Error fetching user:", error);
      } else {
        setUser(user);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLatitude = position.coords.latitude;
        const userLongitude = position.coords.longitude;

        setViewport({
          latitude: userLatitude,
          longitude: userLongitude,
          zoom: 3,
        });

        const sortedData = [...data].sort((a, b) => {
          const distanceA = calculateDistance(userLatitude, userLongitude, a.latitude, a.longitude);
          const distanceB = calculateDistance(userLatitude, userLongitude, b.latitude, b.longitude);
          return distanceA - distanceB;
        });

        setFilteredData(sortedData);

        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [userLongitude, userLatitude],
            zoom: 10,
            speed: 1.5,
            curve: 1,
          });
        }
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  }, [data]);

  const filterData = useCallback((search, state, city) => {
    let filtered = data.filter(bar =>
      (search ? bar.name.toLowerCase().includes(search.toLowerCase()) ||
        bar.address.toLowerCase().includes(search.toLowerCase()) ||
        bar.city.toLowerCase().includes(search.toLowerCase()) ||
        bar.state.toLowerCase().includes(search.toLowerCase()) ||
        (bar.reviews && bar.reviews.toLowerCase().includes(search.toLowerCase())) ||
        (bar.website && bar.website.toLowerCase().includes(search.toLowerCase())) : true) &&
      (state ? bar.state === state : true) &&
      (city ? bar.city === city : true)
    );
    setFilteredData(filtered);
  }, [data]);

  useEffect(() => {
    filterData(searchTerm, selectedState, selectedCity);
  }, [searchTerm, selectedState, selectedCity, filterData]);

  const handleStateChange = useCallback((event) => {
    const value = event.target.value;
    setSelectedState(value);
    setSelectedCity("");
    filterData(searchTerm, value, "");

    if (value && stateCoordinates[value]) {
      setViewport({
        latitude: stateCoordinates[value][0],
        longitude: stateCoordinates[value][1],
        zoom: 6,
      });
    }
  }, [searchTerm, filterData]);

  const handleCityChange = useCallback((event) => {
    const value = event.target.value;
    setSelectedCity(value);
    filterData(searchTerm, selectedState, value);
  }, [searchTerm, selectedState, filterData]);

  const handleMarkerClick = useCallback((bar) => {
    setSelectedBar(bar);
    navigate(`/kavabar/${bar.id}`);
  }, [navigate]);

  const handleMarkerHover = useCallback((bar) => {
    setPopupInfo(bar);
  }, []);

  const handleMarkerLeave = useCallback(() => {
    setPopupInfo(null);
  }, []);

  const handleFlyTo = useCallback((latitude, longitude) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 14,
        speed: 1.5,
        curve: 1,
      });
    }
  }, []);

  const toggleFavorite = useCallback(async (barId) => {
    if (!user) {
      setSnackbarMessage("Please log in to favorite a bar.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const updatedFavorites = favorites.includes(barId)
        ? favorites.filter(id => id !== barId)
        : [...favorites, barId];

      const { error } = await supabase
        .from("profiles")
        .update({ favorites: updatedFavorites })
        .eq("id", user.id);

      if (error) {
        console.error("Error updating favorites:", error);
      } else {
        setFavorites(updatedFavorites);
        setSnackbarMessage(favorites.includes(barId)
          ? "Removed from your favorites!"
          : "Added to your favorites!");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error handling like:", error);
    }
  }, [favorites, user]);

  const toggleSeen = useCallback(async (barId) => {
    if (!user) {
      setSnackbarMessage("Please log in to mark a bar as visited.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const updatedSeen = seen.includes(barId)
        ? seen.filter(id => id !== barId)
        : [...seen, barId];

      const { error } = await supabase
        .from("profiles")
        .update({ beenTo: updatedSeen })
        .eq("id", user.id);

      if (error) {
        console.error("Error updating seen list:", error);
      } else {
        setSeen(updatedSeen);
        setSnackbarMessage(seen.includes(barId)
          ? "Removed from your visited bars!"
          : "Added to your visited bars!");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error handling seen:", error);
    }
  }, [seen, user]);

  const toggleDarkMode = useCallback(() => {
    setDarkMode(prevMode => !prevMode);
  }, []);

  const handleImageError = useCallback((e) => {
    e.target.src = "./default.png";
  }, []);

  const CustomMarker = useMemo(() => ({ bar, onClick, onHover, onLeave }) => (
    <div
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      style={{
        cursor: 'pointer',
        transform: hoveredBar === bar ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.2s',
      }}
    >
      <img src="./leafly.svg" alt="Leaf Icon" style={{ width: 24, height: 24, filter: bar === selectedBar ? 'hue-rotate(90deg)' : 'none' }} />
    </div>
  ), [hoveredBar, selectedBar]);

  const getStateCount = useCallback((state) => data.filter(bar => bar.state === state).length, [data]);

  const getCityCount = useCallback((city) => data.filter(bar => bar.city === city).length, [data]);

  const handleScroll = useCallback((event) => {
    if (isMobile) {
      const visibleIndex = Math.round(event.target.scrollLeft / 280);
      const bar = filteredData[visibleIndex];
      if (bar) {
        handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude));
      }
    }
  }, [isMobile, filteredData, handleFlyTo]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", bgcolor: "#f4e7d2", p: isMobile ? 0 : 2 }}>
      <ResponsiveNavBar
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
      />
      {isMobile && (
        <Box sx={{ p: 0, textAlign: 'center', mt: 0 }}>
          <Typography variant="h6" component="i" sx={{ color: '#7a5d4d' }}>
            kavayak map
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {!isMobile && (
          <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            p: { xs: 0, sm: 4.5 },
            mt: { xs: 0, sm: 5 },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
              <InputLabel id="state-select-label" sx={{ color: '#353535' }}>Choose your state</InputLabel>
              <Select
                labelId="state-select-label"
                value={selectedState}
                onChange={handleStateChange}
                fullWidth
                variant="outlined"
                sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.keys(stateCoordinates)
                  .sort((a, b) => getStateCount(b) - getStateCount(a))
                  .map(state => (
                    <MenuItem key={state} value={state}>
                      {stateEmojis[state]} {state} ({getStateCount(state)})
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "50%" } }}>
              <InputLabel id="city-select-label" sx={{ color: '#353535' }}>Choose your city</InputLabel>
              <Select
                labelId="city-select-label"
                value={selectedCity}
                onChange={handleCityChange}
                fullWidth
                variant="outlined"
                disabled={!selectedState}
                sx={{ color: '#353535', bgcolor: '#EBE2CD' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Array.from(new Set(data.filter(bar => bar.state === selectedState).map(bar => bar.city)))
                  .sort((a, b) => getCityCount(b) - getCityCount(a))
                  .map(city => (
                    <MenuItem key={city} value={city}>
                      {cityEmojis[city]} {city} ({getCityCount(city)})
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, flexGrow: 1 }}>
          <Box
            sx={{
              width: { xs: "100%", md: "40%" },
              height: { xs: "auto", md: "100vh" },
              overflowY: { xs: "hidden", md: "auto" },
              overflowX: { xs: "auto", md: "hidden" },
              order: { xs: 2, md: 1 },
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              p: 1,
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#EBE2CD',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#353535',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#353535',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: '#353535 #EBE2CD',
              mt: isMobile ? 2 : 0, // Adjusted this line to move the carousel higher on mobile
            }}
            onScroll={handleScroll}
          >
            {filteredData.map((bar, index) => (
              <Box
                key={index}
                sx={{
                  width: { xs: "280px", md: "100%" },
                  flexShrink: 0,
                  mr: { xs: 2, md: 0 },
                  mb: { xs: 2, md: 2 },
                  p: 3,
                  boxShadow: 1,
                  borderRadius: 2,
                  cursor: "pointer",
                  transition: 'box-shadow 0.2s, transform 0.2s',
                  backgroundColor: '#F5ECD7',
                  '&:hover': {
                    boxShadow: 3,
                    transform: 'translateY(-2px)',
                  },
                }}
                onClick={() => handleFlyTo(parseFloat(bar.latitude), parseFloat(bar.longitude))}
              >
                <Box component="img" src={bar.store_image && bar.store_image.length > 1 ? bar.store_image : "./default.png"} alt={bar.name} sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1 }} onError={handleImageError} />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box component="span" sx={{ fontWeight: "bold", color: '#353535' }}>{`${bar.name} ${cityEmojis[bar.city] || ''} ${stateEmojis[bar.state] || ''}`}</Box>
                    <Box component="span" sx={{ color: '' }}>{bar.city}, {bar.state}</Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* Commented out as per your previous code
                    <IconButton onClick={(e) => { e.stopPropagation(); toggleFavorite(bar.id); }}>
                      {favorites.includes(bar.id) ? <Favorite sx={{ color: '#eb9c64' }} /> : <FavoriteBorder sx={{ color: '#eb9c64' }} />}
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); toggleSeen(bar.id); }}>
                      {seen.includes(bar.id) ? <Visibility sx={{ color: '#4caf50' }} /> : <VisibilityOff sx={{ color: '#1976d2' }} />}
                    </IconButton>
                    */}
                  </Box>
                </Box>
                <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); handleMarkerClick(bar); }} sx={{ mt: 1, bgcolor: '#8fbf9f', '&:hover': { bgcolor: '#6c8f7d' } }}>
                  Details
                </Button>
              </Box>
            ))}
          </Box>

          <Box sx={{
            height: { xs: "50vh", md: "100vh" },
            width: { xs: "100%", md: "60%" },
            order: { xs: 1, md: 2 }
          }}>
            <Map
              ref={mapRef}
              initialViewState={viewport}
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxAccessToken={MAPBOX_TOKEN}
              scrollZoom={true}
            >
              {filteredData.map((bar, index) => {
                const lat = parseFloat(bar.latitude);
                const lng = parseFloat(bar.longitude);
                if (isNaN(lat) || isNaN(lng)) {
                  console.log(`Invalid coordinates for ${bar.name}: Latitude: ${lat}, Longitude: ${lng}`);
                  return null;
                }
                return (
                  <Marker
                    key={index}
                    latitude={lat}
                    longitude={lng}
                  >
                    <CustomMarker
                      bar={bar}
                      onClick={() => handleMarkerClick(bar)}
                      onHover={() => handleMarkerHover(bar)}
                      onLeave={handleMarkerLeave}
                    />
                  </Marker>
                );
              })}
              {popupInfo && (
                <Popup
                  latitude={parseFloat(popupInfo.latitude)}
                  longitude={parseFloat(popupInfo.longitude)}
                  closeButton={false}
                  closeOnClick={false}
                  onClose={handleMarkerLeave}
                  anchor="top"
                >
                  <Box sx={{
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    bgcolor: '#EBE2CD',
                    maxWidth: 300,
                  }}>
                    <Box component="img"
                      src={popupInfo.store_image && popupInfo.store_image.length > 1 ? popupInfo.store_image : "./default.png"}
                      alt={popupInfo.name}
                      sx={{ width: "100%", height: 128, objectFit: "cover", borderRadius: 1, mb: 1 }}
                      onError={handleImageError}
                    />
                    <Typography variant="h6" sx={{ mt: 0, mb: 1, fontWeight: "bold", color: '#353535' }}>{popupInfo.name}</Typography>
                    <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>Rating: {popupInfo.rating}</Typography>
                    {popupInfo.website && (
                      <Typography variant="body2" sx={{ mt: 0, mb: 1, color: '#353535' }}>
                        <a href={popupInfo.website} target="_blank" rel="noopener noreferrer" style={{ color: '#8fbf9f' }}>Visit Website</a>
                      </Typography>
                    )}
                  </Box>
                </Popup>
              )}
              <GeolocateControl position="bottom-left" />
              <NavigationControl position="bottom-right" />
              <Source
                id="user-location"
                type="geojson"
                data={{
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [viewport.longitude, viewport.latitude],
                  },
                }}
              >
                <Layer
                  id="point"
                  type="circle"
                  paint={{
                    "circle-radius": 10,
                    "circle-color": "#eb9c64",
                  }}
                />
              </Source>
            </Map>
          </Box>
        </Box>
      </Box>
      <Box component="footer" sx={{ bgcolor: "#353535", color: "#F5ECD7", py: 2, mt: "auto", display: { xs: "none", md: "block" } }}>
        {/* Footer content */}
      </Box>
      <InputModal isOpen={isInputModalOpen} onClose={() => setInputModalOpen(false)} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Box>
  );
};

export default LandingPage;


