import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    TextField, Button, Box, Typography, useMediaQuery, ThemeProvider, createTheme, CssBaseline,
    IconButton, Pagination, Grid, Snackbar, Modal, Menu, MenuItem, Chip, Card, CardContent, Avatar,
    CardMedia, FormControl, InputLabel, Select, Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
    PersonAdd as PersonAddIcon, Map as MapIcon, Add as AddIcon, Menu as MenuIcon,
    Star as StarIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, Favorite as FavoriteIcon,
    FavoriteBorder as FavoriteBorderIcon
} from "@mui/icons-material";
import { ThemeContext } from "../ThemeContext";
import supabase from "./supabaseClient";

const HomePage = () => {
    const { darkMode } = useContext(ThemeContext);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState(new Map());
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [sortCriteria, setSortCriteria] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [currentBanner, setCurrentBanner] = useState(0);
    const [user, setUser] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [beenToArray, setBeenToArray] = useState([]);
    const [favoritesArray, setFavoritesArray] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [petitions, setPetitions] = useState([]);

    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const querySearchTerm = queryParams.get("search") || "";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { page } = useParams();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const itemsPerPage = 20;

    const stateEmojis = {
        "AL": "🛤️", "AK": "❄️", "AZ": "🌵", "AR": "🌳", "CA": "🌴",
        "CO": "⛰️", "CT": "🏛️", "DE": "🏖️", "FL": "🌞", "GA": "🍑",
        "HI": "🌺", "ID": "🥔", "IL": "🌽", "IN": "🏎️", "IA": "🌽",
        "KS": "🌻", "KY": "🐎", "LA": "🎷", "ME": "🦞", "MD": "🏀",
        "MA": "🦞", "MI": "🚗", "MN": "⛷️", "MS": "🎸", "MO": "🌆",
        "MT": "🏞️", "NE": "🌽", "NV": "🎰", "NH": "⛷️", "NJ": "🏖️",
        "NM": "🌶️", "NY": "🗽", "NC": "🏞️", "ND": "🌾", "OH": "🛤️",
        "OK": "🛢️", "OR": "🌲", "PA": "🔔", "RI": "⚓", "SC": "🌴",
        "SD": "🗿", "TN": "🎸", "TX": "🤠", "UT": "🏜️", "VT": "🧀",
        "VA": "🛤️", "WA": "🌧️", "WV": "⛏️", "WI": "🧀", "WY": "🏞️"
    };

    const bannerImages = ["/raintwo.png"];

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: bars, error } = await supabase
                    .from("bars")
                    .select("id, name, state, city, latitude, longitude, tags, rating, store_image");

                if (error) throw error;

                setData(bars);
                setFilteredData(bars);

                const allTags = new Map();
                const allCities = new Map();
                bars.forEach((bar) => {
                    if (bar.tags) {
                        bar.tags.split(", ").forEach((tag) => {
                            const cleanedTag = tag.replace(/[\[\]"']/g, '');
                            allTags.set(cleanedTag, (allTags.get(cleanedTag) || 0) + 1);
                        });
                    }
                    if (!allCities.has(bar.state)) {
                        allCities.set(bar.state, new Set());
                    }
                    allCities.get(bar.state).add(bar.city);
                });

                const sortedTags = Array.from(allTags.entries()).sort((a, b) => b[1] - a[1]);
                setTags(sortedTags.slice(0, 40).map(([tag]) => tag));
                setCities(allCities);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchUserLocation = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                }
            );
        };

        fetchUserLocation();
    }, []);

    useEffect(() => {
        filterData(querySearchTerm, selectedState, selectedCity, selectedTags, "distance");
    }, [querySearchTerm, selectedState, selectedCity, selectedTags, data, userLocation]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerImages.length);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const { data, error } = await supabase.auth.getUser();
            if (error) {
                console.error("Error fetching user:", error);
            } else {
                setUser(data.user);
                if (data.user) {
                    fetchUserProfile(data.user.id);
                }
            }
        };

        fetchUser();
    }, []);

    const fetchUserProfile = async (userId) => {
        const { data, error } = await supabase
            .from('profiles')
            .select('beenTo, favorites, avatar_url')
            .eq('id', userId)
            .single();

        if (error) {
            console.error("Error fetching user profile:", error);
        } else {
            setBeenToArray(data.beenTo.map(String) || []);
            setFavoritesArray(data.favorites.map(String) || []);
            setAvatarUrl(data.avatar_url || null);
        }
    };

    useEffect(() => {
        const fetchProfilesALL = async () => {
            try {
                const { data: profilesData, error } = await supabase
                    .from('profiles')
                    .select('username, avatar_url, homebar');
                if (error) throw error;
                setProfiles(profilesData);
            } catch (error) {
                console.error("Error fetching profiles:", error);
            }
        };

        fetchProfilesALL();
    }, []);

    const handleDetailsClick = (bar) => {
        navigate(`/kavabar/${bar.id}`);
    };

    const handleLogin = async () => {
        try {
            await supabase.auth.signInWithOAuth({ provider: 'google' });
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };

    const handleLoginMap = async () => {
        try {
            const { error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
            if (error) throw error;
            navigate("/map");  // Forward the user to the map after login
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };


    const handleBeenTo = async (barId) => {
        if (!user) {
            setLoginModalOpen(true);
            return;
        }

        try {
            const barIdStr = barId.toString();
            const updatedBeenTo = beenToArray.includes(barIdStr)
                ? beenToArray.filter(id => id !== barIdStr)
                : [...beenToArray, barIdStr];

            const { error } = await supabase
                .from("profiles")
                .update({ beenTo: updatedBeenTo })
                .eq("id", user.id);

            if (error) {
                console.error("Error updating beenTo list:", error);
            } else {
                setBeenToArray(updatedBeenTo);
                setSnackbarMessage(updatedBeenTo.includes(barIdStr)
                    ? "Added to your visited bars!"
                    : "Removed from your visited bars!");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error handling beenTo:", error);
        }
    };

    const handleLike = async (barId) => {
        if (!user) {
            setLoginModalOpen(true);
            return;
        }

        try {
            const barIdStr = barId.toString();
            const updatedFavorites = favoritesArray.includes(barIdStr)
                ? favoritesArray.filter(id => id !== barIdStr)
                : [...favoritesArray, barIdStr];

            const { error } = await supabase
                .from("profiles")
                .update({ favorites: updatedFavorites })
                .eq("id", user.id);

            if (error) {
                console.error("Error updating favorites:", error);
            } else {
                setFavoritesArray(updatedFavorites);
                setSnackbarMessage(updatedFavorites.includes(barIdStr)
                    ? "Added to your favorites!"
                    : "Removed from your favorites!");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error handling like:", error);
        }
    };

    const filterData = (searchTerm, state, city, tags, sortCriteria) => {
        let filtered = data;

        if (searchTerm) {
            filtered = filtered.filter(
                (bar) =>
                    bar.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    bar.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    bar.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (bar.reviews && bar.reviews.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }

        if (state) {
            filtered = filtered.filter((bar) => bar.state === state);
        }

        if (city) {
            filtered = filtered.filter((bar) =>
                bar.city.toLowerCase().includes(city.toLowerCase())
            );
        }

        if (tags.length > 0) {
            filtered = filtered.filter(
                (bar) => bar.tags && tags.some((tag) => bar.tags.includes(tag))
            );
        }

        if (sortCriteria) {
            filtered = sortData(filtered, sortCriteria);
        }

        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 3958.8; // Radius of the Earth in miles
        const rlat1 = lat1 * (Math.PI / 180);
        const rlat2 = lat2 * (Math.PI / 180);
        const difflat = rlat2 - rlat1;
        const difflon = (lon2 - lon1) * (Math.PI / 180);

        return (
            2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) ** 2 +
                Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) ** 2))
        );
    };

    const sortData = (data, criteria) => {
        return [...data].sort((a, b) => {
            if (criteria === "rating") {
                return b.rating - a.rating;
            } else if (criteria === "distance" && userLocation) {
                const distanceA = calculateDistance(
                    userLocation.latitude,
                    userLocation.longitude,
                    a.latitude,
                    a.longitude
                );
                const distanceB = calculateDistance(
                    userLocation.latitude,
                    userLocation.longitude,
                    b.latitude,
                    b.longitude
                );
                return distanceA - distanceB;
            }
            return 0;
        });
    };

    const handleStateFilterChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);
        setSelectedCity("");
        filterData(querySearchTerm, selectedState, "", selectedTags, "distance");
    };

    const handleCityFilterChange = (event) => {
        const selectedCity = event.target.value;
        setSelectedCity(selectedCity);
        filterData(querySearchTerm, selectedState, selectedCity, selectedTags, "distance");
    };

    const handleTagFilterChange = (tag) => {
        const updatedTags = selectedTags.includes(tag)
            ? selectedTags.filter((t) => t !== tag)
            : [...selectedTags, tag];

        setSelectedTags(updatedTags);
        filterData(querySearchTerm, selectedState, selectedCity, updatedTags, "distance");
    };

    const getTopStates = (data) => {
        const stateCounts = data.reduce((acc, bar) => {
            acc[bar.state] = (acc[bar.state] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(stateCounts)
            .sort((a, b) => b[1] - a[1])
            .map((entry) => ({ state: entry[0], count: entry[1] }));
    };

    const topStates = getTopStates(data);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const darkTheme = createTheme({ palette: { mode: "dark" } });
    const lightTheme = createTheme({
        palette: {
            mode: "light",
            background: { default: "#f0f0f0" },
            text: { primary: "#000000" },
        },
    });

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                bgcolor: darkMode ? "#121212" : "#f0f0f0",
                color: darkMode ? "#ffffff" : "#000000",
            }}>
                {/* Banner and Search Bar */}
                <Box sx={{
                    width: "100%",
                    height: { xs: "300px", sm: "350px", md: "400px" },
                    backgroundImage: `url(${bannerImages[currentBanner]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}>
                    <Typography
                        variant="h3"
                        sx={{
                            position: "absolute",
                            top: { xs: "10%", sm: "15%" },
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
                            fontWeight: "bold",
                            textAlign: "center",
                            px: { xs: 2, sm: 0 },
                            color: "white",
                        }}
                    >
                        <style>
                            @import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
                        </style>

                        {/* Applying the Permanent Marker font */}
                        <div style={{
                            fontFamily: '"Permanent Marker", cursive',
                            fontWeight: 400,
                            fontStyle: 'normal'
                        }}>
                            Bula Maps 🦙
                        </div>
                    </Typography>
                    <Box sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        display: "flex",
                        gap: "10px",
                    }}>
                        {isMobile ? (
                            <>
                                <IconButton
                                    sx={{
                                        color: "white",
                                        border: "2px solid white",
                                        borderRadius: "50px",
                                        fontSize: { xs: "1.2rem", md: "1.5rem" },
                                    }}
                                    onClick={handleMenuOpen}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {user ? (
                                        <>
                                            {avatarUrl ? (
                                                <MenuItem onClick={() => navigate("/profile")}>
                                                    <img
                                                        src={avatarUrl}
                                                        alt="Avatar"
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '50%',
                                                            marginRight: '10px'
                                                        }}
                                                    />
                                                </MenuItem>
                                            ) : (
                                                <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
                                            )}
                                            <MenuItem onClick={() => navigate("/add")}>Add Bar</MenuItem>
                                        </>
                                    ) : (
                                        <MenuItem onClick={() => setLoginModalOpen(true)}>Sign Up</MenuItem>
                                    )}
                                    <MenuItem
                                        onClick={() => {
                                            if (!user) {
                                                setLoginModalOpen(true);
                                            } else {
                                                navigate("/map");
                                            }
                                        }}
                                    >
                                        Map
                                    </MenuItem>
                                </Menu>
                                <IconButton
                                    sx={{
                                        color: "white",
                                        border: "2px solid white",
                                        borderRadius: "50px",
                                        fontSize: { xs: "1.2rem", md: "1.5rem" },
                                    }}
                                    onClick={() => {
                                        if (!user) {
                                            setLoginModalOpen(true);
                                        } else {
                                            navigate("/map");
                                        }
                                    }}
                                >
                                    <MapIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                {user ? (
                                    <>
                                        {avatarUrl ? (
                                            <img
                                                src={avatarUrl}
                                                alt="Avatar"
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => navigate("/profile")}
                                            />
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => navigate("/profile")}
                                                sx={{ borderRadius: "50px", marginLeft: 2 }}
                                            >
                                                Profile
                                            </Button>
                                        )}
                                        <IconButton
                                            sx={{
                                                color: "white",
                                                border: "2px solid white",
                                                borderRadius: "50px",
                                                fontSize: { xs: "1.2rem", md: "1.5rem" },
                                            }}
                                            onClick={() => navigate("/map")}
                                        >
                                            <MapIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                color: "white",
                                                border: "2px solid white",
                                                borderRadius: "50px",
                                                fontSize: { xs: "1.2rem", md: "1.5rem" },
                                            }}
                                            onClick={() => navigate("/add")}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setLoginModalOpen(true)}
                                            sx={{ borderRadius: "50px", marginLeft: 2 }}
                                        >
                                            Sign Up
                                        </Button>
                                        <IconButton
                                            sx={{
                                                color: "white",
                                                border: "2px solid white",
                                                borderRadius: "50px",
                                                fontSize: { xs: "1.2rem", md: "1.5rem" },
                                            }}
                                            onClick={() => setLoginModalOpen(true)}
                                        >
                                            <MapIcon />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'flex',
                            gap: 2,
                            width: 'max-content',
                        }}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Search every kava bar in the US"
                            sx={{
                                bgcolor: 'white',
                                borderRadius: { xs: '8px', sm: '100px' },
                                boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                                flex: 14,
                                minWidth: '300px',
                                mx: { xs: 0, sm: 2 },
                                width: { xs: '90%', sm: '90%' },
                            }}
                            value={querySearchTerm}
                            onChange={(e) => {
                                navigate(`?search=${encodeURIComponent(e.target.value)}`);
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'row',
                                gap: { xs: 2, sm: 1 },
                                width: { xs: 'min-content', sm: 'auto' },
                            }}
                        >
                            <FormControl variant="outlined" sx={{ minWidth: { xs: '100%', sm: '90px' } }}>
                                <InputLabel htmlFor="state-select">State</InputLabel>
                                <Select
                                    native
                                    value={selectedState}
                                    onChange={handleStateFilterChange}
                                    label="State"
                                    inputProps={{
                                        name: 'state',
                                        id: 'state-select',
                                    }}
                                    sx={{ bgcolor: 'white', borderRadius: '10px', flex: 'none' }}
                                >
                                    <option aria-label="None" value="" />
                                    {topStates.map(({ state, count }, idx) => (
                                        <option key={idx} value={state}>
                                            {stateEmojis[state]} {state} ({count})
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" sx={{ minWidth: { xs: '100%', sm: '140px' } }}>
                                <InputLabel htmlFor="city-select">City</InputLabel>
                                <Select
                                    native
                                    value={selectedCity}
                                    onChange={handleCityFilterChange}
                                    label="City"
                                    inputProps={{
                                        name: 'city',
                                        id: 'city-select',
                                    }}
                                    sx={{ bgcolor: 'white', borderRadius: '10px', flex: 'none' }}
                                    disabled={!selectedState}
                                >
                                    <option aria-label="None" value="" />
                                    {selectedState &&
                                        Array.from(cities.get(selectedState)).map((city, idx) => (
                                            <option key={idx} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>


                {/* Tags Section */}
                <Box sx={{ bgcolor: "white", py: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            overflowX: "auto",
                            py: 0,
                            px: 2,
                            gap: 1,
                            justifyContent: "center",
                            flexWrap: "nowrap",
                        }}
                    >
                        <Typography variant="h6" sx={{ mr: 2, alignSelf: "center" }}>
                            Filter by:
                        </Typography>
                        {tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                clickable
                                onClick={() => handleTagFilterChange(tag)}
                                color={selectedTags.includes(tag) ? "primary" : "default"}
                                sx={{ margin: "0 5px" }}
                            />
                        ))}
                    </Box>
                </Box>

                {/* Main content */}
                <Box
                    sx={{
                        flexGrow: 1,
                        py: 0,
                        px: 10,
                        bgcolor: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ maxWidth: "1800px", width: "180%" }}>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            {paginatedData.map((bar) => {
                                const barIdStr = bar.id.toString();
                                const isFavorite = favoritesArray.includes(barIdStr);
                                const isBeenTo = beenToArray.includes(barIdStr);

                                // Filter profiles that have this bar as their homebar
                                const matchedProfiles = profiles.filter(profile => profile.homebar === bar.id);

                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={bar.id}>
                                        <Card
                                            sx={{
                                                borderRadius: "16px",
                                                overflow: "clip",
                                                boxShadow: "none",
                                                transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
                                                "&:hover": {
                                                    boxShadow: "none",
                                                },
                                                maxWidth: "400px",
                                                height: "400px",
                                                display: "flex",
                                                flexDirection: "column",
                                                position: "relative",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flex: '0 0 75%',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleDetailsClick(bar)}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={bar.store_image || "./default.png"}
                                                    alt={bar.name}
                                                    sx={{
                                                        height: "200px",
                                                        objectFit: "cover",
                                                    }}
                                                    onError={(e) => {
                                                        e.target.src = "./default.png";
                                                    }}
                                                />
                                                <CardContent
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <Typography
                                                            gutterBottom
                                                            variant="h5"
                                                            component="div"
                                                            sx={{
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                flexGrow: 1,
                                                            }}
                                                        >
                                                            {bar.name}
                                                        </Typography>
                                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <IconButton
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleLike(bar.id);
                                                                }}
                                                                sx={{ p: 0 }}
                                                            >
                                                                {isFavorite ? (
                                                                    <FavoriteIcon sx={{ color: "#ff1744" }} />
                                                                ) : (
                                                                    <FavoriteBorderIcon sx={{ color: "#1976d2" }} />
                                                                )}
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleBeenTo(bar.id);
                                                                }}
                                                                sx={{ p: 0 }}
                                                            >
                                                                {isBeenTo ? (
                                                                    <VisibilityIcon sx={{ color: "#4caf50" }} />
                                                                ) : (
                                                                    <VisibilityOffIcon sx={{ color: "#1976d2" }} />
                                                                )}
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bar.city}, {bar.state}
                                                    </Typography>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                                        <StarIcon sx={{ fontSize: "1rem", color: "#FFD700" }} />
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{ ml: 0.5 }}
                                                        >
                                                            {bar.rating ? bar.rating.toFixed(1) : "N/A"}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{ ml: 2 }}
                                                        >
                                                            {userLocation
                                                                ? `${calculateDistance(
                                                                    userLocation.latitude,
                                                                    userLocation.longitude,
                                                                    bar.latitude,
                                                                    bar.longitude
                                                                ).toFixed(1)} mi`
                                                                : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            gap: 1,
                                                            mt: 1,
                                                            maxHeight: "40px",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {bar.tags && bar.tags
                                                            .replace(/[\[\]"']/g, '')
                                                            .split(', ')
                                                            .map((tag, index) => (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        bgcolor: "#f0f0f0",
                                                                        borderRadius: "12px",
                                                                        px: 2,
                                                                        py: 1,
                                                                        display: "inline-block",
                                                                        fontSize: "0.875rem",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                    }}
                                                                >
                                                                    {tag}
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                </CardContent>
                                            </Box>

                                            {/* Tooltip/Box for matched profiles */}
                                            {matchedProfiles.length > 0 && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        bottom: 8,
                                                        right: 8,
                                                        display: "flex",
                                                        gap: 1,
                                                    }}
                                                >
                                                    {matchedProfiles.map((profile, idx) => (
                                                        <Tooltip
                                                            key={idx}
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">{profile.username}</Typography>
                                                                    {profile.homebar && (
                                                                        <Typography color="inherit">Homebar: {profile.homebar}</Typography>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                            arrow
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: 35,
                                                                    height: 35,
                                                                    borderRadius: '50%',
                                                                    bgcolor: '#90EE90',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        opacity: 0.4,
                                                                    },
                                                                }}
                                                                onClick={() => navigate(`/user/${profile.username}`)}
                                                            >
                                                                <Avatar
                                                                    src={profile.avatar_url || "./tomo.gif"}
                                                                    alt={profile.username}
                                                                    sx={{ width: 30, height: 30 }}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    ))}
                                                </Box>
                                            )}
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>


                        <Box mt={4} display="flex" justifyContent="center">
                            <Pagination
                                count={Math.ceil(filteredData.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </Box>

                </Box>

                {/* Users Section */}
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2, // Increase gap for better spacing
                            justifyContent: 'center',
                            width: { xs: '90%', sm: '70%' },
                            p: 2, // Add padding for some inner spacing
                            bgcolor: 'background.paper', // Background color for contrast
                            borderRadius: '40px', // Rounded corners
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                            transition: 'all 0.3s ease-in-out', // Smooth transition for interactions
                            '&:hover': {
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow on hover
                                transform: 'scale(1.02)', // Subtle scale up on hover
                            },
                            backdropFilter: 'blur(10px)', // Slight blur effect
                        }}
                    > <h1> New users ❤️:  </h1>
                        {profiles.slice(0, 40).map((profile, index) => (
                            <Tooltip
                                key={index}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Beta User ❤️</Typography>
                                        {profile.homebar && (
                                            <Typography color="inherit">Homebar: {profile.homebar}</Typography>
                                        )}
                                    </React.Fragment>
                                }
                                arrow
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '12px',
                                        bgcolor: '#90EE90',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.4,
                                        },
                                    }}
                                    onClick={() => navigate(`/user/${profile.username}`)}
                                >
                                    <Avatar
                                        src={profile.avatar_url || "./tomo.gif"}
                                        alt={profile.username}
                                        sx={{ width: 35, height: 35 }}
                                    />
                                </Box>
                            </Tooltip>
                        ))}
                    </Box>
                </Box>s
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                />
                <Modal
                    open={loginModalOpen}
                    onClose={() => setLoginModalOpen(false)}
                    aria-labelledby="login-modal-title"
                    aria-describedby="login-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 300,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 1,
                        }}
                    >
                        <Typography id="login-modal-title" variant="h6" component="h2">
                            Please Log In
                        </Typography>
                        <Typography id="login-modal-description" sx={{ mt: 2 }}>
                            To look at our map please sign in 👑
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {

                                setLoginModalOpen(false);
                                handleLoginMap();
                            }}
                            sx={{ mt: 2 }}
                        >
                            Log In
                        </Button>
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>
    );
};

export default HomePage;
