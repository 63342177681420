// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
// import { ThemeProvider, CssBaseline, useMediaQuery, createTheme, Box, Typography, Button } from '@mui/material';
// import supabase from './components/supabaseClient';
// import HomePage from './components/HomePage';
// import Map from './components/Map';
// import KavaBarDetail from './components/KavaBarDetail';
// import AddBar from './components/AddBar';
// import ChatPage from './components/ChatPage';
// import ProfilePage from './components/ProfilePage';
// import ProfileOnboardingPage from './components/ProfileOnboardingPage';
// import ProfileEditPage from './components/ProfileEditPage';
// import LoginModal from './components/LoginModal';
// import ResponsiveNavBar from './components/ResponsiveNavBar';
// import { PetitionProvider } from './components/PetitionContext';

// const App = () => {
//   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
//   const [darkMode, setDarkMode] = useState(prefersDarkMode);
//   const [user, setUser] = useState(null);
//   const [userEmail, setUserEmail] = useState(null);
//   const [hasProfile, setHasProfile] = useState(false);
//   const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
//   const [isFirstLogin, setIsFirstLogin] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const checkUserProfile = async (email) => {
//     if (!email) {
//       console.log('No email available, skipping profile check');
//       return false;
//     }
//     console.log(`Checking profile for email: ${email}`);
//     const { data, error } = await supabase
//       .from('profiles')
//       .select('has_profile')
//       .eq('email', email)
//       .single();

//     if (error) {
//       console.error('Error fetching profile:', error);
//       return false;
//     }

//     console.log('Profile data:', data);
//     return data?.has_profile || false;
//   };

//   useEffect(() => {
//     const initializeApp = async () => {
//       console.log('Initializing app...');

//       const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
//       if (storedSession && storedSession.user) {
//         console.log('Existing session found:', storedSession);
//         setUser(storedSession.user);
//         setUserEmail(storedSession.user.email);
//         const profileStatus = await checkUserProfile(storedSession.user.email);
//         console.log(`Profile status for user ${storedSession.user.id}: ${profileStatus}`);
//         setHasProfile(profileStatus);

//         const isFirstLogin = !localStorage.getItem('hasSeenFirstLoginPopup');
//         if (isFirstLogin) {
//           setIsFirstLogin(true);
//         }
//       } else {
//         console.log('No existing session found');
//       }

//       const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//         console.log('Auth state change event:', event);
//         if (event === 'SIGNED_IN' && session) {
//           console.log('User signed in:', session.user);
//           setUser(session.user);
//           setUserEmail(session.user.email);
//           localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//           const profileStatus = await checkUserProfile(session.user.email);
//           console.log(`Profile status for user ${session.user.id}: ${profileStatus}`);
//           setHasProfile(profileStatus);
//           if (!localStorage.getItem('hasSeenFirstLoginPopup')) {
//             setIsFirstLogin(true);
//           }
//         } else if (event === 'SIGNED_OUT') {
//           console.log('User signed out');
//           setUser(null);
//           setUserEmail(null);
//           setHasProfile(false);
//           setIsFirstLogin(false);
//           localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
//         }
//       });

//       setLoading(false);
//       console.log('App initialization complete');

//       return () => {
//         authListener.subscription.unsubscribe();
//       };
//     };

//     initializeApp();

//   }, []);

//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? 'dark' : 'light',
//     },
//   });

//   const toggleDarkMode = () => {
//     setDarkMode(!darkMode);
//   };

//   const closeFirstLoginModal = () => {
//     setIsFirstLogin(false);
//     localStorage.setItem('hasSeenFirstLoginPopup', 'true');
//     navigate('/onboarding');
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <PetitionProvider>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         {location.pathname !== '/' && location.pathname !== '/home' && (
//           <ResponsiveNavBar
//             darkMode={darkMode}
//             toggleDarkMode={toggleDarkMode}
//             user={user}
//             setUser={setUser}
//           />
//         )}
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/map" element={<Map />} />
//           <Route path="/kavabar/:id" element={<KavaBarDetail />} />
//           <Route path="/add" element={<AddBar />} />
//           <Route
//             path="/profile"
//             element={
//               user
//                 ? (hasProfile
//                   ? <ProfilePage user={user} />
//                   : <Navigate to="/onboarding" />)
//                 : <Navigate to="/" />
//             }
//           />
//           <Route
//             path="/onboarding"
//             element={
//               user && !hasProfile
//                 ? <ProfileOnboardingPage user={user} setUser={setUser} setHasProfile={setHasProfile} />
//                 : <Navigate to="/profile" />
//             }
//           />
//           <Route
//             path="/profile/edit"
//             element={
//               user ? <ProfileEditPage user={user} setUser={setUser} /> : <Navigate to="/" />
//             }
//           />
//         </Routes>
//         {isFirstLogin && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: '20%',
//               left: '50%',
//               transform: 'translate(-50%, -20%)',
//               bgcolor: '#fff',
//               p: 4,
//               borderRadius: '16px',
//               boxShadow: '0 10px 20px rgba(0,0,0,0.25)',
//               textAlign: 'center',
//               zIndex: 1300,
//             }}
//           >
//             <Typography variant="h4" gutterBottom>
//               🎉 Congratulations!
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Thank you for signing up. Please create your profile.
//             </Typography>
//             <Button
//               variant="contained"
//               onClick={closeFirstLoginModal}
//               sx={{ mt: 2 }}
//             >
//               Create Profile
//             </Button>
//           </Box>
//         )}
//         <LoginModal
//           open={isLoginModalOpen}
//           onClose={() => setIsLoginModalOpen(false)}
//           onLogin={async (session) => {
//             if (session) {
//               console.log('Login successful:', session);
//               setUser(session.user);
//               setUserEmail(session.user.email);
//               localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//               const profileStatus = await checkUserProfile(session.user.email);
//               console.log(`Profile status after login for user ${session.user.id}: ${profileStatus}`);
//               setHasProfile(profileStatus);

//               // Route based on profile status
//               if (profileStatus) {
//                 navigate('/profile');
//               } else {
//                 navigate('/onboarding');
//               }
//             }
//             setIsLoginModalOpen(false);
//           }}
//         />
//       </ThemeProvider>
//     </PetitionProvider>
//   );
// };

// const AppWrapper = () => (
//   <Router>
//     <App />
//   </Router>
// );

// export default AppWrapper;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline, createTheme, Box, Typography, Button } from '@mui/material';
import supabase from './components/supabaseClient';
import HomePage from './components/HomePage';
import Map from './components/Map';
import KavaBarDetail from './components/KavaBarDetail';
import AddBar from './components/AddBar';
import ChatPage from './components/ChatPage';
import ProfilePage from './components/ProfilePage';
import ProfileOnboardingPage from './components/ProfileOnboardingPage';
import ProfileEditPage from './components/ProfileEditPage';
import LoginModal from './components/LoginModal';
import ResponsiveNavBar from './components/ResponsiveNavBar';
import { PetitionProvider } from './components/PetitionContext';
import { Analytics } from "@vercel/analytics/react"

const App = () => {
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [hasProfile, setHasProfile] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const checkUserProfile = async (email) => {
    if (!email) {
      console.log('No email available, skipping profile check');
      return false;
    }
    console.log(`Checking profile for email: ${email}`);
    const { data, error } = await supabase
      .from('profiles')
      .select('has_profile')
      .eq('email', email)
      .single();

    if (error) {
      console.error('Error fetching profile:', error);
      return false;
    }

    console.log('Profile data:', data);
    return data?.has_profile || false;
  };

  useEffect(() => {
    const initializeApp = async () => {
      console.log('Initializing app...');

      const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
      if (storedSession && storedSession.user) {
        console.log('Existing session found:', storedSession);
        setUser(storedSession.user);
        setUserEmail(storedSession.user.email);
        const profileStatus = await checkUserProfile(storedSession.user.email);
        console.log(`Profile status for user ${storedSession.user.id}: ${profileStatus}`);
        setHasProfile(profileStatus);

        const isFirstLogin = !localStorage.getItem('hasSeenFirstLoginPopup');
        if (isFirstLogin) {
          setIsFirstLogin(true);
        }
      } else {
        console.log('No existing session found');
      }

      const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
        console.log('Auth state change event:', event);
        if (event === 'SIGNED_IN' && session) {
          console.log('User signed in:', session.user);
          setUser(session.user);
          setUserEmail(session.user.email);
          localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
          const profileStatus = await checkUserProfile(session.user.email);
          console.log(`Profile status for user ${session.user.id}: ${profileStatus}`);
          setHasProfile(profileStatus);
          if (!localStorage.getItem('hasSeenFirstLoginPopup')) {
            setIsFirstLogin(true);
          }
        } else if (event === 'SIGNED_OUT') {
          console.log('User signed out');
          setUser(null);
          setUserEmail(null);
          setHasProfile(false);
          setIsFirstLogin(false);
          localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
        }
      });

      setLoading(false);
      console.log('App initialization complete');

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    initializeApp();

  }, []);

  const theme = createTheme({
    palette: {
      mode: 'light', // Force light mode
    },
  });

  const closeFirstLoginModal = () => {
    setIsFirstLogin(false);
    localStorage.setItem('hasSeenFirstLoginPopup', 'true');
    navigate('/onboarding');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PetitionProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {location.pathname !== '/' && location.pathname !== '/home' && (
          <ResponsiveNavBar
            user={user}
            setUser={setUser}
          />
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/map" element={<Map />} />
          <Route path="/kavabar/:id" element={<KavaBarDetail />} />
          <Route path="/add" element={<AddBar />} />
          <Route
            path="/profile"
            element={
              user
                ? (hasProfile
                  ? <ProfilePage user={user} />
                  : <Navigate to="/onboarding" />)
                : <Navigate to="/" />
            }
          />
          <Route
            path="/onboarding"
            element={
              user && !hasProfile
                ? <ProfileOnboardingPage user={user} setUser={setUser} setHasProfile={setHasProfile} />
                : <Navigate to="/profile" />
            }
          />
          <Route
            path="/profile/edit"
            element={
              user ? <ProfileEditPage user={user} setUser={setUser} /> : <Navigate to="/" />
            }
          />
          <Route path="/user/:username" element={<ProfilePage user={user} />} />

        </Routes>
        {isFirstLogin && (
          <Box
            sx={{
              position: 'fixed',
              top: '20%',
              left: '50%',
              transform: 'translate(-50%, -20%)',
              bgcolor: '#fff',
              p: 4,
              borderRadius: '16px',
              boxShadow: '0 10px 20px rgba(0,0,0,0.25)',
              textAlign: 'center',
              zIndex: 1300,
            }}
          >
            <Typography variant="h4" gutterBottom>
              🎉 Congratulations!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Thank you for signing up. Please create your profile.
            </Typography>
            <Button
              variant="contained"
              onClick={closeFirstLoginModal}
              sx={{ mt: 2 }}
            >
              Create Profile
            </Button>
          </Box>
        )}
        <LoginModal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLogin={async (session) => {
            if (session) {
              console.log('Login successful:', session);
              setUser(session.user);
              setUserEmail(session.user.email);
              localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
              const profileStatus = await checkUserProfile(session.user.email);
              console.log(`Profile status after login for user ${session.user.id}: ${profileStatus}`);
              setHasProfile(profileStatus);

              // Route based on profile status
              if (profileStatus) {
                navigate('/profile');
              } else {
                navigate('/onboarding');
              }
            }
            setIsLoginModalOpen(false);
          }}
        />
      </ThemeProvider>
      <Analytics /> {/* Vercel Analytics */}

    </PetitionProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;



