// PetitionContext.js
import React, { createContext, useState } from 'react';

export const PetitionContext = createContext();

export const PetitionProvider = ({ children }) => {
    const [petitionModalOpen, setPetitionModalOpen] = useState(false);

    return (
        <PetitionContext.Provider value={{ petitionModalOpen, setPetitionModalOpen }}>
            {children}
        </PetitionContext.Provider>
    );
};
