import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField, Button, Container, Paper, Typography, Box, Avatar, MenuItem, Select, FormControl, InputLabel, CircularProgress, Snackbar, IconButton, Alert
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import supabase from './supabaseClient';

const MAX_AVATAR_SIZE_MB = 50;
const MAX_BANNER_SIZE_MB = 5;

const ProfileEditPage = ({ user, setUser }) => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        bio: '',
        avatar_url: '',
        bannerImage: '', // Field for banner image URL
        homebar: '',
    });
    const [bars, setBars] = useState([]);
    const [avatarFile, setAvatarFile] = useState(null);
    const [bannerFile, setBannerFile] = useState(null); // State for banner file
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [loadingMessage, setLoadingMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            if (!user || !user.email) return;

            console.log('Fetching profile data...');
            try {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('email', user.email)
                    .single();

                if (error) {
                    console.error('Error fetching profile data:', error);
                } else {
                    setFormData({
                        bio: data.bio || '',
                        avatar_url: data.avatar_url || '',
                        bannerImage: data.bannerImage || '', // Set initial banner URL
                        homebar: data.homebar || '',
                    });
                    console.log('Fetched profile data:', data);
                }
            } catch (error) {
                console.error('Caught error in fetchProfileData:', error);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.email) {
            fetchProfileData();
        }
    }, [user]);

    useEffect(() => {
        const fetchBars = async () => {
            console.log('Fetching bars...');
            try {
                const { data, error } = await supabase
                    .from('bars')
                    .select('id, name, store_image');

                if (error) {
                    console.error('Error fetching bars:', error);
                } else {
                    console.log('Fetched bars:', data);
                    setBars(data);
                }
            } catch (error) {
                console.error('Caught error in fetchBars:', error);
            }
        };

        fetchBars();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(`Input change: ${name} = ${value}`);
        setFormData({ ...formData, [name]: value });
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_AVATAR_SIZE_MB * 1024 * 1024) {
                console.log(`File size exceeds ${MAX_AVATAR_SIZE_MB}MB: ${file.size}`);
                setSnackbarMessage(`File size exceeds ${MAX_AVATAR_SIZE_MB}MB.`);
                setAlertSeverity('error');
                setSnackbarOpen(true);
            } else {
                console.log(`Avatar file selected: ${file.name}`);
                setAvatarFile(file);
            }
        }
    };

    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_BANNER_SIZE_MB * 1024 * 1024) {
                console.log(`File size exceeds ${MAX_BANNER_SIZE_MB}MB: ${file.size}`);
                setSnackbarMessage(`File size exceeds ${MAX_BANNER_SIZE_MB}MB.`);
                setAlertSeverity('error');
                setSnackbarOpen(true);
            } else {
                console.log(`Banner file selected: ${file.name}`);
                setBannerFile(file);
            }
        }
    };

    const uploadAvatar = async (file) => {
        console.log('Uploading avatar...');
        setLoadingMessage('Uploading avatar...');
        const fileExt = file.name.split('.').pop();
        const fileName = `${user.id}.${fileExt}`;
        const filePath = `avatars/${fileName}`;

        console.log(`Avatar file path: ${filePath}`);
        let { error: uploadError } = await supabase.storage
            .from('avatars')
            .upload(filePath, file, { upsert: true });

        if (uploadError) {
            console.error('Error uploading avatar:', uploadError);
            throw uploadError;
        }

        const { data: publicUrlData, error: urlError } = supabase.storage
            .from('avatars')
            .getPublicUrl(filePath);

        if (urlError) {
            console.error('Error getting public URL for avatar:', urlError);
            throw urlError;
        }

        console.log('Avatar uploaded successfully:', publicUrlData.publicUrl);
        return publicUrlData.publicUrl;
    };

    const uploadBanner = async (file) => {
        console.log('Uploading banner...');
        setLoadingMessage('Uploading banner...');
        const fileExt = file.name.split('.').pop();
        const fileName = `${user.id}-banner.${fileExt}`;
        const filePath = `banners/${fileName}`;

        console.log(`Banner file path: ${filePath}`);
        let { error: uploadError } = await supabase.storage
            .from('banners')
            .upload(filePath, file, { upsert: true });

        if (uploadError) {
            console.error('Error uploading banner:', uploadError);
            throw uploadError;
        }

        const { data: publicUrlData, error: urlError } = supabase.storage
            .from('banners')
            .getPublicUrl(filePath);

        if (urlError) {
            console.error('Error getting public URL for banner:', urlError);
            throw urlError;
        }

        console.log('Banner uploaded successfully:', publicUrlData.publicUrl);
        return publicUrlData.publicUrl;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting profile edit form data:', formData);

        let avatar_url = formData.avatar_url;
        if (avatarFile) {
            try {
                avatar_url = await uploadAvatar(avatarFile);
                console.log('New avatar URL:', avatar_url);
            } catch (error) {
                console.error('Error uploading avatar:', error);
                setSnackbarMessage('Failed to upload avatar.');
                setAlertSeverity('error');
                setSnackbarOpen(true);
                return;
            }
        }

        let bannerImage = formData.bannerImage;
        if (bannerFile) {
            try {
                bannerImage = await uploadBanner(bannerFile);
                console.log('New banner URL:', bannerImage);
            } catch (error) {
                console.error('Error uploading banner:', error);
                setSnackbarMessage('Failed to upload banner.');
                setAlertSeverity('error');
                setSnackbarOpen(true);
                return;
            }
        }

        const profileData = {
            email: user.email,
            ...formData,
            avatar_url,
            bannerImage: bannerImage, // Ensure bannerImage is set correctly in the profile
            has_profile: true,
        };

        console.log('Updating profile data:', profileData);
        setLoadingMessage('Updating profile...');
        try {
            const { error } = await supabase
                .from('profiles')
                .update(profileData)
                .eq('email', user.email);

            if (error) {
                console.error('Error updating profile:', error);
                setSnackbarMessage('Failed to update profile.');
                setAlertSeverity('error');
                setSnackbarOpen(true);
            } else {
                setUser({ ...user, ...profileData });
                console.log('Profile updated successfully:', profileData);
                setSnackbarMessage('Profile updated successfully! 🎉');
                setAlertSeverity('success');
                setSnackbarOpen(true);
                setTimeout(() => {
                    navigate('/profile');
                }, 1000);
            }
        } catch (error) {
            console.error('Unexpected error during profile update:', error);
            setSnackbarMessage('An unexpected error occurred.');
            setAlertSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoadingMessage('');
        }
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress /><Typography>Loading...</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Paper elevation={2} sx={{ p: 9.5 }}>
                <Typography variant="h5">Edit Your Profile</Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <Box mb={2} position="relative">
                        <Box
                            component="img"
                            src={formData.bannerImage || "default-banner.png"}
                            alt="Banner"
                            sx={{ width: '100%', height: 200, objectFit: 'cover' }}
                        />
                        <Box position="absolute" top={8} right={8}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="banner-upload"
                                type="file"
                                onChange={handleBannerChange}
                            />
                            <label htmlFor="banner-upload">
                                <IconButton component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </Box>
                    </Box>
                    <TextField
                        fullWidth
                        label="Bio"
                        variant="outlined"
                        margin="normal"
                        name="bio"
                        value={formData.bio}
                        onChange={handleInputChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="home-bar-label">Home Bar</InputLabel>
                        <Select
                            labelId="home-bar-label"
                            id="homebar"
                            name="homebar"
                            value={formData.homebar}
                            label="Home Bar"
                            onChange={handleInputChange}
                        >
                            {bars.map(bar => (
                                <MenuItem key={bar.id} value={bar.id}>
                                    <Box display="flex" alignItems="center">
                                        <Avatar
                                            src={bar.store_image || "default.png"}
                                            alt={bar.name}
                                            sx={{ width: 40, height: 40, marginRight: 1 }}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default.png";
                                            }}
                                        />
                                        <Typography variant="body1">{bar.name}</Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2 }}
                    >
                        Upload Avatar
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleAvatarChange}
                        />
                    </Button>
                    {avatarFile && <Typography>{avatarFile.name}</Typography>}
                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Update Profile
                        </Button>
                    </Box>
                </Box>
                <Box mt={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate('/profile')}
                    >
                        Back to Profile
                    </Button>
                </Box>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
                    {loadingMessage || snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ProfileEditPage;
