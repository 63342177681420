import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import supabase from './supabaseClient';

const LoginModal = ({ open, onClose }) => {
    const handleSignIn = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
        });
        if (error) {
            console.error('Error signing in:', error);
        }
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Sign In
                </Typography>
                <Button variant="contained" color="primary" onClick={handleSignIn}>
                    Sign In with Google
                </Button>
            </Box>
        </Modal>
    );
};

export default LoginModal;
