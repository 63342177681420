// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//     TextField, Button, Container, Paper, Typography, Box, Avatar, MenuItem, Select, FormControl, InputLabel, Checkbox, Card, CardMedia, ButtonBase, CircularProgress, ListSubheader
// } from '@mui/material';
// import Masonry from '@mui/lab/Masonry';
// import supabase from './supabaseClient';

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 3958.8; // Radius of the Earth in miles
//     const rlat1 = lat1 * (Math.PI / 180);
//     const rlat2 = lat2 * (Math.PI / 180);
//     const difflat = rlat2 - rlat1;
//     const difflon = (lon2 - lon1) * (Math.PI / 180);

//     const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
//         Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
//     return d;
// };

// const ProfileOnboardingPage = ({ user, setUser }) => {
//     const [loading, setLoading] = useState(true);
//     const [formData, setFormData] = useState({
//         username: '',
//         full_name: '',
//         bio: '',
//         avatar_url: '',
//         homebar: '',
//         favorites: [],
//     });
//     const [bars, setBars] = useState([]);
//     const [sortedBars, setSortedBars] = useState([]);
//     const [displayBars, setDisplayBars] = useState([]);
//     const [avatarFile, setAvatarFile] = useState(null);
//     const [userLocation, setUserLocation] = useState(null);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [showCount, setShowCount] = useState(25);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchBars = async () => {
//             console.log('Fetching bars...');
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('id, name, store_image, latitude, longitude, state');

//                 if (error) {
//                     console.error('Error fetching bars:', error);
//                 } else {
//                     console.log('Fetched bars:', data);
//                     const updatedBars = data.map(bar => ({
//                         ...bar,
//                         store_image: bar.store_image || 'default.png'
//                     }));
//                     setBars(updatedBars);
//                 }
//             } catch (error) {
//                 console.error('Caught error in fetchBars:', error);
//             }
//         };

//         fetchBars();
//     }, []);

//     useEffect(() => {
//         const fetchLocation = () => {
//             console.log('Fetching user location...');
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     console.log('User location:', position.coords);
//                     setUserLocation({
//                         latitude: position.coords.latitude,
//                         longitude: position.coords.longitude
//                     });
//                 },
//                 (error) => {
//                     console.error("Error getting user location:", error);
//                 }
//             );
//         };

//         fetchLocation();
//     }, []);

//     useEffect(() => {
//         const sortBars = () => {
//             if (userLocation && bars.length > 0) {
//                 console.log('Sorting bars...');
//                 const sorted = [...bars].sort((a, b) => {
//                     const distanceA = calculateDistance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude);
//                     const distanceB = calculateDistance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude);
//                     return distanceA - distanceB;
//                 });
//                 console.log('Sorted bars:', sorted);
//                 setSortedBars(sorted);
//                 setDisplayBars(sorted.slice(0, 10));
//             }
//         };

//         sortBars();
//     }, [userLocation, bars]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleAvatarChange = (e) => {
//         setAvatarFile(e.target.files[0]);
//     };

//     const uploadAvatar = async (file) => {
//         const fileExt = file.name.split('.').pop();
//         const fileName = `${user.id}.${fileExt}`;
//         const filePath = `avatars/${fileName}`;

//         let { error: uploadError } = await supabase.storage
//             .from('avatars')
//             .upload(filePath, file, { upsert: true });

//         if (uploadError) {
//             throw uploadError;
//         }

//         const { data: { publicUrl }, error: urlError } = supabase.storage
//             .from('avatars')
//             .getPublicUrl(filePath);

//         if (urlError) {
//             throw urlError;
//         }

//         return publicUrl;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Submitting profile form data:', formData);

//         let avatar_url = formData.avatar_url;
//         if (avatarFile) {
//             try {
//                 avatar_url = await uploadAvatar(avatarFile);
//             } catch (error) {
//                 console.error('Error uploading avatar:', error);
//                 return;
//             }
//         }

//         const profileData = {
//             id: user.id, // Use the user's ID from the session
//             ...formData,
//             avatar_url,
//         };

//         try {
//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert(profileData, {
//                     onConflict: ['id'],
//                     returning: 'minimal',
//                 });

//             if (error) {
//                 console.error('Error updating profile:', error);
//             } else {
//                 setUser({ ...user, ...profileData, has_profile: true });
//                 console.log('Profile updated successfully:', profileData);
//                 navigate('/profile'); // Redirect to profile page after update
//             }
//         } catch (error) {
//             console.error('Unexpected error during profile update:', error);
//         }
//     };

//     const renderBarsWithStates = () => {
//         const filteredBars = sortedBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase()));

//         const barsByState = filteredBars.reduce((acc, bar) => {
//             if (!acc[bar.state]) {
//                 acc[bar.state] = [];
//             }
//             acc[bar.state].push(bar);
//             return acc;
//         }, {});

//         const stateLabels = Object.keys(barsByState).sort();

//         return stateLabels.map(state => [
//             <ListSubheader key={state}>{state}</ListSubheader>,
//             ...barsByState[state].sort((a, b) => a.name.localeCompare(b.name)).map(bar => (
//                 <MenuItem key={bar.id} value={bar.id}>
//                     <Box display="flex" alignItems="center">
//                         <Avatar
//                             src={bar.store_image || "default.png"}
//                             alt={bar.name}
//                             sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
//                             onError={(e) => {
//                                 e.target.onerror = null;
//                                 e.target.src = "default.png";
//                             }}
//                         />
//                         <Typography variant="body1" sx={{ fontSize: 18 }}>{bar.name}</Typography>
//                     </Box>
//                 </MenuItem>
//             ))
//         ]);
//     };

//     const handleSearchChange = (e) => {
//         setSearchTerm(e.target.value);
//     };

//     if (loading) {
//         return <Container><CircularProgress /><Typography>Loading...</Typography></Container>;
//     }

//     return (
//         <Container maxWidth="xl">
//             <Paper elevation={3} sx={{ p: 3 }}>
//                 <Typography variant="h5">Create Your Profile</Typography>
//                 <Box component="form" onSubmit={handleSubmit}>
//                     <TextField
//                         fullWidth
//                         label="Username"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="username"
//                         value={formData.username}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="full_name"
//                         value={formData.full_name}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bio"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="bio"
//                         value={formData.bio}
//                         onChange={handleInputChange}
//                     />
//                     <FormControl fullWidth margin="normal">
//                         <InputLabel id="home-bar-label">Home Bar</InputLabel>
//                         <Select
//                             labelId="home-bar-label"
//                             id="homebar"
//                             name="homebar"
//                             value={formData.homebar}
//                             label="Home Bar"
//                             onChange={handleInputChange}
//                             sx={{ fontSize: 18 }}
//                         >
//                             {renderBarsWithStates()}
//                         </Select>
//                     </FormControl>
//                     <Button
//                         variant="contained"
//                         component="label"
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Avatar
//                         <input
//                             type="file"
//                             hidden
//                             accept="image/*"
//                             onChange={handleAvatarChange}
//                         />
//                     </Button>
//                     {avatarFile && <Typography>{avatarFile.name}</Typography>}
//                     <Box mt={2}>
//                         <Typography variant="h6">Visited Bars</Typography>
//                         <TextField
//                             fullWidth
//                             placeholder="Search bars..."
//                             variant="outlined"
//                             value={searchTerm}
//                             onChange={handleSearchChange}
//                             sx={{
//                                 mb: 2,
//                                 borderRadius: '25px',
//                                 '& .MuiOutlinedInput-root': {
//                                     borderRadius: '25px'
//                                 }
//                             }}
//                         />
//                         <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5 }} spacing={2}>
//                             {displayBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase())).map((bar) => (
//                                 <ButtonBase
//                                     key={bar.id}
//                                     onClick={() => handleVisitedChange(bar.id)}
//                                     sx={{ width: '100%' }}
//                                 >
//                                     <Card sx={{
//                                         borderRadius: '16px',
//                                         overflow: 'hidden',
//                                         boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
//                                         transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
//                                         '&:hover': {
//                                             boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
//                                         }
//                                     }}>
//                                         <CardMedia
//                                             component="img"
//                                             image={bar.store_image || "./default.png"}
//                                             alt={bar.name}
//                                             sx={{
//                                                 height: '300px',
//                                                 objectFit: 'cover'
//                                             }}
//                                             onError={(e) => {
//                                                 e.target.onerror = null;
//                                                 e.target.src = "./default.png";
//                                                 console.error(`Failed to load image for ${bar.name}: ${bar.store_image}`);
//                                             }}
//                                         />
//                                         <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
//                                             <Typography variant="body2" noWrap sx={{ fontWeight: 'bold' }}>{bar.name}</Typography>
//                                             <Checkbox
//                                                 checked={formData.favorites.includes(bar.id)}
//                                                 onChange={() => handleVisitedChange(bar.id)}
//                                             />
//                                         </Box>
//                                     </Card>
//                                 </ButtonBase>
//                             ))}
//                         </Masonry>
//                     </Box>
//                     <Box mt={2}>
//                         <Button variant="contained" color="primary" type="submit">
//                             Submit
//                         </Button>
//                     </Box>
//                 </Box>
//             </Paper>
//         </Container>
//     );
// };

// export default ProfileOnboardingPage;
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//     TextField, Button, Container, Paper, Typography, Box, Avatar, MenuItem, Select, FormControl, InputLabel, Checkbox, Card, CardMedia, ButtonBase, CircularProgress, ListSubheader
// } from '@mui/material';
// import Masonry from '@mui/lab/Masonry';
// import supabase from './supabaseClient';

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 3958.8; // Radius of the Earth in miles
//     const rlat1 = lat1 * (Math.PI / 180);
//     const rlat2 = lat2 * (Math.PI / 180);
//     const difflat = rlat2 - rlat1;
//     const difflon = (lon2 - lon1) * (Math.PI / 180);

//     const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
//         Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
//     return d;
// };

// const ProfileOnboardingPage = ({ user, setUser }) => {
//     const [loading, setLoading] = useState(true);
//     const [formData, setFormData] = useState({
//         username: '',
//         full_name: '',
//         bio: '',
//         avatar_url: '',
//         homebar: '',
//         favorites: [],
//     });
//     const [bars, setBars] = useState([]);
//     const [sortedBars, setSortedBars] = useState([]);
//     const [displayBars, setDisplayBars] = useState([]);
//     const [avatarFile, setAvatarFile] = useState(null);
//     const [userLocation, setUserLocation] = useState(null);
//     const [searchTerm, setSearchTerm] = useState("");
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchBars = async () => {
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('id, name, store_image, latitude, longitude, state');

//                 if (error) {
//                     console.error('Error fetching bars:', error);
//                 } else {
//                     const updatedBars = data.map(bar => ({
//                         ...bar,
//                         store_image: bar.store_image || 'default.png'
//                     }));
//                     setBars(updatedBars);
//                 }
//             } catch (error) {
//                 console.error('Caught error in fetchBars:', error);
//             }
//         };

//         fetchBars();
//     }, []);

//     useEffect(() => {
//         const fetchLocation = () => {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     setUserLocation({
//                         latitude: position.coords.latitude,
//                         longitude: position.coords.longitude
//                     });
//                 },
//                 (error) => {
//                     console.error("Error getting user location:", error);
//                 }
//             );
//         };

//         fetchLocation();
//     }, []);

//     useEffect(() => {
//         const sortBars = () => {
//             if (userLocation && bars.length > 0) {
//                 const sorted = [...bars].sort((a, b) => {
//                     const distanceA = calculateDistance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude);
//                     const distanceB = calculateDistance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude);
//                     return distanceA - distanceB;
//                 });
//                 setSortedBars(sorted);
//                 setDisplayBars(sorted.slice(0, 10));
//             }
//         };

//         sortBars();
//     }, [userLocation, bars]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleAvatarChange = (e) => {
//         setAvatarFile(e.target.files[0]);
//     };

//     const uploadAvatar = async (file) => {
//         const fileExt = file.name.split('.').pop();
//         const fileName = `${user.id}.${fileExt}`;
//         const filePath = `avatars/${fileName}`;

//         let { error: uploadError } = await supabase.storage
//             .from('avatars')
//             .upload(filePath, file, { upsert: true });

//         if (uploadError) {
//             throw uploadError;
//         }

//         const { data: { publicUrl }, error: urlError } = supabase.storage
//             .from('avatars')
//             .getPublicUrl(filePath);

//         if (urlError) {
//             throw urlError;
//         }

//         return publicUrl;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         let avatar_url = formData.avatar_url;
//         if (avatarFile) {
//             try {
//                 avatar_url = await uploadAvatar(avatarFile);
//             } catch (error) {
//                 console.error('Error uploading avatar:', error);
//                 return;
//             }
//         }

//         const profileData = {
//             id: user.id,
//             ...formData,
//             avatar_url,
//         };

//         try {
//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert(profileData, {
//                     onConflict: ['id'],
//                     returning: 'minimal',
//                 });

//             if (error) {
//                 console.error('Error updating profile:', error);
//             } else {
//                 setUser({ ...user, ...profileData, has_profile: true });
//                 navigate('/profile');
//             }
//         } catch (error) {
//             console.error('Unexpected error during profile update:', error);
//         }
//     };

//     const renderBarsWithStates = () => {
//         const filteredBars = sortedBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase()));

//         const barsByState = filteredBars.reduce((acc, bar) => {
//             if (!acc[bar.state]) {
//                 acc[bar.state] = [];
//             }
//             acc[bar.state].push(bar);
//             return acc;
//         }, {});

//         const stateLabels = Object.keys(barsByState).sort();

//         return stateLabels.map(state => [
//             <ListSubheader key={state}>{state}</ListSubheader>,
//             ...barsByState[state].sort((a, b) => a.name.localeCompare(b.name)).map(bar => (
//                 <MenuItem key={bar.id} value={bar.id}>
//                     <Box display="flex" alignItems="center">
//                         <Avatar
//                             src={bar.store_image || "default.png"}
//                             alt={bar.name}
//                             sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
//                             onError={(e) => {
//                                 e.target.onerror = null;
//                                 e.target.src = "default.png";
//                             }}
//                         />
//                         <Typography variant="body1" sx={{ fontSize: 18 }}>{bar.name}</Typography>
//                     </Box>
//                 </MenuItem>
//             ))
//         ]);
//     };

//     const handleSearchChange = (e) => {
//         setSearchTerm(e.target.value);
//     };

//     const handleVisitedChange = (barId) => {
//         setFormData(prevState => {
//             const favorites = prevState.favorites.includes(barId)
//                 ? prevState.favorites.filter(id => id !== barId)
//                 : [...prevState.favorites, barId];
//             return { ...prevState, favorites };
//         });
//     };

//     if (loading) {
//         return <Container><CircularProgress /><Typography>Loading...</Typography></Container>;
//     }

//     return (
//         <Container maxWidth="xl">
//             <Paper elevation={3} sx={{ p: 3 }}>
//                 <Typography variant="h5">Create Your Profile</Typography>
//                 <Box component="form" onSubmit={handleSubmit}>
//                     <TextField
//                         fullWidth
//                         label="Username"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="username"
//                         value={formData.username}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="full_name"
//                         value={formData.full_name}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bio"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="bio"
//                         value={formData.bio}
//                         onChange={handleInputChange}
//                     />
//                     <FormControl fullWidth margin="normal">
//                         <InputLabel id="home-bar-label">Home Bar</InputLabel>
//                         <Select
//                             labelId="home-bar-label"
//                             id="homebar"
//                             name="homebar"
//                             value={formData.homebar}
//                             label="Home Bar"
//                             onChange={handleInputChange}
//                             sx={{ fontSize: 18 }}
//                         >
//                             {renderBarsWithStates()}
//                         </Select>
//                     </FormControl>
//                     <Button
//                         variant="contained"
//                         component="label"
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Avatar
//                         <input
//                             type="file"
//                             hidden
//                             accept="image/*"
//                             onChange={handleAvatarChange}
//                         />
//                     </Button>
//                     {avatarFile && <Typography>{avatarFile.name}</Typography>}
//                     <Box mt={2}>
//                         <Typography variant="h6">Visited Bars</Typography>
//                         <TextField
//                             fullWidth
//                             placeholder="Search bars..."
//                             variant="outlined"
//                             value={searchTerm}
//                             onChange={handleSearchChange}
//                             sx={{
//                                 mb: 2,
//                                 borderRadius: '25px',
//                                 '& .MuiOutlinedInput-root': {
//                                     borderRadius: '25px'
//                                 }
//                             }}
//                         />
//                         <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5 }} spacing={2}>
//                             {displayBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase())).map((bar) => (
//                                 <ButtonBase
//                                     key={bar.id}
//                                     onClick={() => handleVisitedChange(bar.id)}
//                                     sx={{ width: '100%' }}
//                                 >
//                                     <Card sx={{
//                                         borderRadius: '16px',
//                                         overflow: 'hidden',
//                                         boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
//                                         transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
//                                         '&:hover': {
//                                             boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
//                                         }
//                                     }}>
//                                         <CardMedia
//                                             component="img"
//                                             image={bar.store_image || "./default.png"}
//                                             alt={bar.name}
//                                             sx={{
//                                                 height: '300px',
//                                                 objectFit: 'cover'
//                                             }}
//                                             onError={(e) => {
//                                                 e.target.onerror = null;
//                                                 e.target.src = "./default.png";
//                                                 console.error(`Failed to load image for ${bar.name}: ${bar.store_image}`);
//                                             }}
//                                         />
//                                         <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
//                                             <Typography variant="body2" noWrap sx={{ fontWeight: 'bold' }}>{bar.name}</Typography>
//                                             <Checkbox
//                                                 checked={formData.favorites.includes(bar.id)}
//                                                 onChange={() => handleVisitedChange(bar.id)}
//                                             />
//                                         </Box>
//                                     </Card>
//                                 </ButtonBase>
//                             ))}
//                         </Masonry>
//                     </Box>
//                     <Box mt={2}>
//                         <Button variant="contained" color="primary" type="submit">
//                             Submit
//                         </Button>
//                     </Box>
//                 </Box>
//             </Paper>
//         </Container>
//     );
// };

// export default ProfileOnboardingPage;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Container, Button, Typography, Box, TextField, Paper } from '@mui/material';
// import supabase from './supabaseClient';

// const ProfileOnboardingPage = ({ user, setUser }) => {
//     const [formData, setFormData] = useState({
//         username: '',
//         full_name: '',
//         bio: '',
//         avatar_url: '',
//         homebar: '',
//         favorites: [],
//     });
//     const [avatarFile, setAvatarFile] = useState(null);
//     const navigate = useNavigate();

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleAvatarChange = (e) => {
//         setAvatarFile(e.target.files[0]);
//     };

//     const uploadAvatar = async (file) => {
//         const fileExt = file.name.split('.').pop();
//         const fileName = `${user.id}.${fileExt}`;
//         const filePath = `avatars/${fileName}`;

//         let { error: uploadError } = await supabase.storage
//             .from('avatars')
//             .upload(filePath, file, { upsert: true });

//         if (uploadError) {
//             throw uploadError;
//         }

//         const { data: { publicUrl }, error: urlError } = supabase.storage
//             .from('avatars')
//             .getPublicUrl(filePath);

//         if (urlError) {
//             throw urlError;
//         }

//         return publicUrl;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Submitting profile form data:', formData);

//         let avatar_url = formData.avatar_url;
//         if (avatarFile) {
//             try {
//                 avatar_url = await uploadAvatar(avatarFile);
//             } catch (error) {
//                 console.error('Error uploading avatar:', error);
//                 return;
//             }
//         }

//         const profileData = {
//             id: user.id, // Use the user's ID from the session
//             ...formData,
//             avatar_url,
//         };

//         try {
//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert(profileData, {
//                     onConflict: ['id'],
//                     returning: 'minimal',
//                 });

//             if (error) {
//                 console.error('Error updating profile:', error);
//             } else {
//                 setUser({ ...user, ...profileData, has_profile: true });
//                 console.log('Profile updated successfully:', profileData);
//                 navigate('/profile'); // Redirect to profile page after update
//             }
//         } catch (error) {
//             console.error('Unexpected error during profile update:', error);
//         }
//     };

//     return (
//         <Container maxWidth="md">
//             <Paper elevation={3} sx={{ p: 3 }}>
//                 <Typography variant="h5">Complete Your Profile</Typography>
//                 <Box component="form" onSubmit={handleSubmit}>
//                     <TextField
//                         fullWidth
//                         label="Username"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="username"
//                         value={formData.username}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="full_name"
//                         value={formData.full_name}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bio"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="bio"
//                         value={formData.bio}
//                         onChange={handleInputChange}
//                     />
//                     <Button
//                         variant="contained"
//                         component="label"
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Avatar
//                         <input
//                             type="file"
//                             hidden
//                             accept="image/*"
//                             onChange={handleAvatarChange}
//                         />
//                     </Button>
//                     {avatarFile && <Typography>{avatarFile.name}</Typography>}
//                     <Box mt={2}>
//                         <Button variant="contained" color="primary" type="submit">
//                             Submit
//                         </Button>
//                     </Box>
//                 </Box>
//             </Paper>
//         </Container>
//     );
// };

// export default ProfileOnboardingPage;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//     TextField, Button, Container, Paper, Typography, Box, Avatar, MenuItem, Select, FormControl, InputLabel, Checkbox, Card, CardMedia, ButtonBase, CircularProgress, ListSubheader, Chip, Snackbar, Grid, Skeleton, IconButton
// } from '@mui/material';
// import { PhotoCamera } from '@mui/icons-material';
// import Confetti from 'react-confetti';
// import supabase from './supabaseClient';

// const MAX_AVATAR_SIZE_MB = 50;

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 3958.8;
//     const rlat1 = lat1 * (Math.PI / 180);
//     const rlat2 = lat2 * (Math.PI / 180);
//     const difflat = rlat2 - rlat1;
//     const difflon = (lon2 - lon1) * (Math.PI / 180);

//     const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
//         Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
//     return d;
// };

// const snarkyBios = [
//     "Government Approved Non-Alcoholic Enthusiast 🍵",
//     "Kava and Kratom: Nature's Mood Boosters 🌿",
//     "Living the non-alcoholic life... like a rebel ✌️",
//     "Keeping it chill with Kava, no regrets! 😎",
//     "I got 99 problems, but Kratom ain't one 💪",
//     "Pokémon trainer by day, Kava lover by night 🎮",
// ];

// const ProfileOnboardingPage = ({ user, setUser, setHasProfile }) => {
//     const [loading, setLoading] = useState(true);
//     const [formData, setFormData] = useState({
//         username: '',
//         full_name: '',
//         bio: '',
//         avatar_url: '',
//         homebar: '',
//         beenTo: [],
//         bannerImage: '',
//     });
//     const [bars, setBars] = useState([]);
//     const [sortedBars, setSortedBars] = useState([]);
//     const [displayBars, setDisplayBars] = useState([]);
//     const [avatarFile, setAvatarFile] = useState(null);
//     const [bannerFile, setBannerFile] = useState(null);
//     const [userLocation, setUserLocation] = useState(null);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [showMore, setShowMore] = useState(false);
//     const [confettiVisible, setConfettiVisible] = useState(false);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');

//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchProfileData = async () => {
//             if (!user || !user.email) return;

//             try {
//                 const { data, error } = await supabase
//                     .from('profiles')
//                     .select('*')
//                     .eq('email', user.email)
//                     .single();

//                 if (error) {
//                     console.error('Error fetching profile data:', error);
//                 } else {
//                     setFormData({
//                         username: data.username || '',
//                         full_name: data.full_name || '',
//                         bio: data.bio || '',
//                         avatar_url: data.avatar_url || '',
//                         homebar: data.homebar || '',
//                         beenTo: data.beenTo || [],
//                         bannerImage: data.bannerImage || '',
//                     });
//                     console.log('Fetched profile data:', data);
//                 }
//             } catch (error) {
//                 console.error('Caught error in fetchProfileData:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (user && user.email) {
//             fetchProfileData();
//         }
//     }, [user]);

//     useEffect(() => {
//         const fetchBars = async () => {
//             console.log('Fetching bars...');
//             try {
//                 const { data, error } = await supabase
//                     .from('bars')
//                     .select('id, name, store_image, latitude, longitude, state');

//                 if (error) {
//                     console.error('Error fetching bars:', error);
//                 } else {
//                     console.log('Fetched bars:', data);
//                     const updatedBars = data.map(bar => ({
//                         ...bar,
//                         store_image: bar.store_image || 'default.png'
//                     }));
//                     setBars(updatedBars);
//                 }
//             } catch (error) {
//                 console.error('Caught error in fetchBars:', error);
//             }
//         };

//         fetchBars();
//     }, []);

//     useEffect(() => {
//         const fetchLocation = () => {
//             console.log('Fetching user location...');
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     console.log('User location:', position.coords);
//                     setUserLocation({
//                         latitude: position.coords.latitude,
//                         longitude: position.coords.longitude
//                     });
//                 },
//                 (error) => {
//                     console.error("Error getting user location:", error);
//                     setLoading(false);
//                 }
//             );
//         };

//         fetchLocation();
//     }, []);

//     useEffect(() => {
//         const sortBars = () => {
//             if (userLocation && bars.length > 0) {
//                 console.log('Sorting bars...');
//                 const sorted = [...bars].sort((a, b) => {
//                     const distanceA = calculateDistance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude);
//                     const distanceB = calculateDistance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude);
//                     return distanceA - distanceB;
//                 });
//                 console.log('Sorted bars:', sorted);
//                 setSortedBars(sorted);
//                 setDisplayBars(sorted.slice(0, 10));
//                 setFormData(prev => ({ ...prev, homebar: sorted[0]?.id || '' }));
//             }
//             setLoading(false);
//         };

//         if (userLocation && bars.length > 0) {
//             sortBars();
//         }
//     }, [userLocation, bars]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         console.log(`Input change: ${name} = ${value}`);
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleShowMore = () => {
//         setShowMore(prevShowMore => !prevShowMore);
//         setDisplayBars(prev => showMore ? sortedBars.slice(0, 10) : sortedBars);
//     };

//     const handleImageUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_BYTES = MAX_AVATAR_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_AVATAR_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);

//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `avatars/${fileName}`;

//             const { data: uploadData, error: uploadError } = await supabase.storage
//                 .from('avatars')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) {
//                 setSnackbarMessage('Error uploading image. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('avatars')
//                 .getPublicUrl(filePath);

//             if (urlError) {
//                 setSnackbarMessage('Error retrieving image URL. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const publicUrl = publicUrlData.publicUrl;
//             setFormData((prev) => ({ ...prev, avatar_url: publicUrl }));
//             setSnackbarMessage('Image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             setSnackbarMessage('Unexpected error occurred during image upload.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleBannerUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) return;

//         const MAX_FILE_SIZE_BYTES = MAX_AVATAR_SIZE_MB * 1024 * 1024;

//         if (file.size > MAX_FILE_SIZE_BYTES) {
//             setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_AVATAR_SIZE_MB} MB.`);
//             setSnackbarOpen(true);
//             return;
//         }

//         try {
//             setLoading(true);

//             const fileExt = file.name.split('.').pop();
//             const fileName = `${Date.now()}.${fileExt}`;
//             const filePath = `banners/${fileName}`;

//             const { data: uploadData, error: uploadError } = await supabase.storage
//                 .from('banners')
//                 .upload(filePath, file, { upsert: true });

//             if (uploadError) {
//                 setSnackbarMessage('Error uploading banner image. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const { data: publicUrlData, error: urlError } = supabase.storage
//                 .from('banners')
//                 .getPublicUrl(filePath);

//             if (urlError) {
//                 setSnackbarMessage('Error retrieving banner image URL. Please try again.');
//                 setSnackbarOpen(true);
//                 setLoading(false);
//                 return;
//             }

//             const publicUrl = publicUrlData.publicUrl;
//             setFormData((prev) => ({ ...prev, bannerImage: publicUrl }));
//             setSnackbarMessage('Banner image uploaded successfully!');
//             setSnackbarOpen(true);
//         } catch (error) {
//             setSnackbarMessage('Unexpected error occurred during banner image upload.');
//             setSnackbarOpen(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Submitting profile form data:', formData);

//         const profileData = {
//             id: user.id,
//             email: user.email,
//             ...formData,
//             has_profile: true
//         };

//         console.log('Profile data to be submitted:', profileData);

//         try {
//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert(profileData, {
//                     onConflict: ['email'],
//                     returning: 'minimal',
//                 });

//             if (error) {
//                 console.error('Error updating profile:', error);
//                 setSnackbarMessage('Error updating profile. Please try again.');
//                 setSnackbarOpen(true);
//             } else {
//                 setUser({ ...user, ...profileData });
//                 setHasProfile(true);
//                 console.log('Profile updated successfully:', profileData);
//                 setConfettiVisible(true);
//                 setSnackbarMessage('Profile updated successfully! 🎉');
//                 setSnackbarOpen(true);
//                 navigate('/profile');
//             }
//         } catch (error) {
//             console.error('Unexpected error during profile update:', error);
//             setSnackbarMessage('An unexpected error occurred. Please try again.');
//             setSnackbarOpen(true);
//         }
//     };

//     const renderBarsWithStates = () => {
//         const filteredBars = sortedBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase()));

//         const barsByState = filteredBars.reduce((acc, bar) => {
//             if (!acc[bar.state]) {
//                 acc[bar.state] = [];
//             }
//             acc[bar.state].push(bar);
//             return acc;
//         }, {});

//         const stateLabels = Object.keys(barsByState).sort();

//         return stateLabels.map(state => [
//             <ListSubheader key={state}>{state}</ListSubheader>,
//             ...barsByState[state].sort((a, b) => a.name.localeCompare(b.name)).map(bar => (
//                 <MenuItem key={bar.id} value={bar.id}>
//                     <Box display="flex" alignItems="center">
//                         <Avatar
//                             src={bar.store_image || "default.png"}
//                             alt={bar.name}
//                             sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
//                             onError={(e) => {
//                                 e.target.onerror = null;
//                                 e.target.src = "default.png";
//                             }}
//                         />
//                         <Typography variant="body1" sx={{ fontSize: 18 }}>{bar.name}</Typography>
//                     </Box>
//                 </MenuItem>
//             ))
//         ]);
//     };

//     const handleSearchChange = (e) => {
//         setSearchTerm(e.target.value);
//         console.log(`Search term: ${e.target.value}`);
//     };

//     const handleVisitedChange = (barId) => {
//         setFormData(prevState => {
//             const beenTo = prevState.beenTo.includes(barId)
//                 ? prevState.beenTo.filter(id => id !== barId)
//                 : [...prevState.beenTo, barId];
//             console.log(`Visited bars updated: ${beenTo}`);
//             return { ...prevState, beenTo };
//         });
//     };

//     if (loading) {
//         return (
//             <Container>
//                 <CircularProgress /><Typography>Loading...</Typography>
//                 <Box mt={2}>
//                     <Grid container spacing={2}>
//                         {Array.from(new Array(10)).map((_, index) => (
//                             <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//                                 <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
//                                     <Skeleton variant="rectangular" width="100%" height={300} />
//                                     <Box p={2}>
//                                         <Skeleton variant="text" />
//                                         <Skeleton variant="text" />
//                                     </Box>
//                                 </Card>
//                             </Grid>
//                         ))}
//                     </Grid>
//                 </Box>
//             </Container>
//         );
//     }

//     return (
//         <Container maxWidth="xl">
//             {confettiVisible && <Confetti />}
//             <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
//                 <Box
//                     component="img"
//                     src={formData.bannerImage || "default-banner.png"}
//                     alt="Banner"
//                     sx={{ width: '100%', height: 200, objectFit: 'cover' }}
//                 />
//                 <Box display="flex" alignItems="center" justifyContent="center" my={2}>
//                     <input
//                         accept="image/*"
//                         style={{ display: 'none' }}
//                         id="banner-upload"
//                         type="file"
//                         onChange={handleBannerUpload}
//                     />
//                     <label htmlFor="banner-upload">
//                         <IconButton component="span">
//                             <PhotoCamera />
//                         </IconButton>
//                     </label>
//                 </Box>
//                 <Typography variant="h5">Create Your Profile</Typography>
//                 <Box component="form" onSubmit={handleSubmit}>
//                     <TextField
//                         fullWidth
//                         label="Username"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="username"
//                         value={formData.username}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Full Name"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="full_name"
//                         value={formData.full_name}
//                         onChange={handleInputChange}
//                     />
//                     <TextField
//                         fullWidth
//                         label="Bio"
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         name="bio"
//                         value={formData.bio}
//                         onChange={handleInputChange}
//                     />
//                     <Box my={2}>
//                         {snarkyBios.map((bio, index) => (
//                             <Chip
//                                 key={index}
//                                 label={bio}
//                                 onClick={() => setFormData({ ...formData, bio })}
//                                 sx={{ margin: 0.5 }}
//                             />
//                         ))}
//                     </Box>
//                     <FormControl fullWidth margin="normal">
//                         <InputLabel id="home-bar-label">Home Bar</InputLabel>
//                         <Select
//                             labelId="home-bar-label"
//                             id="homebar"
//                             name="homebar"
//                             value={formData.homebar}
//                             label="Home Bar"
//                             onChange={handleInputChange}
//                             sx={{ fontSize: 18 }}
//                         >
//                             {renderBarsWithStates()}
//                         </Select>
//                     </FormControl>
//                     <Button
//                         variant="contained"
//                         component="label"
//                         sx={{ mt: 2 }}
//                     >
//                         Upload Avatar
//                         <input
//                             type="file"
//                             hidden
//                             accept="image/*"
//                             onChange={handleImageUpload}
//                         />
//                     </Button>
//                     {formData.avatar_url && (
//                         <Box mt={2}>
//                             <img src={formData.avatar_url} alt="Avatar preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
//                         </Box>
//                     )}
//                     <Box mt={2}>
//                         <Typography variant="h6">Visited Bars</Typography>
//                         <TextField
//                             fullWidth
//                             placeholder="Search bars..."
//                             variant="outlined"
//                             value={searchTerm}
//                             onChange={handleSearchChange}
//                             sx={{
//                                 mb: 2,
//                                 borderRadius: '25px',
//                                 '& .MuiOutlinedInput-root': {
//                                     borderRadius: '25px'
//                                 }
//                             }}
//                         />
//                         <Grid container spacing={2}>
//                             {displayBars.filter(bar => bar.name.toLowerCase().includes(searchTerm.toLowerCase())).map((bar) => (
//                                 <Grid item xs={12} sm={6} md={4} lg={3} key={bar.id}>
//                                     <ButtonBase onClick={() => handleVisitedChange(bar.id)} sx={{ width: '100%' }}>
//                                         <Card sx={{
//                                             borderRadius: '16px',
//                                             overflow: 'hidden',
//                                             boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
//                                             transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
//                                             '&:hover': {
//                                                 boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
//                                             }
//                                         }}>
//                                             <CardMedia
//                                                 component="img"
//                                                 image={bar.store_image || "./default.png"}
//                                                 alt={bar.name}
//                                                 sx={{
//                                                     height: '300px',
//                                                     objectFit: 'cover'
//                                                 }}
//                                                 onError={(e) => {
//                                                     e.target.onerror = null;
//                                                     e.target.src = "./default.png";
//                                                     console.error(`Failed to load image for ${bar.name}: ${bar.store_image}`);
//                                                 }}
//                                             />
//                                             <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
//                                                 <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>{bar.name}</Typography>
//                                                 <Checkbox
//                                                     checked={formData.beenTo?.includes(bar.id) || false}
//                                                     onChange={() => handleVisitedChange(bar.id)}
//                                                 />
//                                             </Box>
//                                         </Card>
//                                     </ButtonBase>
//                                 </Grid>
//                             ))}
//                         </Grid>
//                         {displayBars.length < sortedBars.length && (
//                             <Box mt={2} display="flex" justifyContent="center">
//                                 <Button variant="contained" onClick={handleShowMore}>
//                                     {showMore ? "Show Less" : "Show More"}
//                                 </Button>
//                             </Box>
//                         )}
//                     </Box>
//                     <Box mt={2} display="flex" justifyContent="space-between">
//                         <Button variant="contained" color="primary" type="submit">
//                             Submit Profile
//                         </Button>
//                     </Box>
//                 </Box>
//             </Paper>
//             <Snackbar
//                 open={snackbarOpen}
//                 autoHideDuration={6000}
//                 onClose={() => setSnackbarOpen(false)}
//                 message={snackbarMessage}
//             />
//         </Container>
//     );
// };

// export default ProfileOnboardingPage;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField, Button, Container, Paper, Typography, Box, Avatar, MenuItem, Select, FormControl, InputLabel, Card, CardMedia, CardActionArea, CircularProgress, ListSubheader, Chip, Snackbar, Grid, Skeleton, IconButton, Fab
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Confetti from 'react-confetti';
import supabase from './supabaseClient';

const MAX_AVATAR_SIZE_MB = 50;

// Function to calculate distance between two coordinates
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3958.8; // Earth's radius in miles
    const rlat1 = lat1 * (Math.PI / 180);
    const rlat2 = lat2 * (Math.PI / 180);
    const difflat = rlat2 - rlat1;
    const difflon = (lon2 - lon1) * (Math.PI / 180);

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) ** 2 +
        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) ** 2));
};

const snarkyBios = [
    "Government Approved Non-Alcoholic Enthusiast 🍵",
    "Kava and Kratom: Nature's Mood Boosters 🌿",
    "Living the non-alcoholic life... like a rebel ✌️",
    "Keeping it chill with Kava, no regrets! 😎",
    "I got 99 problems, but Kratom ain't one 💪",
    "Pokémon trainer by day, Kava lover by night 🎮",
];

const ProfileOnboardingPage = ({ user, setUser, setHasProfile }) => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        username: '',
        full_name: '',
        bio: '',
        avatar_url: '',
        homebar: '',
        beenTo: [],
        bannerImage: '',
    });
    const [bars, setBars] = useState([]);
    const [sortedBars, setSortedBars] = useState([]);
    const [displayBars, setDisplayBars] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [showMore, setShowMore] = useState(false);
    const [confettiVisible, setConfettiVisible] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            if (!user || !user.email) return;

            try {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('email', user.email)
                    .single();

                if (error) {
                    console.error('Error fetching profile data:', error);
                } else {
                    setFormData({
                        username: data.username || '',
                        full_name: data.full_name || '',
                        bio: data.bio || '',
                        avatar_url: data.avatar_url || '',
                        homebar: data.homebar || '',
                        beenTo: data.beenTo || [],
                        bannerImage: data.bannerImage || '',
                    });
                }
            } catch (error) {
                console.error('Caught error in fetchProfileData:', error);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.email) {
            fetchProfileData();
        }
    }, [user]);

    useEffect(() => {
        const fetchBars = async () => {
            try {
                const { data, error } = await supabase
                    .from('bars')
                    .select('id, name, store_image, latitude, longitude, state, city');

                if (error) {
                    console.error('Error fetching bars:', error);
                } else {
                    const updatedBars = data.map(bar => ({
                        ...bar,
                        store_image: bar.store_image || 'default.png'
                    }));
                    setBars(updatedBars);
                }
            } catch (error) {
                console.error('Caught error in fetchBars:', error);
            }
        };

        fetchBars();
    }, []);

    useEffect(() => {
        const fetchLocation = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    setLoading(false);
                }
            );
        };

        fetchLocation();
    }, []);

    useEffect(() => {
        const sortBars = () => {
            if (userLocation && bars.length > 0) {
                const sorted = [...bars].sort((a, b) => {
                    const distanceA = calculateDistance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude);
                    const distanceB = calculateDistance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude);
                    return distanceA - distanceB;
                });
                setSortedBars(sorted);
                setDisplayBars(sorted.slice(0, 10));
                setFormData(prev => ({ ...prev, homebar: sorted[0]?.id || '' }));
            }
            setLoading(false);
        };

        if (userLocation && bars.length > 0) {
            sortBars();
        }
    }, [userLocation, bars]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleShowMore = () => {
        setShowMore(prevShowMore => !prevShowMore);
        setDisplayBars(prev => showMore ? sortedBars.slice(0, 10) : sortedBars);
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const MAX_FILE_SIZE_BYTES = MAX_AVATAR_SIZE_MB * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE_BYTES) {
            setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_AVATAR_SIZE_MB} MB.`);
            setSnackbarOpen(true);
            return;
        }

        try {
            setLoading(true);

            const fileExt = file.name.split('.').pop();
            const fileName = `${Date.now()}.${fileExt}`;
            const filePath = `avatars/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('avatars')
                .upload(filePath, file, { upsert: true });

            if (uploadError) {
                setSnackbarMessage('Error uploading image. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const { data: publicUrlData, error: urlError } = supabase.storage
                .from('avatars')
                .getPublicUrl(filePath);

            if (urlError) {
                setSnackbarMessage('Error retrieving image URL. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const publicUrl = publicUrlData.publicUrl;
            setFormData((prev) => ({ ...prev, avatar_url: publicUrl }));
            setSnackbarMessage('Image uploaded successfully!');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Unexpected error occurred during image upload.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleBannerUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const MAX_FILE_SIZE_BYTES = MAX_AVATAR_SIZE_MB * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE_BYTES) {
            setSnackbarMessage(`File is too large. Maximum allowed size is ${MAX_AVATAR_SIZE_MB} MB.`);
            setSnackbarOpen(true);
            return;
        }

        try {
            setLoading(true);

            const fileExt = file.name.split('.').pop();
            const fileName = `${Date.now()}.${fileExt}`;
            const filePath = `banners/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('banners')
                .upload(filePath, file, { upsert: true });

            if (uploadError) {
                setSnackbarMessage('Error uploading banner image. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const { data: publicUrlData, error: urlError } = await supabase.storage
                .from('banners')
                .getPublicUrl(filePath);

            if (urlError) {
                setSnackbarMessage('Error retrieving banner image URL. Please try again.');
                setSnackbarOpen(true);
                setLoading(false);
                return;
            }

            const publicUrl = publicUrlData.publicUrl;
            setFormData((prev) => ({ ...prev, bannerImage: publicUrl }));
            setSnackbarMessage('Banner image uploaded successfully!');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Unexpected error occurred during banner image upload.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const profileData = {
            id: user.id,
            email: user.email,
            ...formData,
            has_profile: true
        };

        try {
            const { error } = await supabase
                .from('profiles')
                .upsert(profileData, {
                    onConflict: ['email'],
                    returning: 'minimal',
                });

            if (error) {
                console.error('Error updating profile:', error);
                setSnackbarMessage('Error updating profile. Please try again.');
                setSnackbarOpen(true);
            } else {
                setUser({ ...user, ...profileData });
                setHasProfile(true);
                setConfettiVisible(true);
                setSnackbarMessage('Profile updated successfully! 🎉');
                setSnackbarOpen(true);
                navigate('/profile');
            }
        } catch (error) {
            console.error('Unexpected error during profile update:', error);
            setSnackbarMessage('An unexpected error occurred. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const renderBarsWithStates = () => {
        const filteredBars = sortedBars.filter(bar => {
            return (
                bar.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (!selectedState || bar.state === selectedState) &&
                (!selectedCity || bar.city === selectedCity)
            );
        });

        const barsByState = filteredBars.reduce((acc, bar) => {
            if (!acc[bar.state]) {
                acc[bar.state] = [];
            }
            acc[bar.state].push(bar);
            return acc;
        }, {});

        const stateLabels = Object.keys(barsByState).sort();

        return stateLabels.map(state => [
            <ListSubheader key={state}>{state}</ListSubheader>,
            ...barsByState[state].sort((a, b) => a.name.localeCompare(b.name)).map(bar => (
                <MenuItem key={bar.id} value={bar.id}>
                    <Box display="flex" alignItems="center">
                        <Avatar
                            src={bar.store_image || "default.png"}
                            alt={bar.name}
                            sx={{ width: 40, height: 40, marginRight: 1, borderRadius: '8px' }}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "default.png";
                            }}
                        />
                        <Typography variant="body1" sx={{ fontSize: 18 }}>{bar.name}</Typography>
                    </Box>
                </MenuItem>
            ))
        ]);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleVisitedChange = (barId) => {
        setFormData(prevState => {
            const beenTo = prevState.beenTo.includes(barId)
                ? prevState.beenTo.filter(id => id !== barId)
                : [...prevState.beenTo, barId];
            return { ...prevState, beenTo };
        });
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setSelectedCity(''); // Reset city when state changes
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress /><Typography>Loading...</Typography>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {Array.from(new Array(10)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
                                    <Skeleton variant="rectangular" width="100%" height={300} />
                                    <Box p={2}>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        );
    }

    const handleScrollDown = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleScrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Container maxWidth="xl">
            {confettiVisible && <Confetti />}
            <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
                <Box
                    component="img"
                    src={formData.bannerImage || "default-banner.png"}
                    alt="Banner"
                    sx={{ width: '100%', height: 200, objectFit: 'cover' }}
                />
                <Box display="flex" alignItems="center" justifyContent="center" my={2}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="banner-upload"
                        type="file"
                        onChange={handleBannerUpload}
                    />
                    <label htmlFor="banner-upload">
                        <IconButton component="span">
                            <PhotoCamera />
                        </IconButton>
                    </label>
                </Box>
                <Typography variant="h5">Create Your Profile</Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        required
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        label="Full Name"
                        variant="outlined"
                        margin="normal"
                        required
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        label="Bio"
                        variant="outlined"
                        margin="normal"
                        required
                        name="bio"
                        value={formData.bio}
                        onChange={handleInputChange}
                    />
                    <Box my={2}>
                        {snarkyBios.map((bio, index) => (
                            <Chip
                                key={index}
                                label={bio}
                                onClick={() => setFormData({ ...formData, bio })}
                                sx={{ margin: 0.5 }}
                            />
                        ))}
                    </Box>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="home-bar-label">Home Bar</InputLabel>
                        <Select
                            labelId="home-bar-label"
                            id="homebar"
                            name="homebar"
                            value={formData.homebar}
                            label="Home Bar"
                            onChange={handleInputChange}
                            sx={{ fontSize: 18 }}
                        >
                            {renderBarsWithStates()}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2 }}
                    >
                        Upload Avatar
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleImageUpload}
                        />
                    </Button>

                    {formData.avatar_url && (
                        <Box mt={2}>
                            <img src={formData.avatar_url} alt="Avatar preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        </Box>
                    )}
                    <Box mt={2}>
                        <Typography variant="h6">Visited Bars</Typography>
                        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                            <TextField
                                fullWidth
                                placeholder="Search bars..."
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{
                                    borderRadius: '25px',
                                    marginRight: 2,  // Adds space between search bar and filters
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '25px'
                                    }
                                }}
                            />
                            <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
                                <InputLabel>State</InputLabel>
                                <Select
                                    value={selectedState}
                                    onChange={handleStateChange}
                                >
                                    <MenuItem value=""><em>All States</em></MenuItem>
                                    {[...new Set(bars.map(bar => bar.state))].sort().map((state) => (
                                        <MenuItem key={state} value={state}>{state}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel>City</InputLabel>
                                <Select
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    disabled={!selectedState}
                                >
                                    <MenuItem value=""><em>All Cities</em></MenuItem>
                                    {selectedState &&
                                        [...new Set(bars.filter(bar => bar.state === selectedState).map(bar => bar.city))].sort().map((city) => (
                                            <MenuItem key={city} value={city}>{city}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Grid container spacing={2}>
                            {bars.filter(bar => {
                                const matchesState = selectedState ? bar.state === selectedState : true;
                                const matchesCity = selectedCity ? bar.city === selectedCity : true;
                                const matchesSearchTerm = bar.name.toLowerCase().includes(searchTerm.toLowerCase());

                                return matchesState && matchesCity && matchesSearchTerm;
                            }).sort((a, b) => {
                                if (!selectedState && !selectedCity) {
                                    const distanceA = calculateDistance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude);
                                    const distanceB = calculateDistance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude);
                                    return distanceA - distanceB;
                                }
                                return 0;
                            }).map((bar) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={bar.id}>
                                    <CardActionArea onClick={() => handleVisitedChange(bar.id)}>
                                        <Card sx={{
                                            borderRadius: '16px',
                                            overflow: 'hidden',
                                            boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                                            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                            '&:hover': {
                                                boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                                            }
                                        }}>
                                            <CardMedia
                                                component="img"
                                                image={bar.store_image || "./default.png"}
                                                alt={bar.name}
                                                sx={{
                                                    height: '300px',
                                                    objectFit: 'cover'
                                                }}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "./default.png";
                                                }}
                                            />
                                            <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                    {bar.name}
                                                </Typography>
                                                {formData.beenTo.includes(bar.id) && (
                                                    <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                                                        Visited
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Card>
                                    </CardActionArea>
                                </Grid>
                            ))}
                        </Grid>
                        {displayBars.length < sortedBars.length && (
                            <Box mt={2} display="flex" justifyContent="center">
                                <Button variant="contained" onClick={handleShowMore}>
                                    {showMore ? "Show Less" : "Show More"}
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button variant="contained" color="primary" type="submit">
                            Submit Profile
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Fab
                color="secondary"
                aria-label="scroll up"
                onClick={handleScrollUp}
                sx={{ position: 'fixed', bottom: 80, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
            <Fab
                color="primary"
                aria-label="scroll down"
                onClick={handleScrollDown}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowDownIcon />
            </Fab>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default ProfileOnboardingPage;

















