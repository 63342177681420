import React, { useState } from 'react';

const states = {
    AL: ["Birmingham", "Montgomery", "Mobile", "Huntsville", "Tuscaloosa", "Hoover", "Dothan", "Auburn", "Decatur", "Madison", "Florence", "Gadsden", "Vestavia Hills", "Prattville", "Phenix City", "Alabaster", "Bessemer", "Enterprise", "Opelika", "Homewood", "Northport", "Anniston", "Prichard", "Athens", "Daphne", "Other"],
    AK: ["Anchorage", "Fairbanks", "Juneau", "Wasilla", "Sitka", "Kenai", "Ketchikan", "Bethel", "Palmer", "Homer", "Nome", "Barrow", "Valdez", "Kodiak", "Seward", "Dillingham", "Cordova", "Unalaska", "North Pole", "Soldotna", "Kotzebue", "Wrangell", "Naknek", "Petersburg", "Other"],
    AZ: ["Phoenix", "Tucson", "Mesa", "Chandler", "Glendale", "Scottsdale", "Gilbert", "Tempe", "Peoria", "Surprise", "Yuma", "Avondale", "Flagstaff", "Goodyear", "Lake Havasu City", "Buckeye", "Casa Grande", "Sierra Vista", "Maricopa", "Oro Valley", "Prescott", "Bullhead City", "Prescott Valley", "Apache Junction", "Other"],
    AR: ["Little Rock", "Fayetteville", "Fort Smith", "Springdale", "Jonesboro", "North Little Rock", "Conway", "Rogers", "Bentonville", "Hot Springs", "Pine Bluff", "Benton", "Texarkana", "Sherwood", "Jacksonville", "Russellville", "Paragould", "Cabot", "Searcy", "Van Buren", "El Dorado", "Bella Vista", "Maumelle", "Bryant", "Other"],
    CA: ["Los Angeles", "San Francisco", "San Diego", "San Jose", "Fresno", "Sacramento", "Long Beach", "Oakland", "Bakersfield", "Anaheim", "Santa Ana", "Riverside", "Stockton", "Irvine", "Chula Vista", "Fremont", "San Bernardino", "Modesto", "Fontana", "Oxnard", "Moreno Valley", "Glendale", "Huntington Beach", "Santa Clarita", "Other"],
    CO: ["Denver", "Colorado Springs", "Aurora", "Fort Collins", "Lakewood", "Thornton", "Arvada", "Westminster", "Pueblo", "Centennial", "Boulder", "Greeley", "Longmont", "Loveland", "Broomfield", "Grand Junction", "Castle Rock", "Commerce City", "Parker", "Littleton", "Northglenn", "Brighton", "Englewood", "Wheat Ridge", "Other"],
    CT: ["Bridgeport", "New Haven", "Stamford", "Hartford", "Waterbury", "Norwalk", "Danbury", "New Britain", "Bristol", "Meriden", "Milford", "West Haven", "Middletown", "Norwich", "Shelton", "Torrington", "New London", "Trumbull", "Naugatuck", "Manchester", "East Hartford", "West Hartford", "Stratford", "Farmington", "Other"],
    DE: ["Wilmington", "Dover", "Newark", "Middletown", "Smyrna", "Milford", "Seaford", "Georgetown", "Elsmere", "New Castle", "Millsboro", "Laurel", "Harrington", "Camden", "Clayton", "Lewes", "Rehoboth Beach", "Bridgeville", "Ocean View", "Delmar", "Selbyville", "Townsend", "Milton", "Bear", "Other"],
    FL: ["Jacksonville", "Miami", "Tampa", "Orlando", "St. Petersburg", "Hialeah", "Tallahassee", "Fort Lauderdale", "Port St. Lucie", "Cape Coral", "Pembroke Pines", "Hollywood", "Miramar", "Gainesville", "Coral Springs", "Clearwater", "Palm Bay", "Pompano Beach", "West Palm Beach", "Lakeland", "Davie", "Miami Beach", "Sunrise", "Plantation", "Other"],
    GA: ["Atlanta", "Augusta", "Columbus", "Macon", "Savannah", "Athens", "Sandy Springs", "Roswell", "Johns Creek", "Albany", "Warner Robins", "Valdosta", "Norcross", "Gainesville", "Douglasville", "Lawrenceville", "Duluth", "Kennesaw", "LaGrange", "Statesboro", "Newnan", "Buford", "Cartersville", "Stockbridge", "Other"],
    HI: ["Honolulu", "Hilo", "Kailua", "Kapolei", "Kaneohe", "Waipahu", "Pearl City", "Waimalu", "Mililani Town", "Kahului", "Kihei", "Lahaina", "Makakilo City", "Ewa Gentry", "Schofield Barracks", "Wahiawa", "Halawa", "Waianae", "Kailua-Kona", "Waipio", "Nanakuli", "Lihue", "Kapaa", "Other"],
    ID: ["Boise", "Meridian", "Nampa", "Idaho Falls", "Pocatello", "Caldwell", "Coeur d'Alene", "Twin Falls", "Lewiston", "Post Falls", "Rexburg", "Moscow", "Eagle", "Kuna", "Ammon", "Chubbuck", "Hayden", "Mountain Home", "Blackfoot", "Garden City", "Jerome", "Burley", "Sunnyside", "Other"],
    IL: ["Chicago", "Aurora", "Rockford", "Joliet", "Naperville", "Springfield", "Peoria", "Elgin", "Waukegan", "Cicero", "Champaign", "Bloomington", "Decatur", "Evanston", "Des Plaines", "Berwyn", "Wheaton", "Belleville", "Elmhurst", "DeKalb", "Moline", "Crystal Lake", "Quincy", "Plainfield", "Other"],
    IN: ["Indianapolis", "Fort Wayne", "Evansville", "South Bend", "Carmel", "Fishers", "Bloomington", "Hammond", "Gary", "Muncie", "Lafayette", "Terre Haute", "Kokomo", "Anderson", "Noblesville", "Greenwood", "Elkhart", "Mishawaka", "Lawrence", "Jeffersonville", "Columbus", "Portage", "New Albany", "Richmond", "Other"],
    IA: ["Des Moines", "Cedar Rapids", "Davenport", "Sioux City", "Iowa City", "Waterloo", "Council Bluffs", "Ames", "West Des Moines", "Dubuque", "Ankeny", "Urbandale", "Cedar Falls", "Marion", "Bettendorf", "Mason City", "Marshalltown", "Clinton", "Burlington", "Ottumwa", "Fort Dodge", "Muscatine", "Coralville", "Johnston", "Other"],
    KS: ["Wichita", "Overland Park", "Kansas City", "Topeka", "Olathe", "Lawrence", "Shawnee", "Manhattan", "Lenexa", "Salina", "Hutchinson", "Leavenworth", "Leawood", "Dodge City", "Emporia", "Garden City", "Junction City", "Derby", "Prairie Village", "Hays", "Liberal", "Pittsburg", "Newton", "Gardner", "Other"],
    KY: ["Louisville", "Lexington", "Bowling Green", "Owensboro", "Covington", "Hopkinsville", "Frankfort", "Henderson", "Richmond", "Jeffersontown", "Paducah", "Pleasure Ridge Park", "Florence", "Valley Station", "Elizabethtown", "Ashland", "Radcliff", "Newburg", "Georgetown", "Fern Creek", "Madisonville", "St. Matthews", "Erlanger", "Shively", "Other"],
    LA: ["New Orleans", "Baton Rouge", "Shreveport", "Lafayette", "Lake Charles", "Kenner", "Bossier City", "Monroe", "Alexandria", "Houma", "Marrero", "New Iberia", "Laplace", "Slidell", "Terrytown", "Opelousas", "Harvey", "Ruston", "Sulphur", "Natchitoches", "Hammond", "Gretna", "Chalmette", "Estelle", "Other"],
    ME: ["Portland", "Lewiston", "Bangor", "South Portland", "Auburn", "Biddeford", "Augusta", "Saco", "Westbrook", "Waterville", "Presque Isle", "Brewer", "Bath", "Caribou", "Old Town", "Rockland", "Ellsworth", "Belfast", "Gardiner", "Calais", "Hallowell", "Eastport", "Pittsfield", "Winslow", "Other"],
    MD: ["Baltimore", "Annapolis", "Frederick", "Rockville", "Gaithersburg", "Bowie", "Hagerstown", "College Park", "Salisbury", "Laurel", "Greenbelt", "Cumberland", "Westminster", "Hyattsville", "Takoma Park", "Easton", "Elkton", "Aberdeen", "Havre de Grace", "Cambridge", "New Carrollton", "Bel Air", "Bladensburg", "Mount Airy", "Other"],
    MA: ["Boston", "Worcester", "Springfield", "Lowell", "Cambridge", "New Bedford", "Brockton", "Quincy", "Lynn", "Fall River", "Newton", "Lawrence", "Somerville", "Framingham", "Haverhill", "Waltham", "Malden", "Brookline", "Plymouth", "Medford", "Taunton", "Chicopee", "Weymouth", "Revere", "Other"],
    MI: ["Detroit", "Grand Rapids", "Warren", "Sterling Heights", "Lansing", "Ann Arbor", "Flint", "Dearborn", "Livonia", "Troy", "Farmington Hills", "Westland", "Kalamazoo", "Wyoming", "Southfield", "Rochester Hills", "Taylor", "Pontiac", "St. Clair Shores", "Royal Oak", "Novi", "Dearborn Heights", "Battle Creek", "Other"],
    MN: ["Minneapolis", "Saint Paul", "Rochester", "Duluth", "Bloomington", "Brooklyn Park", "Plymouth", "Maple Grove", "Woodbury", "St. Cloud", "Eagan", "Eden Prairie", "Coon Rapids", "Blaine", "Burnsville", "Lakeville", "Minnetonka", "Apple Valley", "Edina", "St. Louis Park", "Moorhead", "Mankato", "Shakopee", "Cottage Grove", "Other"],
    MS: ["Jackson", "Gulfport", "Biloxi", "Hattiesburg", "Southaven", "Vicksburg", "Meridian", "Pascagoula", "Tupelo", "Olive Branch", "Clinton", "Pearl", "Starkville", "Columbus", "Brandon", "Ridgeland", "Laurel", "Clarksdale", "Madison", "Oxford", "Ocean Springs", "Natchez", "Greenwood", "Gautier", "Other"],
    MO: ["Kansas City", "Saint Louis", "Springfield", "Columbia", "Independence", "Lee's Summit", "O'Fallon", "Saint Joseph", "Saint Charles", "Saint Peters", "Blue Springs", "Florissant", "Joplin", "Chesterfield", "Jefferson City", "Cape Girardeau", "Wentzville", "Ballwin", "Liberty", "Maryland Heights", "Raytown", "Kirkwood", "Gladstone", "Hazelwood", "Other"],
    MT: ["Billings", "Missoula", "Bozeman", "Great Falls", "Butte", "Helena", "Kalispell", "Havre", "Anaconda", "Miles City", "Belgrade", "Livingston", "Laurel", "Whitefish", "Lewistown", "Sidney", "Glendive", "Columbia Falls", "Polson", "Hamilton", "Dillon", "Hardin", "Glasgow", "Cut Bank", "Other"],
    NE: ["Omaha", "Lincoln", "Bellevue", "Grand Island", "Kearney", "Fremont", "Hastings", "Norfolk", "North Platte", "Columbus", "Papillion", "La Vista", "Scottsbluff", "South Sioux City", "Beatrice", "Lexington", "Alliance", "Gering", "Blair", "York", "McCook", "Nebraska City", "Seward", "Crete", "Other"],
    NV: ["Las Vegas", "Reno", "Henderson", "North Las Vegas", "Sparks", "Carson City", "Fernley", "Elko", "Mesquite", "Boulder City", "Fallon", "Winnemucca", "West Wendover", "Ely", "Yerington", "Hawthorne", "Lovelock", "Tonopah", "Stateline", "Eureka", "Wells", "Caliente", "Pioche", "Virginia City", "Other"],
    NH: ["Manchester", "Nashua", "Concord", "Derry", "Dover", "Rochester", "Salem", "Merrimack", "Hudson", "Londonderry", "Keene", "Bedford", "Portsmouth", "Goffstown", "Laconia", "Windham", "Claremont", "Lebanon", "Pelham", "Somersworth", "Amherst", "Hanover", "Durham", "Exeter", "Other"],
    NJ: ["Newark", "Jersey City", "Paterson", "Elizabeth", "Edison", "Woodbridge", "Lakewood", "Toms River", "Hamilton", "Trenton", "Clifton", "Camden", "Brick", "Cherry Hill", "Passaic", "Middletown", "Union City", "Old Bridge", "Gloucester", "Bayonne", "Franklin", "East Orange", "Vineland", "Union", "Other"],
    NM: ["Albuquerque", "Santa Fe", "Las Cruces", "Rio Rancho", "Roswell", "Farmington", "Clovis", "Hobbs", "Alamogordo", "Carlsbad", "Gallup", "Deming", "Los Lunas", "Chaparral", "Sunland Park", "Las Vegas", "Portales", "Artesia", "Lovington", "Silver City", "Espanola", "Bernalillo", "Ruidoso", "Socorro", "Other"],
    NY: ["New York City", "Buffalo", "Rochester", "Yonkers", "Syracuse", "Albany", "New Rochelle", "Mount Vernon", "Schenectady", "Utica", "White Plains", "Hempstead", "Troy", "Niagara Falls", "Binghamton", "Freeport", "Valley Stream", "Long Beach", "Rome", "North Tonawanda", "Ithaca", "Poughkeepsie", "Jamestown", "Elmira", "Other"],
    NC: ["Charlotte", "Raleigh", "Greensboro", "Durham", "Winston-Salem", "Fayetteville", "Cary", "Wilmington", "High Point", "Concord", "Greenville", "Asheville", "Jacksonville", "Gastonia", "Chapel Hill", "Rocky Mount", "Burlington", "Wilson", "Huntersville", "Kannapolis", "Apex", "Hickory", "Goldboro", "Indian Trail", "Other"],
    ND: ["Fargo", "Bismarck", "Grand Forks", "Minot", "West Fargo", "Williston", "Dickinson", "Mandan", "Jamestown", "Wahpeton", "Devils Lake", "Valley City", "Grafton", "Beulah", "Rugby", "Horace", "Belcourt", "Lincoln", "Stanley", "Watford City", "New Town", "Lisbon", "Mayville", "Carrington", "Other"],
    OH: ["Columbus", "Cleveland", "Cincinnati", "Toledo", "Akron", "Dayton", "Parma", "Canton", "Youngstown", "Lorain", "Hamilton", "Springfield", "Kettering", "Elyria", "Lakewood", "Cuyahoga Falls", "Middletown", "Euclid", "Newark", "Mansfield", "Mentor", "Beavercreek", "Cleveland Heights", "Strongsville", "Other"],
    OK: ["Oklahoma City", "Tulsa", "Norman", "Broken Arrow", "Edmond", "Lawton", "Moore", "Midwest City", "Stillwater", "Enid", "Muskogee", "Owasso", "Bartlesville", "Shawnee", "Yukon", "Ardmore", "Ponca City", "Bixby", "Duncan", "Del City", "Jenks", "Mustang", "Sapulpa", "Sand Springs", "Other"],
    OR: ["Portland", "Eugene", "Salem", "Gresham", "Hillsboro", "Beaverton", "Bend", "Medford", "Springfield", "Corvallis", "Albany", "Tigard", "Lake Oswego", "Keizer", "Grants Pass", "Oregon City", "McMinnville", "Redmond", "Tualatin", "West Linn", "Woodburn", "Forest Grove", "Newberg", "Wilsonville", "Other"],
    PA: ["Philadelphia", "Pittsburgh", "Allentown", "Erie", "Reading", "Scranton", "Bethlehem", "Lancaster", "Levittown", "Harrisburg", "Altoona", "York", "State College", "Wilkes-Barre", "Norristown", "Chester", "Bethel Park", "Williamsport", "Monroeville", "Drexel Hill", "Plum", "Easton", "Lebanon", "Hazleton", "Other"],
    RI: ["Providence", "Warwick", "Cranston", "Pawtucket", "East Providence", "Woonsocket", "Coventry", "North Providence", "Cumberland", "West Warwick", "Johnston", "North Kingstown", "Newport", "Bristol", "Westerly", "Smithfield", "Central Falls", "Portsmouth", "Barrington", "Middletown", "Tiverton", "Narragansett", "East Greenwich", "North Smithfield", "Other"],
    SC: ["Columbia", "Charleston", "North Charleston", "Mount Pleasant", "Rock Hill", "Greenville", "Summerville", "Sumter", "Hilton Head Island", "Florence", "Spartanburg", "Goose Creek", "Aiken", "Myrtle Beach", "Anderson", "Greer", "Greenwood", "Mauldin", "North Augusta", "Easley", "Simpsonville", "Hanahan", "Lexington", "Conway", "Other"],
    SD: ["Sioux Falls", "Rapid City", "Aberdeen", "Brookings", "Watertown", "Mitchell", "Yankton", "Pierre", "Huron", "Spearfish", "Vermillion", "Brandon", "Madison", "Sturgis", "Belle Fourche", "Harrisburg", "Tea", "Hot Springs", "Milbank", "Mobridge", "Lead", "Box Elder", "Lennox", "Canton", "Other"],
    TN: ["Nashville", "Memphis", "Knoxville", "Chattanooga", "Clarksville", "Murfreesboro", "Franklin", "Jackson", "Johnson City", "Bartlett", "Hendersonville", "Kingsport", "Collierville", "Cleveland", "Smyrna", "Germantown", "Brentwood", "Columbia", "Spring Hill", "La Vergne", "Gallatin", "Mount Juliet", "Cookeville", "Morristown", "Other"],
    TX: ["Houston", "San Antonio", "Dallas", "Austin", "Fort Worth", "El Paso", "Arlington", "Corpus Christi", "Plano", "Laredo", "Lubbock", "Garland", "Irving", "Amarillo", "Grand Prairie", "McKinney", "Frisco", "Brownsville", "Pasadena", "Mesquite", "Killeen", "McAllen", "Carrollton", "Midland", "Other"],
    UT: ["Salt Lake City", "West Valley City", "Provo", "West Jordan", "Orem", "Sandy", "Ogden", "St. George", "Layton", "South Jordan", "Lehi", "Millcreek", "Taylorsville", "Logan", "Murray", "Draper", "Bountiful", "Riverton", "Roy", "Cottonwood Heights", "Pleasant Grove", "Spanish Fork", "Tooele", "Cedar City", "Other"],
    VT: ["Burlington", "South Burlington", "Rutland", "Essex Junction", "Barre", "Montpelier", "St. Albans", "Winooski", "Newport", "Northfield", "Morrisville", "Bellows Falls", "Swanton", "Middlebury", "Waterbury", "Bennington", "Lyndonville", "Derby Center", "Manchester Center", "St. Johnsbury", "Randolph", "Williston", "Springfield", "Poultney", "Other"],
    VA: ["Virginia Beach", "Norfolk", "Chesapeake", "Richmond", "Newport News", "Alexandria", "Hampton", "Roanoke", "Portsmouth", "Suffolk", "Lynchburg", "Harrisonburg", "Leesburg", "Charlottesville", "Blacksburg", "Danville", "Manassas", "Petersburg", "Fredericksburg", "Winchester", "Salem", "Herndon", "Staunton", "Fairfax", "Other"],
    WA: ["Seattle", "Spokane", "Tacoma", "Vancouver", "Bellevue", "Kent", "Everett", "Renton", "Yakima", "Federal Way", "Spokane Valley", "Bellingham", "Kennewick", "Auburn", "Pasco", "Marysville", "Lakewood", "Redmond", "Shoreline", "Richland", "Kirkland", "Burien", "Sammamish", "Olympia", "Other"],
    WV: ["Charleston", "Huntington", "Morgantown", "Parkersburg", "Wheeling", "Weirton", "Fairmont", "Martinsburg", "Beckley", "Clarksburg", "South Charleston", "St. Albans", "Vienna", "Bluefield", "Moundsville", "Bridgeport", "Oak Hill", "Dunbar", "Elkins", "Nitro", "Hurricane", "Princeton", "Charles Town", "Buckhannon", "Other"],
    WI: ["Milwaukee", "Madison", "Green Bay", "Kenosha", "Racine", "Appleton", "Waukesha", "Eau Claire", "Oshkosh", "Janesville", "West Allis", "La Crosse", "Sheboygan", "Wauwatosa", "Fond du Lac", "New Berlin", "Wausau", "Brookfield", "Greenfield", "Beloit", "Menomonee Falls", "Franklin", "Oak Creek", "Manitowoc", "Other"],
    WY: ["Cheyenne", "Casper", "Laramie", "Gillette", "Rock Springs", "Sheridan", "Green River", "Evanston", "Riverton", "Jackson", "Cody", "Rawlins", "Lander", "Torrington", "Powell", "Douglas", "Worland", "Buffalo", "Wheatland", "Newcastle", "Thermopolis", "Kemmerer", "Glenrock", "Mills", "Other"]
  };

const InputModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    state: '',
    city: '',
    rating: '',
    about: '',
    contact: '',
    website: '',
    hours: '',
    status: '',
    photo: null,
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, city: '' });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-4 w-full max-w-sm overflow-y-auto" style={{ maxHeight: '80vh' }}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Add New Kava Bar</h2>
          <img src="/default.png" alt="Logo" className="w-10 h-10" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
              pattern="^[#.0-9a-zA-Z\s,-]+$"
              title="Enter a valid address"
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">State</label>
            <select
              name="state"
              value={formData.state}
              onChange={handleStateChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            >
              <option value="">Select State</option>
              {Object.keys(states).map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">City</label>
            <select
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
              disabled={!formData.state}
            >
              <option value="">Select City</option>
              {formData.state &&
                states[formData.state].map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Rating</label>
            <input
              type="number"
              name="rating"
              step="0.1"
              min="0"
              max="5"
              value={formData.rating}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">About</label>
            <textarea
              name="about"
              value={formData.about}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Contact</label>
            <input
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
              pattern="^\d{3}-\d{3}-\d{4}$"
              title="Enter a valid phone number (e.g., 123-456-7890)"
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Website</label>
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Hours</label>
            <input
              type="text"
              name="hours"
              value={formData.hours}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
              pattern="^\[.*\]$"
              title="Enter hours in the format: ['Monday: 11:00 AM – 8:00 PM', 'Tuesday: 11:00 AM – 8:00 PM']"
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Status</label>
            <input
              type="text"
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-xs font-medium mb-1">Photo</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full px-3 py-2 border rounded text-sm"
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full mr-2"
            >
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-full">
              Submit
            </button>
            {/* <button
              type="button"
              className="px-4 py-2 bg-green-500 text-white rounded-full"
              onClick={() => alert('Coming soon!')}
            >
              Restaurant Owner Check
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default InputModal;
